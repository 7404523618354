<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <div class="loading" v-if="$apollo.queries.rashodOrder.loading"></div>
        <section class="section" v-if="rashodOrder">
            <font-awesome-icon icon="arrow-left" id="backbutton" @click="$router.go(-1)"/>
            <h1>Накладная {{order_id}}</h1>
            <p>Получатель: {{rashodOrder.agentName}}</p>
            <p style="margin-bottom: 1.2rem;">Дата: {{rashodOrder.date}}</p>
            <div class="field">
                <div class="control">
                    <label class="label">
                        <input class="checkbox" type="checkbox" v-model="rashodOrder.nocrossout" @change="change_nocrossout">
                        Без вычерков
                    </label>
                </div>
            </div>

            <div class="field">
                <div class="control"
                        :class="{'loading': image_uploading}"
                >
                    <input type="file" accept="image/*" id="file-input-0" class="input" v-on:change="uploadFromCamera" multiple>
                </div>
                <div class="control">
                    <button :disabled="!rashodOrder.img"
                        class="button"
                        @click="removeImg"
                        v-if="rashodOrder.img"
                        style="margin-bottom: .8rem; display: block;">
                        Удалить фото
                    </button>
                </div>
            </div>
            <img
                v-for="img in rashodOrder.images"
                :key="img.id"
                :src="'/' + img.img"
                class="img"
                :class="{'selected': img_selected}"
                @xclick="imgclick"
                data-zoomable
                >
            <div>
                <table class="report doc_income" v-if="rashodOrder">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Наименование</th>
                            <th>Кол-во</th>
                            <th>Цена</th>
                            <th>Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(pos, index) in rashodOrder.positions" class="hover" :key="pos.id">
                            <td>{{index+1}}</td>
                            <td>{{pos.name}}</td>
                            <td class="amount">{{pos.amount}}</td>
                            <td class="price">{{pos.price|currency}}</td>
                            <td class="total">{{pos.total|currency}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5" style="text-align: right; font-size: 110%; font-weight: bold;">
                                Итого: <span v-if="rashodOrder">{{rashodOrder.total|currency}}</span>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"
    import UploadFromCamera from "@/components/UploadFromCamera"
    import mediumZoom from 'medium-zoom'

    export default {

        name: 'RashodOrder',
        components: {
        },
        mixins: [UploadFromCamera],
        props: [
            'order_id', 'user',
        ],
        updated(){
            mediumZoom('[data-zoomable]');
            console.log('updated');
        },
        methods: {

            addCustomData(data){
                data.append('order_id', this.order_id);
            },
            afterUploading(){
                document.getElementById("file-input-0").value="";
                this.$apollo.queries.rashodOrder.refetch();
            },
            change_nocrossout(){
                console.log(this.rashodOrder.nocrossout);
                this.$apollo.mutate({
                    mutation: gql`mutation($code: String!, $nocrossout: Boolean!) {
                        markDocoutNoCrossOut(code: $code, nocrossout: $nocrossout) { newvalue }
                    }`,
                    variables: {
                        "code": this.order_id,
                        "nocrossout": this.rashodOrder.nocrossout,
                    },
                }).then(data => {
                    this.error=null;
                }).catch(error => {
                    this.error=error;
                });
            },
            removeImg(){
                this.$apollo.mutate({
                    mutation: gql`mutation($code: String!) {
                        removeNaklPhoto(code: $code) { ok }
                    }`,
                    variables: {
                        "code": this.order_id,
                    },
                }).then(data => {
                    document.getElementById("file-input").value="";
                    this.$apollo.queries.rashodOrder.refetch();
                    this.error=null;
                }).catch(error => {
                    this.error=error;
                });
            },
            toggle_select() {
                this.img_selected = !this.img_selected;
            },
            imgclick(ev){
                //ev.target.classList.toggle("zoomed");
                //const zoom = mediumZoom(ev.target);
                //zoom.toggle();
                console.log("imgclick");
            },
        },
        data(){
            return {
                img_selected: false,
            }
        },
        apollo: {
            rashodOrder: {
                query: gql`
                    query($code: String!){
                        rashodOrder(code: $code) {
                            date
                            agentName
                            total
                            nocrossout
                            images{id img}
                            positions {id name amount price total}
                        }
                    }`,
                variables() {
                    return {
                        code: this.order_id,
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.doc_income{
        td.amount, td.price, td.total {
            text-align: right;
        }
    }
    img.img {
        max-height: 100px;
        cursor: pointer;
        margin: 10px 10px 0 0;
    }
    img.zoomed {
        max-height: 1200px !important;
    }
    .img.selected {
        border: 3px solid blue;
    }
    #backbutton {
        width: 32px;
        height: 32px;
        margin-bottom: 1.2rem;
        cursor: pointer;
    }
</style>

