<template>
    <div v-if="user">
        <div class="container" v-if="true ||checkGroups(user, ['Расход просмотр'])">
            <loading v-if="$apollo.queries.outgoList.loading"></loading>
            <h1 class="title" @click="clear()">Расходные документы</h1>
            <div class="row">
                <div class="p-1 col-12 col-sm-7 col-lg-4"><date-range-piker v-model="state.dateRange" @input="inputChange"/></div>
                <hosts-select class="p-1 col-12 col-sm-6 col-lg-4" v-model="state.hostId" emit-value="code" @input="inputChange" :user="user"/>
                <sklad-select class="p-1 col-12 col-sm-6 col-lg-4" v-model="state.skladId" emit-value="code" @input="inputChange" :user="user"/>
                <agent-search-select class="p-1 col-12 col-sm-6 col-lg-4" v-model="state.agent" @input="inputChange"/>
                <div class="p-1 col-12 col-sm-6 col-lg-4 row">
                    <reasons-select  class="p-1 col-6" v-model="state.reasonId" @input="inputChange" :user="user"/>
                    <div class="p-1 col-6">
                        <label class="label">Признак</label>
                        <v-select :options="[{code:null, label: '-'}].concat(kinds)" v-if="kinds" v-model="kind"></v-select>
                    </div>
                </div>
                <answers-select class="p-1 col-12 col-md-6 col-lg-4" v-model="state.answerId" emit-value="code" @input="inputChange" :user="user"/>
            </div>
            <div>
                <button
                        style="float:right;margin: 5px;"
                        class="button is-primary submit"
                        :class="{'is-loading':$apollo.queries.outgoList.loading}"
                        :disabled="$apollo.queries.outgoList.loading"
                        @click="submit">Отобразить</button>
            </div>

            <table v-if="outgoList" class="mt-5 table is-striped is-fullwidth" :class="{'loading': $apollo.queries.outgoList.loading}" >
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Дата</th>
                        <th>Клиент</th>
                        <th>Склад</th>
                        <th style="text-align: center;">Сумма</th>
                        <th style="text-align: center;">Оплата</th>
                        <th style="text-align: center;">В ценах прихода</th>
                        <th style="text-align: center;">Наценка</th>
                        <th style="text-align: center;">Прибыль</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in outgoList" :key="i.id" class="hover" :class="rowClass(i)">
                        <td>{{i.id}}</td>
                        <td>{{i.date|date-ddmmyy}}</td>
                        <td>
                            <router-link :to="'/outgo/' + i.id">{{i.agent.name}}</router-link>
                            <div v-if="i.description" class="description">{{i.description}}</div>
                        </td>
                        <td>{{i.sklad.name}}</td>
                        <td class="currency outgo-sum">{{outgoSum(i.outgoposSet)|currency}}</td>
                        <td class="currency trans-sum">{{paymentSum(i.transSet)|currency}}</td>
                        <td class="currency">{{incomeSum(i.outgoposSet)|currency}}</td>
                        <td class="currency">{{((outgoSum(i.outgoposSet)/incomeSum(i.outgoposSet)-1)*100).toFixed(1)}}%</td>
                        <td class="currency">{{outgoSum(i.outgoposSet)-incomeSum(i.outgoposSet)|currency}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr style="font-weight: bold;" v-if="outgoList.length">
                        <td colspan="4"></td>
                        <td colpsan="2" class="currency">Итого: {{total|currency}}</td>
                        <td></td>
                        <td class="currency">{{incomeTotalSum()|currency}}</td>
                        <td class="currency">{{((total/incomeTotalSum()-1)*100).toFixed(1)}}%</td>
                        <td class="currency">{{total-incomeTotalSum()|currency}}</td>
                    </tr>
                    <div v-else>Нет элементов для отображения</div>
                </tfoot>
            </table>
        </div>
        <section class="container section" v-else>
            Недостаточно прав для данного раздела
        </section>
    </div>
</template>

<script>
import gql from "graphql-tag"
import InOutListMixin from './InOutListMixin.vue'
import { formatDate } from '../common/FormControls'

export default {
    mixins: [InOutListMixin],
    computed: {
        total: function () {
            if (this.outgoList)
                return this.outgoList.reduce((accum, e) => accum + this.outgoSum(e.outgoposSet), 0)
            return null;
        }
    },
    data(){
        return {
            kind: null,
            skip: true,
        }
    },
    beforeCreate() {
        this.storage.name = 'OutgoList';
    },
    methods: {
        incomeSum(i) {
            return i.reduce((accum, e) => accum + e.amount * e.incomepos.price, 0);
        },
        rowClass(i) {
            let outgoSum = this.outgoSum(i.outgoposSet);
            let paymentSum = this.paymentSum(i.transSet);
            if (outgoSum == paymentSum)
                return 'completely';
            else if (paymentSum == 0 && outgoSum > 0)
                return 'zero';
            else if (outgoSum > paymentSum)
                return 'partly';
            else if (outgoSum < paymentSum)
                return 'overly';
        },
        incomeTotalSum() {
            if (this.outgoList)
                return this.outgoList.reduce((accum, e) => accum + this.incomeSum(e.outgoposSet), 0)
        },
        submit(){
            this.skip=false;
            this.$apollo.queries.outgoList.refetch();
        },
    },
    apollo: {
        outgoList: {
            query: gql`
                query($dateFrom: Date, $dateTo: Date, $answerId: ID, $reasonId: ID, $skladId: ID, $agentId: ID,$hostId: ID, $kindId: ID){
                    outgoList(dateFrom: $dateFrom, dateTo: $dateTo, answerId: $answerId, reasonId: $reasonId,
                        skladId: $skladId, agentId: $agentId, hostId: $hostId, kindId: $kindId) {
                        id date createDt agent{id name} sklad{id name} answer{id name}
                        outgoposSet{amount price discount incomepos{price}}
                        transSet{amount}
                        description
                    }
                }`,
            skip() {
                return !this.user || this.skip;
            },
            variables() {
                return {
                    dateFrom: formatDate(this.state.dateRange.from, 'yyyy-MM-dd'),
                    dateTo: formatDate(this.state.dateRange.to, 'yyyy-MM-dd'),
                    answerId: this.state.answerId,
                    reasonId: this.state.reasonId,
                    skladId: this.state.skladId,
                    hostId: this.state.hostId,
                    agentId: this.state.agent? this.state.agent.id : null,
                    kindId: this.kind?this.kind.code:null,
                }
            },
            result ( data) {
                this.skip = true;
            },
            errorPolicy: 'all',
            error(error) {
                this.$emit('error', JSON.stringify(error.message));
            },
        },
        kinds: {
            query: gql`
                query{
                    kinds: prop(typeId: 1){
                        code: id label:name
                    }
                }`,
            /*variables() {
                return {
                    id: this.id,
                }
            },*/
            result ({data, loading}) {
                //if (!loading) { this.kindsLoaded(data.kinds); }
            },
            errorPolicy: 'all',
            error(error) {
                this.$emit('error', error);
            },
        },
    },
}
</script>

<style lang="scss" scoped>
    .description{
        font-size: 80%;
    }
    .zero{
        .outgo-sum, .trans-sum{
            color: red;
        }
    }
    .partly{
        .outgo-sum, .trans-sum{
            color: blue;
        }
    }
    .completely{
        .outgo-sum, .trans-sum{
            color: #000;
        }
    }
    .overly{
        .outgo-sum, .trans-sum{
            color: green;
        }
    }

</style>