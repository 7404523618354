import { render, staticRenderFns } from "./TuListItem.vue?vue&type=template&id=52c8f979&scoped=true&"
import script from "./TuListItem.vue?vue&type=script&lang=js&"
export * from "./TuListItem.vue?vue&type=script&lang=js&"
import style0 from "./TuListItem.vue?vue&type=style&index=0&id=52c8f979&prod&lang=scss&scoped=true&"
import style1 from "./TuListItem.vue?vue&type=style&index=1&id=52c8f979&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c8f979",
  null
  
)

export default component.exports