<template>
    <div class="container">
        <div class="field is-grouped">
            <p class="control">
                <button @click="startShtrikh(false)" :disabled="kkt && kkt.isConnected" class="button">connect Штрих</button> 
            </p>
            <p class="control">
                <button @click="startViki(false)" :disabled="kkt && kkt.isConnected" class="button">connect Viki</button> 
            </p>
            <p class="control">
                <button @click="finish" class="button">disconnect</button>
            </p>
            <p class="control">
                <button @click="startWork" class="button">Start work</button>
            </p>
            <p class="control">
                <button @click="testConnection" class="button">Test connection</button>
            </p>
        </div>
        <div class="field">
            <button @click="getInfo" class="button" :disabled="!kkt || !kkt.isConnected">get info</button>
            <div>usbProductId={{usbProductId}}, usbVendorId={{usbVendorId}}</div>
        </div>
        <div class="field is-grouped">
            <div class="control">
                <button @click="beep" :disabled="!kkt || !kkt.isConnected" class="button">beep</button>
            </div>
            <div class="control">
                <button @click="getShortPosStatus" :disabled="!kkt || !kkt.isConnected" class="button">Короткий запрос состояния ККТ</button>
            </div>
            <div class="control">
                <button @click="getPosStatus" :disabled="!kkt || !kkt.isConnected" class="button">Запрос состояния ККТ</button>
            </div>
            <div class="control">
                <button @click="extError" :disabled="!kkt || !kkt.isConnected" class="button">ExtError</button>
            </div>
            <div class="control">
                <button @click="nullingCheck" :disabled="!kkt || !kkt.isConnected" class="button">Nulling Check</button>
            </div>
        </div>
        <div class="field is-grouped">
            <div class="control">
                <button @click="kkt.pullPaper(1)" :disabled="!kkt || !kkt.isConnected" class="button">Протяжка бумаги</button>
            </div>
            <div class="control">
                <button @click="kkt.cutPaper(0)" :disabled="!kkt || !kkt.isConnected" class="button">Отрезать</button>
            </div>
        </div>
        <div class="field is-grouped">
            <div class="control">
                <input type="text" class="input" :disabled="!kkt || !kkt.isConnected" v-model="text">
            </div>
            <div class="control">
                <button @click="printText" :disabled="!kkt || !kkt.isConnected" class="button">print text</button>
            </div>
        </div>
        <div class="field is-grouped">
            <div class="control">
                <button @click="openShift" :disabled="!kkt || !kkt.isConnected" class="button">Открыть смену</button>
            </div>
            <div class="control">
                <button @click="xreport" :disabled="!kkt || !kkt.isConnected" class="button">x-отчет</button>
            </div>
            <div class="control">
                <button @click="zreport" :disabled="!kkt || !kkt.isConnected" class="button">Отчет о закрытии смены</button>
            </div>
        </div>
        <div class="field is-grouped">
            <div class="control">
                <button @click="openCheck" :disabled="!kkt || !kkt.isConnected" class="button">Открыть чек</button>
            </div>
            <div class="control">
                <button @click="income" :disabled="!kkt || !kkt.isConnected" class="button">income</button>
            </div>
            <div class="control">
                <button @click="pay" :disabled="!kkt || !kkt.isConnected" class="button">Оплата</button>
            </div>
            <div class="control">
                <button @click="closeCheck" :disabled="!kkt || !kkt.isConnected" class="button">Закрыть чек</button>
            </div>
        </div>
    </div>
</template>
<script>


import KktMixin from '@/components/KktMixin'
export default {
    data(){
        return {
            usbProductId: null,
            usbVendorId: null,
            text: 'Пример текста',
        }
    },
    mixins: [KktMixin,],
    methods: {
        finish(){
            this.kkt.disconnect();
            this.kkt = null;
        },
        async beep(){
            await this.kkt.beep();
        },
        async openShift(){
            await this.kkt.openShift('1234')
        },
        async openCheck(){
            await this.kkt.openCheck(0)
        },
        async income(){
            await this.kkt.income(1, 1, "Пример");
        },
        async pay(){
            await this.kkt.pay(0, 1, null);
        },
        async closeCheck(){
            await this.kkt.closeCheck(0.00);
        },
        async nullingCheck(){
            await this.kkt.nullingCheck();
        },
        async printText(){
            await this.kkt.printLine(this.text);
        },
        async extError(){
            await this.kkt.extError();
        },
        async zreport(){
            await this.kkt.ZReport();
        },
        async xreport(){
            await this.kkt.XReport();
        },
        commonDumping(){
            this.kkt.commonDumping();
        },
        async getPosStatus(){
            await this.kkt.getPosStatus();
        },
        async getShortPosStatus(){
            await this.kkt.getShortPosStatus();
        },
        testConnection(){
            console.log("testConnection: ", this.kkt.testConnection());
        },
        async startWork(){
            await this.kkt.startWork()
        },
    }
}
</script>