<template>
    <section class="container section">
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <h1>Прогноз остатков</h1>
        <div class="columns" style="margin-top: .5rem;">
            <div class="column">
                <div class="field searchfield">
                    <div class="control">
                        <input placeholder="Найти" class="input" type="text" v-model="q" v-on:input="debounceInput">
                    </div>
                </div>
                <div style="position: relative;">
                    <table v-if="search" class="report striped search-results" style="width: 100%;" :class="{'loading': $apollo.queries.search.loading}">
                        <tr v-for="item in search.items" class="hover" @click="click(item)" :key="item.id">
                            <td>{{item.name}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>


        <div class="modal" :class="{'modal-fx-3dSlit is-active': isActive}">
            <div class="modal-background"></div>
            <div class="modal-content">
                <PredictionProductItem :tuId="tuId" style="background-color: #fff; padding: 16px;"/>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="isActive=false"></button>
        </div>

        
    </section>
    <!--section class="container section" v-else>
        Недостаточно прав
    </section-->
</template>

<script>
    import _ from 'lodash'
    import gql from "graphql-tag"
    import PredictionProductItem from './PredictionProductItem'

    export default {
        name: 'PredictionProduct',
        props: {
            user: Object,
        },
        methods: {
            debounceInput: _.debounce(function (e) {
                this.tu=[];
                this.q_debounced=e.target.value;
            }, 500),
            click(item){
                this.tuId = parseInt(item.id);
                this.isActive = true;
            }
        },
        components: {
            PredictionProductItem,
        },
        data(){
            return {
                tuId: null,
                q: '',
                q_debounced: '',
                error: null,
                isActive: false,
            }
        },
        apollo: {
            search: {
                query: gql`
                    query($q: String!) {
                        search(q: $q, activeOnly: true) {
                            count
                            items {id name}
                        }
                    }`,
                variables() {
                    return {
                        q: this.q_debounced,
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    .searchfield {
        position: sticky;
        top: 66px;
        background: #fff;
        z-index: 10;        
    }
    .search-results td{ cursor: pointer;}
</style>