<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <section class="section">
            <h1>Документы СБИС</h1>
            <div class="notification is-danger my-error" v-if="error">
                {{error}}
            </div>
            <div style="margin-top: 2rem;" class="no-print">
                <div class="field">
                    <label class="label">Период:</label>
                    <div class="control">
                        <date-picker
                            v-model="date_from"
                            class="date"
                            valueType="format"
                            :first-day-of-week=0
                            lang="ru"
                            format="DD-MM-YYYY"
                            placeholder="Дата с"
                            id="from"
                            ></date-picker>

                        &mdash;

                        <date-picker
                            v-model="date_to"
                            class="date"
                            valueType="format"
                            :first-day-of-week=1
                            lang="ru"
                            format="DD-MM-YYYY"
                            placeholder="Дата по"
                            id="to"
                            ></date-picker>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Номер документа:</label>
                    <div class="control">
                        <input type="text" v-model="docnumber" class="input" style="max-width: 180px">
                    </div>
                </div>
                <div class="field">
                    <label class="label">Своя организация:</label>
                    <div class="control select">
                        <select v-model="ourstore">
                            <option value="">-</option>
                            <option value="1">Жукова 7</option>
                            <option value="2">Курочка рядом, Грязнова 39</option>
                            <option value="3">Аскарово</option>
                            <option value="4">Кирса, Юбилейная, 20</option>
                            <option value="5">Октябрьская, 2</option>
                            <option value="6">Тупаково Хисматуллина 48</option>
                            <option value="7">Таштимирово</option>
                        </select>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <button
                            class="button is-primary submit"
                            :class="{'is-loading':$apollo.queries.sbisDocumentsList.loading}"
                            :disabled="$apollo.queries.sbisDocumentsList.loading"
                            @click="submit">Отобразить</button>
                    </div>
                </div>
            </div>
            <table class="doclist" v-if="sbisDocumentsList">
                <tr>
                    <th>Дата</th>
                    <th>Название документа</th>
                    <th>Поставщик</th>
                    <th>Наша точка</th>
                    <th>Сумма</th>
                </tr>
                    <tr v-for="doc in sbisDocumentsList.doclist" class="hover" :key="doc.id">
                        <td>{{doc.date}}</td>
                        <td><router-link :to="'/document/' + doc.id">{{doc.name}}</router-link></td>
                        <td>{{doc.agentname}}</td>
                        <td>{{doc.ourname}}</td>
                        <td>
                            {{(doc.newPrice==null?'-':doc.newPrice)|currency}}
                            <div v-if="!doc.priceChangeDate" class="price-not-changed"></div>
                        </td>
                    </tr>

            </table>
            <a class="button no-print" @click="$emit('print')" v-if="sbisDocumentsList && sbisDocumentsList.doclist">Печать</a>

        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"
    import DatePicker from 'vue2-datepicker'

    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import {format} from 'date-fns'

    export default {
        name: 'SbisList',
        components: {
            DatePicker,
        },
        props: [
            'user',
        ],
        methods: {
            submit(){
                this.skip=false;
                this.$apollo.queries.sbisDocumentsList.refetch();
            },
        },
        data(){
            return {
                date_from: format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
                date_to: format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
                docnumber: "",
                skip: true,
                ourstore: "",
                error: null,
            }
        },
        apollo: {
            sbisDocumentsList: {
                query: gql`
                    query($datefrom: String!, $dateto: String!, $ourstore: String!, $docnumber: String!){
                        sbisDocumentsList(datefrom: $datefrom, dateto: $dateto, ourstore: $ourstore, docnumber: $docnumber) {
                            ok
                            doclist{
                                id date name agentname ourname newPrice
                                priceChangeDate
                            }
                        }
                    }`,
                variables() {
                    return {
                        datefrom: this.date_from==null?'':this.date_from,//(new Date(this.date_from.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))).toDateString(),
                        dateto: this.date_to==null?'':this.date_to, //(new Date(this.date_to.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))).toDateString(),
                        ourstore: this.ourstore,
                        docnumber: this.docnumber,
                    }
                },
                skip(){
                    return this.skip;
                },
                result(data) {
                    this.skip=true;
                },
                errorPolicy: 'all',    
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>
query{

}

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.doclist{
        margin: 2rem 0 1.5rem;
        td{
            padding: 8px 5px;
        }
        th{
            text-align: center;
        }
        td:nth-child(5){
            text-align: right;
            white-space: nowrap;
        }
    }
    .price-not-changed{
        &:before{
            content: 'Цена не изменена';
        }
        font-size: 90%;
        color: red;
        white-space: inherit;
    }

</style>

