<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <h1 class="caption">Метабаза</h1>
        <iframe
            :src="metabaseUrl.url"
            frameborder="0"
            style="width: 100%; height: 810px;"
            allowtransparency
            v-if="metabaseUrl"
        ></iframe>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    export default {
        name: 'Metabase1',
        components: {
        },
        props: {
            user: Object,
        },
        methods: {
        },
        data(){
            return {
            }
        },
        apollo: {
            metabaseUrl: {
                query: gql(`
                    query{
                        metabaseUrl{url}
                    }`),
                //pollInterval: 5 * 60 * 1000, // ms
                //result({data, loading}) { // eslint-disable-line no-unused-vars
                    //if (data && data.user && data.user.id){
                    //}
                //},
                errorPolicy: 'all',
                error(e) {
                    this.error(JSON.stringify(e.message));
                },
            },
        },
	}
</script>

<style lang="scss" scoped>
</style>

