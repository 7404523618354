<template>
    <div>
        <label class="label">Контрагент:</label>
        <treeselect 
            :multiple="false" 
            placeholder="Выберите контрагента..."
            searchPromptText="Начните ввод для поиска"
            noResultsText="Контрагенты не найдены"
            loadingText="Загрузка данных"
            @select="onSelect"
            @input="onInput"
            :cacheOptions="false"
            :options="agentOptions"
            :async="agentAsyncMode"
            :load-options="loadAgentOptions"
            v-model="agentId"
        />
    </div>
</template>
    
<script>
    import gql from "graphql-tag"
    import Treeselect from '@riophae/vue-treeselect'
    import { ASYNC_SEARCH, LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'
    import _ from 'lodash'

    export default {
        props: {
            value: {
                default: null
            },
        },
        watch: {
            value(v) { 
                if(v){
                    this.agentId = v.id
                    this.agentOptions = [v]                    
                }
                else{
                    this.agentId = null
                    this.agentOptions = []  
                }
            },
        },
        data: () => ({
                agentOptions: [],
                agentId: null,
                agentAsyncMode: false,
                callback: null,
                agent_debounced: null,
                //lastAgents: []
            }
        ),
        components: {
            Treeselect,
        },
        mounted(){
            setTimeout(() => { 
                this.agentAsyncMode=true;
            }, 3000);

            //this.lastAgents = JSON.parse(localStorage.getItem('lastAgents'))||[];
            //this.agentOptions = this.lastAgents;
            //this.agentId = this.agentOptions[0].id;        

            if (this.value) {
                //if(!this.agentOptions.find(x=>x.id==this.value.id)){
                    this.agentOptions.push(this.value)
                //}
                this.agentId = this.value.id;                
            }
        },
        methods: {
            onSelect(item){
                this.$emit('input', item);
                //if(!this.lastAgents.find(x=> x.id==item.id)){
                //    this.lastAgents.push(item)
                //    localStorage.setItem('lastAgents', JSON.stringify(this.lastAgents))
                //}
            },
            onInput(id){
                if(!id)
                    this.$emit('input', null);
            },
            loadAgentOptions: _.debounce(function({ action, searchQuery, callback }) {
                //console.log(action, searchQuery, callback)
                if (action === ASYNC_SEARCH) {
                    this.agent_debounced = searchQuery;
                    this.callback = callback;
                } 
                else if (action === LOAD_ROOT_OPTIONS) {
                    //;
                }
            }, 500),
        },
        apollo: {
            searchAgents: {
                query: gql`query($search: String){searchAgents(search: $search) {id label:name}}`,
                variables() {
                    return {
                        search: this.agent_debounced,
                    }
                },
                result ({data, loading}) {
                    if (!loading) {
                        if (this.callback) {
                            if(data.searchAgents)
                                this.callback(null, data.searchAgents);
                            this.callback = null;
                        }
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
        }
    }
</script>
    