<template>
    <div>
        <label class="label">Группа клиент:</label>
        <v-select :options="items" v-model="selected" @input="onInput" />
    </div>
</template>

<script lang="js">
import gql from "graphql-tag"

var cacsh = null;

export function propLabel(id) {
    let x = cacsh? cacsh.find(x => x.code == id)||null : null
    return x? x.label : '';
}

export default {
    props:{
        value: {
            default: null
        },
        emitValue:{
            type: String,
            default: null
        },
        typeId: {
            default: 2
        }
    },
    data(){
        return{
            items: cacsh || [],
            selected: null,
        }
    },
    created(){
        this.setSelected()
    },
    methods:{
        onInput(item) {
            let v = null;
            if(item){
                v = this.emitValue? item[this.emitValue] : item;
            }
            this.$emit('input', v);
        },
        setSelected(){
            if(this.value){
                let property = this.emitValue? this.emitValue : 'code';
                this.selected = this.items.find(x => x[property] == this.value);
            }
        }
    },
    apollo: {
        prop: {
            query: gql`query($typeId: ID) { 
                prop(typeId: $typeId) {code:id label:name}
            }`,
            //query: gql`query{ prop(typeId: $typeId){id name } }`,
            skip(){ 
                return cacsh != null
            },
            variables() {
                return {
                    typeId: this.typeId,
                }
            },
            result({ data, loading }) {
                if (!loading) { 
                    //console.log(data)
                    this.items = data.prop
                    cacsh = this.items
                    this.setSelected();
                }
            },
        },
    }
}
</script>
