<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <div v-if="error || uploadError">
            <div class="notification is-danger my-error">
                {{error}}
                {{uploadError}}
            </div>
        </div>
        <!--div class="loading" v-if="$apollo.queries.rashodOrder.loading"></div-->
        <section class="section">
            <h1>Распознавание</h1>
            <div style="margin-top: 2rem;" class="no-print">
                <div class="field">
                    Страница 1 <input type="file" accept="image/*" id="file-input-0" class="input input-recognize-file" multiple>
                </div>
                <div class="field">
                    Страница 2 <input type="file" accept="image/*" id="file-input-1" class="input input-recognize-file" multiple>
                </div>
                <div class="field">
                    Страница 3 <input type="file" accept="image/*" id="file-input-2" class="input input-recognize-file" multiple>
                </div>
                <div class="field">
                    Страница 4 <input type="file" accept="image/*" id="file-input-3" class="input input-recognize-file" multiple>
                </div>
                <div class="field">
                    Страница 5 <input type="file" accept="image/*" id="file-input-4" class="input input-recognize-file" multiple>
                </div>
                <div class="field">
                    <div class="control">
                        <button
                            class="button is-primary submit"
                            :class="{'is-loading': image_uploading}"
                            :disabled="image_uploading"
                            @click="uploadFromCamera">Отправить документ на распознание</button>
                    </div>
                </div>
            </div>
            <h2 style="margin-top: 2rem;font-weight: bold;">Запросы на распознание</h2>

                <div class="field">
                    <label class="label">Период:</label>
                    <div class="control">
                        <date-picker
                            v-model="date_from"
                            class="date"
                            valueType="format"
                            :first-day-of-week=0
                            lang="ru"
                            format="DD-MM-YYYY"
                            placeholder="Дата с"
                            id="from"
                            ></date-picker>

                        &mdash;

                        <date-picker
                            v-model="date_to"
                            class="date"
                            valueType="format"
                            :first-day-of-week=1
                            lang="ru"
                            format="DD-MM-YYYY"
                            placeholder="Дата по"
                            id="to"
                            ></date-picker>

                        <button
                            class="button is-primary submit"
                            :class="{'is-loading':$apollo.queries.recognizeTask.loading}"
                            :disabled="$apollo.queries.recognizeTask.loading"
                            @click="submit">Отобразить</button>

                    </div>
                </div>

            <table class="report">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Создание</th>
                        <th>Состояние</th>
                        <th>Оригинал</th>
                        <th>Инициатор</th>
                        <th>Ссылка на документ в СБИС</th>
                    </tr>
                </thead>
                <tr v-for="task in recognizeTask" :key="task.id">
                    <td><a :href="'https://app.entera.pro/spaces/' + task.spaceId + '/documents/' + task.docId">{{task.id}}</a></td>
                    <td>{{task.created|datetime('dd.MM.yy HH:mm')}}</td>
                    <td>{{task.state}}</td>
                    <td>
                        <img :src="'/'+task.thumbnail" :data-zoom-src="'/'+task.original" data-zoomable class="preview" v-if="task.original">
                        <img
                            :src="'/'+img.thumbnail"
                            :data-zoom-src="'/'+img.original"
                            data-zoomable
                            class="preview"
                            :class="{'preview-small2': task.recognizetaskimageSet.length>1}"
                            v-for="img in task.recognizetaskimageSet"
                            :key="img.id"
                            >

                    </td>
                    <td>{{task.initiator}}</td>
                    <td>
                        <ul>
                            <li v-for="upload in task.uploadtosbisSet" :key="upload.id">
                                <a :href="upload.hrefOur">Ссылка на документ в СБИС</a>
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"
    import UploadFromCamera from "@/components/UploadFromCamera"
    import mediumZoom from 'medium-zoom'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import {format, parse, subDays} from 'date-fns'

    export default {

        name: 'RecognizeDocument',
        components: {
            DatePicker,
        },
        mixins: [UploadFromCamera],
        props: [
            'user',
        ],
        updated() {
            mediumZoom('[data-zoomable]');
        },
        methods: {
            addCustomData(data){
                data.append('recognize', true);
            },
            afterUploading(){
                document.getElementById("file-input-0").value=null;
                document.getElementById("file-input-1").value=null;
                document.getElementById("file-input-2").value=null;
                document.getElementById("file-input-3").value=null;
                document.getElementById("file-input-4").value=null;
                this.$apollo.queries.recognizeTask.refetch();
            },
            submit(){
                this.skip=false;
                this.$apollo.queries.recognizeTask.refetch();
            },
        },
        data(){
            return {
                img_selected: false,
                img_zoomed: false,
                skip: true,
                error: null,
                date_from: format((new Date()).setDate((new Date()).getDate()-1),'dd-MM-yyyy'),
                date_to: format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
            }
        },
        apollo: {
            recognizeTask: {
                query: gql`
                    query($dateFrom: String!, $dateTo: String!){
                        recognizeTask(dateFrom: $dateFrom, dateTo: $dateTo) {
                            id created taskId spaceId docId state original thumbnail initiator
                             uploadtosbisSet{id hrefOur}
                             recognizetaskimageSet {id original thumbnail}
                        }
                    }`,
                variables() {
                    return {
                        "dateFrom": parse(this.date_from,"dd-MM-yyyy", new Date()),
                        "dateTo": subDays(parse(this.date_to,"dd-MM-yyyy", new Date()),-1),
                    }
                },
                skip(){
                    return this.skip;
                },
                result(data) {
                    this.skip=true;
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
	}
</script>

<style lang="scss" scoped>
    img.preview{
        max-width: 80px;
        max-height: 80px;
        cursor: pointer;
        margin-right: 2px;
    }
    img.preview-small2{
        max-width: 39px;
        max-height: 39px;
    }
    td{
        vertical-align: middle;
    }
    #from, #to {
        width: 124px;
        margin-bottom: .5rem;
    }
    #to {
        margin-right: .5rem;
    }
    .input-recognize-file {
        width: auto;
        vertical-align: baseline;
        border: none;
    }
</style>

