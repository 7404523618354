<template>
    <div>
        <label class="label">Операция:</label>
        <v-select :options="items" v-model="selected" @input="onInput" />
    </div>
</template>

<script>

export default {
    props:{
        value: {
            default: null
        }
    },
    data(){
        return{
            items: [
                { value:  1, label: 'Приход' },
                { value: -1, label: 'Расход' },
            ],
            selected: null,
        }
    },
    created(){
        this.setSelected();
    },
    watch: {
        value(v) {
            if(!this.selected || this.selected.value!=v){
                this.setSelected();
                this.onInput(this.selected);
            } 
        },
    },
    methods:{
        onInput(item) {
            let v = item? item.value : null;
            this.$emit('input', v);
        },
        setSelected(){
            this.selected = this.items.find(x => x.value == this.value) || null;
        }
    },
}
</script>
