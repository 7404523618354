<template>
    <div :class="{'loading': $apollo.queries.inOutHistoryMysql.loading}">
        <div>
            <button @click="showFilter=!showFilter;saveState()" title="Показать/скрыть фильтр"><font-awesome-icon icon="filter"/></button>
            <span style="float: right;cursor: pointer" @click="clear" v-show="!isFilterEmpty">Очистить фильтр</span>
        </div>
        
        <div v-show="showFilter">
            <div class="field is-grouped">
                <date-range-piker class="control" v-model="dateRange" @input="filterChange" />
                <action-select  class="control" style="width: 30%;" v-model="actionId" @input="filterChange"/>{{actionId}}
            </div>

            <div class="field is-grouped">
                <sklad-select class="control" v-model="skladId" emit-value="code" @input="filterChange" style="width: 40%;" :user="user"/>
                <agent-select class="control" v-model="agent" @input="filterChange" style="width: 40%"/>
                <div class="control" >
                    <label class="label">&nbsp;</label>
                    <button class="button is-primary" @click="find">Найти</button>
                </div>
            </div>
            <hr>
        </div>

        <table v-if="inOutHistoryMysql && inOutHistoryMysql.length" class="report">
            <thead>
                <tr>
                    <th></th>
                    <th>Дата</th>
                    <th>Склад</th>
                    <th>Контрагент</th>
                    <th>Операция</th>
                    <th>Цена</th>
                    <th>Справка.2</th>
                </tr>
            </thead>
            <tr v-for="i in inOutHistoryMysql" :key="i.id">
                <td>{{i.docio}}</td>
                <td>{{i.date|date-ddmmyy}}</td>
                <td @click="selSklad(i.sklad)" class="selecteble">{{i.sklad?i.sklad.name:null}}</td>
                <td @click="selAgent(agent)">{{i.agent?i.agent.name:null}}</td>
                <td style="text-align: right;">
                    <div v-if="i.action==1" class="in-amount">+{{parseFloat(i.inAmount)}}</div>
                    <div v-if="i.action==-1" class="out-amount">-{{parseFloat(i.outAmount)}}</div>
                </td>
                <td style="text-align: right;">{{i.inPrice|currency-null2}}</td>
                <td>{{i.informBReg}}</td>
            </tr>
        </table>
        <div v-else-if="!$apollo.queries.inOutHistoryMysql.loading">Нет элементов для отображения</div>
    </div>
</template>

<script>
import gql from "graphql-tag"
import ActionSelect from '../common/ActionSelect.vue'
import AgentSelect from '../common/AgentSelect.vue'
import SkladSelect from '../common/SkladSelect.vue'
import DateRangePiker, { formatDate } from '../common/DateRangePiker.vue'
import { ModuleStorage } from '../common/ModuleStorage'

const storage = new ModuleStorage('TuItemMove', '2')

export default {
    props:{
        id: String,
        user: Object,
    },
    components:{ 
        SkladSelect, 
        DateRangePiker,
        ActionSelect,
        AgentSelect 
    },
    data(){
        return {
            showFilter: false,
            inOutHistoryMysql: null,            
            agent: null,
            actionId: null,
            skladId: null,
            dateRange: {
                from: null,
                to: null
            }
        }
    },
    computed: {
        isFilterEmpty(){
            return  (
                this.agent == null &&
                this.actionId == null &&
                this.skladId == null &&
                this.dateRange.from == null &&
                this.dateRange.to == null
            )
        }
    },
    created() {
       storage.loadTo(this);
    },
    methods: {
        filterChange(){
            this.saveState();
            this.find();
        },
        saveState(){
            storage.saveFor(this, ["showFilter", "dateRange", "skladId", "actionId", "agent"])
        },
        find(){
            this.inOutHistoryMysql = null
        },
        clear(){
            this.agent = null;
            this.actionId = null;
            this.skladId = null;
            this.dateRange.from = null;
            this.dateRange.to = null;
            this.inOutHistoryMysql = null;
            this.saveState()
        },
        selSklad(sklad){
            //if(sklad.id != this.skladId){
                this.skladId = sklad.id;
            //    this.filterChange();
            //}
        },
        selAgent(agent){
            if(agent.id != this.agent.id){
                this.agent = agent.id;
                this.filterChange();
            }
        }
    },
    apollo: {
        inOutHistoryMysql: {
            query: gql`
            query($tuId: ID!, $dateFrom: Date, $dateTo: Date, $skladId: ID, $agentId: ID, $actionId: ID){
                inOutHistoryMysql(tuId: $tuId, dateFrom: $dateFrom, dateTo: $dateTo, skladId: $skladId, agentId: $agentId, actionId: $actionId) {
                    docio date agent{id name} sklad{id name}
                    inAmount inPrice
                    outAmount outPrice action informBReg
                }
            }`,
            skip(){ return this.inOutHistoryMysql },
            variables() {
                return {
                    tuId: this.id,
                    dateFrom: formatDate(this.dateRange.from),//this.dateFrom ? format(parse(this.dateFrom, "dd-MM-yyyy", new Date()), 'yyyy-MM-dd') : null,
                    dateTo: formatDate(this.dateRange.to),//this.dateTo ? format(parse(this.dateTo, "dd-MM-yyyy", new Date()), 'yyyy-MM-dd') : null,
                    skladId: this.skladId,
                    agentId: this.agent? this.agent.id : null,
                    actionId: this.actionId
                }
            },
            result ({ data, loading }) {
                if (!loading) {
                    console.log(data);
                }
            },
            error(error) {
                this.error = JSON.stringify(error.message);
            },
        },
    }
}
</script>

<style lang="scss" scoped>
.selecteble{
    cursor: pointer;
}
.selecteble:hover{
    color: blue;
}
.in-amount{
    color: green;
    text-align: right;
}
.out-amount{
    color: red;
    text-align: right;
}
</style>
