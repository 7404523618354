<template>
    <div>
        <label class="label update-data">Склад:</label>
        <v-select :options="sklads" v-model="selected" @input="onInput" />
    </div>
</template>

<script>
import ApolloSkladMixin from '../Warehouse/ApolloSkladMixin';

export default {
    name: "SkladSelect",
    mixins: [ApolloSkladMixin, ],
    props:{
        value: {
            default: null
        },
        user: Object,
    },
    data(){
        return{
            selected: null,
        }
    },
    watch: {
        value(v) {
            if(!this.selected || this.selected.code!=v){
                this.setSelected();
                this.onInput(this.selected);
            } 
        },
    },
    methods:{
        onInput(item) {
            this.$emit('input', item? item.code : null);
        },
        setSelected(){
            if(this.value){
                this.selected = this.sklads.find(x => x.code == this.value)||null;
            }
        },
        onUpdate(){
            //this.items = [];
            //this.inited = false;
        },
        skladLoaded(data){
            this.setSelected();
            this.$forceUpdate();
        },
    },
}
</script>
<style>
.update-data span{
    visibility: hidden
}
.update-data:hover span{
    visibility: visible
}
</style>