<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <section class="section" >
            <div class="field has-addons no-print">
                <div class="control">
                    <input class="input" type="text" placeholder="Номер " v-model="docoutInput">
                </div>
                <div class="control">
                    <a class="button is-info" @click="docout=docoutInput">
                        Показать
                    </a>
                </div>
            </div>
            <div class="loading" v-if="$apollo.queries.outgoWithIncomePrice.loading"></div>
            <div v-if="outgoWithIncomePrice">
                <h1>Документ {{docout}}</h1>
                <table class="report striped">
                    <thead>
                        <tr>
                            <th>Код</th>
                            <th>Наименование</th>
                            <th>Кол-во</th>
                            <th>Цена реализации</th>
                            <th>Цена прихода</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="i in outgoWithIncomePrice">
                            <td>{{i.nomer}}</td>
                            <td>{{i.name}}</td>
                            <td style="text-align:right;">{{i.amount}}</td>
                            <td style="text-align:right;">{{i.price|currency}}</td>
                            <td style="text-align:right;">{{i.incomePrice|currency}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3" style="text-align:right">
                                Итого:
                            </td>
                            <td style="text-align:right;">{{ total|currency }}</td>
                            <td style="text-align:right;">{{ totalInIncomePrice|currency }}</td>
                        </tr>
                    </tfoot>

                </table>
            </div>
            <div style="margin-top: 2rem;" class="no-print">
                <a class="button" @click="$emit('print')">Печать</a>
            </div>            
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"

    export default {
        components: {
        },
        props: [
            'user',
        ],
        computed: {
            total() {
                return this.outgoWithIncomePrice.reduce((a, v) => (a + v.amount*v.price), 0);
            },        
            totalInIncomePrice() {
                return this.outgoWithIncomePrice.reduce((a, v) => (a + v.amount*v.incomePrice), 0);
            },        
        },
        data(){
            return {
                docoutInput: null,
                docout: null,
            }
        },
        apollo: {
            outgoWithIncomePrice: {
                query: gql`
                    query($docout: ID){
                        outgoWithIncomePrice(docout: $docout) {
                            nomer
                            name
                            amount
                            price
                            incomePrice
                        }
                    }`,
                variables() {
                    return {
                        docout: this.docout,
                    }
                },
                skip() {
                    return this.docout==null;
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
tfoot{
    font-weight: bold;
}
@media print {
    h1, .report, div.total {
        font-size: 60%;
    }
    table.report td, table.report th {
        padding: 2px;
    }
}
</style>

