<template>
    <div class="container" v-if="checkGroup(user, 'Отчет по операторам')">
        <section class="section">
            <h1>Отчет по операторам</h1>
            <div style="margin-top: 2rem;" class="no-print">
                <div class="field">
                    <label class="label">Период:</label>
                    <div class="control">
                        <date-picker
                            v-model="date_from"
                            class="date"
                            valueType="format"
                            :first-day-of-week=0
                            lang="ru"
                            format="DD-MM-YYYY"
                            placeholder="Дата с"
                            id="from"
                            ></date-picker>

                        &mdash;

                        <date-picker
                            v-model="date_to"
                            class="date"
                            valueType="format"
                            :first-day-of-week=1
                            lang="ru"
                            format="DD-MM-YYYY"
                            placeholder="Дата по"
                            id="to"
                            ></date-picker>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <button
                            class="button is-primary submit"
                            :class="{'is-loading':$apollo.queries.sbisOperator.loading}"
                            :disabled="$apollo.queries.sbisOperator.loading"
                            @click="submit">Отобразить</button>
                    </div>
                </div>
            </div>
            <div v-if="sbisOperator">
                <h2 style="margin-top: 2rem;">Документы СБИС</h2>
                <table class="doclist">
                    <tr>
                        <th></th>
                        <th v-for="date in dates" :key="date">
                            {{date}}
                        </th>
                    </tr>
                        <tr v-for="doc in sbisOperator.answerlist" class="hover" :key="doc.id">
                            <td>{{doc.name}}</td>
                            <td v-for="date in dates" :key="date">
                                <span v-for="d in doc.positions.list" :key="d.id">
                                    <span v-if="d.date==date">{{d.val}}</span>
                                </span>
                            </td>
                        </tr>

                </table>
                <a class="button no-print" @click="$emit('print')" v-if="sbisOperator">Печать</a>
            </div>

        </section>

        <div style="margin: 10px;" class="no-print">
            <json-viewer
                v-show='true || show_json' v-if="sbisOperator"
                :expand-depth=0
                copyable
                boxed
                expanded
                :value="JSON.parse(JSON.parse(sbisOperator.json))"></json-viewer>

        </div>

    </div>
</template>

<script>

    import gql from "graphql-tag"
    import DatePicker from 'vue2-datepicker'

    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import {format} from 'date-fns'

    export default {
        name: 'OperatorsProductivity',
        components: {
            DatePicker,
        },
        props: [
            'user',
        ],
        methods: {
            submit(){
                this.skip=false;
                this.$apollo.queries.sbisOperator.refetch();
            },
        },
        data(){
            return {
                date_from: format((new Date()).setDate((new Date()).getDate()-6),'dd-MM-yyyy'),
                date_to: format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
                skip: true,
                show_json: false,
                dates: [],
            }
        },
        apollo: {
            sbisOperator: {
                query: gql`
                    query($datefrom: String!, $dateto: String!){
                        sbisOperator(datefrom: $datefrom, dateto: $dateto) {
                            ok
                            answerlist{ name positions {sum list{date val}} }
                            json
                        }
                    }`,
                variables() {
                    return {
                        datefrom: this.date_from==null?'':this.date_from,
                        dateto: this.date_to==null?'':this.date_to,
                    }
                },
                skip(){
                    return this.skip;
                },
                result(data) {
                    this.skip=true;
                    this.dates=this.dates.splice();
                    //console.log(data.data.sbisOperator.answerlist);
                    for (let i of data.data.sbisOperator.answerlist){
                        for (let j of i.positions.list){
                            if (!this.dates.includes(j.date))
                                this.dates.unshift(j.date);
                        }
                    }
                    var d3=[];
                    for (let i of this.dates){
                        d3.push(new Date(i));
                    }
                    this.dates = d3.sort((a, b) => a - b);
                    var d4=[];
                    for (let i of this.dates){
                        d4.push( format(i, 'yyyy-MM-dd'));
                    }
                    this.dates=d4;
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>
query{

}

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.doclist{
        margin: 2rem 0 1.5rem;
        td{
            padding: 8px 5px;
            text-align: center;
            white-space: nowrap;
        }
        th{
            text-align: center;
            padding: 3px 3px;
            font-size: 90%;
        }
        td:nth-child(1){
            text-align: left;
        }
    }

</style>

