<template>
    <ul class="menu-list" v-if="filteredGoods(id).length>0">
        <li v-for="i in filteredGoods(id)" :key="i.id">
            <a @click="$emit('select', i.name, parseInt(i.id))" :class="{'is-active': branch_id==i.id}">
                <img :src="'/' + i.icon" v-if="i.icon">
                {{i.name}}
            </a>
            <branch :goods="goods" :id="i.id" :branch_id="branch_id" @select="$emit('select', arguments[0], arguments[1])"/>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'branch',
        props: {
            goods: Array,
            id: String,
            branch_id: Number,
        },
        methods: {
            filteredGoods: function(x) {
                if (this.goods!=null)
                    return this.goods.filter(function(u) {
                        return u.parentId==x
                    });
                else
                    return null;
            },
        },
    }
</script>

<style>
    .menu-list li img {
        max-width: 32px;
        vertical-align: middle;
    }
</style>