 <template>
    <div class="a4" v-if="search">
        <div class="select no-print" style="margin-bottom: 1rem;">
            <select v-model="ticketType">
                <option value="sklad">Склад</option>
                <option value="rozn">Розница</option>
            </select>
        </div>
        <div v-if="ticketType=='sklad'">
            <div class="ticket" v-for="ticket in search.items" :key="ticket.id">
                <div class="name">
                    <span>{{ticket.name}}</span>
                </div>
                <div class="inbox">
                    <div class="lbl">упаковка</div>
                    <div class="value">x {{ticket.inbox}} = {{ticket.inbox*ticket.price|currency}}</div>
                </div>
                <div class="price">
                    <div class="lbl">Цена:</div>
                    <div class="value">{{ticket.price|currency-null}}</div>
                </div>
                <div class="code">
                    <div class="lbl">Код:</div> <div class="value">{{ticket.nomer}}</div>
                </div>
                <div class="sub">
                    <div>ООО Дионис</div>
                    <div>{{date}}</div>
                </div>
            </div>
        </div>

        <div v-if="ticketType=='rozn'">
            <div class="ticket-rozn" v-for="ticket in search.items" :key="ticket.id">
                <div class="name">
                    <span>{{ticket.name}}</span>
                </div>
                <div class="price" :class="{'is-weight': ticket.isWeight}">
                    <div class="value">
                        <span v-if="ticket.isWeight">
                            <span v-if="ticket.transformSbisPrice && ticket.weight">{{ticket.priceRetail/ticket.weight|currency-null}}</span>
                            <span v-else>{{ticket.priceRetail|currency-null}}</span>
                            /кг.
                        </span>
                        <span v-else>{{ticket.priceRetail|currency-null}}</span>
                        
                    </div>
                </div>
                
                <div class="sub">
                    <div>{{ticket.nomer}}</div>
                    <div>{{date}}</div>
                </div>
            </div>
        </div>

        <div style="margin: 2rem 0;" class="no-print">
            <a class="button" @click="$emit('print')">Печать</a>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import {format, parse} from 'date-fns'
    export default {
        props: {
            idList: Array,
            user: Object,
        },
        data: () => ({
                date: format(new Date(),'dd.MM.yyyy'),
                ticketType: 'rozn',
            }
        ),
        apollo: {
            search: {
                query(){
                    if (this.checkGroup(this.user, 'Справочник товаров ReadOnly')){
                        return gql`query($idList: [ID]){
                            search(idList: $idList) {
                                count
                                items{
                                    id name nomer inbox 
                                    price: price
                                    priceRetail: price
                                    transformSbisPrice
                                    weight
                                    isWeight
                                }
                            }
                        }`
                    } else{
                        return gql`query($idList: [ID]){
                            search(idList: $idList) {
                                count
                                items{
                                    id name nomer inbox 
                                    price: priceOpt
                                    priceRetail: price
                                    transformSbisPrice
                                    weight
                                    isWeight
                                }
                            }
                        }`;
                    }
                },
                variables() {
                    return {
                        idList: this.idList,
                    }
                },
                /*result ({ data, loading }) {
                    if (!loading) {
                    }
                },*/
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        }
    }
</script>

<style lang="scss">
    .a4{
        width: 210mm;
        padding: 10px;
    }
    .ticket {
        width: 6.5cm;
        height: 4.5cm;
        .name {
            font-size: 12.5pt;
        }
        .price {
            .lbl{
                font-size: 13pt;
            }
            .value{
                font-size: 20pt;
            }
        }
    }
    .ticket-rozn {
        width: 5.1cm;
        height: 3.5cm;
        .name {
            font-size: 11pt;
        }
        .price .value {
            font-size: 25pt;
            margin-top: 8pt;            
        }
        .price.is-weight .value{
            font-size: 20pt;
        }
    }
    .ticket, .ticket-rozn {
        border: .1mm dotted #999;
        display: inline-block;
        padding: 3pt;
        position: relative;
        .name {
            text-align: center;
            font-weight: bold;
            line-height: 6mm;
            height: 12mm;
            overflow: hidden;
        }
        .inbox {
            display: flex;
            align-items: baseline;
            .lbl{
                font-size: 9pt;
            }
            .value{
                margin-left: 8pt;
                font-size: 12pt;
            }
        }
        .price {
            display: flex;
            align-items: baseline;
            font-weight: bold;
            line-height: 9mm;
            .value{
                margin-left: 8pt;
                min-height: 28px;
            }
        }
        .code {
            display: flex;
            align-items: baseline;
            font-weight: bold;
            line-height: 9mm;
            .lbl{
                font-size: 13pt;
            }
            .value{
                margin-left: 8pt;
                font-size: 20pt;
            }
        }
        .sub {
            display: flex;
            justify-content: space-between;
            font-size: 10pt;
            bottom: 0;
            position: absolute;
            left: 5px;
            right: 5px;
        }
    }
</style>
