<template>
  <div class="tabs is-boxed">
    <ul>
      <li v-for="t in tabs" :class="{'is-active': t.id==value.id}" @click="select(t)"><a>{{ t.label }}</a> </li>
    </ul>
  </div>  
</template>

<script>
export default {
  props: {
      tabs: Array,
      value: null
    },
    created() {
      if (!this.value) {
        this.select(this.tabs[0]||null)
      }
    },
    methods: {
      select(v){
        this.$emit('input', v);
      }
    },
}
</script>