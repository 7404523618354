<template>
    <div :class="{'loading': $apollo.queries.predictionProductItem.loading}">
        <h1 v-if="predictionProductItem">{{predictionProductItem.name}}</h1>
        <div class="control" style="margin: .8rem 0;">
            <label class="radio">
                <input type="radio" name="foobar" v-model="skladId" value="1">
                Кирса
            </label>
            <label class="radio">
                <input type="radio" name="foobar" v-model="skladId" value="3">
                Автомобилистов 8г
            </label>
            <label class="radio">
                <input type="radio" name="foobar" v-model="skladId" value="11">
                Автомобилистов 18е
            </label>
        </div>
        <table class="ppi" v-if="predictionProductItem">
            <tr>
                <td colspan="2"><b>Расход</b></td>
            </tr>
            <tr>
                <td>Количество:</td>
                <td>{{predictionProductItem.amount}}</td>
            </tr>
            <tr>
                <td>Сумма:</td>
                <td>{{predictionProductItem.summa|currency}}</td>
            </tr>
            <tr>
                <td>Цена:</td>
                <td>{{predictionProductItem.price|currency}}</td>
            </tr>
            <tr>
                <td>Остаток:</td>
                <td>{{predictionProductItem.balance}}</td>
            </tr>
            <tr>
                <td colspan="2"><b>Рекомендация</b></td>
            </tr>
            <tr>
                <td>Кол-во:</td>
                <td>{{Math.floor(predictionProductItem.amount/2)-predictionProductItem.balance}}</td>
            </tr>
            <tr>
                <td colspan="2"><b>Последний приход</b></td>
            </tr>
            <tr>
                <td>Дата:</td>
                <td>{{predictionProductItem.lastDate|date-ddmmyy}}</td>
            </tr>
            <tr>
                <td>Количество:</td>
                <td>{{predictionProductItem.lastAmount}}</td>
            </tr>
            <tr>
                <td>Цена:</td>
                <td>{{predictionProductItem.lastPrice|currency}}</td>
            </tr>
        </table>
        <div v-if="!$apollo.queries.predictionProductItem.loading && !predictionProductItem" style="min-height: 140px;">
                Нет данных
        </div>
    </div>
</template>

<script>
    //import { defineComponent } from '@vue/composition-api'
    import gql from "graphql-tag"

    export default {
        props: {
            tuId: Number,
        },
        data(){
            return {
                skladId: 1,
            }
        },
        apollo: {
            predictionProductItem: {
                query: gql`
                    query($tuId: Int!, $skladId: ID!) {
                        predictionProductItem(tuId: $tuId, skladId: $skladId) {
                            name amount summa price balance lastDate lastAmount lastPrice
                        }
                    }`,
                variables() {
                    return {
                        tuId: this.tuId,
                        skladId: ''+this.skladId,
                    }
                },
                skip() {
                    return this.tuId==null
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    table.ppi{
        td {
            padding: 5px 10px 5px 5px;
        }
    }
    div.loading {
        color: #ccc;
    }
</style>
