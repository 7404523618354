import axios from 'axios';
const referer = 'https://kirsa.9733.ru'

function multRound(amount, price){
    return Math.round(Math.round(amount *1000 * price * 100) / 1000)/100
}

function summ(list){
    if (list)
        return list.reduce((accum, e) => accum + multRound(e.amount, e.price), 0);
    else
        return null;
}

function isAmc(barcode){
    return barcode.length == 150 || barcode.length == 68
}

async function checkMarks(list, kkm){
    for (let i of list){
        if (i.arrMark){
            for (let itemMark of i.arrMark){
                await kkm.posImpl.checkKm(itemMark.mark, 0)
                await kkm.proccess(new IncludeKm(1));
            }
        }
    }
}

async function appendPositionsToKkt(list, retr, kkt, taxGroup){
    for (let i of list){
        //this.mylog("before kkm.income/retrn", {amount: i.amount, price: i.price, name: i.name})
        if (!retr){
            if (i.arrMark){
                for (let itemMark of i.arrMark){
                    await kkt.income(i.amount, i.price, i.name, taxGroup, itemMark.mark).catch(async error => { throw error });
                }
            } else {
                await kkt.income(i.amount, i.price, i.name, taxGroup).catch(async error => { throw error });
            }
        } else {
            await kkt.retrn(i.amount, i.price, i.name).catch(async error => { throw error });
        }
        //this.mylog("after kkm.income/retrn")
    }
}


async function sendToEgais(xml, utmUrl){
    const blob = new Blob([xml], {type : 'text/xml'});
    const formData = new FormData();
    formData.append("xml_file", blob);
    return axios.post('http://' + utmUrl + '/xml',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': referer,
                },
            }
        )
}

async function chequeToKkt(dataCreate, pos, vue, kkt, taxGroup){
    const xml = (dataCreate && dataCreate.chequeXml)?dataCreate.chequeXml : null
    if (xml){
        if (pos.utmSendCheque){
            vue.description='Регистрация в ЕГАИС...'
            await sendToEgais(xml, pos.utmUrl).then(
                async response => {
                    vue.description='Печать чека...'
                    await appendPositionsToKkt(vue.list, vue.retr, kkt, taxGroup).catch(async error => { throw error });
                    //await kkt.pay(0, this.total(), null);
                    await kkt.closeCheck(
                        vue.payWithCard?0:Math.max(vue.cashAmount, vue.total()),
                        vue.payWithCard?vue.total():0,
                        ).catch(async error => { throw error });
                },
                async error=>{
                    // выбор отменить чек или исключить алкоголь
                    vue.payProgressError = error;
                    vue.payProgressIcon = 'attention';
                    throw error
                })
        }
    } else {
        await appendPositionsToKkt(vue.list, vue.retr, kkt, taxGroup)
        const s1=vue.payWithCard?0:Math.max(vue.cashAmount, vue.total())
        const s2=vue.payWithCard?vue.total():0
        await kkt.closeCheck(s1, s2).catch(async error => {
                /*
                if (error.toString().startsWith('Error: Принтер не готов')){
                    this.$emit('paperOut')
                    await new Promise(r => setTimeout(r, 1000));
                    //await setTimeout(function() {
                    //    b = a + 4;
                    //}, (30 * 1000));
                    mycontinue = true
                }*/
                throw error
            });
    }
}

export {chequeToKkt, summ, multRound, checkMarks, isAmc}
