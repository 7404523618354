<template>
<div>
    <div class="container" v-if="user && !user.isAnonymous">
        <div v-if="error">
            <div class="notification is-danger my-error">
                {{error}}
            </div>
        </div>
        <div class="loading" v-if="$apollo.queries.svodBread.loading"></div>
        <section class="section" v-if="svodBread">
            <h1 class="no-print">Сводный отчет по хлебо-булочным изделиям</h1>

            <div class="field no-print" style="margin-top: 20px; margin-bottom: 3rem;">
                <div class="control">
                    <date-picker
                        v-model="date"
                        class="date"
                        valueType="format"
                        :first-day-of-week=0
                        lang="ru"
                        format="DD-MM-YYYY"
                        placeholder="Дата"
                        id="from"
                        ></date-picker>
                </div>
            </div>

            <div>
                <table class="doc_income striped">
                    <thead>
                        <tr>
                            <th>Контрагент</th>
                            <th v-for="bi in svodBread.breads" :key="bi.id">
                                <div class="vert">{{bi.name}}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="agent in svodBread.agents" class="hover" :key="agent.idAgent">
                            <td style="text-align: left;">{{agent.name}}</td>
                            <td v-for="bi in svodBread.breads" :key="bi.id">
                                <span v-for="x in agent.positions" :key="x.idTu">
                                    <span v-if="x.idTu==bi.id">
                                        {{x.kolfakt}}
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th style="text-align: right;">Итого:</th>
                            <th v-for="bi in svodBread.breads" :key="bi.id">
                                {{sum(bi.id)}}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div style="margin-top: 2rem;" class="no-print">
                <a class="button" @click="$emit('print')">Печать</a>
            </div>
            <div style="margin-top: 2rem;" class="no-print">
                <a class="button is-primary" @click="createPurchaseInvoice" :disabled="sending || !checkGroup(user, 'Создание накладной по хлебу')" :class="{'is-loading': sending}">
                    Создать приходную накладную
                </a>
            </div>
        </section>
    </div>
    <div style="margin: 10px;" class="no-print">
                <!--div style="margin-top: 3rem;"><span  class="action" @click="show_json=!show_json">json</span></div-->
                <!--pre v-show='show_json'>
                    {{JSON.stringify(JSON.parse(JSON.parse(sbisDocumentIncome.json))['result'], undefined, 4)}}
                </pre-->

    </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import {format, parse} from 'date-fns'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';

    export default {
        name: 'SvodBread',
        components: {
            DatePicker,
        },
        props: {
            user: Object,
        },
        methods: {
            createPurchaseInvoice(){
                if (!this.checkGroup(this.user, 'Создание накладной по хлебу'))
                    return;
                this.sending=true;
                let a=[];
                this.svodBread.breads.forEach((element) => {
                    let sum=this.sum(element.id);
                    if (sum>0)
                        a.push(
                            {
                                id: element.id,
                                amount: sum,
                                baseprice: element.baseprice,
                            });
                });
                this.$apollo.mutate({
                    mutation: gql`mutation($json: JSONString!) {
                        createBreadIncomeDocument(json: $json) { ok }
                    }`,
                    variables: {
                        json: JSON.stringify(a),
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    this.sending=false;
                    this.error=null;
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },
            sum(id){
                let s=0;
                this.svodBread.agents.forEach((element) => {
                    element.positions.forEach((e2) => {
                        if (e2.idTu==id)
                            s+=parseInt(e2.kolfakt);
                    });
                });
                return s;
            }
        },
        data(){
            return {
                error: null,
                sending: false,
                skip: true,
                date: format((new Date()).setDate((new Date()).getDate()+1),'dd-MM-yyyy'),
            }
        },
        apollo: {
            svodBread: {
                query: gql`
                    query($date: Date!){
                        svodBread(date: $date){
                            breads{id name baseprice}
                            agents {idAgent name positions{idTu kolfakt}}
                        }
                    }`,
                variables() {
                    return {
                        date: format(parse(this.date,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                        }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.doc_income{
        td, th{
            padding: 8px 10px;
            vertical-align: middle;
            text-align: center;
        }
    }
    .vert{
        text-orientation: mixed;
        writing-mode: vertical-lr;
        white-space: nowrap;
    }

    @media print
    {
        .section
        {
            padding: 0 !important;
            font-size: 80%;
        }
        table.doc_income{
            td, th{
                border: 1px solid;
            }

        }
    }

</style>

