<template>
    <ModalWindow :isActive='detailsActive' id="details">
        <div class="modal-card" v-if="detailTuId">
            <header class="modal-card-head">
                <div class="modal-card-title">
                    {{ detail.name }}
                    <div>{{ detail.nomer }}</div>
                </div>
                <button class="delete" aria-label="close" @click="$emit('close')"></button>
            </header>
            <section class="modal-card-body">
                {{ detail.ts|datetime }} {{ detail.editor }} {{ detail.fact }}
                <table v-if="inOutHistoryMysql && inOutHistoryMysql.length" class="report">
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Контрагент</th>
                            <th>Операция</th>
                            <th>Цена</th>
                        </tr>
                    </thead>
                    <tr v-for="i in inOutHistoryMysql" :key="i.id">
                        <td>{{i.date|date-ddmmyy}}</td>
                        <td>{{i.agent?i.agent.name:null}}</td>
                        <td style="text-align: right;">
                            <div v-if="i.action==1" class="in-amount">+{{parseFloat(i.inAmount)}}</div>
                            <div v-if="i.action==-1" class="out-amount">-{{parseFloat(i.outAmount)}}</div>
                        </td>
                        <td style="text-align: right;">{{i.inPrice|currency-null2}}</td>
                    </tr>
                </table>
                <div v-else-if="inOutHistoryMysql && inOutHistoryMysql.length==0">Нет элементов для отображения</div>
            </section>
        </div>
        <footer class="modal-card-foot">
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-primary" @click="$emit('close')">Закрыть</button>
                </div>
            </div>
        </footer>
    </ModalWindow>
</template>

<script>
    import ModalWindow from "../ModalWindow.vue"
    export default {
        props: {
            //user: Object,
            //auditId: String,
            detailTuId: String,
            detailsActive: Boolean,
            detail: Object,
            inOutHistoryMysql: Array,
        },
        components: {
            ModalWindow,
        },
    }
</script>