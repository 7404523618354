<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <div class="modal-card-title">
                    {{item?item.name:null}}
                </div>
                <button class="delete" aria-label="close" @click="close"></button>
            </header>
            <section class="modal-card-body" style="min-height: 520px;">
                <div :class="{'loading': false}" v-if="item" @keydown.esc="onEscape">
                    <div class="tabs is-boxed">
                        <ul>
                            <li :class="{'is-active': tab=='main'}"><a @click="tab='main'">Основное</a></li>
                            <li :class="{'is-active': tab=='other'}"><a @click="tab='other'">Дополнительно</a></li>
                        </ul>
                    </div>
                    <div v-show="tab=='main'">
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Имя
                                </label>
                                <input type="text" class="input" v-model="item.name" 
                                       :class="{'is-danger': !firstSaveAttept && (!item.name || !item.name.trim())}">
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Адрес
                                </label>
                                <input type="text" class="input" v-model="item.address">
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Признак
                                </label>
                                <v-select :options="[{code:null, label: '-'}].concat(kinds)" v-if="kinds" v-model="kind"></v-select>
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Ответственный
                                </label>
                                <v-select :options="[{code:null, label: '-'}].concat(answers)" v-if="answers" v-model="answer"></v-select>
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    <input class="checkbox" type="checkbox" v-model="item.isArc"
                                        >
                                    Архив
                                </label>
                            </div>
                        </div>        
                    </div>

                    <div v-show="tab=='other'">
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Вид цены
                                </label>
                                <v-select :options="[{code:null, label: '-'}].concat(pricetypes)" v-if="pricetypes" v-model="pricetype"></v-select>
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Приоритетный склад
                                </label>
                                <v-select :options="[{code:null, label: '-'}].concat(sklads)" v-if="sklads" v-model="sklad"></v-select>
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Запасной  склад
                                </label>
                                <v-select :options="[{code:null, label: '-'}].concat(sklads)" v-if="sklads" v-model="secondarySklad"></v-select>
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Группа
                                </label>
                                <v-select :options="[{code:null, label: '-'}].concat(agroups)" v-if="agroups" v-model="agroup"></v-select>
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Плательщик ТОРГ-12
                                </label>
                                <input type="text" maxlength="512" class="input" v-model="item.torg12payer">
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Грузополучатель ТОРГ-12
                                </label>
                                <input type="text"  maxlength="512" class="input" v-model="item.torg12consignee">
                            </div>
                        </div>     
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-primary" @click="save" :class="{'is-loading': sending}">Сохранить</button>
                    </div>
                    <div class="control">
                        <button class="button" @click="close">Отмена</button>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import DictItemMixin from '../DictItemMixin'
    import AgentMixin from './AgentMixin.vue'

    export default {
        props: {
            id: String,
            user: Object,
        },
        data(){
            return {
                item: null,
            };
        },
        components: {
        },
        mixins: [
            DictItemMixin, AgentMixin,
        ],
        methods: {
            onEscape(){
                //this.$emit('close');
                this.close();
            },
            close(){
                //this.$emit('close');
                this.$router.go(-1);
            },
            save(){
                this.firstSaveAttept=false;
                if (this.item.name==null || this.item.name.trim()==''){
                    this.tab = 'main';
                    return false;
                }
                this.saveAgent({
                        id: this.id,
                        name: this.item.name,
                        isArc: this.item.isArc,
                        kindId: this.kind?this.kind.code:null,
                        agroupId: this.agroup ? this.agroup.code: null,
                        address: this.item.address,
                        answerId: this.answer ? this.answer.code: null,
                        pricetypeId: this.pricetype?this.pricetype.code: null,
                        prioritySkladId: this.sklad?this.sklad.code:null,
                        secondSkladId: this.secondarySklad?this.secondarySklad.code:null,
                        torg12payer: this.item.torg12payer,
                        torg12consignee: this.item.torg12consignee,
                    })
            }
        },
        apollo: {
            item: {
                query: gql`
                    query($id: ID) {
                        item: agent(id: $id) {
                            id name address isArc kind{id} answer{id} pricetype{id} prioritySklad{id} secondSklad{id} agroup{id}
                            torg12payer torg12consignee
                        }
                    }`,
                variables() {
                    return {
                        id: this.id,
                    }
                },
                result({data, loading}) {
                    if (!loading){
                        if (this.kinds)
                            this.kindsLoaded(this.kinds)
                        if (this.agroups)
                            this.agroupsLoaded(this.agroups)
                        if (this.answers)
                            this.answerLoaded(this.answers)
                        if (this.pricetypes)
                            this.pricetypesLoaded(this.pricetypes)
                        if (this.sklads)
                            this.skladLoaded(this.sklads)
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>

