<template>
    <div class="tabs is-boxed no-print" style="margin-top: 1rem;">
        <ul>
            <li :class="{'is-active': item==1}">
                <a :href="'/#/audit/' + auditId + '/'">Внесение данных</a>
            </li>
            <li :class="{'is-active': item==2}">
                <a :href="'/#/reports/audit-shortage/' + auditId + '/'">Недостача</a>
            </li>
            <li :class="{'is-active': item==3}">
                <a :href="'/#/reports/audit-overage/' + auditId + '/'">Избыток</a>
            </li>
            <li :class="{'is-active': item==4}">
                <a :href="'/#/reports/audit-shortage-reverse/' + auditId + '/'">Нет на полке</a>
            </li>
        </ul>
    </div>        
</template>

<script>
export default {
    props: {
        item: Number,
        auditId: String,
        disable: Boolean,
    }
}
</script>