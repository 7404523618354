<template>
    <div class="container" v-if="checkGroup(user, 'Отчет по накладным')">
        <div v-if="error" class="container">
            <div class="notification is-danger my-error">
                {{error}}
            </div>
        </div>
        <section class="section">
            <h1>Накладные</h1>
            <div style="margin: 1.2rem 0 1.2rem 0;" class="no-print">
                <div class="field">
                    <label class="label">Ответственный:</label>
                    <!--div class="control select" :class="{'is-loading':$apollo.queries.answers.loading}">
                        <select v-model="answer_id">
                            <option value="">-</option>
                            <option :value="answer.id" v-for="answer in answers">{{answer.name}}</option>
                        </select>
                    </div-->
                    <div class="control select">
                        <select v-model="answer_id">
                            <option value="">-</option>
                            <option value="2">Михаил</option>
                            <option value="10">Артем</option>
                        </select>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Дата:</label>
                    <div class="control">
                        <date-picker
                            v-model="date"
                            class="date"
                            valueType="format"
                            :first-day-of-week=0
                            lang="ru"
                            format="DD-MM-YYYY"
                            placeholder="Дата"
                            id="from"
                            ></date-picker>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button
                            class="button is-primary submit"
                            :class="{'is-loading':$apollo.queries.todayOrderList.loading}"
                            :disabled="$apollo.queries.todayOrderList.loading || !answer_id || !date"
                            @click="submit">Отобразить</button>
                    </div>
                </div>
            </div>

            <table class="doclist" v-if="todayOrderList" :class="{'loading':$apollo.queries.todayOrderList.loading}">
                <thead>
                    <tr>
                        <th>Номер</th>
                        <th>Ск</th>
                        <th>Фото</th>
                        <th>Вычерк</th>
                        <th>Контрагент</th>
                        <th>Вес</th>
                        <th>Сумма</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="doc in todayOrderList" class="hover" :class="{'shipped': doc.img}" :key="doc.id">
                        <!--td><span class="action" @click="shipped(doc)">{{doc.code}}</span></td-->
                        <td>{{doc.code}}</td>
                        <td>{{doc.sklad}}</td>
                        <td><font-awesome-icon icon="image" v-if="doc.img"/></td>
                        <td>{{doc.nocrossout?'':'Вычерк'}}</td>
                        <td><router-link :to="'/reports/rashod-order/' + doc.code + '/'">{{doc.agentName}}</router-link></td>
                        <!--td><router-link :to="'/document/' + doc.id">{{doc.name}}</router-link></td-->
                        <td class="weight">{{doc.weight}}</td>
                        <td class="total">{{doc.sum|currency}}</td>
                    </tr>
                    <tr class="total">
                        <td></td><td></td><td></td><td></td><td></td>
                        <td class="weight">{{sumweight}} кг</td>
                        <td></td>
                    </tr>
                </tbody>

            </table>

            <!--a class="button no-print" @click="print" v-if="todayOrderList">Печать</a-->

        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"

    import DatePicker from 'vue2-datepicker'

    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import {format, parse} from 'date-fns'

    export default {
        name: 'TodayOrderList',
        components: {
            DatePicker,
        },
        props: [
            'user',
            'answer_in_id',
            'date_in',
        ],
        methods: {
            submit(){
                this.$router.push(
                    {
                        name: 'rashod-order-list1',
                        params: {
                            answer_in_id: this.answer_id,
                            date_in: this.date,
                        }
                    });
                this.skip=false;
            },
            /*shipped(item){
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql`mutation($code: String!, $shipped: Boolean!) {
                        markDocoutShipped(code: $code, shipped: $shipped) { newvalue }
                    }`,
                    variables: {
                        code: item.code,
                        shipped: item.shipped?false:true,
                    },
                }).then(data => {
                    this.sending=false;
                    this.error=null;
                    //console.log(data.data);
                    item.shipped=data.data.markDocoutShipped.newvalue;
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },*/
        },
        data(){
            return {
                date: this.date_in? this.date_in : format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
                error: null,
                answer_id: this.answer_in_id,
                skip: false,
                sumweight: null,
            }
        },
        apollo: {
            /*answers:{
                query: gql`
                    query{
                      answers{id name}
                    }
                `,
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },*/
            todayOrderList: {
                query: gql`
                    query($answerId: ID!, $date: Date!){
                        todayOrderList(answerId: $answerId, date: $date){
                            id code sum date agentName sklad img weight nocrossout
                        }
                    }`,
                variables() {
                    return {
                        answerId: this.answer_in_id,
                        date: format(parse(this.date,"dd-MM-yyyy", new Date()),"yyyy-MM-dd"),
                    }
                },
                skip(){
                    return this.skip || this.answer_in_id==null;
                },
                result({data, loading}) { // eslint-disable-line no-unused-vars
                    this.skip=true;
                    this.sumweight=0;
                    this.todayOrderList.forEach(e=>{this.sumweight+=e.weight});
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>
query{

}

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.doclist.loading{
        opacity: 0.33;
    }
    table.doclist{
        font-size: 95%;
        td{
            padding: 8px 5px;
        }
        th{
            text-align: center;
            padding: 5px 3px;
        }
        td.weight, td.total {
            white-space: nowrap;
            text-align: right;
        }
        tr.shipped {
            color: #aaa;
            a {
                color: #99b5e2;
            }
        }
        tr.total {
            font-weight: bold;
        }
    }

</style>

