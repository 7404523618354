<template>
    <div>
        <div class="field is-grouped">
            <div class="control">
                <label class="label">
                    Имя
                </label>
                <input type="text" class="input" v-model="name" 
                        :class="{'is-danger': !firstSaveAttept && (!name || !name.trim())}">
            </div>
            <div class="control">
                <label class="label">
                    Красивое имя для сайта
                </label>
                <input type="text" class="input" v-model="beautyName">
            </div>
            <div class="control">
                <label class="label">
                    Код
                </label>
                <input type="text" class="input" v-model="nomer">
            </div>
        </div>     

        <div class="columns">
            <div class="column control">
                <label class="label">
                    Категория
                </label>
                <treeselect 
                    v-model="categoryId" 
                    :multiple="false" 
                    :options="categoriesTree" 
                    :class="{'is-danger': !firstSaveAttept && !categoryId}"/>
            </div>

            <div class="column control">
                <label class="label">
                    Дополнительное поле поиска
                </label>
                <input type="text" class="input" v-model="extsearch">
            </div>
        </div>

        <div class="field">
            <div class="control">
                <label class="label">
                    <input class="checkbox" type="checkbox" v-model="transformSbisPrice">
                    Трансформация цены для СБИС
                </label>
            </div>
        </div>     

        <div class="columns" style="margin-top: 4rem;">
            <div class="column">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            Ед.измерения
                        </label>
                        <input type="text" class="input" v-model="unit" style="max-width: 120px;">
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            Вес (нетто) кг.
                        </label>
                        <currency-input v-model="weight" class="input"
                            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'ru'}"
                            style="width: 120px" />
                    </div>
                </div>        
                <div class="field">
                    <div class="control">
                        <label class="label">
                            <input class="checkbox" type="checkbox" v-model="isWeight">
                            Весовой товар
                        </label>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            В упаковке
                        </label>
                        <currency-input v-model="inbox" class="input"
                            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 0, locale: 'ru'}"
                            style="width: 120px" />

                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label class="label">
                            <input class="checkbox" type="checkbox" v-model="packOrderOnly"
                                @keydown.esc="onEscape"
                                >
                            Заказ только упаковками
                        </label>
                    </div>
                </div>        
            </div>
        </div>

        <div class="columns" style="margin-top: 4rem;">

            <div class="column is-one-third">
                <div class="control">
                    <label class="label">
                        Цена реализации
                    </label>
                    <currency-input v-model="price" class="input"
                        :options="{ currency: 'RUB', locale: 'ru'}"/>
                </div>
            </div>

            <div class="column is-one-third">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            Цена оптовая
                        </label>
                        <currency-input v-model="price5" class="input" :options="{ currency: 'RUB', locale: 'ru'}" />
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label class="label">
                            <input class="checkbox" type="checkbox" v-model="excludePrice5Koef">
                            Исключить из расчета оптовой цены
                        </label>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <label class="label">
                            Индивидуальный коэфициент расчета оптовой цены
                        </label>
                        <currency-input v-model="tuPrice5Koef" class="input"
                            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 2, locale: 'ru'}"
                            style="width: 120px" />
                    </div>
                </div>
            </div>

            <div class="column is-one-third">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            Цена доставки
                        </label>
                        <currency-input v-model="price7" class="input" :options="{ currency: 'RUB', locale: 'ru'}" />
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label class="label">
                            <input class="checkbox" type="checkbox" v-model="excludePrice7Koef">
                            Исключить из расчета цены доставки
                        </label>
                    </div>
                </div>     
                <div class="field">
                    <div class="control">
                        <label class="label">
                            Индивидуальный коэфициент расчета цены доставки
                        </label>
                        <currency-input v-model="tuPrice7Koef" class="input"
                            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 2, locale: 'ru'}"
                            style="width: 120px" />
                    </div>
                </div>
            </div>
        </div>     

        <div class="field has-addons">
            <div class="control">
                <input type="text" class="input" placeholder="Штрихкод" ref="barcodeInput" v-model="barcodeValue"
                        @keypress.enter="barcodeChange"
                        >
            </div>
            <div class="control">
                <a class="button" @click="barcodeChange">
                    Добавить
                </a>
            </div>
        </div>
        <ul class="barcode-list">
            <li v-for="b in barcodes" :key="b.id">{{b.value}} <span class="action" @click="deleteBarcode(b.value)">Удалить</span></li>
        </ul>

        <div class="field is-grouped">
            <div class="control">
                <button class="button is-primary" @click="save" :class="{'is-loading': sending}" :disabled="sending || xx()">Сохранить</button>
            </div>
            <div class="control">
                <button class="button is-primary" @click="saveAndNew" :class="{'is-loading': sending}" :disabled="sending || xx()">Сохранить и новая карточка</button>
            </div>
            <div class="control">
                <button class="button" @click="createNew" :disabled="sending">Сбросить</button>
            </div>
        </div>

        <TitleWithCheckModal :isActive='created'>
            Запись создана
            <div class="field" style="padding: .5rem 0;">
                <button class="button" @click="created=false;">Продолжить</button>
            </div>
        </TitleWithCheckModal>
        

        <div style="margin-bottom: 5rem;"></div>
    </div>    
</template>

<script>
    import gql from "graphql-tag"
    import TuMixin from './TuMixin'
    import DictItemMixin from './DictItemMixin.vue'
    import TitleWithCheckModal from '../TitleWithCheckModal'
    export default {
        data: ()=>({
            selected: [],
            created: false,
            barcodes: [],

            name: null,
            beautyName: null,
            nomer: null,
            categoryId: null,
            extsearch: null,
            transformSbisPrice: false,
            unit: null,
            isWeight: false,
            weight: null,
            inbox: null,
            packOrderOnly: false,
            price: null,
            excludePrice5Koef: false,
            excludePrice7Koef: false,
            tuPrice5Koef: null,
            tuPrice7Koef: null,
        }),
        props: {
            user: Object,
        },
        mixins: [
            TuMixin, DictItemMixin,
        ],
        components: {
            TitleWithCheckModal,
        },
        methods: {
            xx(){
                return this.checkGroup(this.user, 'Справочник товаров ReadOnly')
            },
            createNew(){
                this.name = null
                this.beautyName = null
                this.nomer = null
                this.categoryId = null
                this.extsearch = null
                this.transformSbisPrice = false
                this.unit = null
                this.isWeight = false
                this.weight = null
                this.inbox = null
                this.packOrderOnly = false
                this.price = null
                this.excludePrice5Koef = false
                this.excludePrice7Koef = false
                this.tuPrice5Koef = null
                this.tuPrice7Koef = null
                this.firstSaveAttept = true
                this.barcodes = []
            },
            createCopy(){
                this.nomer = null
                this.firstSaveAttept = true
                this.barcodes = []
            },
            barcodeChange() {
                this.barcodes.push({id: null, value: this.barcodeValue})
                this.barcodeValue = null
                this.deleted = false
            },
            deleteBarcode(value){
                //this.barcodes.find
                this.barcodes = this.barcodes.filter(function( obj ) {
                    return obj.value !== value;
                });
            },
            save(){
                this.saveX(this.createNew)
            },
            saveAndNew(){
                this.saveX(this.createCopy)
            },
            saveX(fPost){
                this.firstSaveAttept = false;
                if (!this.name || !this.name.trim() || !this.categoryId)
                    return false;

                this.sending = true;
                this.$apollo.mutate({
                    mutation: gql`mutation(
                            $name: String!,
                            $nomer: String,
                            $categoryId: ID,
                            $extsearch: String, 
                            $beautyName: String, 
                            $packOrderOnly: Boolean!,
                            $isWeight: Boolean!,
                            $weight: Decimal,
                            $unit: String,
                            $inbox: Int,
                            $excludePrice7Koef: Boolean!, 
                            $tuPrice7Koef: Decimal,
                            $excludePrice5Koef: Boolean!, 
                            $tuPrice5Koef: Decimal,
                            $transformSbisPrice: Boolean,
                            $prices: [PriceInputType]
                            $barcodes: [BarcodeInputType]!
                            ) {
                        tuExt(
                            name: $name, 
                            nomer: $nomer,
                            categoryId: $categoryId,
                            extsearch: $extsearch,
                            beautyName: $beautyName,
                            packOrderOnly: $packOrderOnly,
                            isWeight: $isWeight,
                            weight: $weight,
                            unit: $unit,
                            inbox: $inbox,
                            excludePrice7Koef: $excludePrice7Koef,
                            tuPrice7Koef: $tuPrice7Koef,
                            excludePrice5Koef: $excludePrice5Koef,
                            tuPrice5Koef: $tuPrice5Koef,
                            transformSbisPrice: $transformSbisPrice,
                            prices: $prices,
                            barcodes: $barcodes
                        ) { ok id }
                    }`,
                    variables: {
                        name: this.name,
                        nomer: this.nomer,
                        categoryId: this.categoryId,
                        extsearch: this.extsearch,
                        beautyName: this.beautyName,
                        packOrderOnly: this.packOrderOnly,
                        isWeight: this.isWeight,
                        weight: this.weight,
                        unit: this.unit,
                        inbox: this.inbox,
                        excludePrice7Koef: this.excludePrice7Koef,
                        tuPrice7Koef: this.tuPrice7Koef?(1+this.tuPrice7Koef/100):null,
                        excludePrice5Koef: this.excludePrice5Koef,
                        tuPrice5Koef: this.tuPrice5Koef?(1+this.tuPrice5Koef/100):null,
                        transformSbisPrice: this.transformSbisPrice,
                        barcodes: this.barcodes,
                        prices: [
                            {id: null, pricetypeId: null, value: this.price, deleted: false}, 
                            {id: null, pricetypeId: 5, value: this.price5, deleted: false}, 
                            {id: null, pricetypeId: 7, value: this.price7, deleted: false}, 
                        ]
                    },
                }).then(data => {
                    this.sending = false;
                    fPost();
                    this.created = true;
                    this.timerid = setTimeout(() => {
                        this.created = false;
                    }, 3200);

                    /*this.$emit('factAdded', parseInt(this.amount));
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                    //this.$emit('close');
                }).catch(error => {
                    this.sending=false;
                    this.$emit('error', error);
                    //this.$apollo.queries.auditFacts.refetch();
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    ul.barcode-list{
        li{
            padding: 5px 0 5px 0;
        }
        margin: 1rem 0 2rem 0;
    }

</style>