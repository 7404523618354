<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <loading v-if="$apollo.queries.sumFruitsReport.loading"></loading>
        <section class="section">
            <h1>Сводный отчет по заявкам на фрукты</h1>

            <div class="field">
                <label class="label">Дата: {{ date }}</label>
                <div class="control">
                    <date-picker
                        v-model="date"
                        class="date"
                        valueType="format"
                        :first-day-of-week=0
                        lang="ru"
                        format="DD-MM-YYYY"
                        placeholder="Дата"
                        id="from"
                        ></date-picker>
                </div>
            </div>
            <div>
                <ul>
                    <li v-for="r in route">
                        <label class="checkbox">
                            <input type="checkbox" class="checkbox" :id="r.id" :value="r.id"  v-model="checkedRoutes"> {{ r.name }}
                        </label>
                    </li>
                </ul>
            </div>
            <table class="report striped">
                <tr v-for="(i, idx) in sumFruitsReport">
                    <td>{{ idx+1 }}</td>
                    <td>{{ i.name }}</td>
                    <td style="text-align: right;">{{ i.amount }}</td>
                    <td style="text-align: center;">{{ i.measureName }}</td>
                </tr>
            </table>
        </section>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import {format, parse } from 'date-fns'
    import Loading from '../Loading.vue'

    import gql from "graphql-tag"

    export default {
        components: {
            DatePicker, 
        },
        props: [
            'user',
        ],
        data(){
            return {
                date: format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
                checkedRoutes: [],
            }
        },
        methods: {
        },
        apollo: {
            sumFruitsReport: {
                query: gql(`query($date: Date!, $routes: [ID]!){sumFruitsReport(date: $date, routes: $routes){name amount measureName}}`),
                variables(){
                    return { 
                        date: format(parse(this.date,"dd-MM-yyyy", new Date()),"yyyy-MM-dd"),
                        routes: this.checkedRoutes,
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
                skip(){
                    return !this.date;
                },
            },
            route: {
                query: gql`query{route{id name}}`,
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            }
        },
	}
</script>

<style lang="scss" scoped>
</style>

