<template>
    <div class="container section" v-if="checkGroup(user, 'Ревизия')">
        <loading v-if="$apollo.queries.reportShortageReverse.loading"></loading>
        <!--font-awesome-icon icon="arrow-left" id="backbutton" @click="$router.go(-1)"/-->
        <audit-detail 
            :detailsActive="detailsActive" 
            @close="detailsActive=false"
            :detail="detail"
            :inOutHistoryMysql="inOutHistoryMysql"
            :detailTuId="detailTuId"/>
        <h1>Ревизия. Недостача (не встречено на полке)</h1>
        <h3 v-if="reportShortageReverse">
            Склад: {{reportShortageReverse.audit.sklad.name}}. Начало {{reportShortageReverse.audit.created|datetime}}
        </h3>
        <div v-if="audit">Примечание: {{reportShortageReverse.audit.info}}</div>

        <audit-menu :item='4' :auditId='auditId'>
        </audit-menu>

        <table class="report striped" style="margin-top: 2rem;">
            <thead>
                <tr>
                    <th>#</th>
                    <th>id</th>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>Учет</th>
                    <!--th>Факт</th>
                    <th>Списано</th-->
                    <th>Недостача</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in reportShortageReverse.report" class="hover" :key="item.tuId">
                    <td>{{index+1}}</td>
                    <td>{{item.tuId}}</td>
                    <td>{{item.nomer}}</td>
                    <td>{{item.name}}</td>
                    <td>
                        <a @click="details(item.tuId, {name: item.name, nomer: item.nomer, ts:item.ts, editor: item.editor, fact: item.fact})">
                            {{item.acount|number-null03}}
                        </a>
                    </td>
                    <!--td>{{item.fact || 0}}</td>
                    <td>{{item.adj}}</td-->
                    <td>{{ (item.acount - (item.fact || 0) - item.adj) |number-null03 }}</td>
                </tr>
            </tbody>
        </table>
        <div class="field">
            <button class="button is-primary" @click="createShortageReverse" :class="{'is-loading': sending}" :disabled="sending || $apollo.queries.reportShortageReverse.loading">
                Создать расходную накладную
            </button>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import AuditSO from './AuditSO.vue'
    export default {
        mixins: [
            AuditSO,
        ],
        methods: {
            createShortageReverse(){
                this.sending=true;
                this.error=null;
                this.$apollo.mutate({
                    mutation: gql`mutation($auditId: ID!) {
                        createShortageReverse(auditId: $auditId) { ok }
                    }`,
                    variables: {
                        auditId: this.auditId,
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    this.sending=false;
                    this.error=null;
                    this.$apollo.queries.reportShortageReverse.refetch();
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                    this.$apollo.queries.reportShortageReverse.refetch();
                });
            },
        },
        apollo: {
            reportShortageReverse: {
                query: gql`
                    query($id: ID!){
                        reportShortageReverse(id: $id){
                            audit {
                                id created info relative sybase
                                sklad {
                                    id name
                                }
                            }
                            report {
                                tuId nomer name acount fact adj ts editor
                            }
                        }
                    }`,
                variables() {
                    return {
                        id: this.auditId,
                        }
                },
                errorPolicy: 'all',
                result ({ data, loading }) { // eslint-disable-line no-unused-vars
                    if (!loading) {
                        //this.fill();
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },

        }
    }
</script>

<style lang="scss" scoped>
    table {
        td:nth-child(1), td:nth-child(2), td:nth-child(5), td:nth-child(6){
            text-align: right;
        }
    }
</style>