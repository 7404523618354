<template>
    <div class="container" v-if="user && !user.isAnonymous">

        <div v-if="error" class="no-print">
            <div class="notification is-danger my-error">
                {{error}}
            </div>
        </div>

        <div class="loading" v-if="$apollo.queries.rashodOrder.loading"></div>
        <section class="section" v-if="rashodOrder">
            <font-awesome-icon icon="arrow-left" id="backbutton" @click="$router.go(-1)" class="no-print"/>
            <h1>{{order_id}} &mdash; {{rashodOrder.date|date-ddmmyy}} &mdash; {{rashodOrder.agentName}}</h1>
            <!--p style="margin-bottom: 1.2rem;" class="no-print">Дата: {{rashodOrder.date}}</p-->

            <table class="report doc_income striped" v-if="rashodOrder">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Наименование</th>
                        <th>Кол-во</th>
                        <th class="checkout"></th>
                        <th>Ед</th>
                        <th>Упак</th>
                        <th>Цена</th>
                        <th>Сумма</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(pos, index) in rashodOrder.positions" class="hover" :key="pos.id">
                        <td class="idx">{{index+1}}</td>
                        <td>{{pos.name}}</td>
                        <td class="amount">{{pos.amount}}</td>
                        <td class="checkout"></td>
                        <td class="edizm">{{pos.edizm}}</td>
                        <td class="inbox">{{Math.floor(pos.amount/pos.inbox)}}/{{pos.inbox}}<span v-show="pos.amount%pos.inbox>0" v-once>+{{pos.amount%pos.inbox}}</span></td>
                        <td class="price">{{pos.price|currency}}</td>
                        <td class="total">{{pos.total|currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="8" style="text-align: right; font-size: 110%; font-weight: bold;">
                            Итого: <span v-if="rashodOrder">{{rashodOrder.total|currency}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a class="button no-print" @click="print">Печать</a>
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"
    import UploadFromCamera from "@/components/UploadFromCamera"
    import mediumZoom from 'medium-zoom'

    export default {

        name: 'StockmanOrder',
        components: {
        },
        props: [
            'order_id', 'user',
        ],
        methods: {
            print(){
                this.$apollo.mutate({
                    mutation: gql`mutation($code: String!) { markAsPrinted(code: $code) { ok } }`,
                    errorPolicy: 'all',
                    variables: {
                        "code": this.order_id,
                    },
                }).then(data => {
                }).catch(error => {
                    this.error=error;
                });
                this.$emit('print');
            },
        },
        data(){
            return {
                error: null,
            }
        },
        apollo: {
            rashodOrder: {
                query: gql`
                    query($code: String!){
                        rashodOrder(code: $code) {
                            date
                            agentName
                            total
                            nocrossout
                            images{id img}
                            positions {
                                id name amount price total edizm inbox
                            }
                        }
                    }`,
                errorPolicy: 'all',
                variables() {
                    return {
                        code: this.order_id,
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.doc_income{
        td.idx, td.amount, td.price, td.total, td.edizm, td.inbox {
            text-align: right;
        }
    }
    img.img {
        max-height: 100px;
        cursor: pointer;
        margin: 10px 10px 0 0;
    }
    img.zoomed {
        max-height: 1200px !important;
    }
    .img.selected {
        border: 3px solid blue;
    }
    #backbutton {
        width: 32px;
        height: 32px;
        margin-bottom: 1.2rem;
        cursor: pointer;
    }
    .checkout{
        display: none;
    }
    @media print {
        table.doc_income{
            margin: 0.5rem 0;
            width: 100%;
            max-width: 100%;
            .checkout{
                display: table-cell;
                width: 5mm;
            }
            td, th{
                border: .2mm solid #999;
                font-size: 11px;
                padding: .5mm;
            }
            td.checkout{
                div {
                    border: 1px solid #999;
                    display: block;
                    width: 4mm;
                    height: 4mm;
                }
            }
        }
    }
</style>

