<template>
    <div>
        <label class="label update-data">Склад:<span @click="onUpdate" title="Ctrl+ click - update data">x</span></label>
        <v-select :options="items" v-model="selected" @input="onInput" />
    </div>
</template>

<script>
import gql from "graphql-tag"

var cacsh = null

export default {
    name: "SkladSelect",
    props:{
        value: {
            default: null
        }

    },
    data(){
        return{
            items: [],
            selected: null,
            inited: true
        }
    },
    created(){
        try {
            let items = JSON.parse(localStorage.getItem("sklads"));
            if(items){
                this.items = items;
                this.setSelected();
            }
            else
                this.inited = false;
        } 
        catch (e) {
            this.inited = false;
        }
    },
    watch: {
        value(v) {
            if(!this.selected || this.selected.code!=v){
                this.setSelected();
                this.onInput(this.selected);
            } 
        },
    },
    methods:{
        onInput(item) {
            this.$emit('input', item? item.code : null);
        },
        setSelected(){
            if(this.value){
                this.selected = this.items.find(x => x.code == this.value)||null;
            }
        },
        onUpdate(){
            this.items = [];
            this.inited = false;
        },
    },
    apollo: {
        sklads: {
            query: gql`query{sklads {code:id label:name}}`,
            skip(){ 
                return this.inited 
            },
            result({ data, loading }) {
                if (!loading) { 
                    //console.log(data)
                    this.items = data.sklads
                    this.setSelected();
                    localStorage.setItem("sklads", JSON.stringify(this.items))
                }
            },
        },
    }
}
</script>
<style>
.update-data span{
    visibility: hidden
}
.update-data:hover span{
    visibility: visible
}
</style>