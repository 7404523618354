<template>
    <div class="container" v-if="checkGroups(user, ['Проставление веса'])">
        <weight-dialog v-if="dialogItem!=null" :item="dialogItem" 
                :scalesValue="inputWeight" 
                @result="change(arguments[0], dialogItem);dialogItem=null;"
                @close="dialogItem=null">
        </weight-dialog>
        <h1>Проставление веса</h1>
        <div>Показания весов:&nbsp;<span :class="{'weight-sedated': isWeightSedated}">{{ inputWeight }} кг.</span></div>

        <div class="control" style="margin-top: 2rem;">
            <label class="radio" v-for="r in route"><input type="radio" name="route" v-model="routeId" @change="routeChange" :value="r.id"> {{ r.name }}</label>
            <label class="radio"><input type="radio" name="route" v-model="routeId" :value="null"> Без маршрута</label>
        </div>

        <div class="field">
            <div class="control">
                <date-picker 
                    v-model="dateFrom" 
                    class="date"
                    valueType="format" 
                    :first-day-of-week=0 
                    lang="ru" 
                    format="DD-MM-YYYY" 
                    placeholder="Дата с"
                    id="from"
                    ></date-picker>
                &mdash;
                <date-picker 
                    v-model="dateTo" 
                    class="date"
                    valueType="format" 
                    :first-day-of-week=1 
                    lang="ru" 
                    format="DD-MM-YYYY" 
                    placeholder="Дата по"
                    id="to"
                    ></date-picker>
            </div>
        </div>


        <div class="tabs is-boxed">
            <ul>
                <li :class="{'is-active': tab=='demands'}"><a @click="tab='demands'">Заявки</a></li>
                <li :class="{'is-active': tab=='fruits'}"><a @click="tab='fruits'">Фрукты/овощи</a></li>
            </ul>
        </div>

        <div style="display: flex;" v-if="tab=='demands'">
            <div class="d1">
                <button class="button" @click="selectedId=null;$apollo.queries.demandWeightList.refetch()">Обновить</button>
                <ul class="demand-list" :class="{'loading': $apollo.queries.demandWeightList.loading}">
                    <li v-for="i in demandWeightList" @click="selectedId=i.id" :class="{'current': selectedId==i.id}">
                        <div>
                            {{ i.date|date-ddmmyy }} &mdash;
                        </div> 
                        <div>{{ i.agent.name }} <div class="info">{{i.info}}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div style="margin-top: 3rem;">
                <table class="report fruit-list1" :class="{'loading': $apollo.queries.demandWeightItem.loading}">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Цена</th>
                            <th>Зак</th>
                            <th>Ед</th>
                            <th>Факт</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr v-for="item in demandWeightItem" class="x hover" style="cursor: pointer" @click="openWeightDialog(item)">
                        <td class="img"><img :src="'https://kirsa.9733.ru/' + item.img"/></td>
                        <td>{{ item.name }}</td>
                        <td style="text-align: right;">{{ item.price }}</td>
                        <td style="text-align: right;">{{ item.amount }}</td>
                        <td>{{ item.measureName }}</td>
                        <td style="text-align: right;">
                            {{item.weightAmount}} кг.
                            <!--currency-input 
                                style="width: 120px; text-align: right; margin-left: 1rem"
                                v-model="item.weightAmountEdit"
                                @change="change($event, item)"
                                class="input" id='inputfact'
                                :options="{ currency: 'EUR', 
                                            currencyDisplay: 'hidden', 
                                            precision: 3,
                                            nullOnClean: true
                                        }"
                                /--> 
                        </td>
                    </tr>
                </table>
                <div v-if="selectedId" style="display: flex; margin: 0.5rem 0; align-items: center; justify-content: flex-start;">
                    <v-select :options="fruits" v-if="fruits" v-model="newFruit"></v-select>
                    <button class="button" style="margin-left: 0.5rem;" @click="addFruit(newFruit)" :disabled="!newFruit">Добавить</button>
                </div>
                <button class="button" v-if="selectedId" @click="sendDemand(selectedId)" :disabled="sending">Отправить</button>
            </div>
        </div>

        <div style="display: flex;" v-if="tab=='fruits'">
            <div style="margin-top: 20px; margin-right: 3rem;">
                <button class="button" @click="selectedTuId=null;$apollo.queries.demandWeightListByTu.refetch()">Обновить</button>
                <ul class="demand-list2" :class="{'loading': $apollo.queries.demandWeightListByTu.loading}">
                    <li v-for="i in demandWeightListByTu" @click="selectedTuId=i.id" :class="{'current': selectedTuId==i.id}">
                        <img :src="'https://kirsa.9733.ru/' + i.img"/> 
                        <div>{{ i.name }}</div>
                    </li>
                </ul>
            </div>
            <div class="d4">
                <table class="report" :class="{'loading': $apollo.queries.demandWeightItemByTu.loading}">
                    <tr v-for="item in demandWeightItemByTu" class="x hover" style="cursor: pointer" @click="openWeightDialog(item)">
                        <td>{{ item.date|date-ddmmyy }}</td>
                        <td>{{ item.agentName }}</td>
                        <td style="text-align: right;">{{ item.amount }}</td>
                        <td>{{ item.measureName }}</td>
                        <td>{{ item.weightAmount }} кг</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    //import ScalesMixin from "../ScalesMixin"
    import {format, parse} from 'date-fns'
    import WeightDialog from "./WeightDialog.vue"
    import DatePicker from 'vue2-datepicker'

    export default {
        props: {
            user: Object,
            isScalesConnected: Boolean,
            inputWeight: Number,
            isWeightSedated: Boolean,
            scales: Object,
        },
        //mixins: [ScalesMixin,],
        components: { WeightDialog, DatePicker, },
        data: function(){
            return {
                sending: false,
                itemModal: false,
                bread: null,
                item: null,
                error: null,
                date: null,
                intervalid1: null,
                selectedId: null,
                selectedTuId: null,
                interwalWeight: null,
                weightValue: null,
                tab: 'demands',
                routeId: null,
                scalesCreatingTimer: null,
                dialogItem: null,
                dateFrom: format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
                dateTo: format((new Date()).setDate((new Date()).getDate()+1),'dd-MM-yyyy'),
                newFruit: null,
            }
        },
        created() {
            //this.scalesCreatingTimer = setTimeout(() => {
            //    this.scalesCreating();
            //}, 500);
        },
        methods: {
            routeChange(){
                this.selectedId=null;
            },
            openWeightDialog(item){
                this.dialogItem=item;
            },
            addFruit(newFruit){
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!, $tuId: ID!, $price: Decimal!) {
                        addToDemand(id: $id, tuId: $tuId, price: $price) { ok }
                    }`,
                    variables: {
                        id: this.selectedId,
                        tuId: this.newFruit.code,
                        price: this.newFruit.price,
                    },
                }).then(data => {
                    this.$apollo.queries.demandWeightItem.refetch();
                }).catch(error => {
                    this.$emit('error', JSON.stringify(error.message))
                });

                this.newFruit=null;

            },
            async startGettingValues(){
                /*await this.connectScales(true)
                if (!this.isScalesConnected){
                    await this.connectScales(true)
                }*/
                
                this.interwalWeight = setInterval(async () => {
                    console.log('---', this.scales)
                    if (this.scales && this.scales.scalesImpl){
                        console.log('---')
                        this.weightValue = await this.scales.scalesImpl.getValue()
                    }
                }, 250);
            },
            stopGettingValues(){
                clearInterval(this.interwalWeight)
                this.disconnectScales()
                this.weightValue=null
            },
            async readWeight(){
                //await this.connectScales();
                //this.weightValue = await this.scales.scalesImpl.getValue()
                this.interwalWeight = setInterval(async () => {
                    this.weightValue = await this.scales.scalesImpl.getValue()
                }, 500);
                //await this.disconnectScales();
            },
            /*changeByTu(x, item){
                if (x==item.weightAmount)
                    return
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!, $demandGid: ID!, $value: Decimal) {
                        setFruitWeight(id: $id, demandGid: $demandGid, value: $value) { ok }
                    }`,
                    variables: {
                        id: item.dlId,
                        demandGid: item.gid,
                        value: x,
                    },
                }).then(data => {
                    this.sending=false;
                    item.weightAmount=x;
                }).catch(error => {
                    this.sending=false;
                    this.$emit('error', JSON.stringify(error.message))
                });
            },*/
            change(x, item){
                if (x==item.weightAmount)
                    return
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!, $demandGid: ID!, $value: Decimal) {
                        setFruitWeight(id: $id, demandGid: $demandGid, value: $value) { ok }
                    }`,
                    variables: {
                        id: item.id,
                        demandGid: item.demandGid,
                        value: x,
                    },
                }).then(data => {
                    this.sending=false;
                    item.weightAmount=x;
                }).catch(error => {
                    this.sending=false;
                    this.$emit('error', JSON.stringify(error.message))
                });
            },
            sendDemand(gid){
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!) {
                        setDemandCompleted(id: $id) { 
                            ok outgoId 
                        }
                    }`,
                    variables: {
                        id: gid,
                    },
                }).then(data => {
                    this.sending=false;
                    this.selectedId=null;
                    this.$apollo.queries.demandWeightList.refetch();
                    console.log(data);
                    console.log(data.data.setDemandCompleted.outgoId);
                    window.open('/#/outgo/'+data.data.setDemandCompleted.outgoId, '_blank');
                    /*
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                }).catch(error => {
                    this.sending=false;
                    this.$emit('error', JSON.stringify(error.message))
                });
            },
        },
        apollo: {
            route: {
                query: gql`query {route{id name}}`,
                skip() { return this.user == null || this.user.isAnonymous},
                error(error) {
                    this.$emit('error', JSON.stringify(error.message))
                },
            },
            demandWeightList: {
                query: gql`
                    query($id: ID, $dateFrom: Date!, $dateTo: Date!) {
                        demandWeightList(routeId: $id, dateFrom: $dateFrom, dateTo: $dateTo) {
                            id date agent{id name} info
                        }
                    }`,
                variables() { 
                    return { 
                        id: this.routeId, 
                        dateFrom: format(parse(this.dateFrom,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                        dateTo: format(parse(this.dateTo,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                    } 
                },
                skip() { return this.user == null || this.user.isAnonymous},
                result({data, loading}) { this.demandWeightItem=null; },
                error(error) { this.$emit('error', JSON.stringify(error.message))},
            },
            demandWeightItem: {
                query: gql`
                    query ($id: ID!, $dateFrom: Date!, $dateTo: Date!){
                        demandWeightItem(id: $id, dateFrom: $dateFrom, dateTo: $dateTo) {
                            id demandGid name amount weightAmount weightAmountEdit:weightAmount measureName
                            img price
                        }
                    }`,
                variables() { 
                    return { 
                        id: this.selectedId, 
                        dateFrom: format(parse(this.dateFrom,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                        dateTo: format(parse(this.dateTo,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                    } 
                },
                skip() { return !this.selectedId },
                error(error) { this.$emit('error', JSON.stringify(error.message))},
            },
            demandWeightListByTu: {
                query: gql`
                    query($id: ID, $dateFrom: Date!, $dateTo: Date!) {
                        demandWeightListByTu(routeId: $id, dateFrom: $dateFrom, dateTo: $dateTo) {
                            id name img
                        }
                    }`,
                variables() {
                    return {
                        id: this.routeId,
                        dateFrom: format(parse(this.dateFrom,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                        dateTo: format(parse(this.dateTo,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                    }
                },
                skip() { return this.user == null || this.user.isAnonymous},
                error(error) {
                    this.$emit('error', JSON.stringify(error.message))
                },
            },
            demandWeightItemByTu: {
                query: gql`
                    query ($tuId: ID!, $routeId: ID, $dateFrom: Date!, $dateTo: Date!){
                        demandWeightItemByTu(tuId: $tuId, routeId: $routeId, dateFrom: $dateFrom, dateTo: $dateTo) {
                            demandGid:gid id:dlId date agentName amount weightAmount weightAmountEdit:weightAmount measureName
                        }
                    }`,
                variables() {
                    return {
                        tuId: this.selectedTuId,
                        routeId: this.routeId,
                        dateFrom: format(parse(this.dateFrom,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                        dateTo: format(parse(this.dateTo,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                    }
                },
                skip() {
                    return !this.selectedTuId
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message))
                },
            },
            fruits: {
                query: gql`
                    query ($matrixId: ID) {
                        fruits(matrixId: $matrixId) {
                            code:id label:name goodsId quantity price balance isWeight inbox quantityType img
                            images {id img} measures{id name}
                        }
                    }`,
                variables() {
                    return {
                        matrixId: 1,
                    }
                },
                skip() {
                    return this.user == null || this.user.isAnonymous
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
                result({data, loading}){
                    //data.fruits.forEach(e=>{
                    //})
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 150%;
    }

    .tabs{
        margin-top: 20px;
    }
    .buttons-values {
        display: flex;
        button, h1{
            margin-right: 14px;
        }
    }
    .demand-list {
        margin-top:2rem;
        li {
            padding: .5rem;
            cursor: pointer;
        }
    }

    .demand-list2 {
        margin-top:2rem;
        li {
            padding: .5rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            img {
                margin-right: 1rem;
                max-height: 40px;
            }
        }
    }

    .d1 {
        margin-top: 20px;
        margin-right: 3rem;
        ul{
            li>div{
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
                .info{
                    font-size: 80%;
                }
            }
        }
    }
    .d4 {
        margin-top: 3rem;
        table {
            tr>td{
                vertical-align: middle;

            }
        }
    }
    tr.x td {
        vertical-align: middle;
    }

    .fruit-list1 td.img img{
        max-height: 40px;
    }
</style>
