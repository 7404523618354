import gql from "graphql-tag"
export default {
    data(){
        return {
            skipAnswerFetching: true,
            answerFetchedOnce: false,
        }
    },
    mounted(){
        this.answerFetchedOnce=false;
        if (this.user){
            this.fetchAnswers();
        }
    },
    watch:{
        user(val) {
            if (val && !this.answerFetchedOnce)
                this.fetchAnswers();
        },
    },
    methods: {
        fetchAnswers(){
            if (this.answerFetchedOnce)
                return;
            this.answerFetchedOnce=true;
            try{
                let checksumAnswer = localStorage.getItem("checksum:answer")
                if (!checksumAnswer || checksumAnswer!=(this.user.id+'-'+this.user.userGroupsTsCnt+'-'+this.user.checksumAnswer)){
                    this.skipAnswerFetching=false;
                } else {
                    let storagedAnswers = JSON.parse(localStorage.getItem("json:answers"))
                    if (storagedAnswers==null){
                        this.skipAnswerFetching=false;
                    } else {
                        this.answers = storagedAnswers;
                        this.answerLoaded(this.answers);
                    }
                }
            } catch (e){
                this.skipAnswerFetching=false;
            }
        },
    },
    apollo: {
        answers: {
            query: gql`query{answers {code:id label:name}}`,
            skip() {
                return this.skipAnswerFetching
            },
            result ({data, loading}) {
                localStorage.setItem("json:answers", JSON.stringify(data.answers))
                localStorage.setItem("checksum:answer", (this.user.id+'-'+this.user.userGroupsTsCnt+'-'+this.user.checksumAnswer))
                this.answerLoaded(data.answers);
                this.skipAnswerFetching=true;
            },
        },
    },
}
