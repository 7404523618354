import gql from "graphql-tag"
export default {
    data(){
        return {
            skipHostFetching: true,
            hostFetchedOnce: false,
        }
    },
    mounted(){
        this.hostFetchedOnce=false;
        if (this.user){
            this.fetchHosts();
        }
    },
    watch:{
        user(val) {
            if (val && !this.hostFetchedOnce)
                this.fetchHosts();
        },
    },
    methods: {
        fetchHosts(){
            if (this.hostFetchedOnce)
                return;
            this.hostFetchedOnce=true;
            try{
                let checksumHost = localStorage.getItem("checksum:host")
                if (!checksumHost || checksumHost!=(this.user.id+'-'+this.user.userGroupsTsCnt+'-'+this.user.checksumHost)){
                    this.skipHostFetching=false;
                } else {
                    let storagedHosts = JSON.parse(localStorage.getItem("json:hosts"))
                    if (storagedHosts==null){
                        this.skipHostFetching=false;
                    } else {
                        this.hosts = storagedHosts;
                        this.hostLoaded(this.hosts);
                    }
                }
            } catch (e){
                this.skipHostFetching=false;
            }
        },
    },
    apollo: {
        hosts: {
            query: gql`query{hosts {code:id label:name}}`,
            skip() {
                return this.skipHostFetching
            },
            result ({data, loading}) {
                localStorage.setItem("json:hosts", JSON.stringify(data.hosts))
                localStorage.setItem("checksum:host", (this.user.id+'-'+this.user.userGroupsTsCnt+'-'+this.user.checksumHost))
                this.hostLoaded(data.hosts);
                this.skipHostFetching=true;
            },
        },
    },
}
