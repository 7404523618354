<template>
    <div class="container" v-if="checkGroups(user, ['Заказы', 'Продавец СБИС', 'Продавец'])">
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <section class="section">
            <h1>Заказ хлебобулочных изделий</h1>

            <div>
                Заказ хлеба с 13:00 по 18:59. Текущее время {{date|datetime}}
            </div>
            <div v-if="!orderTime()" style="margin-top: 2rem;">
                Заказ доступен с 13:00 по 18:59
            </div>
            <div v-else>
                <div style="margin: 1.5rem 0;">
                    <router-link to="/bread-confirm" class="button is-primary">
                        Перейти к подтверждению заказа</router-link>
                </div>

                <div class="tu" style="margin-top: 2rem;">
                    <TuListGrid
                        ref="tulist"
                        :tu="bread?bread:null"
                        :user="user"
                        @modcart="modcart"
                        :loading="$apollo.queries.bread.loading"
                        />
                </div>
            </div>

        </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import TuListGrid from './TuListGrid.vue'
    //import EditItem from '@/components/EditItem.vue'
    //import {format} from 'date-fns'
    //import _ from 'lodash'
    import CartMixin from './CartMixin'

    export default {
        name: 'FastOrder',
        components: {
            TuListGrid, // EditItem,
        },
        props: [
            'user',
            'category',
            'showmissing',
            'ppage',
            'q',
            ],
        mixins: [CartMixin],
        data: function(){
            return {
                itemModal: false,
                bread: null,
                item: null,
                error: null,
                date: null,
                intervalid1: null,
            }
        },
        created(){
            this.date=new Date();
            if (this.intervalid1){
                clearInterval(this.intervalid1);
            }
            this.intervalid1 = setInterval(() => {
                this.date=new Date();
            }, 1000);
        },
        methods: {
            getCartName(){
                return 'SECONDARY-CART-ID';
            },
            user_fetched(){
            },
            tu_refetch(){
            },
            orderTime(){
                let d=this.date;
                if (d.getHours()>=13 && (d.getHours()<18 || (d.getHours()==18 && d.getMinutes()<59)))
                    return true;
                return false;
            },
        },
        apollo: {
            bread: {
                query: gql`
                    query {
                        bread {
                            id name goodsId quantity price balance isWeight inbox quantityType img
                            images {id img}
                        }
                    }`,
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 150%;
    }
</style>
