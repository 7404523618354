<template>
    <section class="container" ref="agentList" v-show="user && !user.isAnonymous">
        <h1 class="title">Справочник контрагентов</h1>
        <div class="tabs is-boxed no-print">
            <ul>
                <li :class="{'is-active': tab=='search'}"><a @click="tab='search'">Поиск</a></li>
                <li :class="{'is-active': tab=='new'}"><a @click="tab='new'">Новая</a></li>
                <li :class="{'is-active': tab=='selected'}"><a @click="tab='selected'">Выбранное</a></li>
                <!--li :class="{'is-active': tab=='last'}"><a @click="tab='last'">Последние</a></li-->
            </ul>
        </div>

        <div class="field searchfield is-grouped is-grouped-multiline" v-show="tab=='search'">
            <div class="control" style="flex: 1;">
                <input placeholder="Найти" class="input" type="text" v-model="search" v-on:input="debounceInput"
                    style="min-width: 120px;"
                    ref="searchInput"
                    >
            </div>
            <p class="control">
                <CheckboxSlider @input="changeActiveOnly" :checked="activeOnly" ref="activeOnly">Только актив</CheckboxSlider>
            </p>
        </div>

        <div v-show="tab=='new'">
        </div>


        <div style="position: relative;" v-show="tab!='new'">
            <table v-if="list" class="report search-result striped" :class="{'loading': $apollo.queries.list.loading}">
                <thead>
                    <tr>
                        <th>Наименование</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in list.items" :key="item.id" class="hover" :class="{'is-arc': item.isArc}">
                        <td>
                            <router-link :to="'/dict/agent/' + item.id">{{item.name}}</router-link>
                        </td>
                        <td>{{item.address}}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <div v-if="list" class="count">
                Кол-во: {{list.count}}
            </div>
            <Pager
                :page="page"
                :count="list.count"
                :perpage="40"
                @change="changepage"
                v-if="list"
                ref="pager"/>
        </div>

        <div v-show="tab=='new'">
            <AgentNew :user="user" @error="$emit('error', arguments[0])"></AgentNew>
        </div>

    </section>
    <!--section class="container section" v-else>
        Недостаточно прав
    </section-->
</template>

<script>
    import gql from "graphql-tag"
    import ListMixin from '@/components/Dict/ListMixin.vue'
    import AgentNew from './AgentNew.vue'

    export default {
        name: 'AgentList',
        data: () => ({
                tab: 'search',
            }
        ),
        props: {
            user: Object,
        },
        mixins: [ListMixin, ],
        components: {
            AgentNew,
        },
        methods: {
            push(){
                this.$router.push(
                    {
                        name: 'agent-list',
                        query: {
                            q: this.q_debounced,
                            ao: this.activeOnly,
                            pg: this.page,
                        }
                    });
            },
        },
        apollo: {
            list: {
                query: gql`
                    query($q: String!, $activeOnly: Boolean, $offset: Int) {
                        list: agent2(search: $q, activeOnly: $activeOnly, offset: $offset) {
                            items {id name address isArc}
                            count
                        }
                    }`,
                variables() {
                    return this.tab=='search'?{
                        q: this.q_debounced,
                        activeOnly: this.activeOnly,
                        offset: (this.page-1)*40,
                    }:{
                    }
                },
                skip(){
                    return !this.q_debounced || this.tab=='new';
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>

