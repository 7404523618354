<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <div class="field is-grouped">
            <div class="control" style="width: 35%;">
                <label class="label">Склад:</label>
                <v-select :options="sklads" v-if="sklads" v-model="sklad"></v-select>
            </div>
            <div class="control" style="width: 35%;">
                <label class="label">Тип:</label>
                <div class="select">
                    <select v-model="doctype" class="input">
                        <option value="all">Всё</option>
                        <option value="waybill">Накладные</option>
                    </select>
                </div>
            </div>
        </div>
        <table class="report">
            <tr v-for="i in egaisDocuments" :key="i.id">
                <td><router-link :to="'/egais-list/' + i.id + '/'">{{i.id}}</router-link></td>
                <td>{{i.created|datetime}}</td>
                <td>{{i.fsrarId}}</td>
                <td>{{i.wbregid}}</td>
                <td>{{i.identity}}</td>
                <td>
                    <a :href="'https://kirsa.9733.ru/' + i.file">source</a>
                </td>
                <td>{{i.entity}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    export default {
        props: {
            user: Object,
        },
        data: ()=>({
            sklad: null,
            fsrarId: null,
            doctype: 'all',
        }),
        watch: {
            sklad: function (val){
                console.log(val)
                if (val){
                    this.fsrarId = this.sklad.fsrar
                } else 
                    this.fsrarId = null
            }
        },
        apollo: {
            sklads: {
                query: gql`query{sklads {code:id label:name fsrar} }`,
                result ({data, loading}) {
                    //if (!loading) { this.skladLoaded(data.sklads); }
                },
            },
            egaisDocuments: {
                query: gql`
                    query($fsrarId: String, $doctype: String){
                        egaisDocuments(fsrarId: $fsrarId, doctype: $doctype){
                            id file fsrarId created entity wbregid identity
                        }
                    }`,
                variables() {
                    return {
                        fsrarId: this.fsrarId,
                        doctype: this.doctype,
                    }
                },
                //result ({ data, loading }) {
                //    if (!loading) {
                //    }
                //},
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        }, 
    }
</script>