<template>
    <div>
        <label class="label">{{ label }}:</label>
        <date-picker v-model="value" class="deliverydate" valueType="format" :first-day-of-week="1" lang="ru"
            format="DD-MM-YYYY" :not-before="new Date()" placeholder="Дата"
            :not-after="(new Date()).setDate((new Date()).getDate() + 6)"  @input="onInput">
        </date-picker>
    </div>
</template>
    
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/index.css'

export default {
    props:{
        value: null,
        label: {
            type: String,
            default: 'Дата'
        }
    },
    components: { DatePicker },
    methods: {
        onInput() { 
            this.$emit('input', this.value);
        }
    },
}

</script>