<template>
<div>
    <div class="container" v-if="user && !user.isAnonymous">
        <div class="loading" v-if="$apollo.queries.sbisDocumentIncome.loading"></div>
        <section class="section" v-if="sbisDocumentIncome">
            <h1>{{sbisDocumentIncome.name}}</h1>
            <p>Дата: {{sbisDocumentIncome.date}}</p>
            <p v-if="!sbisDocumentIncome.priceChangeDate" class="price-not-changed"></p>
            <div>
                <table class="doc_income" v-if="sbisDocumentIncome">
                    <thead>
                        <tr>
                            <th>Код</th>
                            <th>Наименование</th>
                            <th>Кол-во</th>
                            <th>Цена</th>
                            <th>Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="pos in sbisDocumentIncome.positions" class="hover" :key="pos.code">
                            <td>{{pos.code}}</td>
                            <td>{{pos.name}}</td>
                            <td>{{pos.amount}}</td>
                            <td v-if="pos.price">{{pos.price|currency}}</td>
                            <td v-if="!pos.price">
                                <input class="input input-price" v-model="pos.input" type="text" placeholder="Цена">
                            </td>
                            <td v-if="pos.price || pos.input">{{(pos.price || pos.input)*pos.amount|currency}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5" style="text-align: right; font-size: 110%; font-weight: bold;">
                                Итого: <span v-if="sum()">{{sum()|currency}}</span>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <a class="button no-print" @click="$emit('print')">Печать</a>
        </section>
    </div>
    <div style="margin: 10px;" class="no-print">
                <!--div style="margin-top: 3rem;"><span  class="action" @click="show_json=!show_json">json</span></div-->
                <!--pre v-show='show_json' v-if="sbisDocumentIncome">
                    {{JSON.stringify(JSON.parse(JSON.parse(sbisDocumentIncome.json))['result'], undefined, 4)}}
                </pre-->
                <json-viewer
                    v-show='true || show_json' v-if="sbisDocumentIncome"
                    :expand-depth=0
                    copyable
                    boxed
                    expanded
                    :value="JSON.parse(JSON.parse(sbisDocumentIncome.json))"></json-viewer>

    </div>
    </div>
</template>

<script>

    import gql from "graphql-tag"

    export default {
        name: 'SbisDocument',
        components: {
        },
        props: [
            'id', 'user',
        ],
        methods: {
            sum(){
                if (this.sbisDocumentIncome){
                    var summa=0;
                    for (let i of this.sbisDocumentIncome.positions){
                        console.log(i);
                        if (i.price==null){
                            if (i.input){
                                summa += i.input*i.amount;
                            }
                            else{
                                summa=null;
                                break;
                            }
                        }
                        else
                            summa += i.price*i.amount;
                    }
                    return summa;
                }
                else{
                    return '';
                }
            }
        },
        data(){
            return {
                skip: true,
                show_json: false,
            }
        },
        apollo: {
            sbisDocumentIncome: {
                query: gql`
                    query($id: String!){
                        sbisDocumentIncome(id: $id) {
                            ok
                            json
                            name
                            date
                            positions {code name amount price}
                            priceChangeDate
                        }
                    }`,
                variables() {
                    return {
                        id: this.id,
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.doc_income{
        margin-top: 3rem;
        td, th{
            padding: 5px 5px;
            vertical-align: middle;
        }
        th{
            text-align: center;
        }
        td:nth-child(3), td:nth-child(4), td:nth-child(5){
            text-align: right;
            white-space: nowrap;
        }
        td:nth-child(1){
            text-align: center;
        }
    }
    .price-not-changed{
        &:before{
            content: 'Цена не изменена';
        }
        color: red;
        margin: 1rem 0;
    }
    .input-price{
        width: 80px;
    }
</style>

