<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <div class="title" @click="clearState()"> Приходные документы</div>
        <div class="row">
            <div class="p-1 col-12 col-sm-7 col-lg-4"><date-range-piker v-model="state.dateRange" @input="inputChange"/></div>
            <hosts-select class="p-1 col-12 col-sm-6 col-lg-4" v-model="state.hostId" emit-value="code" @input="inputChange" :user="user"/>
            <sklad-select class="p-1 col-12 col-sm-6 col-lg-4" v-model="state.skladId" emit-value="code" @input="inputChange" :user="user"/>
            <agent-search-select class="p-1 col-12 col-sm-6 col-lg-4" v-model="state.agent" @input="inputChange"/>
            <reasons-select class="p-1 col-12 col-sm-6 col-lg-4" v-model="state.reasonId" @input="inputChange" :user="user"/>
            <answers-select class="p-1 col-12 col-sm-6 col-lg-4" v-model="state.answerId" emit-value="code" @input="inputChange" :user="user"/>
        </div>
        <div> 
            <button
                style="float:right;margin: 5px;"
                class="button is-primary submit"
                :class="{'is-loading':$apollo.queries.incomeList.loading}"
                :disabled="$apollo.queries.incomeList.loading"
                @click="submit">Отобразить</button>
        </div>

        <table v-if="incomeList" class="mt-5 table is-striped is-fullwidth" :class="{'loading': $apollo.queries.incomeList.loading}">
            <thead>
                <tr>
                    <th>№</th>
                    <th>Дата</th>
                    <th>Поставщик</th>
                    <th>Ответственный</th>
                    <th>Склад</th>
                    <th>Сумма</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="i in incomeList" :key="i.id" class="hover">
                    <td>{{i.id}}</td>
                    <td>{{i.date|date-ddmmyy}}</td>
                    <td>
                        <router-link :to="'/income/' + i.id">{{i.agent.name}}</router-link>
                        <div style="font-size: 11px;">{{ i.description }}</div>
                    </td>
                    <td>{{i.answer?i.answer.name:null}}</td>
                    <td>{{i.sklad.name}}</td>
                    <td class="total">{{i.totalCalc|currency}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="incomeList.length">
                    <td colspan="5"></td>
                    <td colpsan="2" style="text-align: right; font-weight: bold;">Итого: {{total|currency}}</td>
                </tr>
                <div v-else>Нет элементов для отображения</div>
            </tfoot>
        </table>
    </div>
</template>

<script>
import gql from "graphql-tag"
import InOutListMixin from './InOutListMixin.vue'
import { formatDate } from '../common/FormControls'

export default {
    mixins: [InOutListMixin,],
    data(){
        return {
            skip: true,
        }
    },
    computed: {
        total: function () {
            let sum = 0;
            if (this.incomeList){
                this.incomeList.forEach(e => { 
                    sum += parseFloat(e.totalCalc)
                });
            }
            return sum;
        }
    },
    beforeCreate() {
        this.storage.name = 'IncomeList';
    },
    methods: {
        inputChange() {
            
        },
        submit(){
            this.skip=false;
            this.$apollo.queries.incomeList.refetch();
        },
    },
    apollo: {
        incomeList: {
            query: gql`
                    query($dateFrom: Date, $dateTo: Date, $answerId: ID, $reasonId: ID, $skladId: ID, $agentId: ID,$hostId: ID){
                        incomeList(dateFrom: $dateFrom, dateTo: $dateTo, answerId: $answerId, reasonId: $reasonId,
                            skladId: $skladId, agentId: $agentId, hostId: $hostId) {
                            id date createDt agent{id name} sklad{id name} answer{id name} totalCalc description
                        }
            }`,
            skip() {
                return !this.user || this.skip;
            },
            variables() {
                return {
                    dateFrom: formatDate(this.state.dateRange.from, 'yyyy-MM-dd'),
                    dateTo: formatDate(this.state.dateRange.to, 'yyyy-MM-dd'),
                    answerId: this.state.answerId,
                    reasonId: this.state.reasonId,
                    skladId: this.state.skladId,
                    hostId: this.state.hostId,
                    agentId: this.state.agent? this.state.agent.id : null,
                }
            },
            result ( data) {
                this.skip = true;
            },
            errorPolicy: 'all',
            error(error) {
                this.$emit('error', JSON.stringify(error.message));
            },
        },
    }
}
</script>

<style lang="scss" scoped>
td.total {
    text-align: right;
}
</style>