<template>
    <div class="container" v-if="checkGroup(user, 'Отчет по накладным')">
        <div v-if="error" class="container">
            <div class="notification is-danger my-error">
                {{error}}
            </div>
        </div>
        <section class="section">
            <h1>Заявки и накладные</h1>
            <div style="margin: 1.2rem 0 1.2rem 0;" class="no-print">
                <div class="field">
                    <label class="label">Дата:</label>
                    <div class="control">
                        <date-picker
                            v-model="date"
                            class="date"
                            valueType="format"
                            :first-day-of-week=0
                            lang="ru"
                            format="DD-MM-YYYY"
                            placeholder="Дата"
                            id="from"
                            ></date-picker>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button
                            class="button is-primary submit"
                            :class="{'is-loading':$apollo.queries.orderKladovshik.loading}"
                            :disabled="$apollo.queries.orderKladovshik.loading || !date"
                            @click="submit">Отобразить</button>
                    </div>
                </div>
            </div>

            <div class="tabs">
                <ul>
                    <li :class="{'is-active': tab=='zayavki'}"><a @click="tab='zayavki'">Заявки</a></li>
                    <li :class="{'is-active': tab=='nakl'}"><a @click="tab='nakl'">Накладные</a></li>
                </ul>
            </div>

            <div class="doclist" v-if="tab=='zayavki' && orderKladovshik" :class="{'loading':$apollo.queries.orderKladovshik.loading}">
                <div v-for="doc in orderKladovshik" class="zayavki-item" :key="doc.id">
                    <div class="agent">{{doc.agentName}}</div>
                    <div class="info">{{doc.answerName}}. {{doc.id}}. {{doc.info}}</div>
                    <div class="total">{{doc.sum|currency}}</div>
                    <div class="create-invoice">
                        <span class="action" @click="createInvoice(doc)" :class="{'sending': sending}">создать накладную</span>
                    </div>
                    <!--div class="details">
                        <table>
                            <tr>
                                <td>Пример</td>
                            </tr>
                        </table>
                    </div-->
                </div>
            </div>

            <table class="doclist nakl" v-if="tab=='nakl'" :class="{'loading':$apollo.queries.todayOrderList.loading}">
                <tbody>
                    <tr v-for="doc in todayOrderList" 
                        :key="doc.id"
                        class="hover" @click="goToInvoice(doc)" :class="{'not-printed': !doc.printed}">
                        <td>
                            <div class="agent">{{doc.agentName}}</div>
                        </td>
                        <td>
                            <div class="total">{{doc.sum|currency}}</div>
                        </td>
                    </tr>
                </tbody>

            </table>

            <!--a class="button no-print" @click="print" v-if="orderKladovshik">Печать</a-->

        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"

    import DatePicker from 'vue2-datepicker'

    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import {format, parse} from 'date-fns'

    export default {
        name: 'StockmanOrderList',
        components: {
            DatePicker,
        },
        props: [
            'user',
            'date_in',
        ],
        methods: {
            goToInvoice(doc) {
                console.log(doc);
                this.$router.push(
                    {
                        name: 'stockman-order',
                        params: {
                            date_in: this.date,
                            order_id: doc.code,
                        }
                    });
            },
            createInvoice(doc){
                if (this.sendind)
                    return;
                this.sending = true;
                this.$apollo.mutate({
                    mutation: gql`mutation($demandId: ID!, $answerId: ID!, $skladId: ID!) {
                        createInvoice(demandId: $demandId, answerId: $answerId, skladId: $skladId) { ok }
                    }`,
                    variables: {
                        demandId: doc.id,
                        answerId: doc.answerId,
                        skladId: doc.skladId,
                    },
                }).then(data => {
                    this.sending=false;
                    this.error=null;
                    this.skip=false;
                    this.skip2=false;
                    this.$apollo.queries.orderKladovshik.refetch();
                    this.$apollo.queries.todayOrderList.refetch();
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });

            },
            submit(){
                this.$router.push(
                    {
                        name: 'stockman-orders',
                        params: {
                            date_in: this.date,
                        }
                    });
                this.error=null;
                this.skip=false;
                this.skip2=false;
                this.$apollo.queries.orderKladovshik.refetch();
                this.$apollo.queries.todayOrderList.refetch();
            },
        },
        data(){
            return {
                date: this.date_in? this.date_in : format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
                error: null,
                tab: 'zayavki',
                skip: false,
                skip2: false,
                order_id: null,
                sending: false,
            }
        },
        apollo: {
            orderKladovshik: {
                query: gql`
                    query($date: Date!){
                        orderKladovshik(date: $date){
                            id docout agentName info sum answerId skladId answerName
                        }
                    }`,
                variables() {
                    return {
                        date: format(parse(this.date,"dd-MM-yyyy", new Date()),"yyyy-MM-dd"),
                    }
                },
                skip(){
                    return this.skip;
                },
                errorPolicy: 'all',
                result(data){
                    this.skip=true;
                },
                /*result(data) {
                    this.skip=true;
                    this.sumweight=0;
                    this.todayOrderList.forEach(e=>{this.sumweight+=e.weight});
                },*/
                error(error) {
                    this.error = JSON.stringify(error.message);
                    this.skip = true;
                },
            },
            getOrderDetails: {
                query: gql`
                    query($id: ID!){
                        getOrderDetails(id: $id){
                            name amount price total
                        }
                    }`,
                variables() {
                    return {
                        id: this.order_id,
                    }
                },
                skip(){
                    return this.order_id == null;
                },
                errorPolicy: 'all',
                result(data){
                    this.skip_order = true;
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                    this.skip_order = true;
                },
            },
            todayOrderList: {
                query: gql`
                    query($answerId: ID!, $date: Date!){
                        todayOrderList(answerId: $answerId, date: $date){
                            id code sum date agentName sklad img weight printed
                        }
                    }`,
                errorPolicy: 'all',
                variables() {
                    return {
                        answerId: 5,
                        date: format(parse(this.date,"dd-MM-yyyy", new Date()),"yyyy-MM-dd"),
                    }
                },
                skip(){
                    return this.skip2;
                },
                result(data) { // eslint-disable-line no-unused-vars
                    this.skip2=true;
                    //this.sumweight=0;
                    //this.todayOrderList.forEach(e=>{this.sumweight+=e.weight});
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.doclist.loading{
        opacity: 0.33;
    }
    div.agent, div.total {
        font-size: 125%;
        padding: 8px 0;
    }
    div.total {
        text-align: right;
        white-space: nowrap;
    }
    .zayavki-item{
        margin: .5rem 0;
        padding: 1rem;
        display: grid;
        cursor: pointer;
        &:hover {
            background: #f3f3f3;
        }
        grid-template:
            "name price"
            "info create-invoice";
        grid-template-columns: auto 155px;

        .agent {
            font-size: 125%;
            grid-area: name;
        }
        div.total {
            font-size: 125%;
            grid-area: price;
            text-align: right;
        }
        .info {
            font-size: 95%;
            color: #777;
            grid-area: info;
        }

        .create-invoice{
            font-size: 95%;
            grid-area: create-invoice;
            text-align: right;
            .action {
                color: #007cb7;
            }
            .action.sending {
                color: #cecece;
            }
        }

        tr.shipped {
            color: #aaa;
            a {
                color: #99b5e2;
            }
        }
        tr.total {
            font-weight: bold;
        }
    }
    table.nakl{
        tr{
            cursor: pointer;
        }
        .not-printed{
            color: red;
        }
    }

</style>

