<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <section class="section">
            <h1 v-if="item">{{item.name}}</h1>
            <div class="loading" v-if="$apollo.queries.productDetail.loading"></div>
            <h2>История прихода</h2>
            <table class="report product-detail">
                <tr>
                    <th>Дата</th>
                    <th>Контрагент</th>
                    <th>Цена</th>
                    <th>Количество</th>
                    <th>Сумма</th>
                </tr>
                <tr v-for="detail in productDetail" :key="detail.id">
                    <td>{{detail.date|date-ddmmyy}}</td>
                    <td>{{detail.agentName}}</td>
                    <td>{{detail.price|currency}}</td>
                    <td>{{detail.amount}}</td>
                    <td>{{detail.amount*detail.price|currency}}</td>
                </tr>
            </table>
        </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"

    export default {
        name: 'ProductDetail',
        components: {
        },
        props: [
            'user',
            'tu_id',
            'sklad_id',
        ],
        methods: {
        },
        data(){
            return {
            }
        },
        apollo: {
            item: {
                query: gql`
                    query($id: ID!){
                        item(id:$id) {
                          id name img images{img}
                        }
                    }
                `,
                variables() {
                    return {
                        id: this.tu_id,
                    }
                },
            },
            productDetail: {
                query: gql`
                    query($skladId: ID!, $tuId: ID!){
                        productDetail(skladId: $skladId, tuId: $tuId) {
                            balance price date amount agentId agentName
                        }
                    }`,
                variables() {
                    return {
                        tuId: this.tu_id,
                        skladId: this.sklad_id,
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    h1 {
        text-weight: bold;
        font-size: 170%;
    }
    h2 {
        text-weight: bold;
        font-size: 140%;
    }
    .report {
        margin-top: 0;
    }
    .report.product-detail {
        td:nth-child(3), td:nth-child(4), td:nth-child(5){
            text-align: right;
        }
    }
</style>

