<template>
    <div>
        <treeselect 
            :multiple="false" 
            placeholder="Выберите контрагента..."
            searchPromptText="Начните ввод для поиска"
            noResultsText="Контрагенты не найдены"
            loadingText="Загрузка данных"
            @select="$emit('select', $event)"
            @input="$emit('input', $event)"
            :cacheOptions="false"
            :options="agentOptions"
            :async="agentAsyncMode"
            :disabled="disabled"
            :load-options="loadAgentOptions"
            v-model="agentId"
        />
    </div>
</template>
    
    <script>
        import gql from "graphql-tag"
        import Treeselect from '@riophae/vue-treeselect'
        import { ASYNC_SEARCH, LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'
        import _ from 'lodash'
        import { lightFormat } from "date-fns"
    
        export default {
            props: {
                initAgent: Object,
                disabled: Boolean,
            },
            watch: {
                initAgent() { 
                    this.agentId = this.initAgent.id
                    this.agentOptions = [this.initAgent]
                },
            },
            data: () => ({
                    agentOptions: [],
                    agentId: null,
                    agentAsyncMode: false,
                    callback: null,
                    agent_debounced: null,
                }
            ),
            components: {
                Treeselect,
            },
            mounted(){
                setTimeout(() => { 
                    this.agentAsyncMode=true;
                }, 1000);
    
                if (this.initAgent) {
                    this.agentId = this.initAgent.id
                    this.agentOptions = [this.initAgent]
                }
            },
            methods: {
                loadAgentOptions: _.debounce(function({ action, searchQuery, callback }) {
                        //console.log(action, searchQuery, callback)
                        if (action === ASYNC_SEARCH) {
                            this.agent_debounced = searchQuery;
                            if (this.agent_debounced==null || this.agent_debounced=='')
                                this.searchAgents=[];
                            this.callback = callback;
                        } else if (action === LOAD_ROOT_OPTIONS) {
                            
                        }
                    }, 500),
            },
            apollo: {
                searchAgents: {
                    query: gql`query($search: String){searchAgents(search: $search) {id label:name}}`,
                    variables() {
                        return {
                            search: this.agent_debounced,
                        }
                    },
                    skip(){
                        return !this.agent_debounced;
                    },
                    result ({data, loading}) {
                        if (!loading) {
                            if (this.callback) {
                                //data.searchAgents = [{ id: 100, label: 'Item-100' }, { id: 555, label: 'Item-555' }]
                                if(data.searchAgents)
                                    this.callback(null, data.searchAgents);
                                this.callback = null;
                            }
                        }
                    },
                    errorPolicy: 'all',
                    error(error) {
                        this.$emit('error', JSON.stringify(error.message));
                    },
                },
            }
        }
    </script>
    