<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <section class="section" v-if="item">
            <h1 class="title">{{item.name}}</h1>
            <div>
                <ul>
                    <li>Остаток: {{item.balance}}</li>
                    <li>Базовая цена: {{item.price|currency}}</li>
                </ul>
                <div class="tabs is-boxed">
                    <ul>
                        <li :class="{'is-active': tab=='sklad'}"><a @click="tab='sklad'">Остатки</a></li>
                        <li :class="{'is-active': tab=='pics'}"><a @click="tab='pics'">Картинки</a></li>
                    </ul>
                </div>
                <div v-show="tab=='sklad'">
                    <div class="field">
                        <label class="label">Годен до:</label>
                        <div class="control">
                            <date-picker
                                v-model="item.expiration"
                                class="date"
                                valueType="format"
                                :first-day-of-week=0
                                lang="ru"
                                format="YYYY-MM-DD"
                                id="expiration_date"
                                ></date-picker>
                        </div>
                    </div>
                    <button class="button is-primary" @click="save()">Сохранить</button>
                </div>

                <div class="grd" v-show="tab=='pics'">
                    <div class="imglist" id="imglist">
                        <img
                            :src="'/' + img.img"
                            class="img"
                            :class="{'selected': selected.includes(parseInt(img.id))}"
                            v-for="img in item.images" :key="img.id"
                            @click="toggle_select(img)"
                            >
                    </div>

                    <div class="uploader" v-if="user.isStaff">
                        <div style="margin-right: 1rem;">
                            <button :disabled="!selected.length"
                                @click="removeSelected"
                                style="margin-bottom: .8rem; display: block;">
                                Удалить выбранное
                            </button>
                            <button @click="paste" class="button is-small" style="margin-bottom: .8rem; display: block;">
                                Вставить из буфера
                            </button>
                            <!--button class="button is-small" :disabled="!base64fromclipboard" @click="uploadPasted">
                                Загрузить
                            </button>
                            <button class="button is-small" :disabled="!base64fromclipboard" @click="clear">
                                Очистить
                            </button>
                            <div class="field has-addons" style="margin-top: 2rem;">
                                <div class="control" style="width: 100%;">
                                    <input ref="pasteEl" class="input" type="text" placeholder="Вставить картинку из буфера обмена (Ctrl+V)" @paste="onPaste">
                                </div>
                                <div class="control">
                                    <a class="button is-info" :disabled="!base64fromclipboard" @click="uploadPasted">
                                        Загрузить
                                    </a>
                                </div>
                            </div-->

                            <div class="notification is-danger" v-if="uploadError" style="margin-bottom: .8rem;">{{uploadError}}</div>

                            <img id="fromclipboard" :src="base64fromclipboard">

                            <input type="file" accept="image/*" id="file-input-0" v-on:change="uploadFromCamera">
                        </div>
                        <div style="flex: 1;">
                            <vue-dropzone ref="myVueDropzone" id="dropzone"
                                :options="dropzoneOptions"
                                v-on:vdropzone-sending="sendingEvent"
                                v-on:vdropzone-success="dropzoneSuccess">
                            </vue-dropzone>
                        </div>
                    </div>
                </div>
            </div>
             <json-viewer
                            v-show='true || show_json' v-if="item"
                            :expand-depth=0
                            copyable
                            boxed
                            expanded
                            :value="item"></json-viewer>
        </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    //import axios from 'axios'

    import DatePicker from 'vue2-datepicker'

    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import {format} from 'date-fns'
    import UploadFromCamera from "@/components/UploadFromCamera"


	export default {
        name: 'EditItem',
        components: {
            DatePicker,
            vueDropzone: vue2Dropzone,
        },
        mixins: [UploadFromCamera],
        props: [
            'user', 'item_id', 'itemModal'
        ],
        methods: {
            toggle_select(img) {
                const index = this.selected.indexOf(parseInt(img.id));
                if (index > -1)
                    this.selected.splice(index, 1);
                else
                    this.selected.push(parseInt(img.id));
            },
            addCustomData(data){
                data.append('id', this.item.id);
            },
            beforeUploading(){
                this.uploading=true;
            },
            removeSelected(){
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!) {
                        removeImage(id: $id) { ok }
                    }`,
                    variables: {
                        "id": this.selected[0],
                    },
                }).then(data => {
                    this.selected=[];
                    this.skipQuery=false;
                    this.$apollo.queries.images.refetch();
                }).catch(error => {
                    //itemlist.modcartfinished(true);
                    console.log(error);
                });
            },
            save(){
                this.$apollo.mutate({
                    mutation: gql`mutation($productId: ID!, $expiration: Date!) {
                        saveItem(productId: $productId, expiration: $expiration) { ok }
                    }`,
                    variables: {
                        "productId": this.item.id,
                        "expiration": this.item.expiration,
                    },
                }).then(data => {
                }).catch(error => {
                    //itemlist.modcartfinished(true);
                    console.log(error);
                });
            },

            sendingEvent (file, xhr, formData) {
                formData.append('id', this.item.id);
            },

            dropzoneSuccess(file, request){
                this.skipQuery=false;
                this.$apollo.queries.images.refetch();
            },

            read(blob){
                let reader = new FileReader();
                reader.onload = (event) => {
                    this.base64fromclipboard = event.target.result;
                }
                reader.readAsDataURL(blob);
                this.blob=blob;
            },

            /*clear(){
                console.log("clear");
                this.base64fromclipboard=null;
            },
            onPaste (ev) {
                let items = (ev.clipboardData || ev.originalEvent.clipboardData).items;
                for (let index in items) {
                    var item = items[index];
                    if (item.kind === 'file') {
                        this.read(item.getAsFile());
                    }
                }
            },*/
            async paste(){
                const items = await (navigator.clipboard.read());
                const blob = await items[0].getType("image/png");
                this.read(blob);
                this.uploadPasted();
            },
            uploadPasted(){
                let formData = new FormData();
                formData.append('id', this.item.id);
                formData.append('file', this.blob);
                this.uploadData(formData);
            },
            afterUploading(){
                this.skipQuery=false;
                this.$apollo.queries.images.refetch();
            },
        },
        updated: function () {
            this.$nextTick(function () {
                if (this.scrollToLast){
                    this.scrollToLast=false;
                    let elList=document.getElementById('imglist');
                    if (elList && elList.childElementCount>0){
                        let lastimg = elList.children[elList.childElementCount-1];
                        lastimg.addEventListener('load', (event)=> {
                                lastimg.scrollIntoView({behavior: "smooth"})
                            });
                    }
                    //scrollingElement.scrollIntoView({
                    //    behavior: "smooth"
                    //});
                }
            })
        },
        data(){
            return {
                tab: 'sklad',
                base64fromclipboard: null,
                skipQuery: true,
                selected: [],
                blob: null,
                uploading: false,
                uploadError: null,
                scrollToLast: false,
                dropzoneOptions: {
                    url: '/photo/',
                    thumbnailWidth: 150,
                    maxFilesize: 2,
                    headers: { "My-Awesome-Header": "header value" },
                    dictDefaultMessage: "Перетащите и бросьте сюда файл с картинкой"
                },
            }
        },
        apollo: {
            images: {
                query: gql`
                    query($productId: ID!) {
                        extItemImage(productId: $productId) {
                            id img
                        }
                    }
                `,
                manual: true,
                skip() {
                    return this.skipQuery
                },
                variables() {
                    return {
                        productId: this.item.id
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                        this.item.images = data.extItemImage;
                        this.scrollToLast=true;
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
            item: {
                query: gql`
                    query($id: ID!) {
                        item(id: $id) {
                            id name balance price
                            images {id img}
                            expiration
                        }
                    }`,
                variables() {
                    return {
                        id: this.item_id,
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
	}
</script>

<style lang="scss" scoped>
    @import '@/assets/_variables.scss';
    .img{
        margin-top: 2rem;
        display: block;
    }
    .img.selected {
        border: 3px solid blue;
    }
    .mytitle{
        background: #3273dc;
        margin: 0px;
        color: #fff;
        padding: 10px;
    }
    .modal-content{
        background: #fff;
        width: calc(100% - 8rem);
        @media screen and (max-width: $desktop) {
            width: 100%;
            margin: 0;
            height: 100%;
            max-height: 100%;
        }
    }

    #dropzone{
        @media screen and (max-width: $desktop) {
            display: none;
        }
    }

    #fromclipboard{
        display: none;
        max-height: 64px;
        max-width: 128px;
    }
    .wrap {
        background: #fff;
        position: absolute;
        top: 3rem;
        right: 3rem;
        bottom: 3rem;
        left: 3rem;
    }
    .grd{
        display: flex;
        flex-direction: column;
        height: 100%;
        .imglist{
            overflow-y: scroll;
        }
        .uploader{
            margin: 1rem;
            display: flex;
        }
    }
    .modal-close{
        background: #999;
    }
</style>