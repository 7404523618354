<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <div class="loading" v-if="$apollo.queries.not3price.loading"></div>
        <section class="section" v-if="not3price">
            <h1>Отчет "Не установлена цена"</h1>
            <h3 v-if="not3price">Общее количество: {{not3price.cnt}}</h3>
            <table class="report striped">
                <tr>
                    <th>id</th><th>Номер</th><th>Наименование</th><th>Цена реализ.</th><th>Цена опт</th><th>Цена дост.</th>
                </tr>
                <tr v-for="i in not3price.items" :key="i.id"
                    @click="$router.push('/dict/tu/' + i.id)"
                    class="hover"
                    >
                    <td>{{i.id}}</td>
                    <td>{{i.nomer}}</td>
                    <td>
                        {{i.name}}
                    </td>
                    <td class="amount"><span v-if="i.priceRealization">{{i.priceRealization|currency}}</span></td>
                    <td class="amount"><span v-if="i.priceOpt">{{i.priceOpt|currency}}</span></td>
                    <td class="amount"><span v-if="i.priceDelivery">{{i.priceDelivery|currency}}</span></td>
                </tr>
            </table>
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"

    export default {
        name: 'Not3price',
        components: {
        },
        props: [
            'user',
        ],
        methods: {
        },
        data(){
            return {
            }
        },
        apollo: {
            not3price: {
                query: gql`
                    query{
                       	not3price {cnt items{id nomer name priceRealization priceOpt priceDelivery}}
                    }`,
                /*variables() {
                    return {
                        code: this.order_id,
                    }
                },*/
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    .amount, .cnt {
        text-align: right;
        padding-left: 15px;
    }
    .hover {
        cursor: pointer;
    }
</style>

