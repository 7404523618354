<template>
    <div>
        <div class="field is-grouped">
            <div class="control">
                <label class="label">
                    Имя
                </label>
                <input type="text" class="input" v-model="name" 
                        :class="{'is-danger': !firstSaveAttept && (!name || !name.trim())}">
            </div>
            <div class="control">
                <label class="label">
                    Адрес
                </label>
                <input type="text" class="input" v-model="address">
            </div>
        </div>     
        <div class="field is-grouped">
            <div class="control">
                <label class="label">
                    Признак
                </label>
                <v-select :options="[{code:null, label: '-'}].concat(kinds)" v-if="kinds" v-model="kind"></v-select>
            </div>
            <div class="control">
                <label class="label">
                    Вид цены
                </label>
                <v-select :options="[{code:null, label: '-'}].concat(pricetypes)" v-if="pricetypes" v-model="pricetype"></v-select>
            </div>
        </div>     
        <div class="field is-grouped">
            <div class="control">
                <label class="label">
                    Ответственный
                </label>
                <v-select :options="[{code:null, label: '-'}].concat(answers)" v-if="answers" v-model="answer"></v-select>
            </div>
            <div class="control">
                <label class="label">
                    Группа
                </label>
                <v-select :options="[{code:null, label: '-'}].concat(agroups)" v-if="agroups" v-model="agroup"></v-select>
            </div>
        </div>     
        <div class="field">
            <div class="control">
                <label class="label">
                    Приоритетный склад
                </label>
                <v-select :options="[{code:null, label: '-'}].concat(sklads)" v-if="sklads" v-model="sklad"></v-select>
            </div>
        </div>     
        <div class="field">
            <div class="control">
                <label class="label">
                    Плательщик ТОРГ-12
                </label>
                <input type="text" class="input" v-model="torg12payer">
            </div>
        </div>     
        <div class="field">
            <div class="control">
                <label class="label">
                    Грузополучатель ТОРГ-12
                </label>
                <input type="text" class="input" v-model="torg12consignee">
            </div>
        </div>     

        <div class="field is-grouped">
            <div class="control">
                <button class="button is-primary" @click="save" :class="{'is-loading': sending}" :disabled="sending">Сохранить</button>
            </div>
            <div class="control">
                <button class="button is-primary" @click="saveAndNew" :class="{'is-loading': sending}" :disabled="sending">Сохранить и новая карточка</button>
            </div>
            <div class="control">
                <button class="button" @click="createNew" :disabled="sending">Сбросить</button>
            </div>
        </div>

        <TitleWithCheckModal :isActive='created'>
            Запись создана
            <div class="field" style="padding: .5rem 0;">
                <button class="button" @click="created=false;">Продолжить</button>
            </div>
        </TitleWithCheckModal>
        

        <div style="margin-bottom: 5rem;"></div>
    </div>    
</template>

<script>
    import AgentMixin from './AgentMixin'
    import TitleWithCheckModal from '@/components/TitleWithCheckModal'
    export default {
        data: ()=>({
            created: false,
            name: null,
            address: null,
            firstSaveAttept: true,
            sending: false,
            torg12payer: null,
            torg12consignee: null,
        }),
        components: {
            TitleWithCheckModal,
        },
        props: {
            user: Object,
        },
        mixins: [AgentMixin, ],
        methods: {
            close(){
            },
            createNew(){
                this.name = null
            },
            createCopy(){
                this.nomer = null
                this.firstSaveAttept = true
            },
            save(){
                this.saveX(this.createNew)
            },
            saveAndNew(){
                this.saveX(this.createCopy)
            },
            saveX(fPost){
                this.firstSaveAttept = false;
                if (!this.name || !this.name.trim())
                    return false;

                this.saveAgent({
                        name: this.name,
                        isArc: false,
                        kindId: this.kind?this.kind.code:null,
                        agroupId: this.agroup ? this.agroup.code: null,
                        address: this.address,
                        answerId: this.answer ? this.answer.code: null,
                        pricetypeId: this.pricetype?this.pricetype.code: null,
                        prioritySkladId: this.sklad?this.sklad.code:null,
                        torg12consignee: this.torg12consignee,
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>