<template>
	<div class="container">
        <h1>Настройки</h1>
        <div class="tabset">
            <div class="tabs is-boxed" style="margin-top: 1rem;">
                <ul>
                    <li :class="{'is-active': tab=='scaner'}">
                        <a @click="tab='scaner'">Сканер</a>
                    </li>
                    <li :class="{'is-active': tab=='scale-weight'}">
                        <a @click="tab='scale-weight'">Весы</a>
                    </li>
                    <li :class="{'is-active': tab=='extension'}">
                        <a @click="tab='extension'">Разное</a>
                    </li>
                </ul>
            </div>        
        </div>

        <div v-show="tab=='scaner'">
            <h2 style="margin: 30px 0 16px;"><b>Сканер&nbsp;</b>
                <span v-if="!isScannerConnected">&mdash; 
                    <span style="color: red">нет соединения</span>
                </span>
                <span v-if="isScannerConnected">&mdash; 
                    <span style="color: green">подключено</span>
                </span>
                &nbsp;
                <span>{{ scannedBarcode }}</span>
            </h2>
            <h2 style="margin-top: 2rem">EAN-13 (Штрихкод)</h2>
            <div class="field is-grouped" style="margin-top: 20px;" v-if="!isScannerConnected">
                <div class="control">
                    <button class="button is-large" id="connectScanner" @click="$emit('connectScanner')">Подключить сканер</button>
                </div>
            </div>
            <div style="display: flex; align-items: center;">
                <div>
                    <img src="@/assets/test-barcode.gif">
                </div>
                <div>
                    <svg class="checkmark" v-show="scannedBarcode==ean13test" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </div>
            </div>
            <h2 style="margin-top: 2rem">DataMatrix (Честный знак, ЕГАИС)</h2>
            <div style="display: flex; align-items: center;">
                <div>
                    <img src="@/assets/datamatrix-test.png">
                </div>
                <div>
                    <svg class="checkmark" v-show="scannedBarcode==dataMatrixTest" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </div>
            </div>
        </div>
        <div v-show="tab=='scale-weight'">
            <h1 style="margin: 30px 0 16px;"><b>Весы&nbsp;</b>
                <span v-if="!isScalesConnected">&mdash; 
                    <span style="color: red">нет соединения</span>
                </span>
                <span v-if="isScalesConnected">&mdash; 
                    <span style="color: green">подключено ({{scales.getScalesName()}})</span>
                </span>
            </h1>

            <div class="field is-grouped" style="margin-top: 20px;">
                <div class="control">
                    <button class="button is-large" @click="$emit('connectScales')">Подключить весы</button>
                </div>
            </div>

            <div class="field has-addons" v-if="isScalesConnected">
                <div class="control" style="display: flex; align-content: center; align-items: center; margin-right: 14px; font-size: 125%;">
                    Показания:&nbsp;<span :class="{'weight-sedated': isWeightSedated}">{{ inputWeight }} кг.</span>
                </div>
            </div>
        </div>
        <div v-show="tab=='extension'">
            <h1 style="margin: 30px 0 16px;"><b>Расширение</b></h1>

            <a href="/static/kirsa-ext.crx" download>скачать</a>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
	export default {
        name: 'Options',
        data: function(){
            return {
                ean13test: '4337182088592',
                dataMatrixTest: '0104603934000786215Uk,gCNUgFikY93ya8f',
                tab: 'scaner', // 'scale-weight'
            }
        },
        props: {
            isScannerConnected: Boolean,
            scannedBarcode: String,
            isScalesConnected: Boolean,
            inputWeight: Number,
            isWeightSedated: Boolean,
            scales: Object,
        },
        watch: {
            scannedBarcode: function (val) {
                if (val==this.ean13test || val==this.dataMatrixTest){
                    setTimeout(() => {
                        this.$emit('setbarcode',null);
                    }, 2500);                    
                }
            },
        },
	}
</script>

<style lang="scss" scoped>
    .weight-sedated{
        color: green;
    }
</style>