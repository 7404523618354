<template>
    <div class="container" v-if="user && !user.isAnonymous">
    <section class="section">
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <div class="agents-bonuses">
            <div style="flex: 1.4; margin-right: 30px;">
                <div class="field">
                    <div class="control" :class="{'is-loading': $apollo.queries.agent2.loading}">
                        <input class="input" type="text" v-model="search" placeholder="Поиск">
                    </div>
                </div>
                <table class="agents tablex" :class="{'loading': $apollo.queries.agent2.loading}" >
                    <tr v-for="a in (agent2 && agent2.items)?agent2.items:[]" :key="a.id" @click="select_agent(a)" :class="{'selected': agent_id==a.id}">
                        <td>{{a.id}}</td>
                        <td>{{a.name}}</td>
                        <td>{{a.address}}</td>
                    </tr>
                </table>
            </div>
            <div style="flex: 1">
                <div class="agent-details">{{agent_name}}</div>

                <div>Начисления и списания:</div>
                <table class="bonuses tablex" :class="{'loading': $apollo.queries.bonusesAndPayments.loading}">
                    <tr>
                        <th>дата</th>
                        <th>#</th>
                        <th>бонусы</th>
                    </tr>
                    <tr v-for="b in bonusesAndPayments" :key="b.id" :class="{'neg': b.amount<0}">
                        <td class="date">{{b.date}}</td>
                        <td class="docout">{{b.docout}}</td>
                        <td class="amount">{{b.amount|currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center;"
                            v-if="!$apollo.queries.bonusesAndPayments.loading && (!bonusesAndPayments || bonusesAndPayments.length==0)">
                            Нет бонусов
                        </td>
                    </tr>
                </table>
                <div v-if="!$apollo.queries.bonusesAndPayments.loading">
                    <div v-if="bonusesAndPayments && bonusesAndPayments.length>0">
                        <div class="summa-bonuses">
                            Сумма бонусов: {{summa_bonuses|currency}}
                        </div>
                        <div class="field has-addons">
                          <div class="control">
                            <!-- input class="input" type="text" placeholder="Сумма" v-model="x_summa_bonuses" -->
                            <money class="input" v-model="x_summa_bonuses"></money>
                          </div>
                          <div class="control">
                            <a class="button is-info" @click="payment" :disabled="sending" :class="{'is-loading': sending}">
                              Оплатить бонусами
                            </a>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    //import Pager from '@/components/Pager.vue'

    export default {
        name: 'Bonuses',
        /*components: {
            Pager,
        },*/
        props: ['user',],
        data() {
            return {
                loading: false,
                search: null,
                agent_id: null,
                agent_name: null,
                isActive: true,
                summa_bonuses: 0,
                x_summa_bonuses: null,
                sending: false,
                error: null,
            }
        },
        methods: {
            select_agent(item){
                this.agent_id=item.id;
                this.agent_name=item.name;
            },
            calcsum: function(data){
                var s=0;
                if (data && data.data && data.data.bonusesAndPayments){
                    data.data.bonusesAndPayments.forEach((element) => {
                        s+=parseFloat(element.amount);
                    });
                }
                s=s.toFixed(2);
                if (s<0)
                    s = 0;
                this.summa_bonuses=s;
                this.x_summa_bonuses=s;
            },
            payment(){
                this.sending=true;
                console.log(this.agent_id);
                this.$apollo.mutate({
                    mutation: gql`mutation($agentId: ID!, $amount: Decimal!) {
                        addPayment(agentId: $agentId, amount: $amount) { ok }
                    }`,
                    variables: {
                        agentId: this.agent_id,
                        amount: this.x_summa_bonuses,
                    },
                }).then(data => {
                    this.sending=false;
                    //itemlist.modcartfinished();
                    //this.$apollo.queries.cartSummary.refetch();
                    this.error=null;
                    this.x_summa_bonuses = 0;
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
           },
        },
        apollo: {
            bonusesAndPayments: {
                query: gql`query($agentId: ID!) {bonusesAndPayments(agentId: $agentId) {date amount docout}}`,
                variables() {
                    return {
                        agentId: this.agent_id,
                    }
                },
                skip() { return !this.agent_id },
                result(data) { // eslint-disable-line no-unused-vars
                    this.calcsum(data);
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
            agent2: {
                query: gql`query($search: String!) {
                            agent2(search: $search) {
                                items {id name address isArc}
                                count
                            }
                        }`,
                debounce: 500,
                skip() { return !this.search },
                variables() {
                    return {
                        search: this.search,
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            }
        },
	}
</script>

<style lang="scss" scoped>
    .agent-details{
        font-size: 120%;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    table.tablex {
        width: 100%;
        td, th {
            padding: 10px;
        }
        th {
            text-align: center;
        }
        tr {
            cursor: pointer;
        }
        tr:hover {
            background: #f2f2f2;
            color: #333;
        }
        tr.selected:hover {
            background: #1551b1;
            color: #fff;
        }
    }

    table.bonuses{
        max-width: 360px;
        .neg {
            color: red;
        }
        th{
            text-align: center;
        }
        td.date, td.docout {
            text-align: center;
        }
        td.amount{
            text-align: right;
        }
    }
    .agents-bonuses{
        display: flex;
    }
    .selected{
        background: #3273dc;
        color: #fff;
    }
    .summa-bonuses{
        font-weight: bold;
        font-size: 115%;
        margin: 2rem 0;
    }
</style>
