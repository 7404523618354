export class ModuleStorage {
  constructor(name = 'ModuleStorage', ver = '0') {
    this.name = name
    this.ver = ver
  }

  load() {
    try {
      let state = JSON.parse(localStorage.getItem(this.name))
      if (!state || state.ver != this.ver) throw new Error()
      return state.data
    } catch (e) {
      this.clear()
      return null
    }
  }
  loadTo(target){
    let state = this.load();
    if(state){
      Object.assign(target, state);
      return true;
    }
    return false;
  }
  save(data) {
    let state = {
      ver: this.ver,
      data,
    }
    localStorage.setItem(this.name, JSON.stringify(state))
  }
  saveFor(target, propertys){
    let data = {};
    propertys.forEach(x => data[x] = target[x]);
    this.save(data);
  }
  clear() {
    localStorage.removeItem(this.name)
  }
}

