<template>
	<div class="abs" :class="{'active': activeSidebar}" v-click-outside="hide">
        <a class="delete is-medium" @click="hide" v-if="activeSidebar"></a>
        <img src="@/assets/loading.svg" v-show="loading" class="loading-fixed">
        <div v-if="order && !loading">
            <h1 class="title">Заказ <span v-if="order">#{{order.id}} / {{order.date}}</span></h1>
            <table>
                <tr>
                    <td class="dt">Контрагент:</td><td>{{order.agent.name}}</td>
                </tr>
                <tr>
                    <td class="dt">Дата доставки:</td><td>{{order.date}}</td>
                </tr>
                <tr>
                    <td class="dt">Оплата:</td><td>{{order.osn.name}}</td>
                </tr>
                <tr>
                    <td class="dt">Примечание:</td><td>{{order.info}}</td>
                </tr>
            </table>

            <div style="font-weight: bold;">Позиции</div>
        </div>
        <ul class="order" v-if="order && !loading">
            <li v-for="line in order.lines" :key="line.id">
                <div class="id">{{line.id}}</div>
                <div class="img"><img :src="'https://kirsa.9733.ru/' + line.img " alt=""></div>
                <div class="name">{{line.name}}</div>
                <div class="summa">{{line.price|currency}} x {{line.isWeight?line.kolfakt:Math.round(line.kolfakt)}} = <span class="sum">{{line.summa|currency}}</span></div>
            </li>
        </ul>
        <div class="total" v-if="order && !loading">
            <!--div><button class="button is-primary" @click="createWaybill()">Сформировать накладную</button></div-->
            <div>Сумма: {{order.summa | currency}}</div>
        </div>
        <div class="tech-info" v-if="order && order.ymClientId">YM ClientId: {{order.ymClientId}}</div>

    </div>
</template>

<script>
    import gql from "graphql-tag"
    import Sidebar from './Sidebar'
	export default {
        name: 'Order',
        mixins: [Sidebar,],
        props: ['order', 'loading'],
        data: function(){
            return {
                sending: false,
            }
        },
        methods:{
            createWaybill(){
                this.sending = true;
                this.$apollo.mutate({
                    mutation: gql`mutation($waybillId: ID!) {
                        createWaybill(idWaybill: $waybillId){
                            ok
                          }
                    }`,
                    variables: {
                        "waybillId": this.order.id,
                    },
                }).then(data => {
                    this.sending = false;
                    //if (data.data.moveToSklad1.ok==true){
                    //    this.phase='submited';
                    //}
                }).catch(error => {
                    this.sending = false;
                    this.error=error;
                });
            },
        }
	}
</script>

<style lang="scss" scoped>
    @import "@/assets/_variables.scss";

    .delete{
        position: absolute;
        right: calc(100% + -32px);
        top: calc(50% - 32px);
        display: none;
        height: 64px !important;
        width: 64px !important;
        max-width: inherit !important;
        max-height: inherit !important;
        background: #e8e8e8 !important;
        border: 1px solid #7d7d7d;
        &:before, &:after {
            background-color: #444;
        }
    }
    .abs{
        position: fixed;
        top: 110px;
        bottom: 0;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: .5rem;
    }
    @media screen and (max-width: $desktop) {
        .delete{
            display: block !important;
        }
        .abs{
            top: 0px;
            z-index: 100;
            left: 100%;
            width: 85%;
            transition: all .5s ease;
            box-shadow: 0 0 0 0 #fff !important;
        }
        .active{
            left: 15%;
            box-shadow: 0 0 10px 20px rgba(0,0,0,.33) !important;
            transition: all .5s ease;
        }
    }
    ul.order{
        overflow-y: auto;
        li {
            display: grid;
            margin: 1.5rem 0;
            padding: 5px;
            grid-template-areas:
                "id img name"
                "id img price";

            grid-template-columns: .5fr 1fr 6fr;
            .id{
                grid-area: id;
                align-self: center;
                text-align: right;
                padding-right: 4px;
            }
            .img{
                grid-area: img;
                text-align: center;
                img {
                    max-width: 64px;
                }
                padding: 0 4px;
            }
            .name{
                grid-area: name;
            }
            .summa {
                text-align: right;
                grid-area: price;
                .sum{
                    font-size: 133%;
                }
            }
            &:hover{
                background: #eee;
            }
        }
    }
    .dt{
        padding-right: 10px;
    }
    table tr td{
        padding-bottom: 1rem;
    }
    .total{
        text-align: right;
        font-size: 133%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .tech-info{
        color: #999;
        margin-top: 4rem;
    }

</style>