<script>
	export default {
		name: 'TuListItemMixin',
        data(){
            return {
                old: null,
                modcartproccessing: false,
                box: false,
                focused: false,
            }
        },
        methods: {
            onFocus(){
                let input=this.$refs.input;
                input.select();
                this.focused = true;
                this.old=this.item.quantity;
            },
            onBlur(){
                if (this.focused && this.old == this.item.quantity){
                    this.focused = false;
                    return;
                }
                this.setItem();
                this.focused = false;
            },
            onEscape(){
                this.item.quantity=this.old;
                this.$refs.input.blur();
            },
            setItem(){
                if (!this.modcartproccessing){
                    this.modcartproccessing = true;
                    //if (this.item.quantityType===null){
                    //    this.item.quantityType=(this.box?2:1);
                    //}
                    let input=this.$refs.input;
                    if (isNaN(this.item.quantity)) {
                        this.item.quantity=this.old;
                        this.modcartproccessing=false;
                        //input.blur();
                    } else {
                        if (typeof this.item.product !== 'undefined'){
                            if (this.item.product.packOrderOnly && this.item.product.inbox>1){
                                this.item.quantity = Math.round(this.item.quantity/this.item.product.inbox)*this.item.product.inbox;
                            }
                        } else {
                            if (this.item.packOrderOnly && this.item.inbox>1){
                                this.item.quantity = Math.round(this.item.quantity/this.item.inbox)*this.item.inbox;
                            }
                        }
                        this.$emit('modcart', this.item, this, this.item.quantityType );
                        //input.blur();
                    }
                }
            },
            modcartfinished(data){
                this.modcartproccessing=false;
                if (typeof data != "boolean" && data.setcart && data.setcart.updatedValue){
                    this.item.quantity=data.setcart.updatedValue;
                    this.item.valX = data.setcart.updatedValue;
                }
            },
            delta(d){
                let newvalue = parseFloat(this.item.quantity) + (d * (this.box? this.item.inbox : 1));
                this.item.quantity = newvalue<0?0:newvalue;
                this.setItem();
            },
            /*onlyForWeight ($event, item) {
                if (!item)
                    return;
                console.log(item.isWeight);
                console.log(item.weight);
                // console.log($event.keyCode); //keyCodes value
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

                if (!item.isWeight){
                    if (keyCode < 48 || keyCode > 57) {
                        $event.preventDefault();
                    }
                } else {
                    // only allow number and one dot
                    if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || item.quantity.indexOf('.') != -1)) { // 46 is dot
                        $event.preventDefault();
                    }

                    // restrict to 2 decimal places
                    if(item.quantity!=null && item.quantity.indexOf(".")>-1 && (item.quantity.split('.')[1].length > 2)){
                        $event.preventDefault();
                    }
                }
            },*/
        },
	}
</script>
