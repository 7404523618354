<script>
import {
  DateRangePiker,
  format,
  SkladSelect,
  AnswersSelect,
  ReasonsSelect,
  HostsSelect,
  AgentSearchSelect
} from '../common/FormControls.js'
import { ModuleStorage } from '../common/ModuleStorage'

export default {
  components: {
    DateRangePiker,
    SkladSelect,
    AnswersSelect,
    ReasonsSelect,
    HostsSelect,
    AgentSearchSelect
  },
  props: {
    user: Object
  },
  data() {
    return {
      error: null,
      state: {
        skladId: null,
        hostId: null,
        reasonId: null,
        answerId: null,
        agent: null,
        dateRange: {
          from: format(new Date(), 'dd-MM-yyyy'),
          to: format(new Date(), 'dd-MM-yyyy'),
        },
        userId: null
      },
    }
  },
  beforeCreate() {
    this.storage = new ModuleStorage('', '5')
  },
  created() {
    if (this.user)
        this.readState();
  },
  watch: {
    user() {
      this.state.userId = this.user.id;
      this.readState()
    }
  },
  methods: {
    inputChange() {
      this.writeState();
    },
    readState() {
      let state = this.storage.load();
      if (state) {
        if (this.user && state.userId != this.user.id) {
          this.storage.clear()
        }
        else {
          Object.assign(this.state, state)
        }
      }
    },
    writeState() {
      if (this.user) {
        this.storage.save(this.state);
      }
    },
    clearState(storage) {
      this.state.dateRange = {
        from: format(new Date(), 'dd-MM-yyyy'),
        to: format(new Date(), 'dd-MM-yyyy'),
      };
      this.state.reasonId = null;
      this.state.answerId = null;
      this.state.hostId = null;
      this.state.skladId = null;
      this.state.agent = null;
      this.storage.clear()
    }
  },
}
</script>

<style lang="scss">
.report .total {
  text-align: right;
  white-space: nowrap;
}
</style>