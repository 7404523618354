<template>
    <div>
        <label class="label">Организация:</label>
        <v-select :options="hosts" v-model="selected" @input="onInput" />
    </div>
</template>

<script>
    import ApolloHostMixin from '../Warehouse/ApolloHostMixin';
    var cacsh = null

    export function hostLabel(id) {
        let x = cacsh? cacsh.find(x => x.code == id)||null : null
        return x? x.label : '';
    }

    export default {
        name: "HostSelect",
        mixins: [ApolloHostMixin, ],
        props:{
            value: {
                default: null
            },
            user: Object,
            emitValue:{
                type: String,
                default: null
            }
        },
        data(){
            return{
                selected: null,
            }
        },
        methods:{
            onInput(item) {
                let v = null;
                if(item){
                    v = this.emitValue? item[this.emitValue] : item;
                }
                this.$emit('input', v);
            },
            setSelected(){
                if(this.value){
                    let property = this.emitValue? this.emitValue : 'code';
                    this.selected = this.hosts.find(x => x[property] == this.value);
                }
            },
            hostLoaded(data){
                this.setSelected();
                this.$forceUpdate();
            },
        },
    }
</script>
