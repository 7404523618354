<template>
<div>
    <div class="container" v-if="user && !user.isAnonymous">
        <div v-if="error">
            <div class="notification is-danger my-error">
                {{error}}
            </div>
        </div>
        <div class="loading" v-if="$apollo.queries.svodBread.loading"></div>
        <section class="section" v-if="svodBread">
            <h1 class="no-print">Сводный отчет по хлебо-булочным изделиям</h1>

            <div class="field no-print" style="margin-top: 20px; margin-bottom: 3rem;">
                <div class="control">
                    <date-picker
                        v-model="date"
                        class="date"
                        valueType="format"
                        :first-day-of-week=0
                        lang="ru"
                        format="DD-MM-YYYY"
                        placeholder="Дата"
                        id="from"
                        ></date-picker>
                </div>
            </div>

            <div class="tabs is-boxed no-print">
                <ul>
                    <li :class="{'is-active': tab==i.id}" v-for="i in agentList" :key="i.id">
                        <a @click="tab=i.id; $apollo.queries.svodBread.refetch(); fill();">{{i.name}}</a>
                    </li>
                </ul>
            </div>

            <div class="print-only" v-for="i in agentList" :key="i.id">
                <h1 v-if="tab==i.id">
                    {{i.name}}
                </h1>
            </div>

            <div>
                <table class="doc_income striped matrix">
                    <thead>
                        <tr>
                            <th>Контрагент</th>
                            <th v-for="bi in svodBread.breads" :key="bi.id">
                                <div class="vert">{{bi.name}}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in agentListX" class="hover" :key="item.id">
                            <td style="text-align: left;">{{item.agent.name}}</td>
                            <td v-for="bi in item.val" class="tdinput" :key="bi.id">
                                <input type="text" class="amount input no-print"
                                    v-model="bi.amount" @change="change(bi)"
                                    @focus="onFocus"
                                    @keydown.esc="bi.amount=bi.original"
                                >
                                <span class="print-only">{{bi.amount}}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="text-align: right;">Итого по маршруту:</th>
                            <th v-for="bi in svodBread.breads" :key="bi.id">
                                {{ sum(bi.id)}}
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="margin-top: 2rem;" class="no-print">
                <a class="button" @click="$emit('print')">Печать</a>
            </div>

        </section>
    </div>
    <div style="margin: 10px;" class="no-print">
                <!--div style="margin-top: 3rem;"><span  class="action" @click="show_json=!show_json">json</span></div-->
                <!--pre v-show='show_json'>
                    {{JSON.stringify(JSON.parse(JSON.parse(sbisDocumentIncome.json))['result'], undefined, 4)}}
                </pre-->

    </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import {format, parse} from 'date-fns'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';

    export default {
        name: 'SvodBread',
        components: {
            DatePicker,
        },
        props: [
            'user',
        ],
        computed: {
        },
        methods: {
            onFocus(ev){
                let input=ev.target;
                input.select();
            },
            change(b){
                if (!isInt(b.amount) && b.amount.trim()!=''){
                    b.amount = b.original;
                    return;
                }
                this.$apollo.mutate({
                    mutation: gql`mutation($date: Date!, $agentId: ID!, $breadId: ID!, $amount: Int!, $price: Decimal!) {
                        correctBreadOrder(date: $date, agentId: $agentId, breadId: $breadId, amount: $amount, price: $price) { ok }
                    }`,
                    variables: {
                        date: format(parse(this.date,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                        agentId: b.agent_id,
                        breadId: b.bread_id,
                        amount: b.amount.trim()==''||b.amount.trim()=='0'?0:parseInt(b.amount),
                        price: b.price,
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    this.sending = false;
                    this.error = null;
                    if (b.amount.trim()=="")
                        b.amount = "";
                    else
                        b.amount = parseInt(b.amount).toString();
                    b.original = b.amount;
                }).catch(error => {
                    this.sending = false;
                    this.error = error;
                    b.amount = b.original;
                    return false;
                });
            },
            sum: function(id){
                let s=0;
                this.agentListX.forEach((line) => {
                    line.val.forEach((obj) => {
                        if (parseInt(obj.bread_id)==id)
                            if (obj.amount && obj.amount.trim()!='')
                                s+=parseInt(obj.amount);
                    });
                });
                return s;
            },
            /*sum_total(id){
                let s=0;
                this.svodBread.agents.forEach((element) => {
                    element.positions.forEach((e2) => {
                        if (e2.idTu==id)
                            s+=parseInt(e2.kolfakt);
                    });
                });
                return s;
            },*/
            fill(){
                if (!this.agentsAtUserProfile || !this.svodBread || !this.svodBread.agents|| !this.agentList)
                    return;
                let route=this.agentList.find(el=>el.id==this.tab).agents;
                this.agentListX = Array();
                this.agentsAtUserProfile.forEach(agent=>{
                    if (route.find(el => el.id == agent.id)){
                        let line = Array();
                        let findedAgent = this.svodBread.agents.find( el => el['idAgent']==agent.id );
                        this.svodBread.breads.forEach(bread=>{
                            let obj=Object();
                            obj.amount = '';
                            obj.bread_id = bread.id;
                            obj.price = bread.baseprice;
                            obj.agent_id = agent.id;
                            if (findedAgent){
                                let finded = findedAgent.positions.find( el => el['idTu']==bread.id );
                                if (finded){
                                    obj.amount = finded.kolfakt;
                                    obj.original = finded.kolfakt;
                                }
                            }
                            line.push(obj);
                        });
                        this.agentListX.push(
                            {
                                agent: agent,
                                val: line,
                            }
                        );
                    }

                });
            },
        },
        data(){
            return {
                error: null,
                sending: false,
                skip: true,
                agentListX: Array(),
                svodBread: null,
                date: format((new Date()).setDate((new Date()).getDate()+1),'dd-MM-yyyy'),
                tab: '1',
            }
        },
        apollo: {
            agentList: {
                query: gql`query{agentList{id name agents{id name}}}`,
                errorPolicy: 'all',
                result ({ data, loading }) { // eslint-disable-line no-unused-vars
                    if (!loading) {
                        this.fill();
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
            svodBread: {
                query: gql`
                    query($date: Date!){
                        svodBread(date: $date){
                            breads{id name baseprice}
                            agents {idAgent name positions{idTu kolfakt}}
                        }
                    }`,
                variables() {
                    return {
                        date: format(parse(this.date,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'),
                        }
                },
                result ({ data, loading }) { // eslint-disable-line no-unused-vars
                    if (!loading) {
                        this.fill();
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
            agentsAtUserProfile: {
                query: gql`query{agentsAtUserProfile {id name}}`,
                result ({ data, loading }) {
                    if (!loading) {
                        this.agentsAtUserProfile = data.agentsAtUserProfile;
                        this.fill();
                    }
                },
            },
        }
    }
    function isInt(value) {
      return !isNaN(value) &&
             parseInt(Number(value)) == value &&
             !isNaN(parseInt(value, 10));
    }
</script>

<style lang="scss" scoped>
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    .matrix{
        max-width: 100%;
        display: block;
    }
    table.doc_income{
        td, th{
            padding: 8px 10px;
            vertical-align: middle;
            text-align: center;
        }
        th {
            @media screen {
                position: sticky;
                top: 50px;
                z-index: 20;
            }
            background: #fff;
        }
        td.tdinput{
            padding: 1px;
            input.amount {
                width: 50px;
                text-align: center;
            }
        }
    }
    .vert{
        text-orientation: mixed;
        writing-mode: vertical-lr;
        white-space: nowrap;
    }
    .print-only{
        display: none;
    }
    @media print
    {
        .print-only{
            display: inline-block;
        }
        .section
        {
            padding: 0 !important;
        }
        table.matrix{
            td, th{
                border: 1px solid;
                font-size: 10.5pt;
            }

        }
    }

</style>

