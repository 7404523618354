<template>
    <div class="pager">
        <ul>
            <!--li
                v-for="(index,i) in arr" :key="index"
                @click="click(index)" :class="{'active': page==index, 'noselect':!index}">
                <span v-if="index==1">В начало</span>
                <span v-if="index==page-1">Предыдущая</span>
                <span v-if="index==page+1">Следующая</span>
                <span v-if="i==arr.length-1">В конец</span>
            </li-->
            <li :class="{'disabled': page==1}" @click="click(1)">В начало</li>
            <li :class="{'disabled': page==1}" @click="click(page-1)">Предыдущая</li>
            <li :class="{'disabled': !arr.length || page>=arr[arr.length-1]}" @click="click(page+1)">Следующая</li>
            <li :class="{'disabled': !arr.length || page>=arr[arr.length-1]}" @click="click(arr[arr.length-1])">В конец</li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: ['page', 'perpage', 'count',],
        data: function(){
            return {
                arr: [],
            }
        },
        watch: {
            count: function (val) {
                this.rebuild(this.page, val);
            },
        },
        created: function(){
            this.rebuild(this.page);
        },
        methods:{
            rebuild(page){
                this.arr=[];
                if (!this.count){
                    return;
                }
                let last=Math.ceil(this.count/this.perpage);
                for (const i of Array(last).keys()){
                    let j=i+1;
                    if (j==1 || j==last || (j>=(page-1) && j<=(page+1)))
                        this.arr.push(j);
                    else if (j==(page-2) || j==(page+2))
                        this.arr.push(null);
                }
            },
            click(i){
                if (i){
                    this.$emit('change', i);
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/_variables.scss";
    .pager{
        padding: 1px 10px 1px 1px;
        ul{
            li {
                display: inline-block;
                text-align: center;
                cursor: pointer;
                font-size: 26px;
                padding: 12px;
            }
            li.active{
                background-color: #f7f0ce;
            }
            li:hover{
                background-color: $primary;
                color: #fff;
            }
            li.disabled {
                color: #bbb;
            }
            li.disabled:hover{
                background-color: inherit;
                color: #bbb;
                cursor: inherit;
            }
            li.noselect {
                cursor: inherit !important;
                &:hover {
                    background-color: #fff !important;
                }
                &:after {
                    content: '…';
                }
            }
        }
    }
</style>