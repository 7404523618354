<template>
    <div>
        <label class="label"><slot>Контрагент:</slot></label>
        <search-select 
            @select="onSelect" 
            @change="searchChange"
            placeholder="Выберите контрагента..." 
            :itemField = "item => item.name"
            ref="selectRef"
        >
        <!-- <label slot="item" slot-scope="{ node }" :title="node.name">
            <div v-html="$refs.selectRef.markText(node.name)"></div>
        </label> -->
        </search-select>
    </div>
</template>

<script>
import gql from "graphql-tag"
import SearchSelect from './SearchSelect'

export default {
    props: {
        value: {
            default: null
        },
    },
    watch: {
        value(v) { 
            this.$refs.selectRef.setValue(this.value);
        },
    },
    data: () => ({
            search: '',
            offset: 0,
        }
    ),
    components: {
        SearchSelect,
    },
    mounted(){
        this.$refs.selectRef.setValue(this.value);
    },
    methods: {
        onSelect(item){
            this.$emit('input', item);
        },
        searchChange({ search, offset }) {
            this.search = search
            this.offset = offset
        }
    },
    apollo: {
        searchAgents: {
            query: gql`query($search: String){searchAgents(search: $search) {id name}}`,
            variables() {
                return {
                    search: this.search,
                }
            },
            skip() {
                return !this.search;
            },
            result ({data, loading}) {
                if (!loading) {
                    this.offset = 0;
                    this.list = data.searchAgents;
                    this.$refs.selectRef.onLoadOptions({list: data.searchAgents})
                }
            },
            errorPolicy: 'all',
            error(error) {
                this.$emit('error', error);
            },
        },
    }
}
</script>
