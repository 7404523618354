<template>
    <section class="container section" v-if="checkGroup(user, 'Ревизия')">
        <h1>Постановка на приход</h1>

        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <div class="field">
            <div class="control"
                    :class="{'loading': image_uploading}"
            >
                <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="file-input-0" class="input" v-on:change="uploadFromCamera">
                <input type="file" accept=".xml" id="file-input-x" class="input">
            </div>
        </div>
        <table class="report">
            <tr v-for="item in data" :key="item.code" :class="{'not-found': !item.tuId}">
                <td>{{item.code}}</td>
                <td>{{item.name}}</td>
                <td>{{item.amount}}</td>
                <td>{{item.unit}}</td>
                <td>{{item.price.toFixed(2)|currency}}</td>
                <td>{{item.summa.toFixed(2)|currency}}</td>
            </tr>
        </table>

        <div v-if="data"> Строк: {{data.length}}, Сумма: {{ total()|currency }}</div>

        <div class="field">
            <label class="label">Склад:</label>
            <div class="select">
                <select v-model="skladId">
                    <option value="1">Кирса</option>
                    <option value="3">Автомобилистов</option>
                    <option value="5">Таштимирово (Виртуальный склад)</option>
                </select>
            </div>
        </div>
        <!--div class="field">
            <search-dropdown-agent/>
        </div-->

        <button class="button is-primary" @click="submit" :disabled="!data || sending" :class="{'is-loading': sending}">Поставить на приход</button>

    </section>
       
</template>

<script>
    import gql from "graphql-tag"
    import UploadFromCamera from "@/components/UploadFromCamera"
    import SearchDropdownAgent from './SearchDropdownAgent.vue';

    const referer = 'https://kirsa.9733.ru'
    import axios from 'axios';    
    export default{
        name: 'AddIncome',
        mixins: [UploadFromCamera],
        components: {
            SearchDropdownAgent,
        },
        props: {
            user: Object,
        },
        methods: {
            addCustomData(data){
                data.append('xls', true);
            },
            total(){
                let s=null;
                if (this.data){
                    s=0;
                    this.data.forEach(e => {s+=parseFloat(e.summa)});
                }
                return s;
            },
            afterUploading(response){
                //document.getElementById("file-input-0").value="";
                //this.$apollo.queries.rashodOrder.refetch();
                this.data = response.data.items;
                this.agentId = response.data.agent_id;
            },
            submit(){
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql`mutation($skladId: ID!, $data: [AddIncomeItemType]!, $agentId: ID!, $hostId: ID!, $osnId: ID!) {
                        addIncome(skladId: $skladId, data: $data, agentId: $agentId, hostId: $hostId, osnId: $osnId) { ok }
                    }`,
                    variables: {
                        skladId: this.skladId,
                        data: this.data,
                        agentId: this.agentId,
                        hostId: 1,
                        osnId: 1,
                    },
                }).then(data => {
                    this.sending=false;
                    this.error = null;
                    this.data=null;
                    /*
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },
        },
        data(){
            return {
                error: null,
                data: null,
                sending: false,
                skladId: 1,
                agentId: null,
                utmUrl: '192.168.1.69:8080'
            }
        },
        apollo: {
        },
    }
</script>


<style lang="scss" scoped>
    .report {
        height: 500px;
        overflow-y: scroll;
        display: block;
        margin-bottom: 2rem;
    }
    .report{
        td:nth-child(3), td:nth-child(5), td:nth-child(6){
            text-align: right;
        }
        .not-found {
            color: red;
        }
    }

</style>