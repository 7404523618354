import Vue from 'vue'
import routes from './routes'

import 'animate.css'

import apolloProvider from './apollo'

Vue.config.productionTip = false

require('@/assets/backoffice.scss')
require('@/assets/main.scss')

require("./filters.js")

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  symbol: '₽',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'after',
  symbolSpacing: true,
})

import VueFilter from 'vue-filter'
Vue.use(VueFilter)


//import money from 'v-money'
//Vue.use(money, {precision: 2})
import CurrencyInput from '@/components/Weight'
//Vue.use(CurrencyInput)
Vue.component('currency-input', CurrencyInput)

Vue.directive('click-outside', {
  /*bind () {
    	this.event = event => this.vm.$emit(this.expression, event)
    	this.el.addEventListener('click', this.stopProp)
    	document.body.addEventListener('click', this.event)
    },   
    unbind() {
        this.el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.event)
    },
    stopProp(event) { event.stopPropagation() }
    */

  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter({
  //mode: 'history',
  routes, // сокращённая запись для `routes: routes`
})

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faImage, faArrowLeft, faList, faAngleDown, faExclamationTriangle, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faImage)
library.add(faArrowLeft)
library.add(faList)
library.add(faAngleDown)
library.add(faExclamationTriangle)
library.add(faFilter)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import VueEditableGrid from 'vue-editable-grid'
import 'vue-editable-grid/dist/VueEditableGrid.css'
Vue.component('vue-editable-grid', VueEditableGrid)

import VueCookies from 'vue-cookies'
import './registerServiceWorker'
Vue.use(VueCookies)
Vue.$cookies.config('1y')

import Loading from './components/Loading.vue'
Vue.component('loading', Loading)


const MyPlugin = {
  install(Vue, options) {
    // eslint-disable-line no-unused-vars
    Vue.prototype.checkIsAnonymous = (user) => {
      return !user || user.isAnonymous
    }
    Vue.prototype.checkGroup = (user, name) => {
      return user && user.groups && user.groups.find((e) => e.name == name)
    }
    Vue.prototype.checkGroups = (user, names) => {
      return user && user.groups && user.groups.find((e) => names.find((name) => e.name == name))
    }
    Vue.prototype.outgoSum = (item) => {
      return +item.reduce((accum, e) => accum + e.amount * e.price * (e.discount ? 1 - e.discount / 100 : 1), 0).toFixed(2)
    }
    Vue.prototype.paymentSum = (item) => {
      return item.reduce((prev, e) => prev + e.amount, 0)
    }
  },
}
Vue.use(MyPlugin)

/*
if (process.env.NODE_ENV=='production'){
    Vue.config.errorHandler = err => {
        //ym(83894410,'reachGoal','vue-error', {'err': err});
        window.ym(83894410,'params',{'vue-error': event});
    }
}*/

window.vueapp = new Vue({
  router,
  apolloProvider,
  //render: (h) => h(Root),
}).$mount('#app')


