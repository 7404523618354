<template>
    <section class="container" v-if="user && !user.isAnonymous">
        <loading v-if="itemId && $apollo.queries.income.loading"></loading>

        <h1 class="title" v-if="itemId">
            Приход {{itemId}}
        </h1>
        <h1 class="title" v-else>
            Новый приход
        </h1>
        <CheckboxSlider @input="editMode=!editMode" :checked="editMode" :disabled="checkEditable || itemId==null || typeof itemId === 'undefined'"
                    style="position:absolute;right:0; top:0;">
                <span v-if="typeof itemId === 'undefined'">Создание</span>
                <span v-else>Редактирование</span>
        </CheckboxSlider>

        <div class="field is-grouped">
            <div class="control" style="width: 30%">
                <label class="label">Дата:</label>
                <date-picker 
                    v-model="incomeDate" 
                    class="deliverydate"
                    valueType="format" 
                    :first-day-of-week="1" 
                    lang="ru" 
                    :not-before="new Date()" 
                    format="DD.MM.YYYY" 
                    placeholder="Дата доставки"
                    :disabled="!editMode"
                    :not-after="(new Date()).setDate((new Date()).getDate() + 6)"></date-picker>
            </div>

            <div class="control" style="width: 35%;">
                <label class="label">Организация:</label>
                <v-select :options="hosts" v-if="hosts" v-model="host" :disabled="!editMode"></v-select>
            </div>
            <div class="control" style="width: 35%;">
                <label class="label">Склад:</label>
                <v-select :options="sklads" v-if="sklads" v-model="sklad" :disabled="!editMode"></v-select>
            </div>
        </div>

        <div class="field is-grouped">
            <div class="control" style="width: 35%;">
                <label class="label">Поставщик:</label>
                <!--treeselect
                    :multiple="false" 
                    :async="true"
                    placeholder="Выберите поставщика..."
                    searchPromptText="Начните ввод для поиска"
                    noResultsText="Контрагенты не найдены"
                    loadingText="Загрузка данных"
                    v-model="agentId"
                    @select="agentSelect"
                    :load-options="loadAgentOptions"
                    >
                </treeselect-->
                <treeselect-agent @select="agentId=parseInt(arguments[0].id)" 
                    :initOptions="initOptions"
                    :disabled="!editMode" 
                    :initAgent="agent"></treeselect-agent>
            </div>
            <div class="control" style="width: 30%;">
                <label class="label">Основание:</label>
                <v-select :options="reasons" v-if="reasons" v-model="reason" :disabled="!editMode"></v-select>
            </div>
            <div class="control" style="width: 35%;">
                <label class="label">Ответственный:</label>
                <v-select :options="answers" v-if="answers" v-model="answer" :disabled="!editMode"></v-select>
            </div>
        </div>

        <table class="report income-list" style="margin-bottom: 1rem;" v-if="editMode">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>Кол-во</th>
                    <th>Упак</th>
                    <th>Цена прихода</th>
                    <th>Сумма</th>
                    <th>Цена реализации</th>
                    <th>Наценка</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="item.id" class="hover" :class="{'deleted': item.deleted}">
                    <td>{{ index+1 }}</td>
                    <td>{{item.code}}</td>
                    <td><router-link :to="'/dict/tu/'+item.tuId">{{item.name}}</router-link></td>
                    <td>
                        <currency-input v-model="item.amount" class="input" @change="itemAmountChange(item)"
                            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: item.isWeight?3:0, locale: 'ru'}"
                            style="width: 120px; text-align: right;" />
                    </td>
                    <td>
                        <currency-input v-model="item.amountInbox" class="input" @change="itemAmountInboxChange(item)"
                            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 0, locale: 'ru'}"
                            style="width: 60px; text-align: right;" />
                    </td>
                    <td>
                        <currency-input v-model="item.price" class="input" @change="itemPriceChange(item)"
                            :options="{ currency: 'RUB', locale: 'ru'}" style="width: 120px; text-align: right;"/>    
                    </td>
                    <td style="text-align: right;">
                        <currency-input v-model="item.sum" class="input" @change="itemSumChange(item)"
                            :options="{ currency: 'RUB', locale: 'ru'}" style="width: 120px; text-align: right;"/>    
                    </td>
                    <td class="currency">{{item.priceRegular|currency}}</td>
                    <td class="currency">{{item.priceRegular?((item.priceRegular/item.price-1)*100).toFixed(1):''}}%</td>
                    <td>
                        <span class="action" @click="deleteItem(item)" v-if="!item.deleted">Удалить</span>
                        <span class="action" @click="restoreItem(item)" v-if="item.deleted">Восстановить</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="text-align: right;">Итого:</td>
                    <td class="currency">{{totalAmount|currency}}</td>
                    <td class="currency">{{totalRealization|currency}}</td>
                </tr>
            </tbody>
        </table>
        <table class="report income-list" style="margin-bottom: 1rem;" v-else>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>Кол-во</th>
                    <th>Упак</th>
                    <th>Цена прихода</th>
                    <th>Сумма</th>
                    <th>Цена реализации</th>
                    <th>Наценка</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="item.id" class="hover" :class="{'deleted': item.deleted}">
                    <td>{{ index+1 }}</td>
                    <td>{{item.code}}</td>
                    <td>
                        <router-link :to="'/dict/tu/'+item.tuId">{{item.name}}</router-link>
                        <div class="havenot" v-if="!item.barcodes.length">Нет штрих-кода</div>
                    </td>
                    <td class="currency">
                        {{ parseFloat(item.amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}) }}                         
                    </td>
                    <td class="currency">
                        {{ item.amountInbox }}
                    </td>
                    <td class="currency">
                        {{ item.price|currency-null2 }}
                    </td>
                    <td style="text-align: right;">
                        {{ item.sum|currency-null2 }}
                    </td>
                    <td class="currency">{{item.priceRegular|currency-null2}}</td>
                    <td class="currency">{{item.priceRegular?((item.priceRegular/item.price-1)*100).toFixed(1):''}}%</td>
                </tr>
                <tr>
                    <td colspan="5" style="text-align: right;">Итого:</td>
                    <td class="currency">{{totalAmount|currency}}</td>
                    <td class="currency">{{totalRealization|currency}}</td>
                </tr>
            </tbody>
        </table>

        <TitleWithCheck v-show="submitted">
            <div class="text">Приходный документ сохранен</div>
        </TitleWithCheck>

        <div class="field">
            <div class="control tu-items">
                <treeselect 
                    :multiple="true" 
                    :async="true"
                    @select="itemSelect"
                    :always-open="false"
                    :normalizer="tuListNormalizer"
                    placeholder="Выберите товар..."
                    searchPromptText="Начните ввод для поиска"
                    noResultsText="Товар не найден"
                    loadingText="Загрузка данных"
                    :disabled="!editMode"
                    :load-options="loadOptions" >
                    
                    <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
                        <div class="name">{{ node.label[0] }}</div>
                        <div class="price">{{node.label[1]|currency}}</div>
                        <div class="balance">{{node.label[2]}}</div>
                    </label>
                </treeselect>
            </div>
        </div>
        <div class="field is-grouped">
            <p class="control">
                <button class="button is-primary" @click="save" :disabled="checkEmpty || !agentId || !reason || !answer || !sklad || !host || !editMode">
                    Сохранить
                </button>
            </p>
            <p class="control">
                <button class="button" @click="printTickets">Печать ценников</button>
            </p>
            <p class="control">
                <button class="button" @click="loadPrice">Загрузить цены реализации</button>
            </p>
        </div>
    </section>    
</template>

<script>
    import gql from "graphql-tag"
    import SendingMix from '../SendingMix.vue'
    import InOutMixin from './InOutMixin.vue'
    import TitleWithCheck from '../TitleWithCheck.vue'

    import {format, parse} from 'date-fns'
    import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
    const incomeQuery = `
        id 
        description 
        createDt 
        date
        agent{id label:name} 
        reason{code:id label:name} 
        host{code:id label:name} 
        sklad{code:id label:name}
        answer{code:id label:name}
        incomeposSet{
            id 
            amount 
            price 
            tu{
                id name nomer inbox isWeight barcodes{id barcode}                
            } 
            realizationPrice
        }`

    export default {
        mixins: [InOutMixin, SendingMix],
        components: {
            TitleWithCheck,
        },
        data(){
            return {
                skip: true,
            }
        },
        watch: {
            host: function (val) { this.setCookie('incomeHostId', val); },
            sklad: function (val) { this.setCookie('incomeSkladId', val); },
            answer: function (val) { this.setCookie('incomeAnswerId', val); },
            reason: function (val) { this.setCookie('incomeReasonId', val); },
        },        
        computed: {
            totalWeight() { return parseFloat((this.list.reduce((prev, item)=> prev+!item.deleted?0:(item.weight*item.amount), 0)).toFixed(1))},
            totalAmount() { return this.list.reduce((prev, item)=> prev+(item.deleted?0:(item.amount*item.price)), 0) },
            totalRealization() { return this.list.reduce((prev, item)=> prev+(item.deleted?0:(item.amount*item.priceRegular)), 0) },
            checkEmpty: function() {
                return !this.list.length;

            },
            checkEditable() {
                return (this.itemId && !this.checkGroups(this.user, ['Приход изменение'])) 
                    || (this.itemId==null && !this.checkGroups(this.user, ['Приход создание']))
            },
        },
        methods: {
            printTickets(){
                this.$router.push(
                    {
                        name: 'print-ticket',
                        query: {
                            idList: this.list.map(e=>e.tuId),
                        }
                    });
            },
            loadPrice(){
                this.skip=false
                this.$apollo.queries.prices.refetch()
            },
            hostLoaded(data){
                if (!this.itemId) 
                    this.host = this.getCookie('incomeHostId', data); 
            },
            skladLoaded(data){ 
                if (!this.itemId) 
                    this.sklad = this.getCookie('incomeSkladId', data); 
                this.$forceUpdate();
            },
            answerLoaded(data){
                if (!this.itemId) 
                    this.answer = this.getCookie('incomeAnswerId', data); 
            },
            reasonLoaded(data){ 
                if (!this.itemId) 
                    this.reason = this.getCookie('incomeReasonId', data); 
            },
            convertData(){
                return this.list.map(e=>{
                    return {
                        id: e.id,
                        amount: e.amount,
                        price: e.price,
                        tuId: e.tuId,
                        deleted: e.deleted,
                        realizationPrice: e.priceRegular,
                    }
                });
            },
            loadOptions: _.debounce(function({ action, searchQuery, callback }) {
                    if (action === ASYNC_SEARCH) {
                        this.q_debounced = searchQuery;
                        this.callback = callback;
                    }
                }, 500),
            itemSelect(item){
                this.list.push(
                    {
                        tuId: item.id, 
                        name: item.label,
                        code: item.nomer,
                        amount: 1,
                        price: item.lastPrice,
                        priceRegular: item.price,
                        sum: item.lastPrice * 1,
                        inbox: item.inbox,
                        isWeight: item.isWeight,
                        deleted: false,
                    }
                );
            },
            parseAfterLoading(data){
                this.incomeDate = format(parse(data.income.date,"yyyy-MM-dd", new Date()),'dd.MM.yyyy')
                this.sklad = data.income.sklad //{code: data.income.sklad.id, label: data.income.sklad.name}
                this.reason = data.income.reason
                this.host = data.income.host
                this.answer = data.income.answer
                this.agent = data.income.agent
                this.agentId = data.income.agent.id
                this.initOptions = [data.income.agent]
                this.list = data.income.incomeposSet.map(item => {
                    return {
                        id: item.id, 
                        tuId: item.tu.id,
                        name: item.tu.name,
                        code: item.tu.nomer, 
                        price: item.price,
                        priceRegular: item.realizationPrice,
                        amount: item.amount,
                        amountInbox: (!item.tu.isWeight && item.tu.inbox && item.amount%item.tu.inbox==0)?item.amount/item.tu.inbox:null,
                        sum:  Math.round((    item.price*item.amount*(item.discount?(1-item.discount/100):1)     + Number.EPSILON) * 100) / 100,
                        inbox: item.tu.inbox,
                        isWeight: item.tu.isWeight,
                        deleted: false,
                        barcodes: item.tu.barcodes,
                    }
                })
                /*this.list.push(
                    {
                        price: item.lastPrice,
                        priceRegular: item.price,
                    }*/
            },
            save(){
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql(`
                        mutation($date: Date!, $skladId: ID!, $data: [CreateIncomeItemType]!, 
                                 $agentId: ID!, $hostId: ID!, $reasonId: ID!, $answerId: ID!, $id: ID) {
                        createUpdateIncome(date: $date, skladId: $skladId, data: $data, agentId: $agentId, 
                                     hostId: $hostId, reasonId: $reasonId, answerId: $answerId, id: $id) { 
                                        income{` + incomeQuery + `}
                                    }
                    }`),
                    variables: {
                        date: format(parse(this.incomeDate, "dd.MM.yyyy", new Date()), 'yyyy-MM-dd'),
                        skladId: this.sklad.code,
                        data: this.convertData(),
                        agentId: this.agentId,
                        hostId: this.host.code,
                        reasonId: this.reason.code,
                        answerId: this.answer.code,
                        id: this.itemId,
                    },
                }).then(data => {
                    this.sending=false;
                    this.parseAfterLoading(data.data.createUpdateIncome)

                    //if (!this.itemId){
                    //    this.data=null;
                    //    this.list=Array();
                    //}
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                        this.$router.push('/income/' + data.data.createUpdateIncome.income.id)
                    }, 2000);
                }).catch(error => {
                    this.sending=false;
                    this.$emit('error', JSON.stringify(error.message));
                });
            }
        },
        apollo: {
            income: {
                query: gql(`query ($id: ID!) {
                            income(id: $id){` + incomeQuery + `}
                        }`),
                variables() {
                    return {
                        id: this.itemId,
                    }
                },
                skip(){
                    return !this.user || this.user.isAnonymous || this.itemId==null
                }, 
                result({data, loading}) {
                    if (!loading && data && data.income){
                        this.parseAfterLoading(data)
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            search: {
                query: gql`
                    query($q: String, $activeOnly: Boolean, $positiveBalance: Boolean) {
                        search(q: $q, activeOnly: $activeOnly, positiveBalance: $positiveBalance) {
                            count
                            items{
                                id label: name nomer lastPrice isArc balance
                                inbox price isWeight
                            }
                        }
                    }`,
                variables() {
                    return {
                        q: this.q_debounced,
                        activeOnly: true,
                        positiveBalance: false,
                    }
                },
                skip() {
                    return !this.q_debounced;
                },
                result ({ data, loading }) {
                    if (!loading) {
                        if (this.callback){
                            this.callback(null, data.search.items);
                            this.callback = null;
                        }
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            prices: {
                query: gql`
                    query($skladId: ID, $ids: [ID]) {
                        prices(skladId: $skladId, ids: $ids) {
                            id price
                        }
                    }`,
                skip(){
                    return this.itemId==null || this.skip
                }, 
                variables() {
                    return {
                        skladId: this.income.sklad.code,
                        ids: this.list.map(item => {
                            return item.tuId
                        })
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                        this.skip=true
                        data.prices.forEach(e=>{
                            this.list.filter(x => x.tuId==e.id).forEach(x => x.priceRegular=e.price)
                        })
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        },
    }
</script>
<style scoped land="scss">
    .havenot {
        color: red;
        font-size: 13px;
    }
</style>