<template>
    <div class="container" v-if="type=='pricelist' || checkGroups(user, ['Полный прайслист', 'Продавец СБИС', 'Продавец', 'Оптовый клиент'])"> 
        <section class="section start">
            <Groups
                ref="groups"
                :title="'Каталог'"
                :goods="goods"
                :branch_id="branch_id"
                :top="'1'"
                @flickoff="flickoffGroups"
                @select="select_group"
                />
            <div class="tu">
                <div class="field">
                    <div class="control" style="display: flex; align-items: center;">
                        Найти: <input class="input" type="text" v-model="search" v-on:input="debounceInput" :placeholder="placeholder" style="margin-left: 10px;">
                        <router-link to="/cart" style="display: flex; align-items: center; padding: 10px;">
                            <svg class="icon-svg cart">
                                <use xlink:href="@/assets/sprite.svg#icon-cart"></use>
                            </svg>
                            <span v-if="cartSummary" style="white-space: nowrap; display: inline-block;">{{cartSummary.sum}} ₽</span>
                        </router-link>

                    </div>
                </div>

                <div class="field group-selector">
                    <div class="control">
                        <button class="button" @click.stop="$refs.groups.show">Группа: {{category?name:'ВСЁ'}}</button>
                    </div>
                </div>

                <div class="field balance-filter">
                    <div class="control">
                        <label class="label">
                            <input class="checkbox" type="checkbox" @change="push" v-model="availability" :disabled="!user || !user.isStaff">
                            Остаток больше нуля
                        </label>
                    </div>
                </div>
                <!--div class="field picture-filter" v-if="checkGroup(user, 'Фильтр товары без фото')">
                    <div class="control">
                        <div class="select" style="margin-left: 10px;">
                            <select v-model="availabilitySkladX" :disabled="!availability" @change="push">
                                <option value="">Любой склад</option>
                                <option value="1">Кирса</option>
                                <option value="3">Автомобилистов</option>
                            </select>
                        </div>
                    </div>
                </div-->
                <div class="field picture-filter">
                    <div class="control">
                        <label class="label">
                            Производитель:
                            <div class="select" style="margin-left: 10px;">
                                <select v-model="producerId" @change="push">
                                    <option value="">-</option>
                                    <option value="1">КДВ</option>
                                </select>
                            </div>
                        </label>
                    </div>
                </div>
                <TuList
                    ref="tulist"
                    :tu="tu?tu.items:null"
                    :user="user"
                    @modcart="modcart"
                    :loading="$apollo.queries.tu.loading"
                    />

                    
                <Pager
                    :page="page"
                    :count="tu.count"
                    :perpage="40"
                    @change="changepage"
                    v-if="tu"
                    ref="pager"/>
            </div>
        </section>
        <!--EditItem
            v-if="user && !user.isAnonymous && itemModal"
            :item="item"
            :user="user"
            :itemModal="itemModal"
            @closeItem="itemModal=false"
            >
        </EditItem-->
    </div>
</template>

<script>
    import StoreMixin from './StoreMixin.vue'

    export default {
        name: 'Store',
        mixins: [StoreMixin, ],
        methods: {
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/_variables.scss";
    svg.cart {
        height: 24px;
        width: 38px;
        fill: #3273dc;
    }
    .start{
        display: flex;
        div.tu {
            width: 70%;
            @media screen and (max-width: $desktop) {
                width: 100%;
            }
        }
    }
    @media screen and (min-width: $desktop + 1px) {
        .group-selector{
            display: none;
        }
    }
</style>
