<script>
    import CheckboxSlider from '@/components/checkboxSlider'
    import Pager from '@/components/Pager.vue'
    import _ from 'lodash'
    export default {
        props: {
            user: Object,
            q: String,
            ao: Boolean,
            pg: Number,
        },
        data: () => ({
                list: null,
                page: 1,
                search: null,
                q_debounced: '',
                activeOnly: true,
            }
        ),
        components: {
            CheckboxSlider,
            Pager,
        },
        created(){
            this.search = this.q;
            this.q_debounced = this.q;
            this.activeOnly = this.ao;
            this.page = this.pg;
        },
        mounted(){
            this.$refs.activeOnly.setChecked(this.activeOnly);
        },
        watch: {
            pg: function (val){
                this.page = this.pg;
            },
        },
        methods:{
            debounceInput: _.debounce(function (e) {
                this.list=null;
                this.q_debounced=e.target.value;
                this.page=1;
                this.push();
            }, 500),
            changeActiveOnly(){
                this.activeOnly = !this.activeOnly;
                this.page=1;
                this.push();
            },
            changepage(page){
                this.page=page;
                this.$refs.pager.rebuild(this.page);
                this.push();
            },
        }
    }
</script>

<style lang="scss">
    .searchfield {
        position: sticky;
        top: 66px;
        background: #fff;
        z-index: 10;        
    }

.search-result {
        .is-arc {
            background: #ffdede;
        }
    }
    .striped {
        tr.is-arc:nth-child(even){
            background: #f5c3c3;
        }
        tr.is-arc:nth-child(odd){
            background: #ffd7d7;
        }
    }

</style>