<template>
    <div class="container section" v-if="checkGroup(user, 'Ревизия')">
        <loading v-if="$apollo.queries.reportOverage.loading"></loading>
        <!--font-awesome-icon icon="arrow-left" id="backbutton" @click="$router.go(-1)"/-->
        <audit-detail 
            :detailsActive="detailsActive" 
            @close="detailsActive=false"
            :detail="detail"
            :inOutHistoryMysql="inOutHistoryMysql"
            :detailTuId="detailTuId"/>
        <h1>Ревизия. Избыток</h1>
        <h3 v-if="reportOverage && reportOverage.audit">
            Склад: {{reportOverage.audit.sklad.name}}. 
            Начало {{reportOverage.audit.created|datetime}}</h3>
        <div v-if="reportOverage && reportOverage.audit">Примечание: {{reportOverage.audit.info}}</div>

        <audit-menu :item='3' :auditId='auditId'>
        </audit-menu>

        <table class="report striped" style="margin-top: 2rem;">
            <thead>
                <tr>
                    <th>#</th>
                    <th>id</th>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>Учет</th>
                    <th>Факт</th>
                    <!--th>Оприходовано</th-->
                    <th>Избыток</th>
                </tr>
            </thead>
            <tbody v-if="reportOverage && reportOverage.report">
                <tr v-for="(item, index) in reportOverage.report" class="hover" :key="item.id">
                    <td>{{index+1}}</td>
                    <td>{{item.tuId}}</td>
                    <td>{{item.nomer}}</td>
                    <td><router-link :to="'/dict/tu/' + item.tuId">{{item.name}}</router-link></td>
                    <td>
                        <a @click="details(item.tuId, {name: item.name, nomer: item.nomer, ts:item.ts, editor: item.editor, fact: item.fact})">
                            {{ item.acount|number-null03 }}
                        </a>
                    </td>
                    <td>{{( reportOverage.audit.relative?(parseFloat(item.acount)+parseFloat(item.fact)):item.fact )|number-null03}}</td>
                    <!--td>{{parseFloat(item.adj) * -1}}</td-->
                    <td>{{ reportOverage.audit.relative? item.fact: (parseFloat(item.fact)-parseFloat(item.acount?item.acount:0)) |number-null03 }}</td>
                </tr>
            </tbody>
        </table>

        <div class="field">
            <button class="button is-primary" @click="createOverage" :class="{'is-loading': sending}" :disabled="sending">
                Создать приходную накладную
            </button>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import AuditSO from './AuditSO.vue'
    export default {
        mixins: [
            AuditSO,
        ],
        methods: {
            createOverage(){
                this.sending=true;
                if (this.reportOverage.audit.sybase==1){
                    this.$apollo.mutate({
                        mutation: gql`mutation($auditId: ID!) {
                            createOverageNew(auditId: $auditId) { ok }
                        }`,
                        variables: {
                            auditId: this.auditId,
                        },
                    }).then(data => {
                        this.sending=false;
                        this.$apollo.queries.reportOverage.refetch();
                    }).catch(error => {
                        this.sending=false;
                        //this.error=error;
                        this.$emit('error', JSON.stringify(error.message));
                        this.$apollo.queries.reportOverage.refetch();
                    });
                } else {
                    this.$apollo.mutate({
                        mutation: gql`mutation($auditId: ID!) {
                            createOverageNew(auditId: $auditId) { ok }
                        }`,
                        variables: {
                            auditId: this.auditId,
                        },
                    }).then(data => {
                        this.sending=false;
                        this.$apollo.queries.reportOverage.refetch();
                    }).catch(error => {
                        this.sending=false;
                        //this.error=error;
                        this.$emit('error', JSON.stringify(error.message));
                        this.$apollo.queries.reportOverage.refetch();
                    });
                }
            },
        },
        apollo: {
            reportOverage: {
                query: gql`
                    query($id: ID!){
                        reportOverage(id: $id){
                            audit {
                                id created info relative sybase
                                sklad {
                                    id name
                                }
                            }
                            report {
                                tuId nomer name acount fact adj
                            }
                        }
                    }`,
                variables() {
                    return {
                        id: this.auditId,
                        }
                },
                errorPolicy: 'all',
                result ({ data, loading }) {
                    if (!loading) {
                        //this.fill();
                    }
                },
                error(error) {
                    //this.error = JSON.stringify(error.message);
                    this.$emit('error', JSON.stringify(error.message));
                },
            },

        }
    }
</script>

<style lang="scss" scoped>

    thead th{
        position: sticky;
        position: -webkit-sticky;
        top: 55px;
        background: white;
        z-index: 10;
    }

    table {
        td:nth-child(1), td:nth-child(2), td:nth-child(5), td:nth-child(6), td:nth-child(7){
            text-align: right;
        }
    }
</style>