<template>
    <div class="container">
        <h1>Расход # {{itemId}}</h1>
        <table class="report stripped" v-if="outgo && outgo.outgoposSet">
            <tr>
                <th>#</th>
                <th>Код</th>
                <th>Наименование</th>
                <th>Кол-во</th>
                <th>Цена</th>
            </tr>
            <tr v-for="(i, index) in outgo.outgoposSet" :key="i.id" class="hover">
                <td>{{index + 1}}</td>
                <td>{{i.incomepos.tu.nomer}}</td>
                <td>{{i.incomepos.tu.name}}</td>
                <td>{{i.amount}}</td>
                <td>{{i.price|currency-null}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    export default {
        props: {
            itemId: String,
        },
        data() {
            return {
                outgoPos: null,
            }
        },
        apollo: {
            outgo: {
                query: gql`
                    query($id: ID){
                        outgo(id: $id) {
                            outgoposSet{id amount price incomepos{tu{id nomer name}}}
                            
                        }
                    }`,
                variables() {
                    return {
                        id: this.itemId,
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.error = JSON.stringify(error.message);
                },

            },
        },
    }
</script>

<style lang="scss" scoped>
    .report .total {
        text-align: right;
        white-space: nowrap;
    }
</style>