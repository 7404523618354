<template>
    <div class="container section" v-if="checkGroup(user, 'Ревизия')">
        <loading v-if="$apollo.queries.reportShortage.loading"></loading>

        <!--font-awesome-icon icon="arrow-left" id="backbutton" @click="$router.go(-1)"/-->
        <audit-detail 
            :detailsActive="detailsActive" 
            @close="detailsActive=false"
            :detail="detail"
            :inOutHistoryMysql="inOutHistoryMysql"
            :detailTuId="detailTuId"/>
        <h1>Ревизия. Недостача</h1>
        <h3 v-if="reportShortage">
            Склад: {{reportShortage.audit.sklad.name}}. Начало {{reportShortage.audit.created|datetime}}. 
            Движение: {{ reportShortage.audit.relative }}</h3>
        <div v-if="reportShortage && reportShortage.audit">Примечание: {{reportShortage.audit.info}}</div>

        <audit-menu :item='2' :auditId='auditId'>
        </audit-menu>

        <table class="audit-report report striped" style="margin-top: 2rem;">
            <thead>
                <tr>
                    <th>#</th>
                    <th>id</th>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>Учет</th>
                    <th>Факт</th>
                    <!--th>Списано</th-->
                    <th>Недостача</th>
                </tr>
            </thead>
            <tbody v-if="reportShortage">
                <tr v-for="(item, index) in reportShortage.report" class="hover" :key="item.tuId" 
                        :class="{'negative': (parseFloat(item.acount)+parseFloat(item.fact)).toFixed(3)<0}">
                    <td>{{index+1}}</td>
                    <td>{{item.tuId}}</td>
                    <td>{{item.nomer}}</td>
                    <td><router-link :to="'/dict/tu/' + item.tuId">{{item.name}}</router-link></td>
                    <td>
                        <a @click="details(item.tuId, {name: item.name, nomer: item.nomer, ts:item.ts, editor: item.editor, fact: item.fact})">
                            {{ item.acount|number-null03}}
                        </a>
                    </td>
                    <td>{{ (reportShortage.audit.relative?  (parseFloat(item.acount)+parseFloat(item.fact)) : item.fact )|number-null03 }}</td>
                    <!--td>{{item.adj}}</td-->
                    <td>{{ (reportShortage.audit.relative? (-1*parseFloat(item.fact)) : (parseFloat(item.acount)-parseFloat(item.fact?item.fact:0)) )|number-null03}}</td>
                </tr>
            </tbody>
        </table>
        <div class="field">
            <button class="button is-primary" @click="createShortage" :class="{'is-loading': sending}" :disabled="sending || $apollo.queries.reportShortage.loading">
                Создать расходную накладную
            </button>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import AuditSO from './AuditSO.vue'
    export default {
        mixins: [
            AuditSO,
        ],
        methods: {
            createShortage(){
                this.sending=true;
                if (this.reportShortage.audit.sybase==1){
                    this.$apollo.mutate({
                        mutation: gql`mutation($auditId: ID!) {
                            createShortageNew(auditId: $auditId) { ok }
                        }`,
                        variables: {
                            auditId: this.auditId,
                        },
                    }).then(data => { // eslint-disable-line no-unused-vars
                        this.sending=false;
                        this.$apollo.queries.reportShortage.refetch();
                    }).catch(error => {
                        this.sending=false;
                        this.$emit('error', JSON.stringify(error.message));
                        this.$apollo.queries.reportShortage.refetch();
                    });
                } else {
                    this.$apollo.mutate({
                        mutation: gql`mutation($auditId: ID!) {
                            createShortageNew(auditId: $auditId) { ok }
                        }`,
                        variables: {
                            auditId: this.auditId,
                        },
                    }).then(data => { // eslint-disable-line no-unused-vars
                        this.sending=false;
                        this.$apollo.queries.reportShortage.refetch();
                    }).catch(error => {
                        this.sending=false;
                        this.$emit('error', JSON.stringify(error.message));
                        this.$apollo.queries.reportShortage.refetch();
                    });
                }
            },
        },
        apollo: {
            reportShortage: {
                query: gql`
                    query($id: ID!){
                        reportShortage(id: $id){
                            audit {
                                id created info relative sybase
                                sklad {
                                    id name
                                }
                            }
                            report {
                                tuId nomer name acount fact adj ts editor
                            }
                        }
                    }`,
                variables() {
                    return {
                        id: this.auditId,
                        }
                },
                errorPolicy: 'all',
                result ({ data, loading }) { // eslint-disable-line no-unused-vars
                    if (!loading) {
                        //this.fill();
                    }
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        }
    }
</script>

<style lang="scss" scoped>
    #details{
        min-height: 50vh;
    }

    table.audit-report {
        td:nth-child(1), td:nth-child(2), td:nth-child(5), td:nth-child(6), td:nth-child(7), td:nth-child(8){
            text-align: right;
        }
        thead th{
            position: sticky;
            position: -webkit-sticky;
            top: 55px;
            background: white;
            z-index: 10;
        }
        tr.negative{
            background: rgb(255, 128, 128);
        }
    }
</style>
