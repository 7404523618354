<template>
    <section class="section" v-if="checkGroup(user, 'Топливные карты')">
        <h1 class="title">Топливные карты</h1>
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>

        <div class="modal modal-fx-3dSlit" :class="{'is-active': cardToAddMoneyId}">
            <div class="modal-background" @click="cardToAddMoneyId=null"></div>
            <div class="modal-content">
                <div class="box">
                    <div class="title">Пополнение карты ({{cardToAddMoneyName}})</div>
                    <div class="columns">
                        <div class="column is-one-third">
                            <label class="label">Сумма: </label>
                            <div class="control">
                                <currency-input v-model="amountMoney" class="input" :disabled="sendingMoney"
                                    :options="{ currency: 'RUB'}" style="width: 120px" />
                            </div>
                        </div>
                    </div>
                    <div class="field is-grouped">
                        <div class="control">                        
                            <button class="button is-primary" @click="doAddMoney" :class="{'loading': sendingMoney}" style="min-height: 10px;" :disabled="sendingMoney || !amountMoney">Пополнить</button>
                        </div>
                        <div class="control">
                            <button class="button" aria-label="close" @click="cardToAddMoneyId=null">Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="cardToAddMoneyId=null"></button>
        </div>

        <div class="modal modal-fx-3dSlit" :class="{'is-active': cardToAddCheckId}">
            <div class="modal-background" @click="cardToAddCheckId=null"></div>
            <div class="modal-content">
                <div class="box">
                    <div class="title">Внести чек по карте {{cardToAddCheckName}}</div>
                    <div class="field">
                        <label class="label">Автомобиль: </label>
                        <div class="select">
                            <select name="" id="" v-model="addCheckAutoId"
                                :disabled="sendingCheck"
                                >
                                <option v-for="auto in autos" :key="auto.id" :value="auto.id">{{auto.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Топливо: </label>
                        <div class="select">
                            <select name="" id="addCheckFuel" v-model="addCheckFuelId"
                                :disabled="sendingCheck"
                                >
                                <option v-for="fuel in fuels" :key="fuel.id" :value="fuel.id">{{fuel.name}} ({{fuel.lastPrice}})</option>
                            </select>
                        </div>
                    </div>


                    <div class="field">
                        <label class="label">Дата и время чека: </label>
                        <div class="control">
                            <date-picker
                                v-model="addCheckDatetime"
                                class="date"
                                type="datetime"
                                format="DD.MM.YYYY HH:mm"
                                :show-second="false"
                                :first-day-of-week=0
                                lang="ru"
                                placeholder="Дата и время"
                                id="from"
                                ></date-picker>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-one-third">
                            <label class="label">Кол-во (л)</label>
                            <div class="control">
                                <currency-input v-model="checkAmount" class="input" :disabled="sendingCheck"
                                    :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 2}"
                                    style="width: 120px" />

                            </div>
                        </div>
                    </div>

                    <div class="field is-grouped">
                        <div class="control">                        
                            <button class="button is-primary" @click="doAddCheck" :class="{'loading': sendingMoney}" 
                                    style="min-height: 10px;" :disabled="sendingCheck || !addCheckAutoId || !addCheckFuelId">
                                Внести чек
                            </button>
                        </div>
                        <div class="control">
                            <button class="button" aria-label="close" @click="cardToAddCheckId=null">Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="cardToAddCheckId=null"></button>
        </div>


        <div class="cards-wrapper">
            <div class="flip-container" @click="hover(card.id)" :class="{'hover': hovered==card.id}" v-for="card in cardsExt" :key="card.id">
                <div class="flipper">
                    <div class="front newcard" >
                        <div class="cardname">{{card.name}}</div>
                        <div class="autoname">
                            {{card.cardholdName}}
                        </div>
                        <div class="balance">
                            {{ card.summa|currency }}
                        </div>
                        <div class="contactless">
                            <img src="@/assets/contactless.svg">
                        </div>
                    </div>
                    <div class="back">
                        <div class="x-action">
                            <span class="action" style="color: #fff;" @click.stop="dialogAddCheck(card.id, card.name)">
                                Добавить чек
                            </span>
                        </div>
                        <div class="x-action">
                            <span class="action" style="color: #fff;" @click.stop="addMoney(card.id, card.name)">
                                Пополнить
                            </span>
                        </div>
                        <div @click.stop="holderChanged(card, null, null)" v-show="card.autoId">
                            <span class="action" style="color: #fff;">Вернуть карту</span>
                        </div>
                        <!--div @click.stop="" v-show="!card.autoId">
                            <span class="action" style="color: #fff;">Выдать карту ></span>
                        </div-->
                        <!--div class="magnitline"></div-->
                        <ul class="autos">
                            <li v-for="auto in autos" :key="auto.id" @click.stop="holderChanged(card, auto.id, auto.name)" :class="{'selected': auto.id==card.autoId}">
                                {{auto.name}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import gql from "graphql-tag"
    import {format, parse} from 'date-fns'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    export default {
        name: 'FuelCard',
        data(){
            return {
                error: null,
                cardToAddMoneyId: null,
                cardToAddMoneyName: null,
                cardToAddCheckId: null,
                cardToAddCheckName: null,
                amountMoney: 0,
                checkAmount: 0,
                sendingMoney: false,
                sendingCheck: false,
                addCheckAutoId: null,
                addCheckFuelId: null,
                addCheckDatetime: null,
                hovered: null,
            }
        },
        props: [
            'user',
        ],
        components: {
            DatePicker,
        },
        methods: {
            hover(id) {
                //console.log(el);
                //document.getElementsByClassName('flip-container').forEach(e=>{e.classList.remove('hover')});
                //ev.target.classList.toggle('hover');
                //el.classList.add('hover');
                if (this.hovered != id ){
                    this.hovered = id;
                } else {
                    this.hovered = null;
                }
            },
            addMoney(cardId, cardName) {
                this.cardToAddMoneyId = cardId;
                this.cardToAddMoneyName = cardName;
            },
            dialogAddCheck(cardId, cardName) {
                this.cardToAddCheckId = cardId;
                this.cardToAddCheckName = cardName;
            },
            doAddMoney() {
                this.sendingMoney = true;
                this.$apollo.mutate({
                    mutation: gql`mutation($cardId: ID!, $money: Decimal!) {
                        addMoneyToFuelCard(cardId: $cardId, money: $money) { ok }
                    }`,
                    variables: {
                        cardId: this.cardToAddMoneyId,
                        money: this.amountMoney,
                    },
                }).then(data => {
                    this.sendingMoney = false;
                    this.amountMoney = 0;
                    this.error = null;
                    this.cardToAddMoneyId=null;
                    this.$apollo.queries.cardsExt.refetch();
                    /*
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },
            doAddCheck() {
                this.sendingCheck = true;
                this.$apollo.mutate({
                    mutation: gql`mutation($cardId: ID!, $autoId: ID!, $fueltypeId: ID!, $amount: Decimal!, $purchaseTs: DateTime!) {
                        addFuelCheck(cardId: $cardId, autoId: $autoId, fueltypeId: $fueltypeId, amount: $amount, purchaseTs: $purchaseTs) { ok }
                    }`,
                    variables: {
                        cardId: this.cardToAddCheckId,
                        amount: this.checkAmount,
                        autoId: this.addCheckAutoId,
                        fueltypeId: this.addCheckFuelId,
                        purchaseTs: this.addCheckDatetime,
                    },
                }).then(data => {
                    this.sendingCheck = false;
                    this.checkAmount = 0;
                    this.error = null;
                    this.cardToAddCheckId=null;
                    this.$apollo.queries.cardsExt.refetch();
                }).catch(error => {
                    this.sendingCheck = false;
                    this.sending=false;
                    this.error=error;
                });
            },
            holderChanged(item, autoId, autoName) {
                if (item.autoId==autoId){
                    console.log("***");
                    return;
                }
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql`mutation($cardId: ID!, $autoId: ID, $cardholdId: ID) {
                        setCardAuto(cardId: $cardId, autoId: $autoId, cardholdId: $cardholdId) { ok id }
                    }`,
                    variables: {
                        cardId: item.id,
                        autoId: autoId,
                        cardholdId: item.cardholdId,
                    },
                }).then(data => {
                    this.sending=false;
                    this.error = null;
                    item.cardholdId = data.data.setCardAuto.id;
                    item.autoId = autoId;
                    item.cardholdName = autoName;
                    /*
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },
        },
        apollo: {
            cardsExt: {
                query: gql`
                    query{
                        cardsExt {
                            id name autoId cardholdId cardholdName summa
                        }
                    }`,
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
            autos: {
                query: gql`
                    query{
                        autos {
                            id name nomer
                        }
                    }`,
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
            fuels: {
                query: gql`
                    query{
                        fuels {
                            id name lastPrice
                        }
                    }`,
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    .report{
        td {
            vertical-align: middle;
            padding: 10px;
        }
    }
    .newcard {
        .balance {
            text-align: center;
            font-size: 150%;
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
        }
        .cardname {
            text-align: center;
            font-size: 150%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;            
        }
    }


    /* весь контейнер поддерживает перспективу */
    .flip-container {
        perspective: 1000;
        margin-bottom: 1.5rem;
    }

   
    .flip-container, .front, .back {
        width: 320px;
        height: 200px;
    }
    
    /* здесь находится скорость перевертывания */
    .flipper {
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;
    }
    
    /* скройте обратную сторону во время переворота */
    .front, .back {
        border-radius: 10px;
        padding: 1rem;
        color: #fff;

        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    /* лицевая сторона размещена над обратной */
    .front {
        z-index: 2;
        background: linear-gradient(157deg, #d70000, #4d0000), url(https://grainy-gradients.vercel.app/noise.svg);
        .contactless {
            width: 25px;
            opacity: .33;
            position: absolute;
            right: 14px;
            top: 78px;            
        }
    }
    
    /* обратная, изначально скрытая сторона */
    .back {
        transform: rotateY(180deg);
        background: linear-gradient(157deg,#b10000,#3e0000);
        .magnitline {
            position: absolute;
            background: linear-gradient(167deg, #3c3c3c, #202020);
            left: 0;
            right: 0;
            bottom: 20px;
            height: 50px;        
        }
        .x-action {
            margin-bottom: 1.1rem;
        }
        ul.autos {
            position: absolute;
            right: 10px;
            top: 10px;
            bottom: 10px;
            left: 50%;
            overflow-y: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            li{
                padding: 3px;
                margin: 2px 0;
                cursor: pointer;
                &:hover {
                    border: 1px dotted #ccc;
                    padding: 2px;
                }
                transition: background .5s;
            }
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track-piece {
                background: #987b7b;
            }
            &::-webkit-scrollbar-thumb {
                background: #eee;
            }
            li.selected{
                background: #fff;
                color: #111;
                transition: background .5s;
            }
        }
    }   

    .cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;        
    }
    .v-money {
        text-align: right;
    }
</style>