<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <h1 class="title">Накладные склада "Не отгруженно"</h1>
        <div class="loading" v-if="$apollo.queries.notShippedList.loading"></div>
        <div v-if="notShippedList">
            <table class="report striped">
                <tr>
                    <th>Дата</th><th>Номер</th><th>Контрагент</th><th>Кол-во</th><th>Сумма</th>
                </tr>
                <tr v-for="i in notShippedList" class="hover" @click="click(i)" :key="i.docin">
                    <td>{{i.date|date-ddmmyy}}</td>
                    <td>{{i.docin}}</td>
                    <td>{{i.agentName}}</td>
                    <td class="cnt">{{i.cnt}}</td>
                    <td class="amount">{{i.amount|currency}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>

    import gql from "graphql-tag"

    export default {
        name: 'NotShipped',
        components: {
        },
        props: [
            'user',
        ],
        methods: {
            click(i){
                this.$router.push('/reports/notshipped/' + i.docin);
            },
        },
        data(){
            return {
            }
        },
        apollo: {
            notShippedList: {
                query: gql`
                    query{
                       	notShippedList{
                            docin date cnt amount agentName
                        }
                    }`,
                /*variables() {
                    return {
                        code: this.order_id,
                    }
                },*/
                error(error) {
                    this.$emit('error', error);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    .amount, .cnt {
        text-align: right;
        padding-left: 15px;
    }
    .report{
        tr {
            cursor: pointer;
            td{
                padding: 10px;
            }
        }
    }
</style>

