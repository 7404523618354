<template>
    <div>
        <label class="label">Ответственный:</label>
        <v-select :options="answers" v-model="selected" @input="onInput" />
    </div>
</template>

<script>
    import ApolloAnswerMixin from '../Warehouse/ApolloAnswerMixin';
    export default {
        props:{
            value: {
                default: null
            },
            emitValue:{
                type: String,
                default: null
            },
            user: Object,
        },
        mixins: [ApolloAnswerMixin, ],
        data(){
            return{
                selected: null,
            }
        },
        methods:{
            onInput(item) {
                let v = null;
                if(item){
                    v = this.emitValue? item[this.emitValue] : item;
                }
                this.$emit('input', v);
            },
            setSelected(){
                if(this.value){
                    let property = this.emitValue? this.emitValue : 'code';
                    this.selected = this.answers.find(x => x[property] == this.value);
                }
            },
            answerLoaded(data){
                this.setSelected();
                this.$forceUpdate();
            },
        },
    }
</script>
