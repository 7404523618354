<template>
    <table class="products">
            <tr>
                <th>Номер</th>
                <th>Наименование</th>
                <th>Остаток</th>
                <th>Остаток другой склад</th>
                <th>В упак</th>
                <th>Расх.нед.</th>
                <th></th>
                <th></th>
                <th></th>
                <th>Дата прихода</th>
                <th>Цена прихода</th>
            </tr>
            <tr :key="product.id" v-for="(product, idx) in predictionKirsa">
                <td>{{product.nomer}}</td>
                <td style="vertical-align: -webkit-baseline-middle;">
                    <router-link :to="'/reports/product-detail/?tu_id=' + product.id + '&sklad_id=' + skladId">
                        {{product.name}}
                    </router-link>
                    <!--div class="dropdown" @click="dropdown(product.id)" :class="{'is-active': dropdownItemId==product.id}">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" style="height: 32px;">
                                <span style="padding-right: .5rem;">Исключить</span>
                                <font-awesome-icon icon="angle-down"/>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a class="dropdown-item" @click="ignore(product.id, skladId, agent.products, idx, 1)">
                                    На 1 день
                                </a>
                                <a class="dropdown-item" @click="ignore(product.id, skladId, agent.products, idx, 7)">
                                    На неделю
                                </a>
                                <a class="dropdown-item" @click="ignore(product.id, skladId, agent.products, idx, 30)">
                                    На месяц
                                </a>
                                <hr class="dropdown-divider">
                                <a class="dropdown-item" @click="ignore(product.id, skladId, agent.products, idx, 0)">
                                    Навсегда
                                </a>
                            </div>
                        </div>
                    </div-->
                </td>
                <td>{{product.balance}}</td>
                <td>{{product.balanceOther}}</td>
                <td>{{product.inbox}}</td>
                <td>{{Number(product.expenseWeek).toFixed(2)}}</td>
                <td>
                    {{Number(product.week).toFixed(2)}}
                    <span v-if="product.inbox>0" class="package">({{Math.round(product.week/product.inbox)}} уп.)</span>
                </td>
                <td>
                    {{Number(product.month).toFixed(2)}}
                    <span v-if="product.inbox>0" class="package">({{Math.round(product.month/product.inbox)}} уп.)</span>
                </td>
                <td>
                    {{Number(product.twomonth).toFixed(2)}}
                    <span v-if="product.inbox>0" class="package">({{Math.round(product.twomonth/product.inbox)}} уп.)</span>
                </td>
                <td>{{product.incomeDate|date-ddmmyy}}</td>
                <td>{{product.incomeCena|currency}}</td>
            </tr>
        </table>
</template>


<script>
    export default {
        name: 'PredictionBlock',
        components: {
        },
        props: [
            'predictionKirsa',
            'skladId',
        ],
    }
</script>

<style lang="scss" scoped>
    table.products{
        td,th {
            padding: 2px 5px;
            border:1px dotted #000000;
        }
        th {
            font-size: 85%;
            background: #076198;
            color: #fff;
            vertical-align: middle;
        }
        td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6), td:nth-child(7), td:nth-child(8), td:nth-child(10){
            text-align: right;
        }
        th:nth-child(6),th:nth-child(7),th:nth-child(8),th:nth-child(9){
            text-align: center;
            a {
                color: yellow;
            }
        }
        border-collapse: collapse;
        border:1px solid #69899F;
    }
</style>