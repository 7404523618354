import { render, staticRenderFns } from "./AgentList.vue?vue&type=template&id=4889c2e8&scoped=true&"
import script from "./AgentList.vue?vue&type=script&lang=js&"
export * from "./AgentList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4889c2e8",
  null
  
)

export default component.exports