<template>
  <div class="container">
    <div style="position:relative;" class="item-control">

        <div v-show="mode=='text'" 
          class="item-text"
          :class="{placeholder: itemSelected==null}" 
          :title="itemSelected?itemField(itemSelected):''" 
          @click="setInputMode"
        >
          {{ itemSelected? itemField(itemSelected) : ($attrs.placeholder||'Найти' )}}
        </div>
        <input v-show="mode!='text'" 
          class="item-input" 
          ref="inputRef" 
          v-model="text" 
          @input="onInput" 
          @blur="onBlur"
        />

      <div v-if="itemSelected" class="close" @click="clear()">✕</div>
    </div>

    <!-- <div style="position:relative;"></div> -->

      <div v-if="mode!='text'"  class="result-panel">
        <div class="list">
          <div v-for="(item, i) in list" :id="'list-item-'+i" :key="'item-'+i" 
            :class="{'item-focuse': focusedItemIdx==i, 'item-selected': (itemSelected? itemField(item)==itemField(itemSelected): false)}" class="list-item" 
            @click="select(item)"
            @mouseenter="focusedItemIdx = i"
          >
            <slot name="item" :node="item">
              <div v-html="markText(itemField(item))" :title="itemField(item)"></div>
            </slot>
          </div>
          <div v-if="mode=='done' && !isSearchComplite()" @click="load" class="more-btn">Показать ещё...</div>
          <div class='load' v-if="mode=='load'"></div>
        </div>

        <div class="down-panel">
          <div v-if="mode=='input'">&#128161; Начните ввод для поиска ({{minLength}} или больше символов)</div>
          <div v-else-if="mode=='load'">⏳ Загрузка данных..</div>
          <!-- <div v-else-if="mode=='done'">&#128269; Показано {{list.length}} <template v-if="total"> из {{total}}</template> </div> -->
          <div v-else-if="mode=='done'">
            <div v-if="list.length==0">⛔ Ничего не найдено!</div>
            <div v-else>
              &#128269; Найдено элементов: {{list.length}} 
              <template v-if="!isSearchComplite()"> из {{total}} </template>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import _ from 'lodash'
const mark_fn = (s) => `<span class="mark">${s}</span>`;
let mark_reg = new RegExp()
const scrollTo = (i) => {
  let el = document.querySelector('#list-item-' + i)
  if(el) el.scrollIntoView({block: "nearest", inline: "nearest"});
}

export default {
  name: 'SearchSelect',
  props: {
    loadOptions: Function,
    itemField: {
      type: Function,
      default: (v) => v
    }
  },
  data: () => ({
    text: '',
    minLength: 3,
    search: '',
    mode: 'text',
    listAll: [],
    list: [],
    offset: 0,
    total: null,
    focusedItemIdx: 0,
    itemSelected: null,
  }),
  mounted() {
    this.debounceInput = _.debounce(() => {
      this.mode = 'input';

      if (this.text.length >= this.minLength) {
        mark_reg = new RegExp(this.text, "ig");

        if(this.search == this.text){
          this.list = this.listAll;
          this.focusedItemIdx = this.list.findIndex(x => x == this.itemSelected);
          if (this.focusedItemIdx < 0) this.focusedItemIdx = 0;
          scrollTo(this.focusedItemIdx);
        }
        else if(this.search && this.text.startsWith(this.search)){//} && this.isSearchComplite()){
          this.mode = 'load';
          this.list = this.listAll.filter(x => this.itemField(x).search(mark_reg)>=0);
          this.focusedItemIdx = this.list.findIndex(x => x == this.itemSelected);
          if (this.focusedItemIdx < 0) this.focusedItemIdx = 0;
          scrollTo(this.focusedItemIdx);
          this.mode = 'done';
        }
        else{
          this.search = this.text;
          this.listAll = [];
          this.load();
        }
      }
      else {
        this.list = [];
      }
    }, 500);

    document.addEventListener('keydown', this.onKeyDown);
  },
  destroyed: function () {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  watch: {
    disableFocus: function (val){
      if (val){
        console.log('disableFocus remove')
        document.removeEventListener('keydown', this.onKeyDown);
      }
      else {
        console.log('disableFocus add')
        document.addEventListener('keydown', this.onKeyDown);
      }
    },
  },
  methods: {
    isSearchComplite(){
      return  this.total==null || this.listAll.length == this.total;
    },
    onKeyDown(e) {
      if (e.key == 'Escape') {
        //this.clear();
        this.mode = 'text'
      }
      else {
          if (e.key == 'ArrowDown') {
            if (this.focusedItemIdx < this.list.length - 1) {
              this.focusedItemIdx++;
              scrollTo(this.focusedItemIdx)
            }
            else if (this.focusedItemIdx == this.list.length - 1 && this.mode == 'done') {
              if(!this.isSearchComplite())
                this.load()
            }
          }
          else if (e.key == 'ArrowUp' && this.focusedItemIdx > 0) {
            this.focusedItemIdx--;
            scrollTo(this.focusedItemIdx)

          }
          else if (e.key == 'Space') {
            this.select(this.list[this.focusedItemIdx])
          }
          else if (e.key == 'Enter') {
            this.select(this.list[this.focusedItemIdx])
            this.mode = 'text'
          }
          
        }
    },
    onInput() {
      this.debounceInput();
    },
    onBlur() {
      setTimeout(() => this.mode = 'text', 300)
    },
    setInputMode(){
      this.mode = 'input';

      setTimeout(() => {
        this.$refs.inputRef.focus();
        this.focusedItemIdx = this.list.findIndex(x => x == this.itemSelected);
        scrollTo(this.focusedItemIdx);
      }, 100)
    },
    clear() {
      this.offset = 0;
      this.list = [];
      this.total = 0;
      this.text = '';
      this.focusedItemIdx = 0;
      this.itemSelected = null;
      this.mode = 'text';
      this.$refs.inputRef.blur();
      this.$emit('select', null);
    },
    load() {
      this.mode = 'load';

      let data = {
        search: this.search,
        offset: this.listAll.length,
      }

      this.$emit('change', data);
      this.$refs.inputRef.focus();
    },
    setValue(item){
      this.itemSelected = item;
      this.focusedItemIdx = this.list.findIndex(x => x == this.itemSelected);
    },
    onLoadOptions({ list, total = null }) {
//      if (this.mode==load) {
      this.total = total;
      this.listAll = this.listAll.concat(list);
      this.list = this.listAll;
      this.mode = 'done';
        //console.log(this.total, this.list.length, this.mode)
     // }
    },
    select(item) {
      this.itemSelected = item;
      this.$emit('select', item);
      this.mode = 'text';
    },
    markText(text){
      return text.replace(mark_reg, mark_fn);
    }
  },
}
</script>
<style scoped>

.load {
  background: url('~@/assets/loading.svg') no-repeat center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 25px;
  left: 0;
  background-color: #e7e7e7d6;
}

.close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  position: absolute;
  right: 5px;
  top: 8px;
  background-color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
}

.list {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: visible;
}

.list-item {
  padding: 5px 5px 0 15px;
  cursor: pointer;
  white-space: nowrap;
}

.item-focuse{
  background-color: #dff2ff;
}
.item-selected{
  font-weight: bold;
}

.result-panel {
  background-color: #ffffff;
  position: absolute;
  top: 31px;
  width: 100%;
  border-radius: 0 0 5px 5px;
  border: 1px solid #ccc;
  border-top: 1px solid #eee;
  z-index: 999;
  overflow: visible;

}

.down-panel {
  background-color: #ffffff;
  color: #ccc;
  font-size: 11px;
  padding: 5px 15px;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #eee;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-control{
  font-size: 16px;
  height: 34px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  line-height: 34px;
  padding-left: 10px;
  cursor: text;
  background-color: #fff;
  white-space: nowrap;
}
.item-text{
  white-space: nowrap;
  text-overflow:  ellipsis;
  overflow: hidden;
  
}
.placeholder{
  color: #ccc;
}
.item-input {
  font-size: 16px;
  border: none
}

.item-input:focus {
  outline-width: 0;
}

.more-btn {
  cursor: pointer;
  color: #1597ee;
  background-color: #f3fcfd;
  font-size: 15px;
  text-align: center;
  padding: 10px;
}

.more-btn:hover {
  background-color: #dff6f8;
}

</style>
<style lang="scss">
.mark{
  background-color: #f8f6d4;
}
</style>