<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <section class="section" style="padding-top: 0px !important;">
        <h1 class="caption">Разгрузка прихода КДВ</h1>
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <div class="field has-addons searchfield">
            <div class="control">
                <input placeholder="Артикул" class="input" type="text" v-model="q" @change="change">
            </div>
            <div class="control">
                <a class="button">Найти</a>
            </div>
        </div>

        <h2>{{productName}}</h2>
        <table class="result report striped">
            <tr v-for="i in filteredDemands" :key="i.id" @click="click(i)" :class="{'checked': i.checked}">
                <td>{{i.agentName}}</td>
                <td>{{i.amount}}</td>
                <td class="currency">{{i.summa|currency-no-cents}}</td>
            </tr>
            <tr style="font-weight: bold;">
                <td>Итого</td><td>{{filteredSum()}}</td><td></td>
            </tr>
        </table>
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"

    export default {

        name: 'IncomeSkladList',
        /*components: {
            Document,
        },*/
        props: {
            user: Object,
        },
        methods: {
            change(){
                if (this.incomeSearch!=null)
                    this.filteredDemands = this.incomeSearch.filter(x => x.articul.toUpperCase().trim() == this.q.toUpperCase().trim());
                else
                    this.filteredDemands = []
            },
            click(item) {
                item.checked = !item.checked;
            },
            filteredSum(){
                var sum = 0;
                if (this.filteredDemands){
                    this.filteredDemands.forEach(e=>{sum=sum+e.amount});
                }
                return sum;
            },
        },
        data(){
            return {
                q: '',
                find: null,
                error: null,
                productName: null,
                filteredDemands: [],
            }
        },
        apollo: {
            incomeSearch: {
                query: gql`
                    query{
                        incomeSearch {
                            agentName
                            articul
                            productName
                            amount
                            inbox
                            summa
                            checked
                        }
                    }`,
                errorPolicy: 'all',    
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    .searchfield {
        .input {
            max-width: 120px;
        }
    }
    .checked {
        text-decoration: line-through;
        color: #999;
    }
    .result tr{
        cursor: pointer;
    }
</style>

