import Root from './root.vue'
import Index from './components/Index.vue'
import Store from './components/Store/Store.vue'
import StoreKdv from './components/Store/StoreKdv.vue'
import FastOrder from './components/Store/FastOrder.vue'
import Fruits from './components/Fruits/Fruits.vue'
import FruitsConfirm from './components/Fruits/FruitsConfirm.vue'
import Cart from './components/Store/Cart.vue'
import Bonuses from './components/Bonuses.vue'
import Options from './components/Options.vue'
import Auth from './components/Auth.vue'
import History from './components/History.vue'
import ReportPrediction from './components/Reports/ReportPrediction.vue'
import ReportPredictionAgent from './components/Reports/ReportPredictionAgent.vue'
import EditItem from './components/EditItem'
import SbisList from './components/Reports/SbisList'
import SbisDocument from './components/Reports/SbisDocument'
import TodayOrderList from './components/Reports/TodayOrderList'
import OutgoWithIncomePrice from './components/Reports/OutgoWithIncomePrice'

import Metabase1 from './components/Reports/Metabase1'

import StockmanOrderList from './components/Reports/StockmanOrderList'
import StockmanOrder from './components/Reports/StockmanOrder'

import RashodOrder from './components/Reports/RashodOrder'
//import RashodOrder2 from './components/Reports/RashodOrder2'
import OperatorsProductivity from './components/Reports/OperatorsProductivity'
import RecognizeDocument from './components/Entera/RecognizeDocument'
import ProductDetail from './components/Reports/ProductDetail'
import NotShipped from './components/Reports/NotShipped'
import NotShippedDocument from './components/Reports/NotShippedDocument'
import NotDeliveredList from './components/Reports/NotDeliveredList.vue'
import NotDeliveredAllAgentsList from './components/Reports/NotDeliveredAllAgentsList'
import Not3price from './components/Reports/Not3price'
import SvodBread from './components/Reports/SvodBread.vue'
import BreadConfirm from './components/Store/BreadConfirm.vue'
import BreadMatrix from './components/BreadMatrix.vue'
import Audit from './components/Audit'
import AuditAlco from './components/AuditAlco'
import IncomeSkladList from './components/Reports/IncomeSkladList'

import AuditShortage from './components/Reports/AuditShortage'
import AuditShortageReverse from './components/Reports/AuditShortageReverse'
import AuditOverage from './components/Reports/AuditOverage'

import PredictionProduct from './components/Reports/PredictionProduct'
import Tu from './components/Dict/Tu'
import TuItem from './components/Dict/TuItem'
import AddIncome from './components/AddIncome'
import Serial from './components/Serial'
import FuelCard from './components/FuelCard'
import Expense from './components/Expense'
import Income from './components/Warehouse/Income'
import DebitorPay from './components/Warehouse/DebitorPay.vue'
import IncomeList from './components/Warehouse/IncomeList'
import IncomeItem from './components/Warehouse/IncomeItem'
import Outgo from './components/Warehouse/Outgo'
import OutgoList from './components/Warehouse/OutgoList'
import OutgoItem from './components/Warehouse/OutgoItem'
import CashIn from './components/CashIn/CashIn'
import AddCashIn from './components/CashIn/AddCashIn'

import AgentList from './components/Dict/Agent/AgentList'
import AgentItem from './components/Dict/Agent/AgentItem'

import LogItem from './components/LogItem'
import PriceTickets from './components/PriceTickets.vue'
import EgaisList from './components/EgaisList.vue'
import EgaisItem from './components/EgaisItem'

import SetWeightList from './components/Fruits/SetWeightList.vue'
import SumReportFruits from './components/Reports/SumFruitReport.vue'
import CreateTran from './components/Tran/CreateTran.vue'

const routes = [
  { path: '/', component: Root, children: [
    { name: 'index', path: '/', component: Index },
    { name: 'auth', path: '/auth', component: Auth },
    { path: '/store', name: "store", component: Store,
        props: (route) => (
            {
                type: 'store',
                category: route.query.category,
                showmissing: route.query.showmissing=='true',
                ppage: route.query.page==null?1:parseInt(route.query.page),
                q: route.query.q,
                withPhoto: route.query.withPhoto,
                availabilitySklad: route.query.availabilitySklad,
            }), },
    { path: '/price-avto-opt', name: "priceAvto", component: Store,
        props: (route) => (
            {
                type: 'pricelist',
                pricetype: '5',
                category: route.query.category,
                showmissing: false,
                ppage: route.query.page==null?1:parseInt(route.query.page),
                q: route.query.q,
                //withPhoto: route.query.withPhoto,
                availabilitySklad: '3',
            }), },
    { path: '/price-avto-delivery', name: "priceAvtoDelivery", component: Store,
        props: (route) => (
            {
                type: 'pricelist',
                pricetype: '7',
                category: route.query.category,
                showmissing: false,
                ppage: route.query.page==null?1:parseInt(route.query.page),
                q: route.query.q,
                //withPhoto: route.query.withPhoto,
                availabilitySklad: '3',
            }), },
    { path: '/price', name: "price", component: Store,
        props: (route) => (
            {
                type: 'pricelist',
                pricetype: '7',
                category: route.query.category,
                showmissing: false,
                ppage: route.query.page==null?1:parseInt(route.query.page),
                q: route.query.q,
                //availabilitySklad: 1,
            }), },
            /*{ path: '/price', name: "price", component: Store,
        props: (route) => (
            {
                type: 'pricelist',
                category: route.query.category,
                showmissing: route.query.showmissing=='true',
                ppage: route.query.page==null?1:parseInt(route.query.page),
                q: route.query.q,
                withPhoto: route.query.withPhoto,
                availabilitySklad: route.query.availabilitySklad,
            }), },*/
    { path: '/store-kdv', name: "storeKdv", component: StoreKdv,
        props: (route) => (
            {
                category: route.query.category,
                showmissing: route.query.showmissing=='true',
                ppage: route.query.page==null?1:parseInt(route.query.page),
                q: route.query.q,
                withPhoto: route.query.withPhoto,
                availabilitySklad: route.query.availabilitySklad,
            }), },
    { path: '/audit/:auditId/', name: 'Audit', component: Audit, props: (route) => (
        {
            q: route.query.q,
            auditId: route.params.auditId,
        }), 
    },
    { path: '/options/', component: Options, },
    { path: '/auditalco/', component: AuditAlco, },
    { path: '/fast', component: FastOrder, },
    { path: '/fruits', component: Fruits, },
    { path: '/fruits-confirm', component: FruitsConfirm, },
    { path: '/fruits-set-weight', component: SetWeightList, },
    { path: '/bread-confirm', component: BreadConfirm, },
    { path: '/bread-matrix', component: BreadMatrix, },
    { path: '/bonuses', component: Bonuses },
    { path: '/cart', component: Cart },
    { path: '/cart-2/:type/', component: Cart, props: true },
    { path: '/history', component: History },
    { path: '/reports/metabase1', component: Metabase1},
    { path: '/reports/sum-report-fruits', component: SumReportFruits},
    { path: '/reports/prediction', component: ReportPrediction},
    { path: '/reports/prediction/:skladId/', component: ReportPrediction, props: true},
    { path: '/reports/prediction/:skladId/:agentId/:type/', component: ReportPredictionAgent, props: true },
    { path: '/reports/doc-list/', component: SbisList, },
    { path: '/reports/audit-shortage/:auditId/', component: AuditShortage, props: true, },
    { path: '/reports/audit-shortage-reverse/:auditId/', component: AuditShortageReverse, props: true, },
    { path: '/reports/audit-overage/:auditId/', component: AuditOverage, props: true, },
    { path: '/reports/rashod-order', name: "rashod-order-list", component: TodayOrderList, },
    { path: '/reports/rashod-order/:answer_in_id/:date_in', name: "rashod-order-list1", component: TodayOrderList, props: true},
    { path: '/reports/rashod-order/:order_id/', component: RashodOrder, props: true},
    { path: '/reports/stockman-orders', component: StockmanOrderList, },
    { path: '/reports/stockman-orders/:date_in/', name: "stockman-orders", component: StockmanOrderList, props: true, },
    { path: '/reports/stockman-orders/:date_in/:order_id', name: "stockman-order", component: StockmanOrder, props: true, },
    { path: '/reports/prediction-product', name: "prediction-product", component: PredictionProduct, },

  { path: '/reports/operators-productivity/', component: OperatorsProductivity },
  { path: '/reports/notshipped/', component: NotShipped },
  { path: '/reports/notshipped/:docin', name: 'notshipped', component: NotShippedDocument, props: true },

  { path: '/reports/notdelivered/', component: NotDeliveredList },
  { path: '/reports/notdelivered-all-agents-list/', component: NotDeliveredAllAgentsList },

  { path: '/reports/not3price/', component: Not3price },
  { path: '/reports/svod-bread/', component: SvodBread },
  { path: '/reports/outgo-with-income-price', component: OutgoWithIncomePrice },
  { path: '/reports/debet-pays-report', component: () => import(/* webpackChunkName: "reports" */'@/components/Reports/DebetPaysReport.vue'), },
  { path: '/reports/debitors-reports', component: () => import(/* webpackChunkName: "reports" */'@/components/Reports/DebitorsReport.vue'), },
  { path: '/reports/revise-act', component: () => import(/* webpackChunkName: "reports" */'@/components/Reports/ReviseAct.vue'), },  

  { path: '/serial1', component: Serial },
  { path: '/fuelcard', component: FuelCard },
  { path: '/create-tran', component: CreateTran },

  { path: '/dict/tu/:id', name: 'tu-item', component: TuItem, props: true },
  {
    path: '/dict/tu/',
    name: 'tu-dict',
    component: Tu,
    props: (route) => ({
      q: route.query.q,
      pb: route.query.pb !== 'false', // positive balance
      wb: route.query.wb == 'true', // without barcodes
      ao: route.query.ao == 'true' || route.query.ao == null, // active only
      ww: route.query.ww == 'true',
      pg: route.query.pg == null ? 1 : parseInt(route.query.pg),
      skl: route.query.skl == null ? null : parseInt(route.query.skl),
      ci: route.query.ci == null ? null : parseInt(route.query.ci),
      wp: route.query.wp,
    }),
  },
  {
    path: '/dict/agent-list/',
    name: 'agent-list',
    component: AgentList,
    props: (route) => ({
      q: route.query.q,
      ao: route.query.ao == 'true' || route.query.ao == null, // active only
      pg: route.query.pg == null ? 1 : parseInt(route.query.pg),
    }),
  },
  { path: '/dict/agent/:id', name: 'agent-item', component: AgentItem, props: true },
  { path: '/kdv/', component: IncomeSkladList },
  { path: '/dict/add-income/', component: AddIncome },
  {
    path: '/reports/product-detail/',
    component: ProductDetail,
    props: (route) => ({
      tu_id: route.query.tu_id,
      sklad_id: route.query.sklad_id,
    }),
  },

  { path: '/edititem/:item_id', component: EditItem, props: true },
  { path: '/document/:id', component: SbisDocument, props: true },
  { path: '/recognize', component: RecognizeDocument, props: true },
  { path: '/expense', component: Expense, props: true },
  { path: '/income', component: Income, props: true },
  { path: '/income/:itemId', name:'incomeWithId', component: Income, props: true },
  {
    path: '/income-list',
    name: 'incomeList',
    component: IncomeList,
    // props: (route) => ({
    //   df: route.query.df ? route.query.df : format(new Date().setDate(new Date().getDate()), 'dd-MM-yyyy'),
    //   dt: route.query.dt ? route.query.dt : format(new Date().setDate(new Date().getDate()), 'dd-MM-yyyy'),
    //   host: route.query.host,
    //   sklad: route.query.sklad,
    //}),
  },
  { path: '/income-list/:itemId', component: IncomeItem, props: true },
  { path: '/debitor-pay', component: DebitorPay, props: true },
  { path: '/outgo', component: Outgo, props: true },
  { path: '/outgo-list', component: OutgoList, props: true },
  { path: '/outgo/:itemId', component: Outgo, props: true },
  { path: '/log/item/:id', component: LogItem, props: true },
  {
    path: '/pt',
    name: 'print-ticket',
    component: PriceTickets,
    props: (route) => ({
      idList: route.query.idList,
    }),
  },
  { path: '/cashin', component: CashIn },
  { path: '/add-cashin', component: AddCashIn },
  { path: '/egais-list', component: EgaisList },
  { path: '/egais-list/:id/', component: EgaisItem, props: true },
  { path: '/support', component: () => import(/* webpackChunkName: "support" */'@/components/Support/Index.vue')},
  { path: '/support/new-ticket', component: () => import(/* webpackChunkName: "support" */'@/components/Support/NewTicket.vue')},
  { path: '/support/my-tickets', component: () => import(/* webpackChunkName: "support" */'@/components/Support/MyTickets.vue')},
  { path: '/support/work-tickets', component: () => import(/* webpackChunkName: "support" */'@/components/Support/WorkTickets.vue')},
  ]},
  { 
    path: '/demo', 
    component: () => import('@/components/common/DemoLayout.vue'),
    children: [
      { 
        path: 'select', 
        name: 'FilteringSelect',  
        component: () => import('@/components/common/FilteringSelect/Demo.vue'),  
    },
    ] 
  },
]

export default routes;
