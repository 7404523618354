import gql from "graphql-tag"
export default {
    data(){
        return {
            skipSkladFetching: true,
            skladFetchedOnce: false,
        }
    },
    mounted(){
        this.skladFetchedOnce=false;
        if (this.user){
            this.fetchSklads();
        }
    },
    watch:{
        user(val) {
            if (val && !this.skladFetchedOnce)
                this.fetchSklads();
        },
    },
    methods: {
        fetchSklads(){
            if (this.skladFetchedOnce)
                return;
            this.skladFetchedOnce=true;
            try{
                let checksumSklad = localStorage.getItem("checksum:sklad")
                if (!checksumSklad || checksumSklad!=(this.user.id+'-'+this.user.userGroupsTsCnt+'-'+this.user.checksumSklad)){
                    this.skipSkladFetching=false;
                } else {
                    let storagedSklads = JSON.parse(localStorage.getItem("json:sklads"))
                    if (storagedSklads==null){
                        this.skipSkladFetching=false;
                    } else {
                        this.sklads = storagedSklads;
                        this.skladLoaded(this.sklads);
                    }
                }
            } catch (e){
                this.skipSkladFetching=false;
            }
        },
    },
    apollo: {
        sklads: {
            query: gql`query{sklads {code:id label:name}}`,
            skip() {
                return this.skipSkladFetching
            },
            result ({data, loading}) {
                localStorage.setItem("json:sklads", JSON.stringify(data.sklads))
                localStorage.setItem("checksum:sklad", (this.user.id+'-'+this.user.userGroupsTsCnt+'-'+this.user.checksumSklad))
                this.skladLoaded(data.sklads);
                this.skipSkladFetching=true;
            },
        },
    },
}
