<template>
    <div class="modal modal-fx-fadeInScale" :class="{'is-active': item}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content" style="background: #fff; padding: 2em; min-width: 730px;" v-if="item">
            <h1 class="title">Партии</h1>
            <div>{{item.tu.nomer}} {{item.tu.name}}</div>
            <div>В упаковке: {{item.tu.inbox}}</div>
            <loading v-if="loading"></loading>
            <div class="field">
                <vue-editable-grid
                    class=""
                    ref="gridPartlist"
                    id="mygrid-partlist"
                    :column-defs="columnDefs"
                    :row-data="list"
                    row-data-key='id'
                    :enable-filters="false"
                    @cell-updated="cellUpdated"
                    @row-selected="rowSelected"
                    @link-clicked="linkClicked"
                    v-if="!loading"
                    >
                </vue-editable-grid>
            </div>
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-primary" @click="partSelect">Выбрать</button>
                </div>
                <div class="control">
                    <button class="button" @click="close">Отмена</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { isThisISOWeek } from "date-fns";
import gql from "graphql-tag"
    const columnDefsC = [
        //{ sortable: false, filter: false, field: 'eyeColor', headerName: 'Eye color', editable: true },
        //{ sortable: true, filter: true, field: 'name', headerName: 'Name', editable: true, maxlength: 30 },
        { sortable: false, filter: false, field: 'id', headerName: '', type: 'text', editable: false,},
        { sortable: false, filter: false, field: 'date', headerName: 'Приход', type: 'date', format: 'dd.MM.yyyy', editable: false,},
        { sortable: false, filter: false, field: 'balanceTxt', headerName: 'Остаток', type: 'text', editable: false },
        { sortable: false, filter: false, field: 'order', headerName: 'Заказ', type: 'numeric', editable: true },
        { sortable: false, filter: false, field: 'orderInbox', headerName: 'Упак', type: 'numeric', editable: true },
        //{ sortable: true, filter: true, field: 'happiness', headerName: 'Happiness percent', type: 'percent', editable: true },
        //{ sortable: true, filter: true, field: 'isActive', headerName: 'Is active', type: 'boolean', editable: true },
        //{ sortable: true, filter: true, field: 'picture', headerName: 'Picture', type: 'link', editable: false }
    ];

    export default {
        props: {
            item: Object,
            skladId: Number,
        },
        data: () => ({
                columnDefs: columnDefsC,
                list: Array(),
                loading: true,
            }
        ),
        watch: {
            item: function (val) {
                this.list = Array()
                this.loading=true;
            }
        },
        apollo: {
            incomePosByTuSklad: {
                query: gql`
                    query($tuId: ID, $skladId: ID) {
                        incomePosByTuSklad(tuId: $tuId, skladId:$skladId){
                                id amount price income{date}
                                outgoposSet{amount outgo{state}}
                        }
                    }`,   

                variables() {
                    return {
                        skladId: this.skladId,
                        tuId: this.item.tu.id,
                    }
                },
                skip(){
                    return !this.skladId || !this.item || !this.item.tu || !this.item.tu.id;
                },
                result({ data, loading }){
                    this.list=Array();
                    let tmp=Array();
                    data.incomePosByTuSklad.forEach(e=>{
                        let balance = e.amount - e.outgoposSet.filter(x=>x.outgo.state!=2).reduce((a,b)=> +parseFloat(a) +parseFloat(b.amount), 0)
                        balance = Math.round(balance*1000)/1000
                        if (balance){
                            tmp.push(
                                {
                                    id: e.id,
                                    balance: balance,
                                    balanceTxt: balance + ((!this.item.tu.isWeight&&this.item.tu.inbox)?('/' + Math.floor(balance/this.item.tu.inbox) + (balance%this.item.tu.inbox?('+' + balance%this.item.tu.inbox):'')): ''),
                                    price: e.price, 
                                    date: e.income.date, 
                                    order: null,
                                    orderInbox: null,
                                }
                            )
                        }
                    });
                    this.list=tmp.concat();
                    this.loading = false;
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
        },
        methods: {
            cellUpdated(x) {
                let inbox = this.item.tu.inbox;
                if (x.columnIndex==4){
                    x.row.order = x.value * inbox;
                } else if (x.columnIndex==3){
                    x.row.orderInbox =  inbox && x.value%inbox==0 ? x.value/inbox : null;
                }
            },
            rowSelected() {},
            linkClicked() {},
            close() { 
                this.$emit('close');
            },
            partSelect(){
                let ret = Array()
                let brk = false
                this.list.forEach(e=>{
                    if (!brk && ((e.order !=null && e.order>0) || (e.orderInbox !=null && e.orderInbox>0))){
                        let price5 = this.item.tu.priceSet.find(e=>e.pricetype.id==5);
                        let price = price5?price5.price:null;
                        if (e.order>e.balance){
                            ret = Array()
                            brk = true
                        } else{
                            ret.push(
                                {
                                    id: null,
                                    incomeposId: e.id,
                                    tuId: this.item.tu.id,
                                    name: this.item.tu.name,
                                    code: this.item.tu.nomer,
                                    isWeight: this.item.tu.isWeight,
                                    inbox: this.item.tu.inbox,
                                    amount: e.order,
                                    amountInbox: e.orderInbox,
                                    balance: e.balance,
                                    price: price,
                                    incomePosPrice: e.price,
                                    sum: price * e.order,
                                    deleted: false,
                                }
                            );
                        }
                    }
                });
                if (ret.length)
                    this.$emit('close', ret);
            },
        },
    }
</script>
