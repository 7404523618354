<template>
    <li :class="{'is-quantity': item.quantity>0}">
        <div class="img" v-if="(item.images && item.images.length>0)">
            <img :src="'/' + (item.hasOwnProperty('product')?item.product.images[0].img:item.images[0].img)">
        </div>
        <div class="name">
            <!--svg class="icon-svg img" v-if="item.images.length">
                <use xlink:href="@/assets/sprite.svg#icon-img"></use>
            </svg>
            <router-link :to="'/edititem/'+item.id">{{item.name || item.product.name }}</router-link-->
            {{item.name || item.product.name }}
            <!--svg class="icon-svg weight" v-if="item.isWeight || (item.product && item.product.weight)">
                <use xlink:href="@/assets/sprite.svg#weight"></use>
            </svg-->
        </div>
        <div class="nomer">{{item.nomer}}</div>
        <div class="price">
            {{(item.price||item.unitPrice) | currency-null}}
        </div>
        <div class="price_kg" v-if="item.weight && item.weight>1 && isConfectionery(parseInt(item.goodsId))">
            {{(item.price||item.unitPrice)/item.weight | currency}}
        </div>
        <div class="balance" :class="{'balance-none': !item.balance}">
            <span>{{item.balance}}</span>
        </div>
        <div class="delivery-group" :class="'delivery-group-'+item.deliveryGroupId" v-if="user && user.agent && user.agent.ownStore">
            {{deliveryDay(item)}}
        </div>
        <div class="weight" v-if="item.weight && item.weight>0">
            {{parseFloat(item.weight)}} кг.
        </div>
        <div v-if="item.alreadyOrdered" class="already-ordered">
            {{item.alreadyOrdered}}
        </div>

        <div class="inbox is-unselectable" v-if="item.inbox>1" :class="{'bbb': box, 'kg-label': item.isWeight || (item.product && item.product.weight)}">
            <checkbox-slider @input="inbox" :init="box" :disabled="item.hasOwnProperty('product')?item.product.packOrderOnly:item.packOrderOnly || item.inbox==0">
                ({{item.inbox}} <span v-if="item.isWeight || (item.product && item.product.weight)">кг.</span><span v-else>шт.</span>)
            </checkbox-slider>
        </div>
        <div class="remove is-unselectable">
            <a @click.stop="$emit('remove', item)" class="action">Убрать</a>
        </div>
        <div class="pm is-unselectable">
            <div>
                <button class="pm" :disabled="parseFloat(item.quantity)<=0" @click.stop="delta(-1)">-</button>
                <currency-input 
                    v-model="item.quantity"
                    @change="change($event, item)"
                    :disabled="modcartproccessing"
                    autocomplete="one-time-code"
                    class="input"
                    :class="{'loading-4dots': modcartproccessing}"
                    :options="{ currency: 'EUR', 
                                currencyDisplay: 'hidden', 
                                precision: 0,
                                nullOnClean: false,
                            }"
                    style="text-align: right;"/> 

                <span class="kg" v-if="item.isWeight || (item.product && item.product.weight)">кг</span>
                <!--span class="kg" v-if="!item.isWeight && (!item.product || !item.product.weight)" @click.stop="inbox" :class="{action: item.goodsId!='7' && item.inbox>0}">
                    <span v-if="((item.quantityType!==null && item.quantityType!==2) || (item.quantityType===null && !box))">шт</span>
                    <span v-if="(item.quantityType!==null && item.quantityType===2) || (item.quantityType===null && box)">уп</span>
                </span-->
                <!--img src="@/assets/loading-3dots-blue.svg" class="quantity-loading" v-if="modcartproccessing"-->

                <button class="pm" @click.stop="delta(1)">+</button>
            </div>
        </div>
        <div class="description" v-if="item.description">
            <span v-html="item.description"></span>
        </div>
    </li>
</template>

<script>
    //import cartMethods from './cart-methods'
    import CheckboxSlider from '../checkboxSlider.vue'
    import TuListItemMixin from './TuListItemMixin.vue'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'

    const confectioneryGroups=[123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,143,142,140,144,145,146,147,148,149,150,151,153,154,155,156];

	export default {
		name: 'TuListItem',
		props: ['item', 'user',],
        mixins: [TuListItemMixin,],
        components: {CheckboxSlider,},
        beforeMount () {
            if (this.item.packOrderOnly || (this.item.product&&this.item.product.packOrderOnly)){
                this.box=true;
            }
        },
        methods: {
            change(value, item){
                if (value==item.valX)
                    return;
                this.setItem()
            },
            deliveryDay: function(item){
                const dow = parseInt(format(new Date(), "i"))
                const h = parseInt(format(new Date(), 'H'))
                if (item.deliveryGroupId==2){
                    if ((dow==7 && h>=17) || dow==1 || (dow==2 && h<17))
                        return 'Ср'
                    else if ((dow==2 && h>=17) || dow==3 ||(dow==4 && h<17))
                        return 'Пт'
                    else
                        return 'Пн'
                } else if (item.deliveryGroupId==3){
                    if ((dow==1 && h>=17) || dow==2 || (dow==3 && h<17))
                        return 'Чт'
                    else if ((dow==3 && h>=17) || dow==4 || (dow==5 && h<17))
                        return 'Сб'
                    else
                        return 'Вт'
                }
            },
            inbox(){
                this.box=!this.box;
                /*if (!this.item.inbox)
                    return;
                if (this.item.quantityType===null){
                    this.box=!this.box;
                } else {
                    if (this.item.quantityType==1)
                        this.item.quantityType=2;
                    else
                        this.item.quantityType=1;
                    this.setItem();
                }*/
            },
            isConfectionery(groupId){
                return confectioneryGroups.includes(groupId);
            },
            /*onlyForWeight ($event, item) {
                if (!item)
                    return;
                console.log(item.isWeight);
                console.log(item.weight);
                // console.log($event.keyCode); //keyCodes value
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

                if (!item.isWeight){
                    if (keyCode < 48 || keyCode > 57) {
                        $event.preventDefault();
                    }
                } else {
                    // only allow number and one dot
                    if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || item.quantity.indexOf('.') != -1)) { // 46 is dot
                        $event.preventDefault();
                    }

                    // restrict to 2 decimal places
                    if(item.quantity!=null && item.quantity.indexOf(".")>-1 && (item.quantity.split('.')[1].length > 2)){
                        $event.preventDefault();
                    }
                }
            },*/
        },
	}
</script>

<style lang="scss" scoped>
    @import "@/assets/_variables.scss";

    .checkbox-slider{
        margin-top: 0;
    }

    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }    
    li{
        padding: 1rem .5rem;
        margin: 0 -.5rem;
        display: grid;
        grid-template-areas:
            "img name        name        name            name"
            "img nomer       balance     delivery-group  pm"
            "img weight      weight      already-ordered pm"
            "img price       price       price           inbox"
            "img price-kg    price-kg    price-kg        inbox"
            "img description description description     description";
        grid-template-columns: 150px 1fr 1fr 1fr;
        grid-gap: .5rem;
        cursor: pointer;
        &:hover {
            background: #f2f2f2;
        }
    }

    @media screen and (max-width: $tablet) {
        li{
            grid-template-areas:
                "img name name name name"
                "img price price balance balance"
                "inbox inbox pm pm pm"
                "description description description description description"
                "weight weight weight delivery-group delivery-group";
            grid-template-columns: 2.4fr 1fr 1fr 1fr 1fr;
            font-size: 95%;
        }
    }

    div.description{
        grid-area: description;
    }
    div.img{
        grid-area: img;
        text-align: center;
        img {
            max-height: 124px;
        }
    }
    .name {
        grid-area: name;
        font-size: 110%;
        color: #111;
        a {
            color: #111;
        }
    }

    .balance{
        grid-area: balance;
        font-size: 85%;
        &:before{
            content: 'Остаток: ';
        }
    }
    .balance-none{
        &:before{
            content: 'Нет в наличии';
        }
        span{
            display: none;
        }
    }
    .price{
        grid-area: price;
        font-size: 122%;
        white-space:nowrap;
        /*text-align: center;
        &:before{
            content: 'Цена: ';
        }*/
    }
    .price_kg{
        grid-area: price-kg;
        font-size: 85%;
        /*text-align: center;*/
        &:before{
            content: 'Цена за кг: ';
        }
    }
    .weight{
        grid-area: weight;
        font-size: 85%;
        /*text-align: center;*/
        &:before{
            content: 'Вес: ';
        }
    }
    .delivery-group{
        grid-area: delivery-group;
        font-size: 85%;
        &:before{
            content: 'Доставка: ';
        }
    }

    .already-ordered{
        grid-area: already-ordered;
        font-size: 105%;
        color: red;
        font-weight: bold;
        &:before{
            content: 'Уже заказано: ';
        }
    }
    .inbox{
        grid-area: inbox;
        margin: auto;
        /*a {
            &:before{
                content: 'В упак: ';
            }
            color: #4a4a4a;
        }*/
    }
    .nomer{
        grid-area: nomer;
        font-size: 85%;
    }

    div.pm{
        grid-area: pm;
        align-self: center;
        text-align: center;
        min-width: 132px;
        button.pm{
            cursor: pointer;
            border-radius: 16px;
            border: 1px solid #e2e2e2;
            background: #fbf8f8;
            height: 32px;
            width: 32px;    
        }
        &>div{
            position: relative;
            display: inline-block;
            .quantity-loading{
                position: absolute;
                width: 50px;
                left: 50%;
                margin-left: -25px;
                top: 50%;
                margin-top: -25px;
            }
        }
        button{
            outline: none;
        }
        input {
            min-width: 68px;
            font-size: 100%;
            width: 40px;
            text-align: center;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            margin: 0 4px;
        }
        .kg{
            padding-left: 6px;
            padding-right: 6px;
        }
    }

    .remove{
        display: none;
        grid-area: remove;
        
    }
    .cart {
        .remove{
            display: block;
        }
        .balance{
            display: none;
        }
        .price{
            text-align: left;
            align-self: center;
        }
        .inbox a:before{
            content: 'Уп.:';
        }
        .remove, .inbox {
            text-align: center;
            align-self: center;
        }
    }
    @media screen and (max-width: $desktop) {
        .balance:before{
            content: 'Ост.: ';
        }
        .price:before{
            content: none;
        }
        .inbox a:before{
            content: 'Уп.:';
        }
    }

	.is-quantity{
		.name {
			/*font-weight: bold;*/
		}
	}

    .loading {
        position: fixed;
    }

    svg.weight{
        height: 17px;
        width: 16px;
        vertical-align: middle;
        fill: #777;
        margin-top: -5px;        
    }
    svg.img{
        height: 28px;
        width: 28px;
        vertical-align: bottom;
        fill: #333;
    }
</style>

<style lang="scss" scoped>
    .checkbox-slider {
        font-size: 85%;
        &:before{
            content: 'шт.';
            padding-right: 5px;
        }
        .slider {
            width: 41px;
        }
        label.action {
            color: #a9a9a9;
            border-bottom: none;
            &:before{
                content: 'Упак';
                padding-right: 5px;
            }
        }
    }
    .checkbox-slider.disabled {
        &:before, label.action {
            color: #ccc;
        }
    }
    .checkbox-slider .slider {
        background: #999;
    }
    .checkbox-slider input:checked + .slider +label.action {
        color: #444;
    }
    .bbb .checkbox-slider{
        &:before{
            color: #a9a9a9;
        }

    }
    .kg-label .checkbox-slider{
        &:before{
            content: 'кг.';
        }
    }
    .delivery-group-1 {
        &:before{
            content: 'Доставка: ежедневно';
        }
    }
    .delivery-group-2, .delivery-group-2 {
        &:before{
            content: 'Доставка:';
        }
    }

</style>