<template>
    <div>
        <img src="@/assets/loading.svg" v-show="loading" class="loading-fixed">

        <ul class="tu">
            <TuListItem 
                :item="item" 
                v-for="item in tu" 
                :key="item.id"
                @modcart="$emit('modcart', arguments[0], arguments[1], arguments[2])"
                @setEditMode="$emit('setEditMode', arguments[0])"
                :user="user"
                />
        </ul>
    </div>
</template>

<script>
    import TuListItem from './TuListItem'

	export default {
		name: 'TuList',
		props: {
            tu: Array, 
            loading: Boolean, 
            user: Object,
        },
        components: {TuListItem, },
        methods: {
        }
	}
</script>

<style lang="scss">
</style>