<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <h1 class="caption">Накладные в доставке</h1>
        <div class="columns">
            <div class="column" :class="{'loading': $apollo.queries.notDeliveredList.loading}">
                <table v-if="notDeliveredList" class="report striped not-delivered-list">
                    <tr>
                        <th>#</th>
                        <th>Дата</th>
                        <th>Точка</th>
                        <th>Склад</th>
                        <th>Сумма</th>
                    </tr>
                    <tr v-for="i in notDeliveredList.sybase" :key="i.id" class="hover" @click="select(i)"
                        :class="{'current': item && i.code==item.code}"
                        >
                        <td>{{i.code}}</td>
                        <td>{{i.date|date-ddmmyy}}</td>
                        <td>{{i.agentName}}</td>
                        <td>{{i.sklad?i.sklad.name:''}}</td>
                        <td style="white-space: nowrap;">{{i.total | currency}}</td>
                    </tr>
                </table>
            </div>
            <div class="column">
                <Document :item="item" @refetch="refetch"></Document>
            </div>
        </div>

        <div class="columns" v-if="notDeliveredList && notDeliveredList.egais.length>0">
            <div class="column">
                <h2 class="title">ЕГАИС</h2>
                <table class="report striped not-delivered-list">
                    <tr>
                        <th>Дата</th>
                        <th>Склад</th>
                        <th>ТТН</th>
                    </tr>
                    <tr v-for="i in notDeliveredList.egais" :key="i.id" class="hover">
                        <td>{{i.created|datetime}}</td>
                        <td>{{i.sklad.name}}</td>
                        <td><router-link :to="'/egais-list/' + i.id + '/'">{{i.wbregId}}</router-link></td>
                    </tr>
                </table>
            </div>
        </div>


            <!--json-viewer
                        v-show='true || show_json'
                        :expand-depth=0
                        v-if="notDeliveredList"
                        copyable
                        boxed
                        expanded
                        :value="notDeliveredList"></json-viewer-->
    </div>
</template>

<script>

    import gql from "graphql-tag"
    import Document from './Document.vue'

    export default {

        name: 'NotDeliveredList',
        components: {
            Document,
        },
        props: {
            user: Object,
        },
        methods: {
            select(item) {
                this.item=item;
            },
            refetch(){
                this.item=null;
                this.$apollo.queries.notDeliveredList.refetch();
            },
        },
        data(){
            return {
                item: null,
            }
        },
        apollo: {
            notDeliveredList: {
                query: gql`
                    query{
                        notDeliveredList {
                            sybase{
                                date
                                code
                                weight
                                total
                                agentName
                                sklad {id name}
                                positions{id name amount price total edizm inbox}
                                type
                            }
                            egais {
                                id
                                created
                                wbregId
                                fsrarId
                                sklad {
                                    id name
                                }
                            }
                        }
                    }`,
                errorPolicy: 'all',    
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    .not-delivered-list {
        overflow-x: auto;
        display: block;
        tr td {
            cursor: pointer;
        }
        td:nth-child(4), td:nth-child(5){
            text-align: right;
        }
    }
</style>

