<script>
    export default {
        name: 'Sidebar',
        data(){
            return {
                activeSidebar: false,
            }
        },
        methods:{
            show(){
                document.getElementsByTagName('html')[0].classList.add('noscroll-on-mobile');
                this.activeSidebar=true;
            },
            hide(){
                document.getElementsByTagName('html')[0].classList.remove('noscroll-on-mobile');
                if (this.activeSidebar)
                    this.activeSidebar=false;
                this.$emit('sidebarClosed');
            },
        }
    }
</script>
