<script>
    import gql from "graphql-tag"
    import UploadFromCamera from "@/components/UploadFromCamera"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import axios from "axios"

    export default {
        data: ()=>({
            barcodeValue: null,
            categoriesTree: null,
            price5: null,
            price7: null,
            base64fromclipboard: null,
            dropzoneOptions: {
                url: '/photo/',
                thumbnailWidth: 150,
                maxFilesize: 2,
                //headers: { "My-Awesome-Header": "header value" },
                dictDefaultMessage: "Перетащите и бросьте сюда файл с картинкой"
            },
        }),
        mixins: [UploadFromCamera, ],
        components: {
            Treeselect,
        },
        async created(){
            axios.get('/categories_tree.json')
                .then(response => this.categoriesTree = response.data.children)
                .catch(error => this.$emit('error', error))
        },
        methods: {
            prefixBarcodeScanner(event) {
                event.preventDefault();
                this.tab = 'barcodes';
                this.$refs.barcodeInput.focus();
            },
            async paste(){
                const items = await (navigator.clipboard.read());
                const blob = await items[0].getType("image/png");
                this.read(blob);
                this.uploadPasted();
            },
            sendingEvent (file, xhr, formData) {
                formData.append('id', this.id);
            },

            dropzoneSuccess(file, request){ // eslint-disable-line no-unused-vars
                this.skipQuery=false;
                this.$apollo.queries.images.refetch();
            },

            read(blob){
                let reader = new FileReader();
                reader.onload = (event) => {
                    this.base64fromclipboard = event.target.result;
                }
                reader.readAsDataURL(blob);
                this.blob=blob;
            },
            removeSelected(){
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!) {
                        removeImage(id: $id) { ok }
                    }`,
                    variables: {
                        "id": this.selected[0],
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    this.selected=[];
                    this.skipQuery=false;
                    this.$apollo.queries.images.refetch();
                }).catch(error => {
                    //itemlist.modcartfinished(true);
                    this.$emit('error', error);
                });
            },
        },
    }
</script>