<template>
    <div class="container">
        Лог товаров
        <table v-if="logTu" class="report">
            <tr>
                <th>id</th>
                <th>дата и время</th>
                <th>Имя пользователя</th>
                <th>действие</th>
                <th>Таблица</th>
                <th>Поле</th>
                <th>Старое</th>
                <th>Новое</th>
            </tr>
            <tr v-for="i in logTu" :key="i.id">
                <td>{{i.id}}</td>
                <td>{{i.datetime|datetime}}</td>
                <td>{{i.username}} {{i.flName}}</td>
                <td>{{i.action}}</td>
                <td>{{i.table}}</td>
                <td>{{i.field}}</td>
                <td>{{i.old}}</td>
                <td>{{i.new}}</td>
            </tr>
        </table>
    </div>
    
</template>

<script>
    import gql from "graphql-tag"
    let query=gql`query($tuId: ID!){
        logTu(tuId: $tuId){
            id username flName datetime action old new table field
        }
    }`

    export default {
        name: 'LogItem',
        props: {
            user: Object,
            id: String,
        },
        methods: {
        },
        components: {
        },
        data(){
            return {
            }
        },
        apollo: {
            logTu: {
                query: query,
                variables() {
                    return {
                        tuId: this.id,
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        },
    }
</script>

<style scoped lang="scss">

</style>