<template>
    <section class="container section" v-if="checkGroup(user, 'Ревизия')"
        @keydown.f9.capture.prevent.stop="debugkey()"
    >
        <h1 class="title">Ревизия алкоголя</h1>

        <div v-if="barcode">Штрихкод: {{barcode}}</div>
        <div v-else>Сканируйте штрихкод</div>
        <div :class="{'loading': $apollo.queries.search.loading || mutating}">
            <div v-if="search!=null">

                <div v-if="search.count==0">
                    <h1>По штрихкоду {{barcode}} ничего не найдено</h1>
                </div>
                <div v-else>
                    <div v-if="search.count==1">
                        <div v-for="item in search.items" :key="item.id">
                            <h1>{{item.label}}</h1>
                            <h2>Цена: {{item.price|currency}}</h2>
                        </div>
                    </div>

                    <table v-if="search.count>1" style="margin: 1rem 0 0 1rem" class="report">
                        <tr v-for="(item, index) in search.items" :key="index">
                            <td>{{ index+1 }}</td>
                            <td>{{item.label}}</td>
                            <td>{{item.price|currency}}</td>
                        </tr>
                    </table>

                    <div v-if="modeRequireAmc" style="margin: 3rem;">Сканируйте марку</div>
                    <div style="margin: 26px 0; display: flex; align-items: center;" v-show="checked">
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"
                             :class="{'checkmark-yellow': doubled && finded, 'checkmark-red': finded===false || outgopos}" 
                            >
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"
                                    :class="{'checkmark__circle-yellow': doubled && finded,'checkmark__circle-red': finded===false || outgopos}"/>
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                        <div class="block">
                            <div style="padding-left: 1rem;" v-if="doubled===false">Марка учтена</div>
                            <div style="padding-left: 1rem;" v-if="doubled">Марка уже была учтена в этой ревизии</div>
                            <div style="padding-left: 1rem; color: red;" v-if="finded===false">
                                Марка не числится на балансе склада
                            </div>
                            <div style="padding-left: 1rem; color: red;" v-if="outgopos">Была продана {{ outgopos.outgo.date|justdate }}</div>
                        </div>
                    </div>

                </div>

            </div>
            <button class="button" style="margin-top: 4rem;" @click="clear" :disabled="$apollo.queries.search.loading">Сброс</button>
        </div>
    </section>
</template>

<script>
    import _ from 'lodash'
    import gql from "graphql-tag"
    import {isAmc, } from '../POS/Tools.js'

    export default {
        name: 'AuditAlco',
        components: {
        },
        mixins: [
            //BarcodeScanner, 
        ],
        props: {
            user: Object,
            scannedBarcode: String,
            auditId: String,
        },
        watch: {
            scannedBarcode: function (val) {
                this.$emit('clearScannedBarcode')
                if (val && this.modeRequireAmc){
                    console.log("длинна кода: ", val.length);
                    if (!isAmc(val)){
                        return
                    }
                    this.mutating=true
                    this.$apollo.mutate({
                        mutation: gql`mutation($barcode: String!, $amc: String!) {
                            auditAlco(barcode: $barcode, amc: $amc) { ok doubled finded outgopos{id outgo{date}}}
                        }`,
                        variables: {
                            "barcode": this.barcode, 
                            "amc": val,
                        },
                    }).then(data => {  // eslint-disable-line no-unused-vars
                        this.error=null;
                        this.mutating=false;
                        if (data.data.auditAlco.ok){
                            this.checked=true;
                            this.doubled=data.data.auditAlco.doubled;
                            this.finded=data.data.auditAlco.finded;
                            this.outgopos=data.data.auditAlco.outgopos;
                            this.displayTimer = setTimeout(() => {
                                this.barcode = null;
                                this.modeRequireAmc = false;
                                this.checked = false;
                                this.search = null;
                                this.doubled = null;
                                this.finded = null;
                                this.outgopos = null;
                            }, this.outgopos?60000:(this.finded?3000:10000));
                        } else{
                            this.checked=false;
                        }
                    }).catch(error => {
                        this.mutating=false;
                        this.$emit('error', JSON.stringify(error.message));
                    });                    
                } else if (val && !this.modeRequireAmc){
                    console.log("длинна кода: ", val.length);
                    if (val.length != 13 && val.length != 8){
                        return
                    }
                    this.search=null;
                    this.barcode = val;
                }
            },
        },
        methods: {
            debugkey(){
                //this.scannedBarcode = '22N00002V5ORQU7HEEZ830Q70726008010856SZDN9TPRXF3MX44777KQQCV45806B0F'; //'187303555074450421001YEUOBR2KCHLZNCO74VTZZ4BMMUIR7NY6Q35BK66BR65GDJL5Z55QQBTL7HD2SBJR3YOZWAIXZBXQ3BVH5FZEXCIGZFBGATWHXQHGU4S5FLYFS7AUDQSKRHAGPXQZIO4ZQ'
            },
            change(ev){
                if (ev && ev.target && ev.target.value){
                    console.log(ev.target.value)
                    this.scannedBarcode = ev.target.value.trim()
                    ev.target.value=''
                }
            },
            clear(){
                this.barcode=null;
                this.$emit('clearScannedBarcode')
                this.search=null
                this.modeRequireAmc=false;
                this.checked=false
                this.finded=null;
                this.doubled=null;
                this.outgopos=null;
                if (displayTimer)
                    clearTimeout(displayTimer);
                displayTimer=null
            }
        },
        data(){
            return {
                search: null,
                barcode: null,
                modeRequireAmc: false,
                checked: false,
                doubled: null,
                finded: null,
                displayTimer: null,
                mutating: false,
            }
        },
        apollo: {
            search: {
                query: gql`
                    query ($q: String) {
                    search(q: $q, activeOnly: true, positiveBalance: false) {
                        count
                        items {
                            id
                            label: name
                            nomer
                            lastPrice
                            isArc
                            balance
                            inbox
                            price
                        }
                    }
                }`,
                variables() {
                    return {
                        q: this.barcode,
                    }
                },
                skip(){
                    return !this.barcode || this.modeRequireAmc;
                },
                result ({ data, loading }) {
                    if (!loading) {
                        if (data.search.count>0)
                            this.modeRequireAmc = true;
                    }
                },
                //fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>
