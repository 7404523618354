import gql from "graphql-tag"
export default {
    data(){
        return {
            skipReasonFetching: true,
            reasonFetchedOnce: false,
        }
    },
    mounted(){
        this.reasonFetchedOnce=false;
        if (this.user){
            this.fetchReasons();
        }
    },
    watch:{
        user(val) {
            if (val && !this.reasonFetchedOnce)
                this.fetchReasons();
        },
    },
    methods: {
        fetchReasons(){
            if (this.reasonFetchedOnce)
                return;
            this.reasonFetchedOnce=true;
            try{
                let checksumReason = localStorage.getItem("checksum:reason")
                if (!checksumReason || checksumReason!=(this.user.id+'-'+this.user.userGroupsTsCnt+'-'+this.user.checksumReason)){
                    this.skipReasonFetching=false;
                } else {
                    let storagedReasons = JSON.parse(localStorage.getItem("json:reasons"))
                    if (storagedReasons==null){
                        this.skipReasonFetching=false;
                    } else {
                        this.reasons = storagedReasons;
                        this.reasonLoaded(this.reasons);
                    }
                }
            } catch (e){
                this.skipReasonFetching=false;
            }
        },
    },
    apollo: {
        reasons: {
            query: gql`query{reasons {code:id label:name}}`,
            skip() {
                return this.skipReasonFetching
            },
            result ({data, loading}) {
                localStorage.setItem("json:reasons", JSON.stringify(data.reasons))
                localStorage.setItem("checksum:reason", (this.user.id+'-'+this.user.userGroupsTsCnt+'-'+this.user.checksumReason))
                this.reasonLoaded(data.reasons);
                this.skipReasonFetching=true;
            },
        },
    },
}
