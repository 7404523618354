<template>
    <section class="container section">
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <h1>Расходный документ</h1>
        <table class="report">
            <tr v-for="item in list" :key="item.id" class="hover">
                <!--td>{{item.id}}</td-->
                <td>{{item.code}}</td>
                <td>{{item.name}}</td>
                <td>
                    <currency-input v-model="item.amount" class="input"
                        :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3}"
                        style="width: 120px; text-align: right;" />
                </td>
                <td>{{item.price|currency}}</td>
                <td>{{item.price*item.amount|currency}}</td>
            </tr>
            <tr>
                <td colspan="5">
                    <treeselect 
                        :async="true"
                        @select="itemSelect"
                        :load-options="loadOptions" />
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Сумма:</td>
                <td></td>
            </tr>
        </table>
        <!--div class="field">
            <div class="control">
                <treeselect 
                    :async="true"
                    @select="itemSelect"
                    :load-options="loadOptions" />
            </div>
        </div-->
        <div>Партия</div>
    </section>    
</template>

<script>
    import gql from "graphql-tag"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
    import _ from 'lodash'
    export default {
        data(){
            return {
                tusearch: null,
                q_debounced: null,
                error: null,
                tu_id: null,
                callback: null,
                list: [],
            }
        },
        components: {
            Treeselect,
        },
        methods: {
            debounceInput: _.debounce(function (e) {
                this.search=[];
                this.q_debounced=e.target.value;
            }, 500),
            itemSelect(item){
                console.log(item);
                this.list.push(
                    {
                        id: item.id, 
                        name: item.label,
                        code: item.nomer,
                        amount: 1,
                        price: item.price,
                    }
                );
            },
            loadOptions({ action, searchQuery, callback }) {
                console.log(action, searchQuery);
                if (action === ASYNC_SEARCH) {
                    this.q_debounced = searchQuery;
                    this.callback = callback;
                    /*simulateAsyncOperation(() => {
                        const options = [ 1, 2, 3, 4, 5 ].map(i => ({
                            id: `${searchQuery}-${i}`,
                            label: `${searchQuery}-${i}`,
                        }))
                        callback(null, options)
                    })*/
                }
            },
        },
        apollo: {
            search: {
                query: gql`
                    query($q: String, $activeOnly: Boolean, $positiveBalance: Boolean) {
                        search(q: $q, activeOnly: $activeOnly, positiveBalance: $positiveBalance) {
                            count 
                            items {
                                id label: name nomer price isArc balance
                            }
                        }
                    }`,
                variables() {
                    return {
                        q: this.q_debounced,
                        activeOnly: true,
                        positiveBalance: true,
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                        if (this.callback){
                            this.callback(null, data.search);
                            this.callback = null;
                        }
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    .report {
        .hover {
            td {
                vertical-align: middle;
            }
        }
    }
</style>