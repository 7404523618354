<script>
    import AuditMenu from '../AuditMenu.vue'
    import gql from "graphql-tag"
    import AuditDetail from './AuditDetail.vue'
    export default {
        props: {
            user: Object,
            auditId: String,
        },
        data(){
            return {
                sending: false,
                detailTuId: null,
                detailSkladId: null,
                detailsActive: false,
                detail: null,
            }
        },
        components: {
            AuditMenu, AuditDetail,
        },
        methods: {
            details(tuId, x){
                this.detailTuId = tuId;
                this.detail = x;
                this.detailSkladId = 9
            },
        },
        apollo: {
            inOutHistoryMysql: {
                query: gql`
                query($tuId: ID!, $skladId: ID){
                    inOutHistoryMysql(tuId: $tuId, skladId: $skladId) {
                        docio date agent{id name}
                        inAmount inPrice
                        outAmount outPrice action
                    }
                }`,
                skip(){ return !this.detailTuId || !this.detailSkladId},
                variables() {
                    return {
                        tuId: this.detailTuId,
                        skladId: this.detailSkladId,
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                        this.detailsActive=true;
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            /*audit: {
                query: gql`
                    query($id: ID!){
                        audit(id: $id){
                            id created info relative sklad {
                              id name
                            }
                        }
                    }`,
                variables() {
                    return {
                        id: this.auditId,
                        }
                },
                errorPolicy: 'all',
                result ({ data, loading }) {
                    if (!loading) {
                        //this.fill();
                    }
                },
                error(error) {
                    //this.error = JSON.stringify(error.message);
                    this.$emit('error', JSON.stringify(error.message));
                },
            },*/
        },
    }
</script>

<style lang="scss" scoped>
    thead th{
        position: sticky;
        position: -webkit-sticky;
        top: 55px;
        background: white;
        z-index: 10;
    }
</style>
