<template>
<div class="row" style="width: 450px">
    <div class="col" >
        <label class="label">Дата с:</label>
        <date-picker v-model="value.from" class="deliverydate" valueType="format" :first-day-of-week="1" lang="ru"
            format="DD-MM-YYYY" :not-before="new Date()" placeholder="Дата с"
            :not-after="(new Date()).setDate((new Date()).getDate() + 6)"  @input="onInput">
        </date-picker>
    </div>
    <div class="col">
        <label class="label">Дата по:</label>
        <date-picker v-model="value.to" class="deliverydate" valueType="format" :first-day-of-week="1" lang="ru"
            format="DD-MM-YYYY" :not-before="new Date()" placeholder="Дата с"
            :not-after="addDay(6)"  @input="onInput">
        </date-picker>
    </div>
</div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/index.css'
import { format, parse } from 'date-fns'

export default {
    props:{
        value: {
            from: null,
            to: null,
        }
    },
    components: { DatePicker },
    methods: {
        addDay(days=1, date=new Date()){
            return (date).setDate(date.getDate() + days);
        },
        onInput() { 
            this.$emit('input', {
                from: this.value.from,
                to:  this.value.to,
            });
        }
    },
}

function formatDate(d, f = 'yyyy-MM-dd'){
    return d ? format(parse(d, "dd-MM-yyyy", new Date()), f) : null;
}

function reformat(d, toFormat = 'yyyy-MM-dd', fromFornat = 'dd-MM-yyyy'){
    return d ? format(parse(d, fromFornat, new Date()), toFormat) : null;
}

export {format, parse, formatDate, reformat};
</script>
