<template>
    <title-with-check-modal :isActive='true' :type="'empty'" @close="$emit('close')" @keydown.esc="$emit('close')"
            :title="item.name || item.agentName" style="z-index: 1201;" tabindex="0">
        <form action="" autocomplete="off">
            <div class="field">
                <label class="label">Вес:</label>
                <p class="control">
                    <currency-input 
                            style="width: 120px; text-align: right;"
                            v-model="enterWeight"
                            ref="inputWeight"
                            class="input" id='inputWeight'
                            :options="{ currency: 'EUR', 
                                        currencyDisplay: 'hidden', 
                                        precision: 3,
                                        nullOnClean: true
                                        }"
                            /> 
                    <div class="preselected-container-weight" style="margin-top: .5rem">
                        <span class="action" @click="enterWeight=scalesValue">Взять показания с весов ({{scalesValue}} кг)</span>
                    </div>
                </p>
            </div>
            <div class="field">
                <label class="label">Тара:</label>
                <p class="control">
                    <currency-input 
                            style="width: 120px; text-align: right;"
                            v-model="tara"
                            class="input" id='inputfact'
                            :options="{ currency: 'EUR', 
                                        currencyDisplay: 'hidden', 
                                        precision: 3,
                                        nullOnClean: true
                                    }"
                            />
                </p>
                <div style="margin-top:0.5rem;">
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.05">50 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.1">100 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.15">150 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.2">200 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.25">250 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.3">300 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.4">400 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.5">500 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.6">600 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.7">700 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=.8">800 гр</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=1">1 кг</span></div>
                    <div class="preselected-container-weight"><span class="action action-span" @click="tara=1.5">1.5 кг</span></div>
                </div>
            </div>

            <div class="field">
                <button class="button is-primary" style="margin: 20px 0;" 
                        @click="$emit('result', Math.round((enterWeight - (tara?tara:0))*1000)/1000)">  
                    Вес нетто: {{ netto }} кг
                </button>
            </div>
        </form>
    </title-with-check-modal>
</template>

<script>
    import TitleWithCheckModal from '../../components/TitleWithCheckModal.vue'
    export default{
        props:{
            item: Object,
            scalesValue: Number,
        },
        data(){
            return {
                enterWeight: 0,
                tara: null,
            }
        },
        components: { TitleWithCheckModal, },
        computed:{
            netto(){
                return (this.enterWeight - (this.tara?this.tara:0)).toFixed(3);
            }
        },
        mounted(){
            this.tara=null;
            this.enterWeight = this.item.weightAmount;
            //this.$refs.inputWeight.$el.select();
            //this.$refs.inputWeight.$el.focus();
        },
    }
</script>

<style lang="scss" scoped>
    .preselected-container-weight{
        display: inline-block;
        margin-right: 1.5rem;
    }
</style>