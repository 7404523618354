<template>
    <div class="container" v-if="checkGroups(user, ['Полный прайслист', 'Продавец СБИС', 'Продавец', 'Оптовый клиент'])">
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <section class="section start">
            <Groups
                ref="groups"
                :title="'Каталог КДВ'"
                :goods="goods"
                :branch_id="branch_id"
                :top="'124'"
                @flickoff="flickoffGroups"
                @select="select_group"
                />
            <div class="tu">
                <div class="field">
                    <div class="control" style="display: flex; align-items: center;">
                        Найти: <input class="input" type="text" v-model="search" v-on:input="debounceInput" :placeholder="placeholder" style="margin-left: 10px;">
                        <router-link to="/cart-2/kdv/" style="display: flex; align-items: center; padding: 10px;">
                            <svg class="icon-svg cart">
                                <use xlink:href="@/assets/sprite.svg#icon-cart"></use>
                            </svg>
                            <span v-if="cartSummary" style="white-space: nowrap; display: inline-block;">{{cartSummary.sum}} ₽</span>
                        </router-link>

                    </div>
                </div>

                <div class="field group-selector">
                    <div class="control">
                        <button class="button" @click.stop="$refs.groups.show">Группа: {{category?name:'ВСЁ'}}</button>
                    </div>
                </div>

                <div class="field balance-filter">
                    <div class="control">
                        <label class="label">
                            <input class="checkbox" type="checkbox" @change="push" v-model="availability" :disabled="!user || !user.isStaff">
                            Остаток больше нуля
                        </label>
                    </div>
                </div>
                <div class="field picture-filter" v-if="checkGroup(user, 'Фильтр товары без фото')">
                    <div class="control">
                        <div class="select">
                            <select v-model="withPhotoX" @change="push">
                                <option value="">-</option>
                                <option value="with-photo">С фото</option>
                                <option value="without-photo">Без фото</option>
                            </select>
                        </div>
                        <div class="select" style="margin-left: 10px;">
                            <select v-model="availabilitySkladX" :disabled="!availability" @change="push">
                                <option value="0">Любой склад</option>
                                <option value="1">Кирса</option>
                                <option value="3">Автомобилистов</option>
                            </select>
                        </div>
                    </div>
                </div>
                <TuList
                    ref="tulist"
                    :tu="tu?tu.items:null"
                    :user="user"
                    @modcart="modcart"
                    :loading="$apollo.queries.tu.loading"
                    />
                <Pager
                    :page="page"
                    :count="tu.count"
                    :perpage="40"
                    @change="changepage"
                    v-if="tu"
                    ref="pager"/>
            </div>
        </section>
        <!--EditItem
            v-if="user && !user.isAnonymous && itemModal"
            :item="item"
            :user="user"
            :itemModal="itemModal"
            @closeItem="itemModal=false"
            >
        </EditItem-->
    </div>
</template>

<script>
    import StoreMixin from './StoreMixin'

    export default {
        name: 'StoreKdv',
        mixins: [StoreMixin, ],
        created(){
            this.search = this.q;
            this.search_debounced = this.q
            this.branch_id = this.category?parseInt(this.category):124;
            this.availability = !this.showmissing;
            this.withPhotoX = this.withPhoto;
            this.availabilitySkladX = this.availabilitySklad;
            this.page = this.ppage==null?1:this.ppage;
            this.skip = false;
        },
        methods: {
            getCartName(){
                return 'KDV-CART-ID';
            },
            getTopBranchId(){
                return 124;
            },
            getType() {
                return 'kdv';
            },
            getName() {
                return 'storeKdv';
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/_variables.scss';
    svg.cart {
        height: 24px;
        width: 38px;
        fill: #3273dc;
    }
    .start{
        display: flex;
        div.tu {
            width: 70%;
            @media screen and (max-width: $desktop) {
                width: 100%;
            }
        }
    }
    @media screen and (min-width: $desktop + 1px) {
        .group-selector{
            display: none;
        }
    }
</style>
