<template>
    <div class="container">
        <h1 class="title">Оплата</h1>

        <div class="field">
            <div class="control">
                <label class="label">Получатель:</label>
                <treeselect-agent @select="agentId=arguments[0].id"></treeselect-agent>
            </div>
        </div>


        <div class="field">
            <label class="label">Дата: </label>
            <div class="control">
                <date-picker
                    v-model="date"
                    class="date"
                    type="date"
                    format="DD.MM.YYYY"
                    :first-day-of-week=0
                    lang="ru"
                    placeholder="Дата"
                    id="date"
                    ></date-picker>
            </div>
        </div>
        <div class="columns">
            <div class="column is-one-third">
                <div class="control">
                    <label class="label">
                        Сумма
                    </label>
                    <currency-input v-model="amount" class="input" :options="{ currency: 'RUB', locale: 'ru',}"/>
                </div>
            </div>        
        </div>
        <div class="field is-grouped">
            <div class="control">
                <button class="button is-primary" @click="save" :disabled="false" :class="{'is-loading': sending}">Сохранить</button>
            </div>
            <div class="control">
                <button class="button" @click="$emit('close')">Отмена</button>
            </div>
        </div>        

        <table class="report">
            <thead>
                <tr>
                    <th>Дата</th>
                    <th></th>
                    <th>Сумма</th>
                    <th>Оплачено</th>
                    <th>К оплате</th>
                    <th>Разнесение</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="i in filterAndSort()" :key="i.id" :class="rowClass(i)">
                    <td>{{i.date|date-ddmmyy}}</td>
                    <td>{{i.id}}</td>
                    <td class="currency">{{outgoSum(i.outgoposSet)|currency}}</td>
                    <td class="currency">{{paymentSum(i.paymentSet)|currency}}</td>
                    <td class="currency">{{outgoSum(i.outgoposSet)-paymentSum(i.paymentSet)|currency}}</td>
                    <td class="currency">{{i.x}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="currency" colspan="2">Остаток: {{amountDec|currency}}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import CurrencyInput from '@/components/Weight'
    import {format, parse} from 'date-fns'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import TreeselectAgent from '../TreeselectAgent'
    export default {
        data: ()=>({
            date: new Date(),
            amount: 0,
            amountDec: 0,
            sending: false,
            dateFrom: null,
            dateTo: null,
            answerId: null,
            reasonId: null,
            skladId: null,
            hostId: null,
            agentId: null,

        }),
        props: {
        },
        components: {
            CurrencyInput,
            DatePicker,
            TreeselectAgent,
        },
        mounted(){
        },
        methods: {
            rowClass(i){
                let x = + (this.outgoSum(i.outgoposSet) - this.paymentSum(i.paymentSet)).toFixed(2)
                if (x==i.x)
                    return 'overly';
                if (i.x>0)
                    return 'partly';
                return 'zero';
                //return 'overly';
            },
            filterAndSort(){
                this.amountDec=this.amount
                if (!this.outgoList)
                    return null
                let ret = this.outgoList
                    .filter(e=>+(this.outgoSum(e.outgoposSet)-this.paymentSum(e.paymentSet)).toFixed(2)>0)
                    .sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
                ret.forEach(e=>{
                    let z = +(this.outgoSum(e.outgoposSet)-this.paymentSum(e.paymentSet)).toFixed(2)
                    let s = + Math.min(z, this.amountDec).toFixed(2)
                    this.amountDec-=s
                    e.x = s
                })
                return ret
            },
            convertData(){
                return this.filterAndSort().map(e=>{
                    return {
                        id: e.id,
                        amount: e.x,
                    }
                });
            },
            save(){
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql`mutation($agentId: ID!, $date: Date!, $amount: Decimal!, $distribute: [DistributeType]!) {
                        addCashIn(agentId: $agentId, date: $date, amount: $amount, distribute: $distribute) { ok }
                    }`,
                    variables: {
                        agentId: this.agentId,
                        date: format(this.date,'yyyy-MM-dd'),
                        amount: this.amount,
                        distribute: this.convertData(),
                    },
                }).then(data => {
                    this.sending=false;
                    this.error = null;
                    this.data=null;
                    this.$emit('close')
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },
        },
        apollo: {
            outgoList: {
                query: gql`
                    query($dateFrom: Date, $dateTo: Date, $answerId: ID, $reasonId: ID, $skladId: ID, $agentId: ID,$hostId: ID){
                        outgoList(dateFrom: $dateFrom, dateTo: $dateTo, answerId: $answerId, reasonId: $reasonId,
                                   skladId: $skladId, agentId: $agentId, hostId: $hostId) {
                            id date createDt agent{id name} sklad{id name} answer{id name}
                            outgoposSet{amount price discount incomepos{price}}
                            paymentSet{id amount}
                        }
                    }`,
                variables() {
                    return {
                        dateFrom: this.dateFrom?format(parse(this.dateFrom,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'):null,
                        dateTo: this.dateTo?format(parse(this.dateTo,"dd-MM-yyyy", new Date()),'yyyy-MM-dd'):null,
                        answerId: this.answerId ? this.answerId.code: null,
                        reasonId: this.reasonId ? this.reasonId.code: null,
                        skladId: this.skladId ? this.skladId.code: null,
                        hostId: this.hostId ? this.hostId.code: null,
                        agentId: this.agentId,
                    }
                },
                /*result ({ data, loading }) {
                    if (!loading) {
                    }
                },*/
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
        },
    }
</script>
