<template>
    <div class="container" v-if="checkGroups(user, ['Заказы', 'Продавец СБИС', 'Продавец', 'Оптовый клиент'])">
        <section class="section">
            <h1 class="title">История заказов</h1>
            <div class="columns">
                <div class="column order-list">
                    <div class="field">
                        <div class="control">
                            <date-picker 
                                v-model="date_from" 
                                class="date"
                                valueType="format" 
                                :first-day-of-week=0 
                                lang="ru" 
                                format="DD-MM-YYYY" 
                                placeholder="Дата с"
                                id="from"
                                ></date-picker>

                            &mdash;

                            <date-picker 
                                v-model="date_to" 
                                class="date"
                                valueType="format" 
                                :first-day-of-week=1 
                                lang="ru" 
                                format="DD-MM-YYYY" 
                                placeholder="Дата по"
                                id="to"
                                ></date-picker>
                        </div>
                    </div>
                    
                    <div class="field">
                        <div>
                            <v-select :options="[{code:null, label: '-'}].concat(agents)" v-if="agents" v-model="agent"></v-select>
                        </div>
                    </div>
                    <!--div class="field">
                        <div class="select agent" v-if="agents">
                            <select v-model="agent_id">
                                <option value="">-</option>
                                <option :value="agent.id" :key="agent.id" v-for="agent in agents">
                                    {{agent.name}}
                                </option>
                            </select>
                        </div>
                    </div-->
                    <div class="field">
                        <button
                            class="button is-primary submit"
                            :class="{'is-loading':$apollo.queries.orders.loading}"
                            :disabled="$apollo.queries.orders.loading"
                            @click="submit">Отобразить</button>
                    </div>

                    <ul class="history">
                        <li v-for="order in orders"
                                :key="order.id"
                                @click.stop="orderx(order)"
                                :class="{current:order.id==orderid, inactive:orderactive&&order.id==orderid}">
                            <div class="date">#{{order.id}} / {{order.date|date-ddmmyy}}</div>
                            <div class="agent">{{order.agent.name}} // 
                                <i v-if="order.user">{{order.user.firstName}} {{order.user.lastName}}</i></div>
                            <div class="price">
                                {{order.summa | currency-null}}
                                <div class="pname">{{order.osn.name}}</div>
                            </div>
                            <div class="descr">{{order.info}}</div>
                        </li>
                    </ul>
                </div>
                <div class="column">
                    <Order 
                        ref="order" 
                        :order="order" 
                        :loading="$apollo.queries.order.loading" 
                        @orderClosed="orderactive=false"
                        />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    import {format} from 'date-fns'
    import Order from './Order.vue'

    export default {
        name: 'History',
        components: {
            DatePicker, Order,
        },
        props: {
            user: Object,
        },
        methods: {
            /*flickoffOrder(){
                this.$refs.order.hide();
                document.getElementsByTagName('html')[0].classList.remove('noscroll-on-mobile');
                this.orderid=null;
            },*/
            orderx(order){
                this.orderid=order.id;
                this.answerId=order.answerId;
                this.$refs.order.show();
                this.orderactive=true;
            },
            submit(){
                this.skip=false;
                this.$apollo.queries.orders.refetch();
                //this.skip=true;
            },
        },
        data(){
            return {
                date_from: format((new Date()).setDate((new Date()).getDate()),'dd-MM-yyyy'),
                date_to: format((new Date()).setDate((new Date()).getDate()+1),'dd-MM-yyyy'),
                error: null,
                orders: [],
                skip: true,
                activeSidebar: false,
                orderid: null,
                answerId: null,
                orderactive: false,
                agent: null,
            }
        },
        apollo: {
            agents: {
                query: gql`query{agents {code:id label:name}}`,
            },
            orders: {
                query: gql`
                    query($dateFrom: String!, $dateTo: String!, $agentId: ID) {
                        orders(dateFrom: $dateFrom, dateTo: $dateTo, agentId: $agentId) {
                            id date osn{name} agent{name} info state summa answerId     
                            user{id firstName lastName}
                        }
                    }`,
                skip(){
                    return this.skip;
                },
                errorPolicy: 'all',
                variables() {
                    return {
                        "dateFrom": this.date_from,
                        "dateTo": this.date_to,
                        "agentId": this.agent?this.agent.code:null,
                    }
                },
                result(data) { // eslint-disable-line no-unused-vars
                    this.skip=true;
                },
                error(error){
                    this.$emit('error', JSON.stringify(error.message))
                },
            },
            order: {
                query: gql`
                    query($id: ID!, $answerId: ID!) {
                        order(id: $id, answerId: $answerId) {
                            id 
                            date 
                            osn {id name} 
                            agent {id name}
                            info 
                            state 
                            summa
                            ymClientId 
                            lines{
                                id
                                name
                                kolfakt
                                price
                                summa
                                isWeight
                                img
                            }
                        }
                    }`,
                skip(){
                    return this.orderid==null;
                },
                errorPolicy: 'all',
                variables() {
                    return {
                        id: this.orderid,
                        answerId: this.answerId,
                    }
                },
                error(error){
                    console.log('!!!!!errors', error)
                    this.$emit('error', JSON.stringify(error.message))
                    //this.$emit('error', error)
                    //debugger
                },
                result(data) { // eslint-disable-line no-unused-vars
                    this.skip=true;
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/_variables.scss';

    ul.history{
        max-width: 640px;
        li {
            cursor: pointer;
            margin: 1.5rem 0;
            padding: 5px;
            display: grid;
            grid-template-areas:
                "date price"
                "agent price"
                "descr descr";
            grid-template-columns: 2fr 1fr;

            .descr {
                grid-area: descr;
            }
            .price {
                grid-area: price;
                text-align: right;
                font-size: 133%;
            }
            .date {
                grid-area: date;
            }
            .agent {
                grid-area: agent;
                font-size: 90.44%;
            }
            .pname{
                font-size: 68%;
            }
            border-bottom: 1px solid #e8e8e8;
            &:hover{
                background: #eee;
            }
        }
        @media screen and (max-width: $desktop) {
            li.inactive{
                background: #88b2f7 !important;
                color: #fff;
                transition: background .5s;
            }
        }
    }
    #from, #to {
        width: 124px;
        margin-bottom: .5rem;
    }
    #to {
        margin-right: .5rem;
    }
    .submit{
        vertical-align: middle;
    }

</style>