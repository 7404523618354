<template>
    <div class="modal is-active" v-if="user && !user.isAnonymous">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <div class="modal-card-title">
                    {{tux?tux.name:''}}
                    <div style="font-size: 80%; margin-top: 10px;">{{tux?tux.nomer:''}}</div>
                </div>
                <button class="delete" aria-label="close" @click="close"></button>
            </header>
            <section class="modal-card-body">
                <div :class="{'loading': false}" v-if="tux" @keydown.esc="onEscape">
                    <div class="tabs is-boxed">
                        <ul>
                            <li :class="{'is-active': tab=='main'}"><a @click="tab='main'">Основное</a></li>
                            <li :class="{'is-active': tab=='barcodes'}"><a @click="tab='barcodes'">ШК</a></li>
                            <li :class="{'is-active': tab=='price'}"><a @click="tab='price'">Цены</a></li>
                            <li :class="{'is-active': tab=='additional'}"><a @click="tab='additional'">Дополнительно</a></li>
                            <li :class="{'is-active': tab=='pics'}"><a @click="tab='pics'">Картинки</a></li>
                            <li :class="{'is-active': tab=='move'}"><a @click="tab='move';skipInOutHistory=false;">Движ</a></li>
                            <li :class="{'is-active': tab=='moveMysql'}"><a @click="tab='moveMysql';skipInOutHistoryMysql=false;">Дв2</a></li>
                        </ul>
                    </div>
                    <div v-show="tab=='main'">
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Имя
                                </label>
                                <input type="text" class="input" v-model="tux.name" 
                                       :class="{'is-danger': !firstSaveAttept && (!tux.name || !tux.name.trim())}">
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Код
                                </label>
                                <input type="text" class="input" v-model="tux.nomer">
                            </div>
                        </div>     

                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Категория
                                </label>
                                <treeselect 
                                    v-model="tux.category.id" 
                                    :multiple="false" 
                                    :options="categoriesTree" 
                                    :class="{'is-danger': !firstSaveAttept && !tux.category.id}"/>
                            </div>
                        </div>     
                        

                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    <input class="checkbox" type="checkbox" v-model="tux.isArc"
                                        >
                                    Архив
                                </label>
                            </div>
                        </div>        

                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    <input class="checkbox" type="checkbox" v-model="tux.tuext.transformSbisPrice">
                                    Трансформация цены для СБИС
                                </label>
                            </div>
                        </div>     

                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Трансформация опт в розницу (шт)
                                </label>
                                <currency-input v-model="tux.tuext.transformInbox" class="input"
                                    :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3}"
                                    style="width: 120px" />
                            </div>
                        </div>     

                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Вес (нетто) кг.
                                </label>

                                <currency-input v-model="tux.weight" class="input"
                                    :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3}"
                                    style="width: 120px" />
                            </div>
                        </div>        

                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    <input class="checkbox" type="checkbox" v-model="tux.isWeight">
                                    Весовой товар
                                </label>
                            </div>
                        </div>        

                        <div class="field is-grouped">
                            <div class="control">
                                <label class="label">
                                    В упаковке
                                </label>
                                <currency-input v-model="tux.inbox" class="input"
                                    :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 0}"
                                    style="width: 120px" />

                            </div>
                            <div class="control">
                                <label class="label">
                                    <input class="checkbox" type="checkbox" v-model="tux.tuext.packOrderOnly"
                                        @keydown.esc="onEscape"
                                        >
                                    Заказ только упаковками
                                </label>
                            </div>
                        </div>        
                    </div>

                    <div v-show="tab=='price'">

                        <div class="columns">
                            <!--div class="column is-one-third">
                                <div class="control">
                                    <label class="label">
                                        Цена реализации
                                    </label>
                                    <currency-input v-model="tux.price" class="input"
                                        :options="{ currency: 'RUB', locale: 'ru',}"/>
                                </div>
                            </div>
                            <div class="column is-one-third">
                                <div class="control">
                                    <label class="label">
                                        Опт
                                    </label>
                                    <currency-input v-model="price5" class="input"
                                        :options="{ currency: 'RUB', locale: 'ru',}" />
                                </div>
                            </div>
                            <div class="column is-one-third">
                                <div class="control">
                                    <label class="label">
                                        Доставка
                                    </label>
                                    <currency-input v-model="price7" class="input"
                                        :options="{ currency: 'RUB', locale: 'ru',}" />
                                </div>
                            </div-->
                        </div>     

                        <div class="field">
                            <!--vue-editable-grid
                                class="my-grid-class-price"
                                ref="gridPrice"
                                id="mygridPrice"
                                :column-defs="columnDefs"
                                :row-data="price || []"
                                row-data-key='id'
                                :enable-filters="false"
                                @cell-updated="cellUpdatedPrice"
                                @row-selected="rowSelectedPrice"
                                @link-clicked="linkClickedPrice"
                                style="width: 450px;"
                                >
                            </vue-editable-grid-->
                             <ul class="price">
                                <li v-for="p in price" :key="p.id" class="hover" :class="{'deleted': p.deleted}">
                                    <div class="type">{{p.pricetypeName}}</div>
                                    <currency-input v-model="p.price" class="input"
                                        :options="{ currency: 'RUB', locale: 'ru',}" />
                                    <div><span class="action" @click="p.deleted=true">удалить</span></div>    
                                </li>
                            </ul>
                        </div>
                        <div class="field">
                            <div class="field has-addons">
                                <div class="control">
                                    <div class="select">
                                        <select name="pricetype" v-model="addPriceId">
                                            <option value="5">Опт</option>
                                            <option value="7">Доставка</option>
                                            <option value="129">Аскарово Фасоль</option>
                                            <option value="110">Аскарово Любимый</option>
                                            <option value="126">Ташбулатово</option>
                                            <option value="105">Таштимирово (Любимый)</option>
                                            <option value="109">Тупаково 48</option>
                                            <option value="132">Радуга</option>
                                            <option value="144">Жукова 7</option>
                                            <option value="145">Октябрьская 2</option>
                                            <option value="152">Пивзавод</option>
                                            <option value="127">Заря</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <button type="submit" class="button" @click="addPrice">Добавить</button>
                                </div>
                            </div>                            
                        </div>

                        <div class="field" style="margin-top: 2rem;">
                            <div class="control">
                                <label class="label">
                                    <input class="checkbox" type="checkbox" v-model="tux.tuext.excludePrice7Koef">
                                    Исключить из расчета цены доставки
                                </label>
                            </div>
                        </div>     

                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Индивидуальный коэфициент расчета цены доставки
                                </label>
                                <currency-input v-model="tux.tuext.tuPrice7Koef" class="input"
                                    :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 2, locale: 'ru'}"
                                    style="width: 120px" />
                            </div>
                        </div>

                        <div class="field" style="margin-top: 2rem;">
                            <div class="control">
                                <label class="label">
                                    <input class="checkbox" type="checkbox" v-model="tux.tuext.excludePrice5Koef">
                                    Исключить из расчета оптовой цены
                                </label>
                            </div>
                        </div>

                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Индивидуальный коэфициент расчета оптовой цены
                                </label>
                                <currency-input v-model="tux.tuext.tuPrice5Koef" class="input"
                                    :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 2, locale: 'ru'}"
                                    style="width: 120px" />
                            </div>
                        </div>
                    </div>

                    <div v-show="tab=='additional'">
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Красивое имя для сайта
                                </label>
                                <input type="text" class="input" v-model="tux.tuext.beautyName">
                            </div>
                        </div>     
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Дополнительное поле поиска
                                </label>
                                <input type="text" class="input" v-model="tux.tuext.extsearch">
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <label class="label">
                                    Ед.измерения
                                </label>
                                <input type="text" class="input" v-model="tux.unit" style="max-width: 120px;">
                            </div>
                        </div>
                        <div class="field">
                            <router-link :to="'/log/item/' + id">История изменений</router-link>
                        </div>

                        <div class="field">
                            <label class="checkbox">
                                <input type="checkbox" v-model="tux.notForDelivery">
                                Не для доставки
                            </label>
                        </div>

                        <div class="field">
                            <label class="label">Матрица:</label>
                            <ul v-if="tuGroup">
                                <li v-for="g in tuGroup">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="g.checked">
                                        {{ g.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="field">
                            <label class="label">Упаковка:</label>
                            <ul v-if="tux.tuext.tufruits && measure">
                                <li v-for="m in measure">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="m.checked">
                                        {{ m.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div v-show="tab=='pics'">
                        <div class="imglist" id="imglist">
                            <img
                                :src="'/' + img.img"
                                class="img"
                                :class="{'selected': selected.includes(parseInt(img.id))}"
                                v-for="img in tux.images" :key="img.id"
                                @click="toggle_select(img)"
                                >
                        </div>

                        <div class="uploader">
                            <div style="margin-right: 1rem;">
                                <button :disabled="!selected.length"
                                    @click="removeSelected"
                                    style="margin-bottom: .8rem; display: block;">
                                    Удалить выбранное
                                </button>
                                <button @click="paste" class="button is-small" style="margin-bottom: .8rem; display: block;">
                                    Вставить из буфера
                                </button>
                                <!--button class="button is-small" :disabled="!base64fromclipboard" @click="uploadPasted">
                                    Загрузить
                                </button>
                                <button class="button is-small" :disabled="!base64fromclipboard" @click="clear">
                                    Очистить
                                </button>
                                <div class="field has-addons" style="margin-top: 2rem;">
                                    <div class="control" style="width: 100%;">
                                        <input ref="pasteEl" class="input" type="text" placeholder="Вставить картинку из буфера обмена (Ctrl+V)" @paste="onPaste">
                                    </div>
                                    <div class="control">
                                        <a class="button is-info" :disabled="!base64fromclipboard" @click="uploadPasted">
                                            Загрузить
                                        </a>
                                    </div>
                                </div-->

                                <div class="notification is-danger" v-if="uploadError" style="margin-bottom: .8rem;">{{uploadError}}</div>

                                <img id="fromclipboard" :src="base64fromclipboard">

                                <input type="file" accept="image/*" id="file-input-0" v-on:change="uploadFromCamera">
                            </div>
                            <div style="flex: 1;">
                                <vue-dropzone ref="myVueDropzone" id="dropzone"
                                    :options="dropzoneOptions"
                                    v-on:vdropzone-sending="sendingEvent"
                                    v-on:vdropzone-success="dropzoneSuccess">
                                </vue-dropzone>
                            </div>
                        </div>
                    </div>
                    <div v-show="tab=='barcodes'">
                        
                        <div class="field has-addons">
                            <div class="control">
                                <input type="text" class="input" placeholder="Штрихкод" ref="barcodeInput" v-model="barcodeValue"
                                        @keypress.enter="barcodeChange"
                                        >
                            </div>
                            <div class="control">
                                <a class="button is-info" @click="barcodeChange">
                                    Добавить
                                </a>
                            </div>
                        </div>
                        <ul class="barcode-list">
                            <li v-for="(b, idx) in tux.barcodes" :key="b.id" :class="{'deleted': b.deleted}">
                                <router-link :to="'/dict/tu?q=' + b.barcode">{{b.barcode}}</router-link> 
                                &nbsp;<span @click="deleteBarcode(b, idx)">удалить</span>
                                <!--span class="action" @click="deleteBarcode(b.id, b.barcode)">Удалить</span-->
                            </li>
                        </ul>
                        <!--div style="display: flex;">
                            <div>
                                <vue-editable-grid
                                    class="my-grid-class control"
                                    ref="grid"
                                    id="mygrid"
                                    :column-defs="columnDefs"
                                    :row-data="barcode || []"
                                    row-data-key='id'
                                    :enable-filters="false"
                                    :only-border="false"
                                    @cell-updated="cellUpdatedBarcode"
                                    @row-selected="rowSelected"
                                    @link-clicked="linkClicked"
                                    >
                                </vue-editable-grid>
                            </div>
                            <div style="flex: 1">
                                <button @click="addBarcode">+</button>
                            </div>
                        </div-->
                        <!--input type="text" class="input" v-model="sbisSearch" v-on:input="debounceInput" style="margin-top: 1rem;">
                        <table class="control report">
                            <tr v-for="i in sbisItem" :key="'' + i.id + '-' + i.barcode">
                                <td><span class="sbisname" @click="addBarcodeFromSbis(i.barcode)">{{i.name}}</span></td>
                                <td>{{i.barcode}}</td>
                            </tr>
                        </table-->
                    </div>

                    <div v-show="tab=='move'" :class="{'loading': $apollo.queries.inOutHistory.loading}">
                        <table v-if="inOutHistory" class="report">
                            <tr v-for="i in inOutHistory" :key="i.id">
                                <td>{{i.docio}}</td>
                                <td>{{i.date|date-ddmmyy}}</td>
                                <td>{{i.sklad?i.sklad.id:null}}</td>
                                <td>{{i.agent?i.agent.name:null}}</td>
                                <td>{{i.action==1?parseFloat(i.inAmount):null}}</td>
                                <td>{{i.action==-1?parseFloat(i.outAmount):null}}</td>
                                <td>{{i.inPrice|currency-null2}}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-show="tab=='moveMysql'">
                        
                        <tu-item-move :id="id" />
                      
                        <!-- 
                             :class="{'loading': $apollo.queries.inOutHistoryMysql.loading}"
                            
                            <table v-if="inOutHistoryMysql" class="report">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Дата</th>
                                    <th>Склад</th>
                                    <th>Контрагент</th>
                                    <th>Прих</th>
                                    <th>Расх</th>
                                    <th>Цена</th>
                                    <th>Справка.2</th>
                                </tr>
                            </thead>
                            <tr v-for="i in inOutHistoryMysql" :key="i.id">
                                <td>{{i.docio}}</td>
                                <td>{{i.date|date-ddmmyy}}</td>
                                <td>{{i.sklad?i.sklad.name:null}}</td>
                                <td>{{i.agent?i.agent.name:null}}</td>
                                <td style="text-align: right;">{{i.action==1?parseFloat(i.inAmount):null}}</td>
                                <td style="text-align: right;">{{i.action==-1?parseFloat(i.outAmount):null}}</td>
                                <td style="text-align: right;">{{i.inPrice|currency-null2}}</td>
                                <td>{{i.informBReg}}</td>
                            </tr>
                        </table> -->
                    </div>

                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-primary" @click="save" :class="{'is-loading': sending}" :disabled="xx()">Сохранить</button>
                    </div>
                    <div class="control">
                        <button class="button" @click="close">Отмена</button>
                    </div>
                </div>
            </footer>
        </div>
    </div>

</template>

<script>
    import gql from "graphql-tag"
    //import VueNumeric from 'vue-numeric'
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'


    /*import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';*/

    import TuMixin from './TuMixin'
    import DictItemMixin from './DictItemMixin'
    import _ from 'lodash'
    import CurrencyInput from '@/components/Weight'
    import TuItemMove from './TuItemMove.vue'


    const pricetypeFormatter = event => {
        if (event.reverse) {
            // Convert from display to raw
            return event.value
            if (event.value=="5")
                return 'Опт'
            if (event.value=="7")
                return 'Доставка'
            return null
        }
        if (event.value=="5")
            return 'Опт'
        if (event.value=="7")
            return 'Доставка'
        if (event.value=='Опт')
            return "5"
        if (event.value=='Доставка')
            return "7"
        return `???`
    }

    const selectOptions = [
        { value: 5, text: 'Опт' },
        { value: 7, text: 'Доставка' },
        { value: 125, text: 'Тупаково' },
        { value: 126, text: 'Ташбулатово' },
        { value: 127, text: 'Заря' },
        { value: 105, text: 'Таштимирово (Любимый)' },
        { value: 129, text: 'Аскарово Фасоль' },
        { value: 110, text: 'Аскарово Любимый' },
        { value: 132, text: 'Радуга' },
        { value: 144, text: 'Жукова 7' },
        { value: 145, text: 'Октябрьская 2' },
        { value: 152, text: 'Пивзавод' },
    ]
    const columnDefsC = [
        //{ sortable: false, filter: false, field: 'eyeColor', headerName: 'Eye color', editable: true },
        //{ sortable: true, filter: true, field: 'name', headerName: 'Name', editable: true, maxlength: 30 },
        { sortable: false, filter: false, field: 'id', headerName: 'Тип', type: 'select', editable: true, selectOptions: selectOptions, formatter: pricetypeFormatter},
        { sortable: false, filter: false, field: 'price', headerName: 'Цена', type: 'numeric', editable: true },
        { sortable: false, filter: false, field: 'action', headerName: '', type: 'link', editable: false }
        //{ sortable: true, filter: true, field: 'happiness', headerName: 'Happiness percent', type: 'percent', editable: true },
        //{ sortable: true, filter: true, field: 'isActive', headerName: 'Is active', type: 'boolean', editable: true },
    ];


    export default {
        props: {
            id: String,
            goods: Array,
            scannedBarcode: String,
            user: Object,
        },
        watch: {
            scannedBarcode: function (val) {
                if (val){
                    this.tab = 'barcodes';
                    this.barcodeValue = val;
                    this.$refs.barcodeInput.focus();
                    this.$emit('clearScannedBarcode')
                }
            },
        },
        mixins: [TuMixin, DictItemMixin,],
        /*async created() {
            //const foobar = await this.latest();
            this.categories_tree = await import('../../../public/categories_tree.json');
            console.log(this.categories_tree);
        },*/
        data(){
            return {
                rows: [], 
                columnDefs: columnDefsC,
                selectedRow: null,
                skipQuery: false,
                selected: [],
                blob: null,
                uploading: false,
                uploadError: null,
                scrollToLast: false,
                categoriesTree: [],
                sbisSearch: null,
                searchSbisDebounced: null,
                inOutHistory: null,
                inOutHistoryMysql: null,
                skipInOutHistory: true,
                skipInOutHistoryMysql: true,
                addPriceId: null,
                /*money: {
                    decimal: ',',
                    thousands: '',
                    prefix: '',
                    suffix: ' ₽',
                    precision: 2,
                    masked: false
                },
                koef: {
                    decimal: ',',
                    thousands: '',
                    prefix: '',
                    suffix: '',
                    precision: 2,
                    masked: false
                },*/
                value: null,
                price: [],
            };
        },
        components: {
            //VueNumeric,
            //DatePicker,
            vueDropzone: vue2Dropzone,
            CurrencyInput,
            TuItemMove
        },
        methods: {
            xx(){
                return this.checkGroup(this.user, 'Справочник товаров ReadOnly')
            },
            /*formatRow(row) {
                const red = "#ffe5e5";
                const green = "#b6f7b6";
                const { happiness } = row;
                const priceRateBgColor = happiness > 0.6 ? green : red;
                row.$cellStyle = {
                    happiness: priceRateBgColor && { backgroundColor: priceRateBgColor },
                };
                if (row.eyeColor === "blue") {
                    row.$rowStyle = { color: "blue" };
                }
            },*/
            getPricetypeNameById(id){
                switch (id){
                    case '5':
                        return 'Опт'
                    case '7':
                        return 'Доставка'
                    case '109':
                        return 'Тупаково 48'
                    case '125':
                        return 'Тупаково 55'
                    case '126':
                        return 'Ташбулатово'
                    case '105':
                        return 'Таштимирово (Любимый)'
                    case '127':
                        return 'Заря'
                    case '129':
                        return 'Аскарово Фасоль'
                    case '110':
                        return 'Аскарово Любимый'
                    case '132':
                        return 'Радуга'
                    case '152':
                        return 'Пивзавод'
                }
            },
            addPrice(){
                this.price.push({pricetypeId: this.addPriceId, pricetypeName: this.getPricetypeNameById(this.addPriceId), price: null})
            },
            debounceInput: _.debounce(function (e) {
                this.searchSbisDebounced=e.target.value;
            }, 500),
            addBarcodeFromSbis(barcode){
                this.barcode.push({id: null, barcode: barcode});
            },
            cellUpdatedPrice($event){
                console.log("cellUpdatedPrice", $event)

            },
            rowSelectedPrice($event) {
                this.selectedRow = $event.rowData;
                console.log("rowSelectedPrice", $event)
                //console.log("rowSelected", this.$refs.mygridPrice.tryEdit);
                //this.$refs.grid.tryEdit(2, this.columnDefs, 2, 0, "xxx");
            },
            linkClickedPrice($event) {
                console.log($event);
            },
            removeCurrentRow() {
                this.rows = this.rows.filter((row) => row.id !== this.selectedRow.id);
            },
            contextMenu($event) {
                //console.log($event);
            },

            onEscape(){
                //this.$emit('close');
                this.close();
            },
            close(){
                //this.$emit('close');
                this.$router.go(-1);
            },
            toggle_select(img) {
                const index = this.selected.indexOf(parseInt(img.id));
                if (index > -1)
                    this.selected.splice(index, 1);
                else
                    this.selected.push(parseInt(img.id));
            },
            addCustomData(data){
                data.append('id', this.id);
            },
            beforeUploading(){
                this.uploading=true;
            },
            barcodeChange() {
                this.tux.barcodes.push({id: null, barcode: this.barcodeValue, deleted: false, });
                this.barcodeValue = null;
            },
            deleteBarcode(b, idx){
                b.deleted=!b.deleted;
                this.tux.barcodes.splice(idx, 1, b)
                console.log(b)
            },
            save(){
                this.firstSaveAttept=false;
                if (this.tux.name==null || this.tux.name.trim()=='' ||
                    !this.tux.category.id){
                    this.tab = 'main';
                    return false;
                }
                this.sending = true;
                console.log(this.measure.filter(e=>e.checked).map(e=>e.id));
                this.$apollo.mutate({
                    mutation: gql`mutation(
                            $id: ID, $packOrderOnly: Boolean!, 
                            $excludePrice7Koef: Boolean!, 
                            $tuPrice7Koef: Decimal,
                            $excludePrice5Koef: Boolean!, 
                            $tuPrice5Koef: Decimal,
                            $extsearch: String, 
                            $name: String,
                            $nomer: String,
                            $beautyName: String, 
                            $transformSbisPrice: Boolean,
                            $transformInbox: Decimal,
                            $isArc: Boolean!, 
                            $isWeight: Boolean,
                            $notForDelivery: Boolean,
                            $weight: Decimal,
                            $categoryId: ID,
                            $unit: String,
                            $inbox: Int,
                            $prices: [PriceInputType],
                            $barcodes: [BarcodeInputType]!,
                            $measures: [ID],
                            $groups: [ID],
                            ) {
                        tuExt(
                            id: $id, 
                            packOrderOnly: $packOrderOnly,
                            excludePrice7Koef: $excludePrice7Koef,
                            tuPrice7Koef: $tuPrice7Koef,
                            excludePrice5Koef: $excludePrice5Koef,
                            tuPrice5Koef: $tuPrice5Koef,
                            extsearch: $extsearch,
                            name: $name, 
                            nomer: $nomer,
                            beautyName: $beautyName,
                            transformSbisPrice: $transformSbisPrice,
                            transformInbox: $transformInbox,
                            isArc: $isArc,
                            isWeight: $isWeight,
                            notForDelivery: $notForDelivery,
                            weight: $weight,
                            categoryId: $categoryId,
                            unit: $unit,
                            inbox: $inbox,
                            prices: $prices,
                            barcodes: $barcodes,
                            measures: $measures,
                            groups: $groups,
                        ) { ok id }
                    }`,
                    variables: {
                        id: this.id,
                        packOrderOnly: this.tux.tuext.packOrderOnly==null?false:this.tux.tuext.packOrderOnly,
                        excludePrice7Koef: this.tux.tuext.excludePrice7Koef,
                        tuPrice7Koef: this.tux.tuext.tuPrice7Koef?(1+this.tux.tuext.tuPrice7Koef/100):null,
                        excludePrice5Koef: this.tux.tuext.excludePrice5Koef,
                        tuPrice5Koef: this.tux.tuext.tuPrice5Koef?(1+this.tux.tuext.tuPrice5Koef/100):null,
                        extsearch: this.tux.tuext.extsearch,
                        name: this.tux.name,
                        nomer: this.tux.nomer,
                        beautyName: this.tux.tuext.beautyName,
                        transformSbisPrice: this.tux.tuext.transformSbisPrice,
                        transformInbox: this.tux.tuext.transformInbox,
                        isArc: this.tux.isArc,
                        notForDelivery: this.tux.notForDelivery,
                        isWeight: this.tux.isWeight,
                        weight: this.tux.weight,
                        categoryId: this.tux.category.id,
                        unit: this.tux.unit,
                        inbox: this.tux.inbox,
                        barcodes: this.tux.barcodes.map(e=>{
                            return {
                                id: e.id,
                                value: e.barcode,
                                deleted: e.deleted,
                            }
                        }),
                        prices: this.price.map(e=>{
                            return {
                                id: e.id,
                                pricetypeId: e.pricetypeId,
                                value: e.price,
                                deleted: e.deleted,
                            }
                        }),
                        measures: this.measure.filter(e=>e.checked).map(e=>e.id),
                        groups: this.tuGroup.filter(e=>e.checked).map(e=>e.id),
                    },
                }).then(data => {
                    this.sending = false;
                    if (this.id == null && this.tux && this.tux.id) {
                        this.tux.id = parseInt(data.data.tux.id);
                    }
                    /*this.$emit('factAdded', parseInt(this.amount));
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                    //this.$emit('close');
                    this.firstSaveAttept = true;
                    this.close();
                }).catch(error => {
                    this.sending=false;
                    this.$emit('error', JSON.stringify(error.message));
                    //this.$apollo.queries.auditFacts.refetch();
                });
            },
            /*clear(){
                console.log("clear");
                this.base64fromclipboard=null;
            },
            onPaste (ev) {
                let items = (ev.clipboardData || ev.originalEvent.clipboardData).items;
                for (let index in items) {
                    var item = items[index];
                    if (item.kind === 'file') {
                        this.read(item.getAsFile());
                    }
                }
            },*/
            uploadPasted(){
                let formData = new FormData();
                formData.append('id', this.tux.id);
                formData.append('file', this.blob);
                this.uploadData(formData);
            },
            afterUploading(){
                this.skipQuery=false;
                this.$apollo.queries.images.refetch();
            },
            initPrice(data) {
                data.forEach(e=>{
                    console.log(e);
                });
            },
            fillMeasureChecks(){
                if (!this.measure || this.measure.length==0 || !this.tux || !this.tux.tuext.tufruits)
                    return
                this.measure.forEach(e=>{
                    e.checked = this.tux.tuext.tufruits.measure.find(x=>x.id==e.id) != null;
                });
            },
            fillTuGroupChecks(){
                if (!this.tuGroup || this.tuGroup.length==0 || !this.tux || !this.tux.tuext.groups)
                    return
                this.tuGroup.forEach(e=>{
                    e.checked = this.tux.tuext.groups.find(x=>x.group.id==e.id) != null;
                });
            }
            ,
        },
        apollo: {
            tux: {
                query: gql`
                    query($id: ID) {
                        tux(id: $id) {
                            id name nomer price isWeight isArc
                            notForDelivery
                            weight
                            inbox
                            unit: edizm
                            category{id name}
                            priceSet{
                                id pricetype{id name} price
                            }
                            tuext {
                                packOrderOnly 
                                excludePrice7Koef 
                                excludePrice5Koef 
                                tuPrice7Koef tuPrice5Koef
                                extsearch
                                beautyName
                                transformSbisPrice
                                transformInbox
                                tufruits{measure{id}}
                                groups{id group{id}}
                            }
                            barcodes {id barcode}
                        }
                    }`,
                variables() {
                    return {
                        id: this.id,
                    }
                },
                result({data, loading}) {
                    if (!loading){
                        if (data.tux.tuext.tuPrice7Koef != null){
                            this.tux.tuext.tuPrice7Koef=(this.tux.tuext.tuPrice7Koef-1)*100;
                        }
                        if (data.tux.tuext.tuPrice5Koef!==null){
                            this.tux.tuext.tuPrice5Koef=(this.tux.tuext.tuPrice5Koef-1)*100;
                        }
                        
                        this.sbisSearch = this.tux.name;
                        this.searchSbisDebounced = this.tux.name;

                        this.price5=null;
                        this.price7=null;
                        this.price=Array()
                        this.price.push(
                            {
                                id: null,
                                pricetypeId: null,
                                pricetypeName: 'Цена реализации',
                                price: this.tux.price,
                                deleted: false,
                                action: 'удалить'
                            })
                        data.tux.priceSet.sort((a, b) => parseInt(a.pricetype.id)-parseInt(b.pricetype.id)).forEach(e => {
                            switch (e.pricetype.id){
                                case '5':
                                    this.price5 = e['price'];
                                    break;
                                case '7':
                                    this.price7 = e['price'];
                                    break;
                            }
                            this.price.push(
                                {
                                    id: e.id,
                                    pricetypeId: e.pricetype.id,
                                    pricetypeName: e.pricetype.name,
                                    price: e.price,
                                    deleted: false,
                                    action: 'удалить'
                                })

                        });
                        data.tux.barcodes.forEach(e => {
                            e.deleted=false;
                        });
                    }
                    this.fillMeasureChecks();
                    this.fillTuGroupChecks();
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            measure: { 
                query: gql`query {measure{id name}}`,
                result({data, loading}){
                    this.fillMeasureChecks();
                },
            },
            tuGroup: { 
                query: gql`query {tuGroup{id name}}`,
                result({data, loading}){
                    this.fillTuGroupChecks();
                },
            },
            /*sbisItem: {
                query: gql`
                    query($name: String!) {
                        sbisItem(name: $name) {
                            id name price barcode
                        }
                    }
                `,
                skip() {
                    return !this.tux;
                },
                variables() {
                    return {
                        name: this.searchSbisDebounced,
                    }
                },
            },*/
            inOutHistory: {
                query: gql`
                query($tuId: ID!){
                    inOutHistory(tuId: $tuId) {
                        docio date agent{id name} sklad{id name}
                        inAmount inPrice
                        outAmount outPrice action
                    }
                }`,
                skip(){ return this.skipInOutHistory },
                variables() {
                    return {
                        tuId: this.id
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                        console.log(data);
                    }
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            // inOutHistoryMysql: {
            //     query: gql`
            //     query($tuId: ID!){
            //         inOutHistoryMysql(tuId: $tuId) {
            //             docio date agent{id name} sklad{id name}
            //             inAmount inPrice
            //             outAmount outPrice action informBReg
            //         }
            //     }`,
            //     skip(){ return this.skipInOutHistoryMysql },
            //     variables() {
            //         return {
            //             tuId: this.id
            //         }
            //     },
            //     result ({ data, loading }) {
            //         if (!loading) {
            //             console.log(data);
            //         }
            //     },
            //     error(error) {
            //         this.error = JSON.stringify(error.message);
            //     },
            // },
            images: {
                query: gql`
                    query($productId: ID!) {
                        extItemImage(productId: $productId) {
                            id img
                        }
                    }
                `,
                manual: true,
                skip() {
                    return !this.tux || !this.id;
                },
                variables() {
                    return {
                        productId: this.id
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                        this.tux.images = data.extItemImage;
                        this.scrollToLast=true;
                    }
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    table.ppi{
        td {
            padding: 5px 10px 5px 5px;
        }
    }
    div.loading {
        color: #ccc;
    }
    .modal-card{
        min-height: 90%;
    }
    .my-grid-class {
        height: 220px;
        width: 200px;
    }
    .my-grid-class-price {
        height: 200px;
        width: 320px;
    }
    div.is-danger {
        .vue-treeselect__control {
        background: #f14668 !important;
            border: 1px solid #f14668 !important;
        }
    }
    .sbisname {
        cursor: pointer;
    }
    .barcode-list{
        max-width: 270px;
        li {
            padding: 5px;
            cursor: pointer;
            &:hover {
                background: #e5e5e5 !important;
                color: #333;
            }            
        }
    }
    li.deleted{
            a, div, input {
                text-decoration: line-through;
            };
    }
    @media screen and (min-width: 769px){
        .modal-content, .modal-card {
            width: 700px;
        }    
    }

    ul.price li{
        display: flex;
        align-items: center;
        padding: 4px 0;
        .type{
            width: 100px;
            margin-right: 8px;
        }
        .input{
            width: 100px;
            margin-right: 8px;
        }

    }
</style>

<style lang="scss">
    div.is-danger .vue-treeselect__control {
        border: 1px solid #f14668;
    }
</style>
