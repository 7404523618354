<template>
    <div style="padding: 2rem;">
        <h1 class="title">Оплата</h1>
        <h2 class="subtitle">{{agent.name}}</h2>
        <div class="field">
            <label class="label">Дата: </label>
            <div class="control">
                <date-picker
                    v-model="date"
                    class="date"
                    type="date"
                    format="DD.MM.YYYY"
                    :first-day-of-week=0
                    lang="ru"
                    placeholder="Дата"
                    id="date"
                    ></date-picker>
            </div>
        </div>
        <div class="columns">
            <div class="column is-one-third">
                <div class="control">
                    <label class="label">
                        Сумма
                    </label>
                    <currency-input v-model="amount" class="input" :options="{ currency: 'RUB', locale: 'ru',}"/>
                </div>
            </div>        
        </div>
        <div class="field is-grouped">
            <div class="control">
                <button class="button is-primary" @click="save" :disabled="false" :class="{'is-loading': sending}">Сохранить</button>
            </div>
            <div class="control">
                <button class="button" @click="$emit('close')">Отмена</button>
            </div>
        </div>        
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import CurrencyInput from '@/components/Weight'
    import {format, parse} from 'date-fns'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/ru';
    export default {
        data: ()=>({
            amount: 0,
            date: new Date(),
            sending: false,
        }),
        props: {
            agent: Object,
            startAmount: Number,
            outgoId: String,
        },
        components: {
            CurrencyInput,
            DatePicker,
        },
        mounted(){
            this.amount = this.startAmount;
        },
        methods: {
            save(){
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql`mutation($agentId: ID!, $date: Date!, $amount: Decimal!, $distribute: [DistributeType]!) {
                        addCashIn(agentId: $agentId, date: $date, amount: $amount, distribute: $distribute) { ok }
                    }`,
                    variables: {
                        agentId: this.agent.id,
                        date: format(this.date,'yyyy-MM-dd'),
                        amount: this.amount,
                        distribute: [{id: this.outgoId, amount: this.amount},]
                    },
                }).then(data => {
                    this.sending=false;
                    this.error = null;
                    this.data=null;
                    /*
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                    this.$emit('close')
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },
        }
    }
</script>