<script>
    import axios from 'axios'
    export default {
        name: 'UploadFromCamera',
        data(){
            return {
                uploadError: null,
                image_uploading: false,
            }
        },
        methods:{
            uploadFromCamera(items){  // eslint-disable-line no-unused-vars
                let formData = new FormData();
                for (let i=0; i<10; i++){
                    var fi1 = document.getElementById("file-input-"+i);
                    if (fi1) {
                        Array.from(fi1.files).forEach(file => {formData.append("file[]", file)});
                    }
                }

                this.addCustomData(formData);
                this.uploadData(formData);
            },
            beforeUploading(){
            },
            afterUploading(){
            },
            uploadData(formData){
                this.image_uploading=true;
                this.beforeUploading();
                axios.post( '/photo/',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                        this.image_uploading=false;
                        this.skipQuery=false;
                        this.afterUploading(response);
                    } // eslint-disable-line no-unused-vars
                ).catch(error => this.uploading_error(error));
            },
            uploading_error(err){
                this.uploadError=err;
                this.image_uploading=false;
            },
        },
    }
</script>