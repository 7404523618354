<template>
    <div v-if="item">
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <h1>Накладная {{item.code}}</h1>
        <ul>
            <li>Дата: {{item.date|date-ddmmyy}}</li>
            <li>Магазин: {{item.agentName}}</li>
        </ul>

        <div class="field is-grouped" style="margin-top: 1rem;">
            <div class="control">
                <button class="button" @click="setDelivered(true)" :disabled="sending">Принять</button>
            </div>
            <!--div class="control">
                <button class="button" @click="setDelivered(false)" :disabled="sending">Отказаться</button>
            </div-->
        </div>     

        <table class="report striped document">
            <tr v-for="(p, idx) in item.positions" :key="p.id">
                <td>{{idx+1}}</td>
                <td>{{p.name}}</td>
                <td>{{p.amount}}</td>
                <td>{{p.edizm}}</td>
                <td>{{p.total|currency}}</td>
            </tr>
            <tfoot>
                <tr>
                    <td colspan="5" style="font-weight: bold; text-align: right;">Сумма: {{item.total|currency}}</td>
                </tr>
            </tfoot>
        </table>
    </div>
    
</template>
<script>
    import gql from "graphql-tag"
    export default {
        name: 'Document',
        props: {
            item: Object,
        },
        data() {
            return {
                sending: false,
                error: null,
            }
        },
        methods: {
            setDelivered(accept){
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql`mutation($code: ID!, $type: String!, $accept: Boolean) {
                        setDelivered(code: $code, type: $type, accept: $accept) { ok }
                    }`,
                    variables: {
                        code: this.item.code,
                        type: this.item.type,
                        accept: accept,
                    },
                }).then(data => {
                    this.sending=false;
                    if (!data || !data.data || !data.data.setDelivered ){
                        this.error='Возможно не хватает прав';
                    } else {
                        this.error=null;
                        this.$emit('refetch');
                    }
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    table.document {
        overflow-x: auto;
        display: block;
        tr td {
            cursor: pointer;
        }
        td:nth-child(1), td:nth-child(3), td:nth-child(5){
            text-align: right;
        }
    }
</style>
