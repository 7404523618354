<template>
    <ul class="tu cart" :class="{'loading': loading}">

        <TuListItem
            :item="item" 
            :key="item.id"
            v-for="item in cart"
            @modcart="$emit('modcart', arguments[0], arguments[1], arguments[2])"
            @remove="$emit('remove', arguments[0])" 

            />

    </ul>
</template>

<script>
    import TuListItem from './TuListItem.vue'
	export default{
		props: {
            cart: Array,
            loading: Boolean,
        },
        components: {TuListItem,},
        methods:{
            /*filteredCart: function(x) {
            	if (this.cart!=null)
	                return this.cart.filter(function(u) {
                        if (x==0){
                            return u.product.weight==0; // && ...
                        } else if (x==1){
                            return u.product.weight==1;
                        } else if (x==2){
                            return false;
    	                   //return u.product.weight==x;
                        }
        	        });
        	    else
        	    	return null;
            },*/
        },
	}
</script>

<style lang="scss" scoped>
    @import "@/assets/_variables.scss";
	ul.cart{
		li {
            padding: .5rem;
            margin: 1rem -.5rem;
            grid-template-areas:
                "name name name name"
                "price remove inbox pm";
            grid-template-columns: 1fr 1fr 2.5fr 2fr;
        }
        .price {
            text-align: right;
        }
	}
    
    @media screen and (max-width: $desktop) {
        ul.cart {
            li {
                grid-template-areas:
                    "name name name name" 
                    "price remove inbox pm";
                grid-template-columns: 1fr 1fr 1fr 2.6fr;
            }
            .price, .pm {
                text-align: center;
                align-self: center;
            }
        }
    }

</style>