<template>
    <section class="container" ref="tuDict" v-show="user && !user.isAnonymous">
        <h1 class="title" id="dicttu">Справочник товаров</h1>
        <div class="tabs is-boxed no-print">
            <ul>
                <li :class="{'is-active': tab=='search'}"><a @click="tab='search'">Поиск</a></li>
                <li :class="{'is-active': tab=='new'}"><a @click="tab='new'">Новая</a></li>
                <li :class="{'is-active': tab=='selected'}"><a @click="tab='selected'">Выбранное</a></li>
                <!--li :class="{'is-active': tab=='last'}"><a @click="tab='last'">Последние</a></li-->
            </ul>
        </div>

        <div class="field searchfield is-grouped is-grouped-multiline" v-show="tab=='search'">
            <div class="control" style="flex: 1;">
                <input placeholder="Найти" class="input" type="text" v-model="search" v-on:input="debounceInput"
                    style="min-width: 120px;"
                    ref="searchInput"
                    >
            </div>
            <p class="control">
                <CheckboxSlider @input="changeActiveOnly" :checked="activeOnly" ref="activeOnly">Только актив</CheckboxSlider>
            </p>
            <p class="control">
                <CheckboxSlider @input="changeHasNotBarcodeOnly" :checked="hasNotBarcodeOnly" ref="hasNotBarcode">Без ШК</CheckboxSlider>
            </p>
            <p class="control">
                <CheckboxSlider @input="changeWithoutWeight" :checked="withoutWeight" ref="withoutWeight">Вес=0</CheckboxSlider>
            </p>
            <p class="control">
                <CheckboxSlider @input="changePositiveBalance" :checked="positiveBalance" ref="positiveBalance">Наличие</CheckboxSlider>
            </p>
            <div class="control">
                <div class="select">
                    <select v-model="skladId" :disabled="!positiveBalance" @change="changeSkladId">
                        <option></option>
                        <option value="1">Кирса</option>
                        <option value="3">Автомобилистов 8Г</option>
                        <option value="11">Автомобилистов 18Е</option>
                        <option value="8">Белорецк</option>
                        <option value="15">Овощной</option>
                        <option value="25">Тупаково</option>
                        <option value="26">Ташбулатово</option>
                    </select>
                </div>
            </div>
            <div class="control">
                <div class="select">
                    <select v-model="withPhotoX" @change="push">
                        <option value="">-</option>
                        <option value="with-photo">С фото</option>
                        <option value="without-photo">Без фото</option>
                    </select>
                </div>
            </div>
            <div class="control" style="width: 250px;">
                <treeselect 
                    v-model="categoryId" 
                    :multiple="false" 
                    :options="categoriesTree"/>
            </div>
        </div>

        <div class="field searchfield is-grouped is-grouped-multiline" v-show="tab=='selected'">
            <!--div class="dropdown">
                <div class="dropdown-trigger">
                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>Dropdown button</span>
                    <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                    </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                    <a href="#" class="dropdown-item">
                        Склад
                    </a>
                    <a class="dropdown-item">
                        Other dropdown item
                    </a>
                    <a href="#" class="dropdown-item is-active">
                        Active dropdown item
                    </a>
                    <a href="#" class="dropdown-item">
                        Other dropdown item
                    </a>
                    <hr class="dropdown-divider">
                    <a href="#" class="dropdown-item">
                        With a divider
                    </a>
                    </div>
                </div>
            </div-->
            <p class="control">
                <button class="button" @click="printTickets">Печать ценников</button>
            </p>
            <p class="control">
                <button class="button" @click="clearSelected">Очистить</button>
            </p>
        </div>
        <div style="position: relative;" v-show="tab!='new'">
            <table v-if="list" class="report striped search-results" style="width: 100%;" :class="{'loading': $apollo.queries.list.loading}">
                <thead>
                    <tr>
                        <th></th>
                        <th>Код</th>
                        <th>Наименование</th>
                        <th>Ост.</th>
                        <th>Приход</th>
                        <th>Дата</th>
                        <th>Розн.</th>
                        <th>Опт.</th>
                        <th>Нац.</th>
                        <th>Дост.</th>
                        <th class="delivery-inc">Нац.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in list.items" class="hover" :key="item.id" :class="{'is-arc': item.isArc}">
                        <td>
                            <label class="checkbox">
                                <input type="checkbox" v-model="item.selected" class="checkbox" @change="selected(item)">
                            </label>
                        </td>
                        <td class="nomer">{{item.nomer}}</td>
                        <td>
                            <router-link :to="'/dict/tu/' + item.id">{{item.name}}</router-link>
                        </td>
                        <td class="currency">{{Math.round(item.balance)}}</td>
                        <td class="currency">{{item.lastIncomeSybasePrice|currency-null }}</td>
                        <td style="text-align: center;">{{item.lastIncomeSybaseDate|date-ddmmyy}}</td>
                        <td class="currency">{{item.price|currency-null}}</td>
                        <td class="currency">
                            {{item.priceOpt|currency-null}}
                        </td>
                        <td class="currency">
                            {{item.lastIncomeSybasePrice==null||item.priceOpt==null?'': Math.round((item.priceOpt/item.lastIncomeSybasePrice - 1)*100)+'%' }}
                        </td>
                        <td class="currency">{{item.priceDelivery|currency-null}}</td>
                        <td class="currency delivery-inc">
                            {{item.lastIncomeSybasePrice==null||item.priceDelivery==null?'': Math.round((item.priceDelivery/item.lastIncomeSybasePrice - 1)*100)+'%' }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="list" class="count">
                Кол-во: {{list.count}}
            </div>
            <Pager
                :page="page"
                :count="list.count"
                :perpage="40"
                @change="changepage"
                v-if="list"
                ref="pager"/>
        </div>
        <div v-show="tab=='new'">
            <TuNew :user="user" @error="$emit('error', arguments[0])"></TuNew>
        </div>

    </section>
    <!--section class="container section" v-else>
        Недостаточно прав
    </section-->
</template>

<script>
    import gql from "graphql-tag"
    import ListMixin from '@/components/Dict/ListMixin.vue'
    import TuNew from './TuNew'

    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import axios from "axios"

    export default {
        name: 'TuList',
        components: {
            TuNew,
            Treeselect,
        },
        mixins: [
            ListMixin,
        ],
        props: {
            pb: Boolean,
            wb: Boolean,
            ww: Boolean,
            skl: Number,
            wp: String,
            scannedBarcode: String,
            lastAdded: Boolean,
            ci: Number,
        },
        async created(){
        //created(){
            this.positiveBalance = this.pb;
            this.hasNotBarcodeOnly = this.wb;
            this.withoutWeight = this.ww;
            this.skladId = this.skl;
            this.categoryId = this.ci;
            this.skip = false;
            this.withPhotoX = this.wp;
            axios.get('/categories_tree.json')
                .then(response => this.categoriesTree = response.data.children)
                .catch(error => this.$emit('error', error))
        },
        mounted(){
            this.$refs.hasNotBarcode.setChecked(this.hasNotBarcodeOnly);
            this.$refs.positiveBalance.setChecked(this.positiveBalance);
            this.$refs.withoutWeight.setChecked(this.withoutWeight);
        },
        watch: {
            /*gBarcode: function (val) {
                if (val){
                    this.list=null;
                    this.search=val;
                    this.q_debounced = val
                    this.push();
                }
            },*/
            scannedBarcode: function (val) {
                if (val){
                    this.list=null;
                    this.search=val;
                    this.q_debounced = val
                    this.push();
                    this.$emit('clearScannedBarcode')
                }
            },
            categoryId: function(val){
                this.push()
            },
        },
        methods: {
            printTickets(){
                let arr=[];
                this.list.items.forEach(e=>{
                    arr.push(e.id);
                });
                this.$router.push(
                    {
                        name: 'print-ticket',
                        query: {
                            idList: arr,
                        }
                    });
            },
            clearSelected(){
                this.$apollo.mutate({
                    mutation: gql`mutation{
                        clearTuSelection { ok }
                    }`,
                }).then(data => {  // eslint-disable-line no-unused-vars
                    this.$apollo.queries.list.refetch();
                }).catch(error => {
                    this.$emit('error', error);
                });
            },
            selected(item){
                this.$apollo.mutate({
                    mutation: gql`mutation($tuId: ID!, $newValue: Boolean!) {
                        setTuSelected(tuId: $tuId, newValue: $newValue) { ok }
                    }`,
                    variables: {
                        tuId: item.id, 
                        newValue: item.selected,
                    },
                }).then(data => {  // eslint-disable-line no-unused-vars
                }).catch(error => {
                    this.$emit('error', error);
                });
            },
            changeSkladId(){
                this.push();
            },
            clearCategoryId(){
                //this.categoryId = null
                //this.push()
            },
            changeCategoryId(value){
                console.log(value)
                if (value)
                    this.categoryId = value.id
                this.push()
            },
            changePositiveBalance(){
                this.positiveBalance = !this.positiveBalance;
                this.push();
            },
            changeWithoutWeight(){
                this.withoutWeight = !this.withoutWeight;
                this.push();
            },
            changeHasNotBarcodeOnly(){
                this.hasNotBarcodeOnly = !this.hasNotBarcodeOnly;
                this.push();
            },
            prefix(event){
                console.log('tu prefix');
                event.preventDefault();
                this.$refs.searchInput.focus();
                this.list=null;
                this.search='';
                this.q_debounced='';
            },
            push(){
                this.$router.push(
                    {
                        name: 'tu-dict',
                        query: {
                            q: this.q_debounced,
                            pb: this.positiveBalance,
                            wb: this.hasNotBarcodeOnly,
                            ao: this.activeOnly,
                            pg: this.page,
                            ww: this.withoutWeight,
                            skl: this.skladId,
                            ci: this.categoryId,
                            wp: this.withPhotoX,
                        }
                    });
            },
            //click(item_id){
            //    this.$router.push('/dict/tu/' + item_id);
            //}
        },
        data(){
            return {
                tab: 'search',
                withoutWeight: false,
                hasNotBarcodeOnly: false,
                positiveBalance: false,
                withPhotoX: null,
                skladId: null,
                skip: true,
                categoriesTree: [],
                categoryId: null,
            }
        },
        apollo: {
            list: {
                query: gql`
                    query($q: String, $activeOnly: Boolean, $hasNotBarcodeOnly: Boolean, $positiveBalance: Boolean, $offset: Int, $skladId: ID, $withoutWeight: Boolean, $withPhoto: String, $selected: Boolean, $categoryId: ID) {
                        list: search(q: $q, activeOnly: $activeOnly, hasNotBarcodeOnly: $hasNotBarcodeOnly, positiveBalance: $positiveBalance, offset: $offset, skladId: $skladId, withoutWeight: $withoutWeight, withPhoto: $withPhoto, selected: $selected, categoryId: $categoryId) {
                            count items{
                                id name nomer price isArc balance priceOpt priceDelivery lastIncomeSybasePrice lastIncomeSybaseDate
                                selected
                            }
                        }
                    }`,
                variables() {
                    return this.tab=='search'?{
                        q: this.q_debounced,
                        activeOnly: this.activeOnly,
                        hasNotBarcodeOnly: this.hasNotBarcodeOnly,
                        positiveBalance: this.positiveBalance,
                        withoutWeight: this.withoutWeight,
                        offset: (this.page-1)*40,
                        skladId: this.skladId?this.skladId:null,
                        withPhoto:  this.withPhotoX,
                        selected: false,
                        categoryId: this.categoryId
                    }:{
                        selected: true,
                    }
                },
                skip(){
                    return this.skip || this.tab=='new';
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            goods: {
                query: gql`
                    query{
                        goods{
                            id label:name
                        }
                    }`,
                /*variables() {
                    return {
                        q: this.q_debounced,
                    }
                },*/
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    .search-results{ 
        font-size: 94%;
        .nomer {
            max-width: 100px;
        }
    }
    table.loading {
        color: #ccc;
    }
    .delivery-inc{
        display: block;
    }
    .count {
        font-size: 90%;
    }
</style>

