<template>
    <div class="container" v-if="checkGroups(user, ['Заказ фруктов', 'Продавец', 'Продавец СБИС'])">
        <section class="section" v-if="phase!='submited'">
            <h1 class="title">Подтверждение заказа фруктов</h1>

            <table class="report striped confirm" :class="{'loading': $apollo.queries.cart.loading}">
                <thead>
                    <th>#</th>
                    <th>Наименование</th>
                    <th>Кол-во</th>
                </thead>
                <tr v-for="(i,idx) in cart" :key="idx">
                    <td>{{ idx+1 }}</td>
                    <td>
                        {{ i.name }}
                        <div><span class="action" @click="removeFromCart(i)">Удалить</span></div>
                    </td>
                    <td>{{ i.measureName }}</td>
                    <td>{{ i.amount|number-null03 }}</td>
                </tr>
            </table>

            <div style="margin-top: 30px;">
                <!--div class="field">
                    <div class="select agent" v-if="agents">
                        <select v-model="agent_id">
                            <option :value="agent.id" v-for="(agent, index) in agents" :selected="index === 0 ? 'selected' : false" :key="index">
                                {{agent.name}}
                            </option>
                        </select>
                    </div>
                </div-->

                <div class="field">
                    <agent-search-select class="control" v-model="agent" @input="inputChange" v-if="checkGroup(user, 'operator')"/>
                    <v-select :options="[{code:null, label: '-'}].concat(agents)" v-if="agents && !checkGroup(user, 'operator')" v-model="agent"/>
                    
                </div>

                <!--div class="notification is-warning" v-if="breadCount && breadCount.count">
                    <div class="icon-text">
                        <font-awesome-icon icon="exclamation-triangle" style="color: #fff; font-size: 200%; margin-right: 20px;"/>
                        <span>
                            У вас уже была заявка на текущую дату. При повторной подаче заявки, предыдущая заявка будет отменена.
                        </span>
                    </div>
                </div-->

                <div class="field">
                    Комментарий: <input class="input" type="text" v-model="info">
                </div>

                <div class="field" style="margin-top: 20px;">
                    <button class="button is-primary" @click="submit">
                        Отправить заявку
                    </button>
                    <router-link to="/fruits" class="button" style="margin-left: 10px;">Вернуться к заказу</router-link>
                </div>
            </div>
        </section>
        <title-with-check v-if="phase=='submited'" style="margin-top: 2rem;">
                Заказ оформлен!
        </title-with-check>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import TitleWithCheck from '../TitleWithCheck.vue'
    //import TuListGrid from './TuListGrid.vue'
    //import Groups from './Groups.vue'
    //import EditItem from '@/components/EditItem.vue'
    import {format, addDays} from 'date-fns'
    import CartMixin from '../Store/CartMixin.vue'
    import {
        AgentSearchSelect,
    } from '../common/FormControls'


    export default {
        components: {
            //TuListGrid, //EditItem,
            TitleWithCheck,
            AgentSearchSelect,
        },
        props: {
            user: Object,
            category: String,
            showmissing: String,
            q: String,
        },
        mixins: [CartMixin],
        data: function(){
            return {
                phase: 'fast-order',
                itemModal: false,
                item: null,
                page: 1,
                agent: null,
                info: null,
            }
        },
        methods: {
            inputChange() {
            },
            nextDay(){
                return format(addDays(new Date(),1),'dd-MM-yyyy')
            },
            user_fetched(){
            },
            getCartName(){
                return 'FRUIT-CART-ID';
            },
            submit(){
                let clientId=null;
                if (process.env.NODE_ENV=='production'){
                    //window.ym(83894410, 'getClientID', function(cID) {clientId = cID;});                    
                    //ym(83894410,'reachGoal','order-fruits'); // eslint-disable-line
                } else {
                    clientId = process.env.NODE_ENV
                }

                this.$apollo.mutate({
                    mutation: gql`
                              mutation($osnrId: Int!, $txt: String, $date: String!, $agentId: ID!, 
                                       $cartname: String!, $clientId: String) {
                                  order(osnrId: $osnrId, txt: $txt, date: $date, agentId: $agentId, 
                                  cartname: $cartname, clientId: $clientId, orderType: "fruits") { ok }
                              }`,
                    variables: {
                        osnrId: 4,  // delayed pay
                        txt: this.info,
                        date: this.nextDay(),  // format((new Date()).setDate((new Date()).getDate() + 1),'dd-MM-yyyy'),
                        agentId: this.agent.code || this.agent.id,
                        cartname: this.getCartName(),
                        clientId: clientId,
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    //console.log(this.$apollo);
                    this.sended=true;
                    this.phase='submited';
                }).catch(error => {
                    //card.add2cart_finished();
                    //console.log(error);
                    this.$emit('error', JSON.stringify(error.message));
                });
            },
            removeFromCart(item){
                console.log(item)
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!, $cartname: String) {
                        removefromcart(productId: $id, cartname: $cartname) { ok }
                    }`,
                    variables: {
                        id: item.id,
                        cartname: this.getCartName(),
                    },
                }).then(({data, loading}) => { 
                    if (data.removefromcart.ok){
                        for (var i = this.cart.length - 1; i >= 0; --i) {
                            console.log(item.id, this.cart[i])
                            if (this.cart[i].id==item.id){
                                this.cart.splice(i,1);
                            }
                        }
                    }
                }).catch(error => { // eslint-disable-line no-unused-vars
                    //itemlist.modcartfinished(true);
                    //console.log(error);
                });
            },
        },
        apollo: {
            cart: {
                query: gql`
                    query{
                        cart: fruitCart {
                            id name amount measureName
                        }
                    }`,
                    error(error) {
                        this.$emit('error', JSON.stringify(error.message));
                    },
            },
            agents: {
                query: gql`query{agents {code:id label:name}}`,
                result({data, loading}) {
                    if (data && data.agents && data.agents.length && !this.checkGroup(this.user, 'operator')){
                        this.agent = data.agents[0];
                    }
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
                //skip(){
                //    return !this.user;
                //},
            },
        },
    }
</script>

<style lang="scss" scoped>
    table.confirm {
        td:nth-child(3), td:nth-child(4){
            text-align: right;
        }
        .action{
                font-size: 80%;
            }
    }
</style>
