<template>
    <div class="container" v-if="user && !user.isAnonymous && checkGroup(user, 'Отчет - не доставленные накладные')">
        <h1 class="caption">Не доставленные накладные</h1>
        <div class="notification is-danger my-error" v-if="error">
            {{error}}
        </div>
        <section class="section">
            <table v-if="notDeliveredAllAgentsList" class="report striped not-delivered-list" :class="{'loading': $apollo.queries.notDeliveredAllAgentsList.loading}">
                <tr>
                    <th>#</th>
                    <th>Дата</th>
                    <th>Склад</th>
                    <th>Точка</th>
                    <th>Сумма</th>
                </tr>
                <tr v-for="i in notDeliveredAllAgentsList" :key="i.id">
                    <td>{{i.code}}</td>
                    <td>{{i.date|date-ddmmyy}}</td>
                    <td>{{i.sklad?i.sklad.name:''}}</td>
                    <td>{{i.agentName}}</td>
                    <td>{{i.total | currency}}</td>
                </tr>
            </table>
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"

    export default {

        name: 'notDeliveredAllAgentsList',
        components: {
        },
        props: {
            user: Object,
        },
        methods: {
        },
        data(){
            return {
                item: null,
                error: null,
            }
        },
        apollo: {
            notDeliveredAllAgentsList: {
                query: gql`
                    query{
                        notDeliveredAllAgentsList {
                            date
                            code
                            total
                            agentName
                            sklad {id name}
                        }
                    }`,
                errorPolicy: 'all',    
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    .not-delivered-list {
        overflow-x: auto;
        display: block;
        tr td {
            cursor: pointer;
        }
        td:nth-child(5){
            text-align: right;
            white-space: nowrap;
        }
    }
</style>

