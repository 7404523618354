<template>
    <div class="container" v-if="user">
        <tabs :tabs="PayModes" v-model="payMode" />

        <div>
            <div class="group-row resp">
                <agent-search-select  class="control" v-model="agent" @input="inputChange"/>
                <hosts-select class="control" v-model="hostId" emit-value="code" @input="inputChange" :user="user"/>
            </div>

            <div class="group-row resp" >
                <div class="group-row" >
                    <div class="control">
                        <label class="label">Сумма:</label>
                        <currency-input v-model="amount" @change="amount = $event" class="input"
                                    :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 2, locale: 'ru'}"
                                    style="text-align: right;" />
                    </div>
                    <paytypes-select class="control" v-model="paytypeId" emit-value="code"/>
                </div>

                <div class="group-row" >
                    <date-select class="control" v-model="date"/>
                    <div class="control" >
                        <label class="label">Описание:</label>
                        <input class="input" type="text" v-model="description"/>
                    </div>
                </div>

            </div>

            <div class="notification is-warning"  v-if="overAmount>0 && debtsPay.length" style="display: flex; flex-direction: row; align-items: center">
                <div>Сумма оплаты превышает сумму долга на <strong>{{ overAmount|currency }}</strong></div>
            </div>
            
            <div class="group-row mx-6 mt-2">
                <span>
                    <strong style="cursor: pointer" @click="amount=totalDebt" title="Установить эту сумму оплаты">
                        Долг: {{totalDebt|currency}}
                    </strong>
                </span>
                <button class="button is-success" style="max-width:200px" :disabled="(countTrans<0 || paytypeId == null) " @click="payTransaction()"  :title="`Оплатить  позиций: ${ countTrans } \n на общую сумму: ${amount} руб`">&#128179; Оплатить ({{ countTrans }})</button>
            </div>
        </div>
        <hr>
        <!-- :disabled="(countTrans<1 || paytypeId == null) " -->
        <table class="table is-striped is-fullwidth" :class="{'loading': $apollo.queries.debts.loading}" v-if="agent">
            <tbody>
                <tr>
                    <th @click="sortBy('id')" class="sorted-head">№ <span v-html="sortIcon('id') "></span></th>
                    <th @click="sortBy('date')" class="sorted-head">Дата <span v-html="sortIcon('date') "></span></th>
                    <th>Поставщик</th>
                    <th @click="sortBy('debt')" class="sorted-head">Сумма <span v-html="sortIcon('debt') "></span></th>
                    <th @click="sortBy('paid')" class="sorted-head">Оплачено <span v-html="sortIcon('paid') "></span></th>
                    <th>Долг</th>
                    <th>Оплата</th>
                    <th>
                        <button class="button is-primary" @click="requests++;" :disabled="!inputOk" title="Обновить список задолженности">&#8634;</button>
                    </th>
                </tr>
                <tr v-for="i in debtsPay" :key="i.d.id">
                    <td>{{i.d.id}}</td>
                    <td>{{i.d.date|date-ddmmyy}}</td>
                    <td class="agent">{{i.d.agent.name}}</td>
                    <td class="nowrap has-text-right">{{i.d.debt|currency}}</td>
                    <td class="nowrap has-text-right">{{i.d.paid|currency}}</td>
                    <td class="nowrap has-text-right">
                        <template v-if="i.status!='pay-none'" class="pay-wait">
                            <span :class="(i.dolg)==0?'has-text-success-dark':'has-text-danger-dark'" >{{i.dolg|currency}}</span> 
                        </template>
                        <template v-else>
                            {{i.dolg|currency}}
                        </template>
                    </td>
                    <td class="nowrap has-text-right">
                        <div v-if="true ||i.status!='pay-none'" :class="i.status">
                            <span class="mx-2" >{{ i.amount|currency }}</span> 
                            <!-- <span class="mx-2" :class="i.status">{{ statusText(i.status) }}</span> -->
                        </div>
                    </td>
                    <td>
                        <div :class="i.status" class="mx-2">{{ statusText(i.status) }}</div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4"></td>
                    <td style="text-align: right; font-weight: bold;">Итого:</td>
                    <td class="nowrap has-text-right" style="text-align: right; font-weight: bold;">{{totalDebt|currency}}</td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    
        <div v-if="inputOk && !$apollo.queries.debts.loading && debtsPay.length==0">Нет элементов для отображения</div>
        <!-- <div v-if="$apollo.queries.debts.loading" class="is-loading">Loading...</div> -->
        <div class="modal" :class="{'is-active': dialog.show}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head" v-if="dialog.title">
                    <p class="modal-card-title">{{ dialog.title }}</p>
                    <button class="delete" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <p v-html="dialog.text"></p>
                    <div class="loading" v-if="dialog.mode=='process'"></div>
                </section>
                
                <footer v-if="dialog.mode!='process'" class="modal-card-foot">
                    <button class="button is-primary mx-3" @click="dialog.show=false; dialog.onOk();dialog.onOk=()=>{}" >Оk</button>
                    <button class="button is-danger" @click="dialog.show=false; dialog.onCancel();dialog.onCancel=()=>{}" v-if="dialog.mode =='confirm'" >Отмена</button>
                </footer>
            </div>
        </div>

    </div>
</template>
<script lang="js">
import gql from "graphql-tag"
import {
    AgentSearchSelect,
    HostsSelect,
    PaytypesSelect,
    Tabs,
    DateSelect, format, parse, formatDate
} from '../common/FormControls'

const PayStatus = {
    'pay-wait': 'Ожидание',
    'pay-process': 'Выполняется',
    'pay-ok': 'Оплачено',
    'pay-error': 'Ошибка'
}

const PayModes = [{
        id: 'debit',
        label: 'Оплата дебетора',
    }, {
        id: 'credit',
        label: 'Оплата кредитору',
    }
]

export default {
    components: {
        AgentSearchSelect, 
        HostsSelect,
        PaytypesSelect,
        DateSelect,
        Tabs
    },
    props: {
        user: Object
    },
    data() {
        return {
            PayModes,
            payMode: PayModes[0],
            hostId: null,
            agent: null,
            paytypeId: null,
            incomeId: null,
            amount: 0,
            date: format(new Date(), 'dd-MM-yyyy'),
            description: '',
            requests: 0,
            countTrans: 0,
            debtsPay:[],
            sort: {
                by: 'date',
                asc: true
            },
            dialog: {
                show: false,
                mode: 'alert',
                title: '',
                text: '',
                onOk: () => { },
                onCancel: () => { }
            }
        }
    },
    computed: {
        totalDebt() {
            return this.debtsPay.reduce((a, v) => (a + parseFloat(v.d.debt-v.d.paid)), 0);
        },
        overAmount() {
            return this.amount > this.totalDebt? this.amount - this.totalDebt : 0;
        },
        inputOk() { 
            return  this.agent != null //&& this.hostId != null
        },
    },
    watch: {
        amount(amount) {
            this.calcPay(amount);
        },
        debtsPay(){
            this.calcPay(this.amount);
        }
    },
    methods: {
        calcPay(amount) {
            this.countTrans = 0
            if(this.debtsPay)
                this.debtsPay.forEach(x => {
                    
                    x.dolg = x.d.debt - x.d.paid

                    if (x.dolg > 0) {
                        if (amount > 0 ) {
                            x.status = 'pay-wait';
                            if (amount >= x.dolg) {
                                x.amount = x.dolg;
                                amount -= x.dolg;
                                x.dolg = 0;
                            }
                            else {
                                x.amount = amount;
                                x.dolg -= amount
                                amount = 0;
                            }
                            this.countTrans++;
                        }
                        else {
                            x.amount = 0;
                            x.status = 'pay-none';
                        }
                    }
                })
        },
        async payTransaction(confirm = true) {
            if (confirm) {
                let _this = this
                this.dialog.text = `Подтвердите оплату на сумму: ${Math.round((this.amount-this.overAmount)*10)/10} ₽`;
                this.dialog.show = true;
                this.dialog.mode = 'confirm'
                this.dialog.onOk = () => _this.payTransaction(false)
                return;
            }

            this.dialog.show = true;
            this.dialog.mode = 'process'
            this.dialog.text = 'Выполнение оплаты ...'

            let payTotal = 0;
            let amountTotal = 0;
            let trans = []
            this.debtsPay.forEach((x) => { 
                if (x.status == 'pay-wait'){
                    payTotal++;
                    amountTotal += x.amount;
                    trans.push({ amount: x.amount, outgoId: x.d.id })
                } 
            });

            let variables = {
                date: formatDate(this.date),
                creditAgentId: parseInt(this.agent.id),
                paytypeId: parseInt(this.paytypeId),
                description: this.description,
                trans
            }

            const mutation = gql`mutation($date: Date!, $creditAgentId: ID, $paytypeId: ID, $description: String, $trans: [CreateTransType]!){
                createEnter(
                    date: $date, 
                    creditAgentId: $creditAgentId, 
                    paytypeId: $paytypeId, 
                    description: $description, 
                    trans: $trans 
                ){
                    createdId
                }
            }`
  
                    
            this.$apollo.mutate({ mutation, variables })
                .then(res => {
                    let createTrans = res.data.createEnter
                    createTrans.msg = createTrans.msg || ''
                    this.debtsPay.forEach((x) => { if (x.status == 'pay-wait') x.status = 'pay-ok' });
                    this.amount = 0;
                    this.dialog.text += `<hr>Выполнено платежей: ${payTotal}<br>На общую сумму: ${Math.round(amountTotal*10)/10} ₽`
                    this.dialog.mode = 'alert';

                    // update list debtsPay
                    this.requests++;
                })
                .catch(err => {
                    console.log(err)
                    this.debtsPay.forEach((x) => { if (x.status == 'pay-wait') x.status = 'pay-error' });
                    this.dialog.text += `<div style="color: red">${err.message}</div>`
                    this.dialog.mode = 'alert';
                    if(this.amount)
                        this.calcPay(this.amount)
                })
        },
        statusText(status){
            return PayStatus[status]
        },
        inputChange() {
            if (!this.inputOk) {
                this.debtsPay = []
            }
        },
        sortBy(name) {
            if (name) {
                if (this.sort.by == name) {
                    this.sort.asc = !this.sort.asc;
                }
                else {
                    this.sort.asc = true;
                    this.sort.by = name;
                }
            }

            this.debtsPay = this.debtsPay.sort((a, b) => { 
                let p1 = a.d[this.sort.by]
                let p2 = b.d[this.sort.by]

                if (p1 < p2) return this.sort.asc ? -1 : 1;
                if (p1 > p2) return this.sort.asc ? 1 : -1;
                return 0;
            })
        },
        sortIcon(name) {
            if (this.sort.by == name) {
                return this.sort.asc ? '&darr;':'&uarr;';
            } 
            return ''
        }

    },
    apollo: {
        debts: {
            query: gql`query($agentId: ID, $priorTo: Date, $hostId: ID) { 
                debts(agentId: $agentId, priorTo: $priorTo, hostId: $hostId) {id date debt paid agent{name}} }`
            ,
            skip() {
                return !this.inputOk
            },
            variables() {
                return {
                    priorTo: this.date==null? null : formatDate(this.date),
                    agentId: this.agent ? this.agent.id : 0,
                    paytypeId: this.paytypeId,
                    hostId: this.hostId,
                    _r: this.requests
                }
            },
            result ({ data, loading }) {
                if (!loading) {

                    if (data.debts) {
                        this.debtsPay = data.debts
                        .filter(x => {
                            return (this.payMode.id == 'debit')? x.debt > 0 : x.debt < 0
                        }) 
                        .map(x => {
                            x.debt = parseFloat(x.debt);
                            x.paid = parseFloat(x.paid);
                            return { amount: 0, status: 'pay-none', d: x, dolg: x.debt - x.paid, trans: null }
                        })

                        this.sortBy()
                    }
                }
            },
            errorPolicy: 'all',
            error(error) {
                this.debtsPay = []
                this.$emit('error', JSON.stringify(error.message));
            },
        },
    }
}
</script>

<style lang="scss" scoped>
.group-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
.group-row > * {
    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;
}
@media (max-width: 900px) {
  .resp {
    flex-direction: column;
  }
  .resp > * {
    width: 95%;
  }
}
.control{
    margin: 5px;
}
.pay-wait{
    background-color: rgb(216, 243, 250);
    content: 'ожидание';
}
span.pay-wait{
    background-color: rgb(216, 243, 250);
    content: 'ожидание';
}
.pay-ok{
    background-color: rgb(194, 248, 210);
}
.pay-error{
    background-color: rgb(243, 194, 187);
}
.pay-process{
    background-color: rgb(255, 243, 202);
}
.nowrap{
    white-space: nowrap;
    overflow: visible;
}
.agent{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 50%;
    max-width: 600px;
}
input, button{
    height: 34px;
}
.sorted-head{
    cursor: pointer;
    white-space: nowrap;
}
.sorted-head:hover{
    color: #218bd1
}
</style>