<template>
    <div class="control is-loading">
        <input class="input" type="text" :placeholder="placeholder">
    </div>            
</template>
<script>
export default {
    props: {
        placeholder: String,
    }
    
}
</script>