<script>
    import gql from "graphql-tag"
    import ApolloAnswerMixin from '../../Warehouse/ApolloAnswerMixin'
    import ApolloSkladMixin from '../../Warehouse/ApolloSkladMixin'
    export default {
        mixins: [ApolloAnswerMixin, ApolloSkladMixin, ],
        data:()=>({
                kind: null,
                answer: null,
                pricetype: null,
                sklad: null,
                secondarySklad: null,
                agroup: null,
            }
        ),
        methods: {
            kindsLoaded(data){
                if (this.item && this.item.kind){
                    this.kind = data.find(e=>e.code==this.item.kind.id)
                }
            },
            agroupsLoaded(data){
                if (this.item && this.item.agroup){
                    this.agroup = data.find(e=>e.code==this.item.agroup.id)
                }
            },
            skladLoaded(data){
                if (this.item && this.item.prioritySklad){
                    this.sklad = data.find(e=>e.code==this.item.prioritySklad.id)
                }
                if (this.item && this.item.secondSklad){
                    this.secondarySklad = data.find(e=>e.code==this.item.secondSklad.id)
                }
            },
            answerLoaded(data){
                if (this.item && this.item.answer){
                    this.answer = data.find(e=>e.code==this.item.answer.id)
                }
            },
            pricetypesLoaded(data){
                if (this.item && this.item.pricetype){
                    this.pricetype = data.find(e=>e.code==this.item.pricetype.id)
                }
            },
            saveAgent(data){
                this.sending = true;
                this.$apollo.mutate({
                    mutation: gql`
                        mutation($id: ID, $name: String!, $isArc: Boolean!, $kindId: ID, $address: String,
                                 $answerId: ID, $pricetypeId: ID, $prioritySkladId: ID, $secondSkladId: ID, $agroupId: ID,
                                 $torg12payer: String, $torg12consignee: String) {
                            agent(id: $id, name: $name, isArc: $isArc, kindId: $kindId, address: $address, 
                                  answerId: $answerId, pricetypeId: $pricetypeId, prioritySkladId: $prioritySkladId, secondSkladId: $secondSkladId
                                  agroupId: $agroupId, torg12payer: $torg12payer, torg12consignee: $torg12consignee) {
                                ok id 
                            }
                    }`,
                    variables: data,
                }).then(data => {
                    this.sending = false;
                    this.firstSaveAttept = true;
                    this.created = true;
                    this.timerid = setTimeout(() => {
                        this.created = false;
                    }, 3200);

                    this.close();
                }).catch(error => {
                    this.sending=false;
                    this.$emit('error', JSON.stringify(error.message));
                });
            },
        },
        apollo: {
            kinds: {
                query: gql`
                    query{
                        kinds: prop(typeId: 1){
                            code: id label:name
                        }
                    }`,
                /*variables() {
                    return {
                        id: this.id,
                    }
                },*/
                result ({data, loading}) {
                    //if (!loading) { this.kindsLoaded(data.kinds); }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
            agroups: {
                query: gql`
                    query{
                        agroups: prop(typeId: 2){
                            code: id label:name
                        }
                    }`,
                result ({data, loading}) {
                    if (!loading) { this.agroupsLoaded(data.agroups); }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
            /*answers: {
                query: gql`query {
                    answers{code: id label:name}
                    
                }`,
                result ({data, loading}) {
                    if (!loading) { this.answersLoaded(data.answers); }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
            sklads: {
                query: gql`query {
	                sklads{code: id label: name}
                }`,
                result ({data, loading}) {
                    if (!loading) { this.skladsLoaded(data.sklads); }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },*/
            pricetypes: {
                query: gql`
                    query{
                        pricetypes: priceType{
                            code: id label:name
                        }
                    }`,
                /*variables() {
                    return {
                        id: this.id,
                    }
                },*/
                result ({data, loading}) {
                    if (!loading) { this.pricetypesLoaded(data.pricetypes); }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
        },
    }
</script>