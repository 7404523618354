<template>
    <aside  class="abs menu catalog" :class="{'active': activeSidebar}" v-click-outside="hide">
        <a class="delete is-medium" @click="hide" v-if="activeSidebar"></a>
        <h1 class="title">{{title}}</h1>
        <ul class="menu-list">
            <li>
                <!--a @click="group_select({'id': 0, 'name': 'ВСЁ'} )" :class="{'is-active': branch_id==0}">
                    ВСЁ
                </a-->
                <p @click="hide()">
                    <a :class="{'is-active': branch_id==0}" @click="$emit('select', 'ВСЕ', top)">
                        ВСЁ
                    </a>
                </p>
            </li>
        </ul>    
        <branch class="goods" :goods="goods" :id="top" :branch_id="branch_id" @select="select"/>
    </aside>
</template>

<script>
    import branch from './branch.vue'
    import Sidebar from '@/components/Sidebar'
    export default {
        name: 'Group',
        components: {
            branch, 
        },
        mixins: [Sidebar,],
        props: {
            goods: Array,
            branch_id: Number,
            top: String,
            title: String,
        },
        methods: {
            /*group_select(item){
                this.$emit('group_select', item);
                this.hide();
            },*/
            select(name, id){
                this.hide();
                this.$emit('select', name, id);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/_variables.scss";
    .catalog {
        background: #fff;
        width: 30%;
        margin-right: 1.5rem;
    }

    .delete{
        position: absolute;
        right: calc(0% + -32px);
        top: calc(50% - 32px);
        display: none;
        height: 64px !important;
        width: 64px !important;
        max-width: inherit !important;
        max-height: inherit !important;
        background: #e8e8e8 !important;
        border: 1px solid #7d7d7d;
        &:before, &:after {
            background-color: #444;
        }
    }

    @media screen and (max-width: $desktop) {
        .delete{
            display: block !important;
        }

        .catalog{
            display: flex;
            flex-direction: column;

            box-shadow: 0 0 0 0 #fff !important;
            position: fixed;
            top: 0;
            bottom: 0;
            left: -70%;
            width: 70%;
            z-index: 100;
            margin-right: 0;    
            transition: all .5s ease;
        }
        .active{
            left: 0 !important;
            box-shadow: 0 0 10px 20px rgba(0,0,0,.33) !important;
            transition: all .5s ease;
        }
        h1 {
            padding: .5rem;
            margin-bottom: 0 !important;
        }

        ul.goods{
            overflow-y: scroll;
        }
    }


</style>