<script>
    import gql from "graphql-tag"
    import Treeselect from '@riophae/vue-treeselect'
    import TreeselectAgent from '../TreeselectAgent.vue'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/locale/ru'
    import 'vue2-datepicker/index.css'
    import {format, parse} from 'date-fns'
    import _ from 'lodash'
    import CheckboxSlider from '../checkboxSlider2.vue'
    import ApolloSkladMixin from './ApolloSkladMixin'
    import ApolloHostMixin from './ApolloHostMixin'
    import ApolloReasonMixin from './ApolloReasonMixin'
    import ApolloAnswerMixin from './ApolloAnswerMixin'

    export default {
        components: {
            CheckboxSlider,
            TreeselectAgent,
            Treeselect,
            DatePicker,
        },
        props: {
            scannedBarcode: String,
            itemId: String,
            user: Object,
        },
        mixins: [
            ApolloSkladMixin, ApolloHostMixin, ApolloReasonMixin, ApolloAnswerMixin,
        ],
        data(){
            return {
                tusearch: null,
                q_debounced: null,
                tu_id: null,
                agent: null,
                agentId: null,
                initOptions: null,
                sklad: null,
                host: null,
                reason: null,
                answer: null,
                callback: null,
                list: [],
                labelClassName: 'labelClassName',
                incomeDate: this.id?null:format((new Date()).setDate((new Date()).getDate()),'dd.MM.yyyy'),
                submitted: false,
                timerid: null,
                sendingDelete: false,
                editMode: false,
                //skipSkladFetching: true,
            }
        },
        mounted(){
            if (this.itemId==null || typeof this.itemId === 'undefined')
                this.editMode=true;
        },
        methods: {
            debounceInput: _.debounce(function (e) {
                this.search=null;
                this.q_debounced=e.target.value;
            }, 500),
            setCookie(cookieName, value){
                if (value && 'code' in value)
                    this.$cookies.set(cookieName, value['code']);
                else
                    this.$cookies.remove(cookieName);
            },
            getCookie(cookieName, data){
                if (data)
                    return data.find(e=>e.code==this.$cookies.get(cookieName));
                return null;
            },
            hostLoaded(){}, // must be overwriten
            skladLoaded(){}, // must be overwriten
            answerLoaded(){}, // must be overwriten
            reasonLoaded(){}, // must be overwriten

            tuListNormalizer(node) {
                return {
                    id: node.id,
                    label: [node.label, node.lastPrice, node.balance],
                    children: node.children,
                }
            },
            deleteItem(item) {
                let findIndex=null;
                if (item.id == undefined)
                    findIndex=this.list.findIndex(e => e.tuId==item.tuId && e.incomeposId==item.incomeposId);
                else
                    findIndex=this.list.findIndex(e => e.id==item.id && e.incomeposId==item.incomeposId);
                if (findIndex>=0){
                    this.list[findIndex]['deleted']=true;
                }
            },
            restoreItem(item) {
                let findIndex
                if (item.id == undefined)
                    findIndex=this.list.findIndex(e => e.tuId==item.tuId && e.incomeposId==item.incomeposId);
                else
                    findIndex=this.list.findIndex(e => e.id==item.id && e.incomeposId==item.incomeposId);
                if (findIndex>=0){
                    this.list[findIndex]['deleted']=false;
                }
            },
            itemAmountChange(item){
                item.amountInbox = (!item.isWeight && item.inbox && item.amount%item.inbox==0)?item.amount/item.inbox:null;
                item.sum = item.price*item.amount*(item.discount?(1-item.discount/100):1);
            },
            itemAmountInboxChange(item){
                item.amount = item.amountInbox*item.inbox;
                item.sum = item.price*item.amount*(item.discount?(1-item.discount/100):1);
            },
            itemPriceChange(item){
                item.sum = item.price*item.amount*(item.discount?(1-item.discount/100):1);
            },
            itemDiscountChange(item){
                item.sum = item.price*item.amount*(item.discount?(1-item.discount/100):1);
            },
            itemSumChange(item){
                if (item.amount)
                    item.price = Math.round((item.sum/(item.discount?(1-item.discount/100):1))/item.amount*100)/100;
            },
            convertData(){
                return this.list.map(e=>{
                    return {
                        amount: e.amount,
                        price: e.price,
                        incomeposId: e.id,
                    }
                });
            },
            /*fetchSklads(){
                try{
                    let checksumSklad = localStorage.getItem("checksum:sklad")
                    if (!checksumSklad || checksumSklad!=this.user.checksumSklad){
                        this.skipSkladFetching=false;
                    } else {
                        let skladsNew = JSON.parse(localStorage.getItem("skladsNew"))
                        if (skladsNew==null){
                            this.skipSkladFetching=false;
                        } else {
                            this.sklads = skladsNew;
                        }
                    }
                } catch (e){
                    this.skipSkladFetching=false;
                }
            },*/
        },
        apollo: {
            /*sklads: {
                query: gql`query{sklads {code:id label:name}}`,
                skip() {
                    //try{
                    //    let checksumSklads = localStorage.getItem("checksumSklads");
                    //} catch (e) {
                    //}
                    return this.skipSkladFetching
                },
                result ({data, loading}) {
                    console.log('result apollo sklads')
                    localStorage.setItem("skladsNew", JSON.stringify(data.sklads))
                    localStorage.setItem("checksum:sklad", this.user.checksumSklad)
                    if (!loading) { this.skladLoaded(data.sklads); }
                    this.skipSkladFetching=true;
                },
            },
            hosts: {
                query: gql`query{hosts {code:id label:name}}`,
                result ({data, loading}) {
                    if (!loading) { this.hostLoaded(data.hosts); }
                },
            },
            reasons: {
                query: gql`query{reasons {code:id label:name}}`,
                result ({data, loading}) {
                    if (!loading) { this.reasonLoaded(data.reasons); }
                },
            },
            answers: {
                query: gql`query{answers {code:id label:name}}`,
                result ({data, loading}) {
                    if (!loading) { this.answerLoaded(data.answers); }
                },
            },*/
        },

    }
</script>

<style lang="scss">
    table.outgo-list, table.income-list {
        td {
            vertical-align: middle;
        }
        tr.deleted{ 
            background: #e94949 !important;
            color: #fff !important;
        }
    }

    .tu-items {
        .vue-treeselect__multi-value-item-container{
            display: none;
        }
        .vue-treeselect__label{
            display: flex;
            align-items: center;
            .name {
                width: calc( 50% - 4px);
                padding-right: 4px;
            }
            .price {
                width: calc( 25% - 4px );
                padding-right: 4px;
                text-align: right;
            }
            .balance {
                width: 25%;
                text-align: right;
            }
        }
    }
</style>