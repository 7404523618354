<template>
    <section class="section" v-if="user">
        <h1>Перевод средств</h1>
        <div class="field">
            <div class="control">
                От: {{ user.agent.name }}
            </div>
        </div>
        <div class="field">
            <div class="control" style="max-width: 300px">
                <agent-search-select v-model="agent" @input="inputChange">Кому:</agent-search-select>
            </div>
        </div>
        <div class="field">
            <div class="control" style="max-width: 140px">
                <label class="label">
                    Сумма: {{ amount }}
                </label>
                <currency-input-x v-model="amount" class="input" :options="{ currency: 'RUB', locale: 'ru',}" />
            </div>
        </div>
        <div class="field">
            <div class="control">
                <button class="button is-primary" @click="submit">Перевести</button>
            </div>
        </div>
    </section>
</template>

<script>
import {AgentSearchSelect} from '../common/FormControls.js'

import CurrencyInputX from '../../components/Weight.vue'

export default {
    props: {
        user: Object,
    },
    components: {
        AgentSearchSelect,
        CurrencyInputX
    },

    data(){
        return {
            amount: 100,
            agent: null,
        }
    },
    methods: {
        submit(){

        },
        inputChange(x){
            console.log(x)
        },
        change(x,y){

        },
    },

}
</script>