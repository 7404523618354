import { render, staticRenderFns } from "./PredictionProductItem.vue?vue&type=template&id=aaa9bfb8&scoped=true&"
import script from "./PredictionProductItem.vue?vue&type=script&lang=js&"
export * from "./PredictionProductItem.vue?vue&type=script&lang=js&"
import style0 from "./PredictionProductItem.vue?vue&type=style&index=0&id=aaa9bfb8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa9bfb8",
  null
  
)

export default component.exports