<template>
    <div :class="{'is-quantity': item.quantity>0, 'animate__animated animate__fadeOutDown': ign }" class="xxx">
        <div class="img-name-price">
            <div class="ignore" v-if="type=='novelty' && user.id < 3" v-once @click="ignore(item)"/>
            <div style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <img  
                    v-if="(item.images && item.images.length>0)" 
                    :src="'https://kirsa.9733.ru/' + (item.hasOwnProperty('product')?item.product.images[0].img:item.images[0].img)">
            </div>
            <div class="name">
                {{item.name || (item.product?item.product.name:'???') }}
            </div>
            <div class="price">
                {{(item.price||item.unitPrice) | currency}}
            </div>
        </div>
        <div class="remove is-unselectable">
            <a @click.stop="$emit('remove', item)" class="action">Убрать</a>
        </div>
        <div class="pm is-unselectable">
            <div>
                <button class="pm" :disabled="parseFloat(item.quantity)<=0" @click.stop="item.quantity=parseFloat(item.quantity)-1;setItem();">-</button>
                <input
                    type="text"
                    v-model="item.quantity"
                    ref="input"
                    @keypress.enter="setItem"
                    @keydown.esc="onEscape"
                    @focus="onFocus"
                    @blur="onBlur"
                    :class="{'modcartproccessing': modcartproccessing, 'big': item.quantity>0}"
                    @click.stop=""
                    >

                <img src="@/assets/loading-3dots-blue.svg" class="quantity-loading" v-if="modcartproccessing">

                <button class="pm" @click.stop="item.quantity=parseFloat(item.quantity)+1;setItem();">+</button>

            </div>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import TuListItemMixin from '@/components/Store/TuListItemMixin'
	export default {
		name: 'TuListItem',
		props: ['item', 'user', 'type', ],
        mixins: [TuListItemMixin,],
        data(){
            return {
                ign: false,
            }
        },
        methods: {
            ignore(item){
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!) {
                        ignoreNovelty(id: $id) { ok }
                    }`,
                    variables: {
                        id: item.id,
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    this.sending=false;
                    this.error=null;
                    this.ign=true;
                    setTimeout(
                      () => {
                        this.ign=false;
                        this.$emit('removeItem', item);
                      },
                      650
                    )
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });
            },
        },
	}
</script>

<style lang="scss" scoped>
    @import "@/assets/_variables.scss";

    div.img-name-price{
        display: flex;
        position: relative;
        flex-direction: column;
        img {
            max-width: 100%;
            max-height: 256px;
        }
        height: 344px;
        .name {
            font-size: 92%;
            color: #444;
            text-align: left;
        }
        .price{
            font-size: 140%;
            color: #444;
        }
    }
    /*.price{
        text-align: center;
        &:before{
            content: 'Цена: ';
        }
    }*/

    div.pm{
        margin-top: 10px;
        align-self: center;
        text-align: center;
        min-width: 132px;
        button.pm{
            cursor: pointer;
            border-radius: 4px;
            border: none;
            background: #e6e6e6;
            height: 32px;
            width: 32px;
            font-size: 150%;
        }
        &>div{
            position: relative;
            display: inline-block;
            .quantity-loading{
                position: absolute;
                width: 50px;
                left: 50%;
                margin-left: -25px;
                top: 50%;
                margin-top: -25px;
            }
        }
        button{
            outline: none;
        }
        input {
            min-width: 68px;
            width: 40px;
            text-align: center;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            margin: 0 4px;
            font-size: 100%;
        }
        input.big {
            font-weight: bold;
            font-size: 140%;
        }
        input.modcartproccessing{
            color: #eee;
            visibility: hidden;
        }
        .kg{
            padding-left: 6px;
            padding-right: 6px;
        }
    }

    .remove{
        display: none;
    }

    .loading {
        position: fixed;
    }

    .ignore{
        position: absolute;
        z-index: 10;
        background: #f5f4f4;
        padding: 3px 10px;
        border-radius: 10px;
        top: 5px;
        left: 5px;
        cursor: pointer;
        &:before{
            content: 'Убрать из новинок'
        }
    }
</style>

<style lang="scss">
    .action {
        color: #bbb;
    }
    .xxx:hover{
        box-shadow: 0px 0px 10px 7px rgba(230,230,230,0.75);
     }

</style>
