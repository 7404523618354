<script>
    import gql from "graphql-tag"
    import TuList from './TuList.vue'
    import Groups from './Groups.vue'
    //import EditItem from '@/components/EditItem.vue'
    import Pager from '@/components/Pager.vue'
    import _ from 'lodash'
    import CartMixin from './CartMixin'

    export default {
        name: 'StoreMixin',
        components: {
            TuList, Groups, Pager, //EditItem,
        },
        props: {
            user: Object,
            category: String,
            showmissing: Boolean,
            ppage: Number,
            availabilitySklad: String,
            q: String,
            scannedBarcode: String,
            type: String,
            pricetype: String,
        },
        mixins: [CartMixin],
        data: function(){
            return {
                itemModal: false,
                branch_id: null,
                placeholder: null,
                goods: [],
                producerId: null,
                tu: null,
                item: null,
                search: null,
                search_debounced: null,
                availability: true,
                //cartSummary: null,
                page: 1,
                isActive: true,
                activeSidebar: false,
                availabilitySkladX: '',
                name: null,
                skip: true,
            }
        },
        created(){
            this.search = this.q;
            this.search_debounced = this.q
            this.branch_id = this.category?parseInt(this.category):1;
            this.availability = !this.showmissing;
            this.availabilitySkladX = this.availabilitySklad;
            this.page = this.ppage==null?1:this.ppage;
            this.skip = false;
        },
        watch:{
            scannedBarcode: function (val) {
                if (val){
                    this.page=1;
                    this.search_debounced=val;
                    this.push();
                }
            },
            q: function(newVal, oldVal) { // eslint-disable-line no-unused-vars
                this.search = this.q;
                this.search_debounced = this.q
            },
            category: function(newVal, oldVal) { // eslint-disable-line no-unused-vars
                this.branch_id = this.category?parseInt(this.category):1;
            },
            showmissing: function(newVal, oldVal) { // eslint-disable-line no-unused-vars
                this.availability = !this.showmissing;
            },
            ppage: function(newVal, oldVal) { // eslint-disable-line no-unused-vars
                this.page = this.ppage==null?1:this.ppage;
            },
            availabilitySklad(newVal, oldVal) { // eslint-disable-line no-unused-vars
                this.availabilitySkladX = this.availabilitySklad;
            },
        },
            
        methods: {
            user_fetched(){
            },
            random_placeholder(){
                let ph=['Молоко', 'Колбаса', 'Квас', 'Пиво'];
                return ph[Math.floor(Math.random() * (ph.length)) ];
            },
            push(){
                this.$router.push(
                    {
                        //name: this.type=='pricelist'?'priceKirsa':'store',
                        query: {
                            category: ""+this.branch_id,
                            showmissing: !this.availability,
                            page: this.page,
                            q: this.search_debounced,
                            availabilitySklad: this.availabilitySkladX,
                        }
                    });
            },
            debounceInput: _.debounce(function (e) {
                this.page=1;
                this.search_debounced=e.target.value;
                this.push();
                //if (process.env.NODE_ENV=='production'){
                //    window.ym(83894410,'reachGoal','searchparam', {'query': this.search_debounced});
                //}
            }, 500),
            changepage(page){
                this.page=page;
                this.$refs.pager.rebuild(this.page);
                this.push();
            },
            select_group(name, id){
                this.page=1;
                this.search=null;
                this.search_debounced=null;
                if (this.tu) {
                    this.tu.count=0;
                    this.tu.items.splice(0);
                }
                this.name=name;
                this.branch_id=id;
                this.push();
            },
            /*group_select(item){
                this.page=1;
                this.search=null;
                this.search_debounced=null;
                this.tu.count=0;
                this.tu.items.splice(0);
                this.branch_item=item;
            },*/
            tu_refetch(){
                this.tu.count=null;
                this.tu.items.splice(0);
                this.$apollo.queries.tu.refetch();
            },
            flickoffGroups(){
                this.activeSidebar=false;
                document.getElementsByTagName('html')[0].classList.remove('noscroll-on-mobile');
            },
            getCartName(){
                return 'CART-ID';
            },
            getType() {
                return null;
            },
            getName() {
                return 'store';
            },
        },
        apollo: {
            goods: {
                query: gql`
                    query ($xtype: String) {
                        goods (xtype: $xtype) {id name parentId icon}
                    }`,
                variables() {
                    return {
                        xtype: this.getType(),
                    }
                },
                errorPolicy: 'all',
                result({data, loading}){
                    let finded = data.goods.find(el => el.id==this.branch_id)
                    this.name=finded?finded.name:(this.branch_id==1?'ВСЕ':'???')
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            tu: {
                query: gql`
                    query($search: String, $branchId: Int, $availability: Boolean!, $offset: Int, $availabilitySklad: Int, $qtype: String, $producerId: ID, $pricetype: String) {
                        tu(search: $search, branchId: $branchId, availability: $availability, offset: $offset, availabilitySklad: $availabilitySklad, qtype: $qtype, producerId: $producerId, pricetype: $pricetype) {
                            items{
                                id name nomer goodsId quantity price balance isWeight inbox quantityType img
                                images {id img}
                                packOrderOnly
                                weight description deliveryGroupId
                                alreadyOrdered
                            }
                            count
                        }
                    }`,
                variables() {
                    return {
                        search: this.search_debounced?this.search_debounced:this.q,
                        branchId: this.branch_id,
                        availability: this.availability,
                        offset: (this.page-1)*40,
                        availabilitySklad: parseInt(this.availabilitySkladX),
                        qtype: this.getType(),
                        producerId: this.producerId,
                        pricetype: this.pricetype,
                    }
                },
                skip() {
                    return this.skip;
                },
                result({data, loading}){

                    //lastimg.scrollIntoView({behavior: "smooth"});
                    window.scrollTo(
                        {
                            top: 56,
                            behavior: "smooth"
                        }
                    );
                    return data.tu.items.map(e=>{
                            e.valX = e.quantity;
                            return e
                        });
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        },
    }
</script>
