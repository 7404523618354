<template>
    <nav class="navbar is-link is-fixed-top" role="navigation" aria-label="main navigation" id="navbar">
        <div class="container">
            <div class="navbar-brand">
                <router-link to="/" class="navbar-item" v-if="checkGroups(user, ['Полный прайслист', 'Продавец СБИС', 'Продавец'])">Новинки</router-link>
                <router-link to="/reports/notdelivered/" class="navbar-item" v-if="user && !user.isAnonymous">
                    В доставке
                    <div class="in-delivery animate__bounce animate__bounce" v-if="notDeliveredCnt">{{notDeliveredCnt}}</div>
                    <!--div class="in-delivery-loading" v-if="notDeliveredCnt==null">
                    </div-->
                </router-link>

                <a role="button" class="navbar-burger burger" :class="{'is-active': isActive}" aria-label="menu"
                        aria-expanded="false" data-target="navbar-basic" @click="isActive=!isActive">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbar-basic" class="navbar-menu" :class="{'is-active': isActive}">
                <div class="navbar-start">
                    <div class="navbar-item" v-if="user && !user.isAnonymous">
                        <div class="navbar-item has-dropdown is-hoverable">
                            <p @click="isActive=false;" v-if="checkGroups(user, ['Заказ фруктов', 'Заказы', 'Полный прайслист', 'Продавец СБИС', 'Продавец', 'Оптовый клиент'])">
                                <router-link to="/store">
                                    <a class="navbar-link">
                                        Каталог
                                    </a>
                                </router-link>
                            </p>

                            <div class="navbar-dropdown">
                                <router-link to="/fast" class="navbar-item" v-if="checkGroups(user, ['Заказы', 'Продавец СБИС', 'Продавец'])">
                                    Хлеб
                                </router-link>
                                <router-link to="/fruits" class="navbar-item" v-if="checkGroups(user, ['Заказ фруктов', 'Продавец', 'Продавец СБИС'])">
                                    Фрукты
                                </router-link>
                                <!--router-link to="/store-kdv" class="navbar-item" v-if="true || checkGroups(user, ['Полный прайслист', 'Продавец СБИС', 'Продавец', 'Оптовый клиент'])">
                                    КДВ
                                </router-link-->
                            </div>

                        </div>
                    </div>
                    <div class="navbar-item" v-if="user && !user.isAnonymous && checkGroups(user, ['Приходы/Расходы', 'Отчет по задолженности'])">
                        <div class="navbar-item has-dropdown is-hoverable">
                            <p @click="isActive=false;" v-if="checkGroups(user, ['Приходы/Расходы', 'Отчет по задолженности'])">
                                <a class="navbar-link">
                                    Склад
                                </a>
                            </p>

                            <div class="navbar-dropdown">
                                <router-link to="/income-list" class="navbar-item" v-if="checkGroup(user,'Приходы/Расходы')">
                                    Приходные документы
                                </router-link>
                                <router-link to="/income" class="navbar-item" v-if="checkGroup(user,'Приходы/Расходы')">
                                    Приход
                                </router-link>
                                <router-link to="/outgo-list" class="navbar-item" v-if="checkGroup(user,'Приходы/Расходы')">
                                    Расходные документы
                                </router-link>
                                <router-link to="/outgo" class="navbar-item" v-if="checkGroup(user,'Приходы/Расходы')">
                                    Расход
                                </router-link>
                                <router-link to="/debitor-pay" class="navbar-item" v-if="checkGroup(user,'Приходы/Расходы')">
                                    Оплата
                                </router-link>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="navbar-end">
                    <div class="navbar-item" v-if="user && !user.isAnonymous">
                        <div class="navbar-item has-dropdown is-hoverable">
                            <a class="navbar-link">
                                Отчёты
                            </a>
                            <div class="navbar-dropdown">
                                <router-link to="/history" class="navbar-item" v-if="checkGroups(user, ['Заказы', 'Продавец СБИС', 'Продавец', 'Оптовый клиент'])">
                                    История заказов
                                </router-link>
                                <router-link to="/bonuses" class="navbar-item" v-if="user.isStaff">
                                    Просмотр бонусов
                                </router-link>
                                <router-link to="/reports/prediction" class="navbar-item" v-if="user.isStaff">
                                    Прогноз закупки
                                </router-link>
                                <router-link to="/reports/prediction-product" class="navbar-item" v-if="user.isStaff">
                                    Прогноз остатков
                                </router-link>
                                <router-link to="/reports/doc-list" class="navbar-item" 
                                    v-if="checkGroups(user,['Документы СБИС', 'Продавец СБИС'])">
                                    Документы СБИС
                                </router-link>
                                <router-link to="/reports/rashod-order/" class="navbar-item" v-if="checkGroup(user,'Отчет по накладным')">
                                    Накладные
                                </router-link>
                                <router-link to="/reports/stockman-orders/" class="navbar-item" v-if="checkGroup(user,'Заявки и накладные')">
                                    Заявки и накладные
                                </router-link>
                                <router-link to="/reports/notshipped/" class="navbar-item" v-if="checkGroup(user,'Склад Неотгруженно')">
                                    Накладные склада "Не отгруженно"
                                </router-link>
                                <router-link to="/reports/notdelivered-all-agents-list/" class="navbar-item" v-if="checkGroup(user,'Отчет - не доставленные накладные')">
                                    Не доставленные накладные
                                </router-link>
                                <router-link to="/reports/operators-productivity/" class="navbar-item" v-if="checkGroup(user,'Отчет по операторам')">
                                    Отчет по операторам
                                </router-link>
                                <router-link to="/reports/not3price/" class="navbar-item" v-if="checkGroup(user,'Отчет не установлена цена')">
                                    Отчет Не установлена цена
                                </router-link>
                                <router-link to="/reports/svod-bread/" class="navbar-item" v-if="checkGroup(user,'Оператор по хлебу')">
                                    Сводный отчет по хлебу
                                </router-link>
                                <router-link to="/kdv/" class="navbar-item" v-if="checkGroup(user,'Тестирование')">
                                    Разгрузка КДВ
                                </router-link>
                                <a href="/explorer/" target="_blank" class="navbar-item" v-if="checkGroups(user, ['explorer'])">Explorer</a>
                                <router-link to="/reports/outgo-with-income-price" class="navbar-item" v-if="checkGroup(user,'Отчет с ценами прихода')">
                                    Накладные с ценами прихода
                                </router-link>
                                <router-link to="/reports/debitors-reports" class="navbar-item" v-if="checkGroup(user,'Отчет по задолженности')">
                                    Отчет по задолженности
                                </router-link>
                                <router-link to="/reports/debet-pays-report" class="navbar-item" v-if="checkGroup(user,'Отчет по задолженности')" >
                                    Отчет по оплате
                                </router-link>
                                <router-link to="/reports/sum-report-fruits" class="navbar-item" v-if="checkGroup(user,'Проставление веса')" >
                                    Сводный отчет по заказу фруктов
                                </router-link>
                                <router-link to="/reports/revise-act" class="navbar-item" v-if="checkGroup(user,'Отчет по задолженности')" >
                                    Акт сверки
                                </router-link>
                                <router-link to="/reports/metabase1" class="navbar-item" v-if="checkGroup(user,'Тестирование')">
                                    Отчет для Анастасии
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="navbar-item" v-if="user && !user.isAnonymous">
                        <div class="navbar-item has-dropdown is-hoverable">
                            <a class="navbar-link">
                                Разное
                            </a>
                            <div class="navbar-dropdown">
                                <router-link to="/bread-matrix" class="navbar-item" v-if="checkGroup(user, 'Оператор по хлебу')">
                                    Хлеб-таблица
                                </router-link>
                                <router-link to="/dict/tu/" class="navbar-item" v-if="checkGroups(user, ['operator', 'Справочник товаров ReadOnly'])">
                                    Справочник товаров
                                </router-link>
                                <router-link to="/dict/agent-list/" class="navbar-item" v-if="checkGroup(user,'operator')">
                                    Справочник контрагентов
                                </router-link>
                                <router-link to="/recognize" class="navbar-item" v-if="checkGroups(user,['Распознавание', 'Продавец СБИС'])">
                                    Распознавание
                                </router-link>
                                <router-link to="/dict/add-income/" class="navbar-item" v-if="checkGroup(user,'operator')" >
                                    Постановка на приход
                                </router-link>
                                <router-link to="/fuelcard" class="navbar-item" v-if="checkGroup(user,'Топливные карты')" >
                                    Топливные карты
                                </router-link>
                                <router-link to="/egais-list" class="navbar-item" v-if="checkGroup(user,'ЕГАИС')" >
                                    ЕГАИС
                                </router-link>

                                <router-link to="/fruits-set-weight" class="navbar-item" v-if="checkGroup(user,'Проставление веса')" >
                                    Проставление веса
                                </router-link>

                                <router-link to="/support" class="navbar-item" v-if="checkGroup(user,'ЕГАИС')" >
                                    Техподдержка
                                </router-link>
                                <a href="/pos.html" class="navbar-item" v-if="!localback && checkGroup(user,'Касса')" >
                                    Касса
                                </a>
                                <a href="http://localhost/pos.html" class="navbar-item" v-if="localback && checkGroup(user,'Касса')" >
                                    Касса локально
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="navbar-item">
                        <div class="navbar-item has-dropdown is-hoverable" v-if="user && !user.isAnonymous">
                            <a class="navbar-link">
                                <svg class="icon-svg user">
                                    <use xlink:href="@/assets/sprite.svg#icon-human"></use>
                                </svg>
                                {{user.name}}
                            </a>
                            <div class="navbar-dropdown">
                                <router-link to="/options" class="navbar-item" >
                                    Настройки
                                </router-link>
                                <a class="navbar-item" href="/admin/" v-if="user.isStaff">
                                    Администрирование
                                </a>
                                <hr class="navbar-divider" v-if="user.isStaff">
                                <a class="navbar-item" @click="$emit('logout');isActive=false;">
                                    Выход
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</nav>
</template>

<script>
    export default {
        name: 'Navbar',
        components: {
        },
        props: {
            user: Object,
            notDeliveredCnt: Number,
            //user_fetching: false,
        },
        data(){
            return {
                isActive: false,
                localback: false,
            }
        },
        mounted(){
            this.localback = localStorage['localback']=='true'
        },
        updated() {
            document.getElementById('navbar').querySelectorAll('.has-dropdown').forEach(el => {
                el.addEventListener('click', () => {
                    this.isActive = false;
                    let menu = el.querySelector('.navbar-dropdown')
                    menu.style.display = 'none'
                    setTimeout(() => {
                        el.blur()
                        menu.style.display = ''
                    }, 200);
                })
            });
        },        
        methods: {
        }
    }
</script>

<style lang="scss" scope>
    @import "@/assets/_variables.scss";

    svg.user {
        height: 24px;
        width: 38px;
        fill: #fff;
    }

    @media screen and (max-width: $desktop) {
        svg.user {
            height: 20px;
            width: 38px;
            fill: hsl(217, 71%, 53%);
            vertical-align: middle;
            margin-bottom: 3px;
        }
    }

    .in-delivery{
        background: #d40000;
        border-radius: 10px;
        display: block;
        position: absolute;
        width: 22px;
        height: 22px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        right: 0;
        margin-top: -19px;
    }
    .in-delivery-loading{
        background: #dab900;
        border-radius: 10px;
        display: block;
        position: absolute;
        width: 22px;
        height: 22px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        right: 0;
        margin-top: -19px;
    }

</style>
