<template>
    <div class="container-x" v-if="user && !user.isAnonymous && user.isStaff">
        <section class="hero" style="margin: 0 1rem;">
            <h1>Прогноз закупок</h1>
            <!--div class="loading" v-if="$apollo.queries.prediction.loading"></div-->

            <!--button class="btn button" @click.stop="show()">Поставщики {{activeSidebar}}</button-->
            <!--a class="delete is-medium" @click.stop="show()" v-if="!activeSidebar"></a-->


            <ul class="agent-list" style1="position: fixed; top: 63px; bottom: 0; overflow-y: auto; width: 320px;">
                <li class="sklad" :class="{'is-active': skladId==1}"><router-link to="/reports/prediction/1/">Кирса</router-link></li>
                <li class="sklad" :class="{'is-active': skladId==3}"><router-link to="/reports/prediction/3/">Авт. 8г</router-link></li>
                <li class="sklad" :class="{'is-active': skladId==11}"><router-link to="/reports/prediction/11/">Авт. 18е</router-link></li>
                <!--li
                    v-for="agent in ((prediction && prediction.agents)? prediction.agents:[])"
                    :key="agent.id"
                    @click="scrollToAgent(agent.id)"
                >
                    {{agent.name}}
                </li-->
            </ul>


            <div class="tabs is-boxed no-print" style="margin-top: 1rem;">
                <ul>
                    <li :class="{'is-active': tab==1}">
                        <a @click="tab=1">Прогноз</a>
                    </li>
                    <li :class="{'is-active': tab==2}" v-if="skladId!=1">
                        <a @click="tab=2">Налич. Кирса</a>
                    </li>
                    <li :class="{'is-active': tab==3}" v-if="skladId!=3">
                        <a @click="tab=3">Налич. Авт.8г</a>
                    </li>
                    <li :class="{'is-active': tab==4}" v-if="skladId!=11">
                        <a @click="tab=4">Налич. Авт.18е</a>
                    </li>
                </ul>
            </div>        

            <div v-show="tab==1">
                <div
                        v-for="agent in ((prediction && prediction.agents)? prediction.agents:[])"
                        :key="agent.id"
                        :id="'agent-' + agent.id">
                    <h2>{{agent.name}}</h2>
                    <div style="margin-bottom: 3rem;">

                        <!--div
                                :key="product.id"
                                v-for="product in agent.products"
                                style="display: flex;"
                            >

                            <div>{{product.nomer}}</div>
                            <div>
                                <router-link :to="'/reports/product-detail/?tu_id=' + product.id + '&skladId=' + skladId">
                                    {{product.name}}
                                </router-link>
                            </div>
                            <div>{{product.balance}}</div>
                            <div>{{Number(product.expenseWeek).toFixed(2)}}</div>
                        </div-->

                        <table class="products">
                            <tr>
                                <th>Номер</th>
                                <th>Наименование</th>
                                <th>Остаток</th>
                                <th>В упак</th>
                                <th>Расх.нед.</th>
                                <th><router-link :to="'/reports/prediction/'+skladId+'/'+agent.id+'/week/'">Рек.нед.</router-link></th>
                                <th><router-link :to="'/reports/prediction/'+skladId+'/'+agent.id+'/month/'">Рек.мес.</router-link></th>
                                <th><router-link :to="'/reports/prediction/'+skladId+'/'+agent.id+'/2month/'">Рек.2мес.</router-link></th>
                                <th>Дата прихода</th>
                                <th>Цена прихода</th>
                            </tr>
                            <tr
                                :key="product.id"
                                v-for="(product, idx) in agent.products">
                                <td>{{product.nomer}}</td>
                                <td style="vertical-align: -webkit-baseline-middle;">
                                    <router-link :to="'/reports/product-detail/?tu_id=' + product.id + '&skladId=' + skladId">
                                        {{product.name}}
                                    </router-link>
                                    <!--div class="dropdown" @click="dropdown(product.id)" :class="{'is-active': dropdownItemId==product.id}">
                                        <div class="dropdown-trigger">
                                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" style="height: 32px;">
                                                <span style="padding-right: .5rem;">Исключить</span>
                                                <font-awesome-icon icon="angle-down"/>
                                            </button>
                                        </div>
                                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                            <div class="dropdown-content">
                                                <a class="dropdown-item" @click="ignore(product.id, skladId, agent.products, idx, 1)">
                                                    На 1 день
                                                </a>
                                                <a class="dropdown-item" @click="ignore(product.id, skladId, agent.products, idx, 7)">
                                                    На неделю
                                                </a>
                                                <a class="dropdown-item" @click="ignore(product.id, skladId, agent.products, idx, 30)">
                                                    На месяц
                                                </a>
                                                <hr class="dropdown-divider">
                                                <a class="dropdown-item" @click="ignore(product.id, skladId, agent.products, idx, 0)">
                                                    Навсегда
                                                </a>
                                            </div>
                                        </div>
                                    </div-->
                                </td>
                                <td>{{product.balance}}</td>
                                <td>{{product.inbox}}</td>
                                <td>{{Number(product.expenseWeek).toFixed(2)}}</td>
                                <td>
                                    {{Number(product.week).toFixed(2)}}
                                    <span v-if="product.inbox>0" class="package">({{Math.round(product.week/product.inbox)}} уп.)</span>
                                </td>
                                <td>
                                    {{Number(product.month).toFixed(2)}}
                                    <span v-if="product.inbox>0" class="package">({{Math.round(product.month/product.inbox)}} уп.)</span>
                                </td>
                                <td>
                                    {{Number(product.twomonth).toFixed(2)}}
                                    <span v-if="product.inbox>0" class="package">({{Math.round(product.twomonth/product.inbox)}} уп.)</span>
                                </td>
                                <td>{{product.incomeDate|date-ddmmyy}}</td>
                                <td>{{product.incomeCena|currency}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div v-show="tab==2" v-if="skladId=='3' || skladId=='11'">
                <h2>В наличии на складе Кирса</h2>
                <PredictionBlock :predictionKirsa="predictionKirsa" :skladId="skladId"></PredictionBlock>
            </div>

            <div v-show="tab==3" v-if="skladId=='1' || skladId=='11'">
                <h2>В наличии на складе Автомобилистов 8г</h2>
                <PredictionBlock :predictionKirsa="predictionAvt8" :skladId="skladId"></PredictionBlock>
            </div>

            <div v-show="tab==4" v-if="skladId=='1' || skladId=='3'">
                <h2>В наличии на складе Автомобилистов 18е</h2>
                <PredictionBlock :predictionKirsa="predictionAvt18" :skladId="skladId"></PredictionBlock>
            </div>

            <div>
                <ul class="product-by-agent-list">
                    <!--li class="sklad"><router-link to="/reports/prediction/1/">Склад Кирса</router-link></li>
                    <li class="sklad"><router-link to="/reports/prediction/3/">Склад Автомобилистов 8г</router-link></li>
                    <li class="sklad"><router-link to="/reports/prediction/11/">Склад Автомобилистов 18е</router-link></li>
                    <li class="sklad"><router-link to="/reports/prediction/8/">Склад Белорецк</router-link></li-->



                </ul>
                <!--div class="abs" :class="{'active': activeSidebar}" v-click-outside="hide">
                    <ul class="agent-list" style1="position: fixed; top: 63px; bottom: 0; overflow-y: auto; width: 320px;">
                        <li class="sklad"><router-link to="/reports/prediction/1/">Склад Кирса</router-link></li>
                        <li class="sklad"><router-link to="/reports/prediction/3/">Склад Автомобилистов 8г</router-link></li>
                        <li class="sklad"><router-link to="/reports/prediction/11/">Склад Автомобилистов 18е</router-link></li>
                        <li class="sklad"><router-link to="/reports/prediction/8/">Склад Белорецк</router-link></li>
                        <li
                            v-for="agent in ((prediction && prediction.agents)? prediction.agents:[])"
                            :key="agent.id"
                            @click="scrollToAgent(agent.id)"
                        >
                            {{agent.name}}
                        </li>
                    </ul>
                </div-->
            </div>
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"
    import Sidebar from '@/components/Sidebar'
    import PredictionBlock from './PredictionBlock.vue'

    export default {
        name: 'ReportPrediction',
        components: {
            PredictionBlock,
        },
        mixins: [Sidebar,],
        props: [
            'user', 'skladId'
        ],
        methods: {
            dropdown(id) {
                this.dropdownItemId = this.dropdownItemId == id ? null : id;
            },
            scrollToAgent(refName) {
                let element = document.getElementById('agent-' + refName);
                window.scrollTo(
                    {
                        top: element.offsetTop-56,
                        behavior: "smooth"
                    }
                );
                this.hide();
            },
            ignore(id, skladId, arr, idx, days){
                this.$apollo.mutate({
                    mutation: gql`
                        mutation($id: ID!, $sklad: ID!, $days: Int!) {
                            ignore(tu: $id, sklad: $sklad, days: $days) { ok }
                        }`,
                    variables: {
                        "id": id,
                        "sklad": skladId,
                        "days": days,
                    },
                }).then(data => {
                    arr.splice(idx, 1);
                }).catch(error => {
                    this.$emit('error', error);
                });
            },
        },
        data(){
            return {
                dropdownItemId: null,
                tab: 1,
            }
        },
        apollo: {
            prediction: {
                query: gql`
                    query($skladId: ID!, $skladOutId: [ID]!, $agentId: ID){
                        prediction(skladId: $skladId, skladOutId: $skladOutId, agentId: $agentId) {
                            agents{
                                id name
                                products{
                                    id nomer name balance expenseWeek week month twomonth incomeCena incomeDate
                                    inbox
                                }
                            }
                        }
                    }`,
                variables() {
                    return {
                        agentId: this.agent_id,
                        skladId: this.skladId,
                        skladOutId: this.skladId=='1'?[3,11]:(this.skladId=='3'?[1,11]:[1,3]),
                    }
                },
                skip(){
                    return this.skladId==null;
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            predictionKirsa: {
                query: gql`
                    query($skladId: ID!, $skladOutId: ID!){
                        predictionKirsa: predictionKirsa(skladId: $skladId, skladOutId: $skladOutId){
                            id nomer name balance balanceOther expenseWeek week month twomonth incomeCena incomeDate inbox
                        }
                    }`,
                skip(){
                    return this.skladId==null || parseInt(this.skladId)==1;
                },
                variables() {
                    return {
                        skladId: this.skladId,
                        skladOutId: 1,
                    }
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            predictionAvt8: {
                query: gql`
                    query($skladId: ID!, $skladOutId: ID!){
                        predictionAvt8: predictionKirsa(skladId: $skladId, skladOutId: $skladOutId){
                            id nomer name balance balanceOther expenseWeek week month twomonth incomeCena incomeDate inbox
                        }
                    }`,
                skip(){
                    return this.skladId==null || parseInt(this.skladId)==3;
                },
                variables() {
                    return {
                        skladId: this.skladId,
                        skladOutId: 3,
                    }
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            predictionAvt18: {
                query: gql`
                    query($skladId: ID!, $skladOutId: ID!){
                        predictionAvt18: predictionKirsa(skladId: $skladId, skladOutId: $skladOutId){
                            id nomer name balance balanceOther expenseWeek week month twomonth incomeCena incomeDate inbox
                        }
                    }`,
                skip(){
                    return this.skladId==null || parseInt(this.skladId)==11;
                },
                variables() {
                    return {
                        skladId: this.skladId,
                        skladOutId: 11,
                    }
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/_variables.scss";
    h1 {
        font-weight: bold;
        font-size: 115%;
    }

    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    .delete{
        position: absolute;
        right: calc(100% + -32px);
        top: calc(50% - 32px);
        display: none;
        height: 64px !important;
        width: 64px !important;
        max-width: inherit !important;
        max-height: inherit !important;
        background: #e8e8e8 !important;
        border: 1px solid #7d7d7d;
        &:before, &:after {
            background-color: #444;
        }
    }
    table.products{
        td,th {
            padding: 2px 5px;
            border:1px dotted #000000;
        }
        th {
            font-size: 85%;
            background: #076198;
            color: #fff;
            vertical-align: middle;
        }
        td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6), td:nth-child(7), td:nth-child(8), td:nth-child(10){
            text-align: right;
        }
        th:nth-child(6),th:nth-child(7),th:nth-child(8),th:nth-child(9){
            text-align: center;
            a {
                color: yellow;
            }
        }
        border-collapse: collapse;
        border:1px solid #69899F;
    }
    li.sklad {
        font-weight: bold;
    }
    span.package {
        white-space: nowrap;
    }
    ul.agent-list{
          list-style: none;

        li {
            display: inline;
            padding: 3px 10px 3px;
            cursor: pointer;
            &:hover {
                color: #448cff
            }
        }
        li.active{
            background-color: #076198;
            padding: 10px;
            a{
                color: #fff;
            }
        }
    }

    .abs{
        position: fixed;
        top: 56px;
        bottom: 0;
        max-width: 320px;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        overflow-y: auto;
    }
    .product-by-agent-list{
        margin-left: 330px;
        .sklad {
            display: none;
        }
    }
    .agent-list-button {
        display: none;
    }
    @media screen and (max-width: $tablet) {
        .product-by-agent-list{
            margin-left: 0px;
            .sklad {
                display: block;
            }
        }
        .agent-list-button {
            display: block;
            background: #eaeaea;
            display: block;
            width: 54px;
            height: 54px;
            border-radius: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border: 1px solid #ccc;

        }
        .delete{
            display: block !important;
        }
        .abs{
            top: 0px;
            z-index: 100;
            left: 100%;
            width: 85%;
            transition: all .5s ease;
            box-shadow: 0 0 0 0 #fff !important;
        }
    }
    .ignore {
        cursor: pointer;
        font-size: 90%;
        border-bottom: 1px dashed #3e3e3e;
    }
</style>

