<template>
    <div>
        <img src="@/assets/loading.svg" v-show="loading" class="loading-fixed">
        <div class="columns is-multiline is-mobile items">
            <tu-list-item-grid
                :item="item"
                v-for="item in tu"
                @modcart="$emit('modcart', arguments[0], arguments[1], arguments[2])"
                @setEditMode="$emit('setEditMode', arguments[0])"
                class="item-card column is-one-quarter-desktop is-one-third-tablet is-half-mobile"
                :type="type"
                @removeItem="$emit('removeItem', arguments[0])"
                :key="item.id"
                />
        </div>
    </div>
</template>

<script>
    import TuListItemGrid from './ListItem.vue'

	export default {
		name: 'TuListGrid',
		props: ['tu', 'loading', 'user', 'type', ],
        components: {TuListItemGrid, },
        methods: {
        }
	}
</script>
