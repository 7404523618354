<template>
    <div :set="waybill=ed['ns:Documents']['ns:Document']['ns:WayBill_v4']" class="container" v-if="ed">
        <h1 class="title">Товарно-транспартная накладная ЕГАИС</h1>


        <TitleWithCheckModal :isActive='mode=="scanBarcode"' :type="'attention'" @close="closeScanBarcode">
            <h1>{{scanningName}}</h1>
            <h2>Объем: {{capacity}}</h2>
            <h2>Креп.: {{volume}}</h2>
            <h1>{{scannedBarcode}}</h1>
            <p style="margin: 1rem 0;">Отсканируйте штрих-код</p>
            <div class="field is-grouped" style="padding: .5rem 0;">
                <p class="control">
                    <button class="button" @click="registerTu" :disabled="registerDisabled(scannedBarcode)">Зарегистрировать</button>
                </p>
                <p class="control">
                    <button class="button" @click="closeScanBarcode">Отмена</button>
                </p>
            </div>
        </TitleWithCheckModal>

        <div :set="header = waybill[wb+':Header']">
            <h1>Номер документа: {{header[wb+':NUMBER']}}</h1>
            <h2>Дата: {{header[wb+':Date']}}</h2>
            <h2>Дата доставки: {{header[wb+':ShippingDate']}}</h2>

            <div :set="shipper=header[wb+':Shipper'][oref+':UL']" class="b1">
                <h3>Поставщик: {{shipper[oref+':FullName']}}</h3>
                <div style="margin-left: 4rem;">ИНН: {{shipper[oref+':INN']}}</div>
                <div style="margin-left: 4rem;">Адрес: {{shipper[oref+':address'][oref+':description']}}</div>
            </div>

            <div class="b1">
                <h3>Получатель: {{header[wb+':Consignee'][oref+':UL'][oref+':FullName']}}</h3>
                <div style="margin-left: 4rem;">Адрес: {{header[wb+':Consignee'][oref+':UL'][oref+':address'][oref+':description']}}</div>
            </div>
            <h3>Примечание: {{header[wb+':Note']}}</h3>
        </div>
        <div :set="content = waybill[wb+':Content']">
            <ul class="positions">
                <ol v-for="pos in Array.isArray(content[wb+':Position'])?content[wb+':Position']:[content[wb+':Position']]" :key="pos[wb+':Identity']" :set="product=pos[wb+':Product']" 
                    @click="scanBarcode(pos)"
                     :class="{'ok':pos['ok']===true, 'checking':!('ok' in pos), 'not-ok':pos['ok']===false}">

                    <div class="name">
                        <span>{{pos[wb+':Identity']}}</span> &nbsp; <span style="font-size: 115%;">{{product[pref+':FullName']}}</span>
                        <div>
                            Алк.код: {{product[pref+':AlcCode']}}
                        </div>
                        <div>Обьем: {{product[pref+':Capacity']}}</div>
                        <div>Крепкость: {{product[pref+':AlcVolume']}}</div>
                    </div>
                    <div class="price">{{pos[wb+':Price']|currency}}</div>
                    <div class="quantity">{{pos[wb+':Quantity']}}</div>
                </ol>
                <ol>
                    <div class="name">Итого: </div>
                    <div class="price">{{total|currency}}</div>
                </ol>
            </ul>
        </div>
        <button class="button is-primary" :class="{'is-loading': sending}" 
            @click="makeIncome" :disabled="isMakeIncomeDisabled(ed) || buttonPressed || (egaisWaybillStatus && egaisWaybillStatus.status=='Заявка принята')">Принять накладную</button>
        <div style="margin-top: 20px;"></div>
        Статус: <span v-if="egaisWaybillStatus">{{egaisWaybillStatus.status}}</span>

    </div>
</template>

<script>
    import gql from "graphql-tag"
    import TitleWithCheckModal from '@/components/TitleWithCheckModal'
    function getNamespace(obj, ns){
        for (let prop in obj){
            if (obj[prop]==ns){
                return prop.replace(/@xmlns:/g, '')
            }
        }
    }
    export default {
        components: {TitleWithCheckModal,},
        props: {
            id: String,
            user: Object,
            scannedBarcode: String,
        },
        data() {
            return {
                ed: null,
                wbregId: null,
                fsrarId: null,
                sending: false,
                codes: Array(),
                mode: null,
                scanningName: null,
                capacity: null,
                vcode: null,
                alcoCode: null,
                volume: null,
                total: null,
                buttonPressed: false,
                oref: null,
            }
        },
        created(){
            setInterval(() => {
                this.$apollo.queries.egaisWaybillStatus.refetch();
            }, 1000*15);
        },
        methods:{
            registerDisabled(code){
                return /^\d{13}$/gm.exec(code)==null
            },
            isMakeIncomeDisabled(ed){
                if (this.shipper && this.shipper[this.oref+':FullName'] && !this.shipper[this.oref+':FullName'].includes("АгроТрейд")){
                    return false;
                }
                let positions = ed['ns:Documents']['ns:Document']['ns:WayBill_v4'][this.wb+':Content'][this.wb+':Position']
                if (!Array.isArray(positions))
                    positions = [positions]
                if (!positions || positions.length==0)
                    return true
                for (const p of positions){
                    if (p['ok']!==true)
                        return true
                }
                return false
            },
            scanBarcode(pos){
                if (pos['ok']!==false)
                    return
                console.log(pos)
                this.mode='scanBarcode'
                const product = pos[this.wb+':Product']
                this.scanningName = product[this.pref+':FullName']
                this.alcoCode = product[this.pref+':AlcCode']
                this.capacity = product[this.pref+':Capacity']
                this.volume = product[this.pref+':AlcVolume']
                this.vcode = product[this.pref+':ProductVCode']
                this.price = pos[this.wb+':Price']
            },
            closeScanBarcode(){
                this.mode = null
                this.scannedBarcode = null
            },
            registerTu(){
                this.$apollo.mutate({
                    mutation: gql`mutation($alcoCode: String!, $name: String!, $capacity: Decimal!, $volume: Int!, $price: Decimal!, $barcode: String!, $vcode: String) {
                        egaisCreateTua(alcoCode: $alcoCode, name: $name, capacity: $capacity, volume: $volume, price: $price, barcode: $barcode, vcode: $vcode) { ok }
                    }`,
                    variables: {
                        alcoCode: this.alcoCode,
                        name: this.scanningName,
                        capacity: this.capacity,
                        volume: parseInt(this.volume),
                        price: this.price,
                        barcode: this.scannedBarcode,
                        vcode: this.vcode,
                    },
                }).then(data => {
                    this.sending=false;
                    this.error = null;
                    this.data=null;
                    this.scannedBarcode=null
                    this.alcoCode=null
                    this.mode=null
                    this.$apollo.queries.checkAlcoCodes.refetch();
                    /*
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                });

            },
            makeIncome(){
                this.sending=true;
                this.buttonPressed = true;
                this.$apollo.mutate({
                    mutation: gql`mutation($skladId: ID!, $fsrarId: String!, $wbregId: String!) {
                        egaisAct4(skladId: $skladId, fsrarId: $fsrarId, wbregId: $wbregId) { ok }
                    }`,
                    variables: {
                        skladId: 30,
                        fsrarId: this.fsrarId,
                        wbregId: this.wbregId,
                    },
                }).then(data => {
                    this.sending=false;
                    this.error = null;
                    this.data=null;
                    this.$apollo.queries.egaisWaybillStatus.refetch();
                    /*
                    this.error=null;
                    this.amount=null;
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                    }, 3200);
                    this.$apollo.queries.auditFacts.refetch();*/
                }).catch(error => {
                    this.sending=false;
                    this.error=error;
                    this.buttonPressed = false;
                });
            },
        },
        apollo: {
            egaisWaybillStatus:{
                query: gql`
                    query($wbregId: ID!){
                        egaisWaybillStatus(wbregId: $wbregId){
                            status
                        }
                    }`,
                variables() {
                    return {
                        wbregId: this.wbregId,
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                    }
                },
                skip(){
                    return this.wbregId==null;
                },
                errorPolicy: 'all',
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
            checkAlcoCodes: {
                query: gql`
                    query($codes: [ID]){
                        checkAlcoCodes(codes: $codes){
                            tu {id barcodes{barcode}} alcocode
                        }
                    }`,
                variables() {
                    return {
                        codes: this.codes,
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                        let total = 0
                        let positions = this.ed['ns:Documents']['ns:Document']['ns:WayBill_v4'][this.wb+':Content'][this.wb+':Position']
                        if (!Array.isArray(positions))
                            positions=[positions]
                        for (const p of positions){
                            let finded=false
                            console.log(p)
                            total += parseFloat(p[this.wb+':Price']) * parseFloat(p[this.wb+':Quantity'])
                            for (const x of data.checkAlcoCodes){
                                if (p[this.wb+':Product'][this.pref+':AlcCode']==x['alcocode']){
                                    this.$set(p, 'ok', true)
                                    finded=true
                                    break;
                                }
                            }
                            if (!finded)
                                this.$set(p, 'ok', false)
                            this.total = total
                        }
                    }
                },
                skip(){
                    return this.codes.length==0;
                },
                errorPolicy: 'all',
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
            egaisDocument: {
                query: gql`
                    query($id: ID!){
                        egaisDocument(id: $id){
                            json wbregId fsrarId
                        }
                    }`,
                variables() {
                    return {
                        id: this.id,
                    }
                },
                result ({ data, loading }) {
                    if (!loading) {
                        this.ed = JSON.parse(JSON.parse(data.egaisDocument.json));
                        this.wb = getNamespace(this.ed['ns:Documents'], 'http://fsrar.ru/WEGAIS/TTNSingle_v4')
                        this.oref = getNamespace(this.ed['ns:Documents'], 'http://fsrar.ru/WEGAIS/ClientRef_v2')
                        this.pref = getNamespace(this.ed['ns:Documents'], 'http://fsrar.ru/WEGAIS/ProductRef_v2')
                        this.wbregId = data.egaisDocument.wbregId;
                        this.fsrarId = data.egaisDocument.fsrarId;
                        console.log(data.egaisDocument.wbregId)
                        let positions = this.ed['ns:Documents']['ns:Document']['ns:WayBill_v4'][this.wb+':Content'][this.wb+':Position']
                        if (!Array.isArray(positions))
                            positions=[positions]
                        for (var p of positions){
                            this.codes.push(p[this.wb+':Product'][this.pref+':AlcCode'])
                        }

                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }, 
    }
</script>

<style lang="scss" scoped>
    .b1 {
        margin: 0.5rem 0 1.5rem;
    }
    .positions{
        margin-top: 2rem;
        ol {
            padding-bottom: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;            
            .name{
                flex: 1;
                span {
                    color: #000;
                }
            }
            .price{
                width: 120px;
                font-size: 130%;
                text-align: right;
            }
            .quantity{
                width: 120px;
                font-size: 150%;
                text-align: right;
            }
        }
        ol.ok{
            .name span{
                color: green;
            }
        }
        ol.not-ok{
            cursor: pointer;
            border-radius: 10px;
            padding: 10px;
            background: #ffd5ae;
            margin: -10px;
            margin-bottom: 20px;            
            .name span{
                color: red;
            }
        }
    }
</style>
