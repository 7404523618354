<template>
    <div v-if="user && checkGroups(user, ['Расход изменение', 'Расход создание', 'Расход просмотр'])" class="container">
        <loading v-if="$apollo.queries.outgo.loading"></loading>
        <section class="no-print">
            <h1 class="title">Расход {{ itemId }}</h1>
            <CheckboxSlider @input="editMode=!editMode" :checked="editMode" :disabled="checkEditable || itemId==null || typeof itemId === 'undefined'"
                    style="position:absolute;right:0; top:0;">
                <span v-if="typeof itemId === 'undefined'">Создание</span>
                <span v-else>Редактирование</span>
            </CheckboxSlider>
            <div class="columns is-multiline">
                <div class="column is-half-tablet is-one-third-desktop is-full-mobile">
                    <label class="label">Дата:</label>
                    <date-picker 
                        v-model="incomeDate" 
                        class="deliverydate"
                        valueType="format" 
                        :first-day-of-week="1" 
                        lang="ru" 
                        format="DD.MM.YYYY" 
                        :not-before="new Date()" 
                        placeholder="Дата доставки"
                        :disabled="!editMode"
                        :not-after="(new Date()).setDate((new Date()).getDate() + 6)"></date-picker>
                </div>

                <div class="column is-half-tablet is-one-third-desktop isa-full-mobile">
                    <label class="label">Организация:</label>
                    <v-select :options="hosts || []" v-model="host" :disabled="!editMode"></v-select>
                </div>
                <div class="column is-half-tablet is-one-third-desktop is-full-mobile">
                    <label class="label">Склад:</label>
                    <v-select :options="sklads || []" v-model="sklad" :disabled="!editMode"></v-select>
                </div>
                <div class="column is-half-tablet is-one-third-desktop is-full-mobile">
                    <label class="label">Получатель:</label>
                    <treeselect-agent 
                        @select="agentId=parseInt(arguments[0].id)" 
                        :initOptions="initOptions" 
                        :initAgent="agent"
                        :disabled="!editMode"
                        @error="$emit('error', arguments[0])"
                         ></treeselect-agent>
                </div>
                <div class="column is-half-tablet is-one-third-desktop is-full-mobile">
                    <label class="label">Основание:</label>
                    <v-select :options="reasons || []" v-model="reason" :disabled="!editMode"></v-select>
                </div>
                <div class="column is-half-tablet is-one-third-desktop is-full-mobile">
                    <label class="label">Ответственный:</label>
                    <v-select :options="answers" v-if="answers" v-model="answer" :disabled="!editMode"></v-select>
                </div>
            </div>
            
            <h2 class="title">Спецификация</h2>
            <CheckboxSlider @input="groupMode=!groupMode" :checked="groupMode">
                Группировать
            </CheckboxSlider>


            <table class="report outgo-list" v-if="!editMode">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Приход</th>
                        <th>Код</th>
                        <th>Наименование</th>
                        <th>Кол-во</th>
                        <th>Уп</th>
                        <th>Цена</th>
                        <th>Скидка</th>
                        <th>Сумма</th>
                        <th>Наценка</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in groupMode?groupedList:list" :key="item.id" class="hover" :class="{'deleted': item.deleted}">
                        <td style="text-align: right;">{{ index+1 }}</td>
                        <td><router-link :to="'/income/'+item.incomeId">{{item.incomeId}}</router-link>/{{item.incomeposId}}</td>
                        <td>{{item.code}}</td>
                        <td>
                            <router-link :to="'/dict/tu/'+item.tuId">
                                {{item.name}}
                            </router-link>
                        </td>
                        <td style="text-align: right;">
                            {{ parseFloat(item.amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}) }}
                        </td>
                        <td style="text-align: right;">
                            {{ item.amountInbox }}
                        </td>
                        <td style="text-align: right;">
                            {{ item.price|currency-null2 }}
                        </td>
                        <td style="text-align: right;">
                            {{ item.discount }}
                        </td>
                        <td style="text-align: right;">
                            {{ item.sum|currency-null2 }}
                        </td>
                        <td style="text-align: right;">
                            {{(100*(item.price*(item.discount?(1-(item.discount/100)):1)/item.incomePosPrice-1)).toFixed(1)}}%
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="6"></td>
                        <td colspan="3" style="text-align:right; font-weight: bold;">Итого: {{total()|currency}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="6"></td>
                        <td colspan="3" style="text-align:right; font-weight: bold;">Оплачено: {{outgo?paymentSum(outgo.paymentSet):0|currency}}</td>
                        <td></td>
                    </tr>

                </tfoot>
            </table>


            <table class="report outgo-list" v-if="editMode">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Код</th>
                        <th>Наименование</th>
                        <th>Кол-во</th>
                        <th>Уп</th>
                        <th>Цена</th>
                        <th>Скидка</th>
                        <th>Сумма</th>
                        <th>Наценка</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="item.id" class="hover" :class="{'deleted': item.deleted}">
                        <td>{{ index+1 }}</td>
                        <td>{{item.code}}</td>
                        <td>
                            <router-link :to="'/dict/tu/'+item.tuId">
                                {{item.name}}
                            </router-link>
                        </td>
                        <td>
                            <currency-input v-model="item.amount" class="input" @change="itemAmountChange(item)"
                                :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: (item.isWeight || item.amount%1!=0)?3:0, locale: 'ru'}"
                                style="width: 90px; text-align: right;" />
                        </td>
                        <td>
                            <currency-input v-model="item.amountInbox" class="input" @change="itemAmountInboxChange(item)"
                                :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 0, locale: 'ru'}"
                                style="width: 60px; text-align: right;" />
                        </td>
                        <td>
                            <currency-input v-model="item.price" class="input" @change="itemPriceChange(item)"
                                :options="{ currency: 'RUB', locale: 'ru'}" style="width: 90px; text-align: right;"/>    
                        </td>
                        <td>
                            <currency-input v-model="item.discount" class="input" @change="itemDiscountChange(item)"
                                :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 1, locale: 'ru'}" style="width: 60px; text-align: right;"/>    
                        </td>
                        <td style="text-align: right;">
                            <currency-input v-model="item.sum" class="input" @change="itemSumChange(item)"
                                :options="{ currency: 'RUB', locale: 'ru'}" style="width: 120px; text-align: right;"/>    
                        </td>
                        <td>{{(100*(item.price*(item.discount?(1-(item.discount/100)):1)/item.incomePosPrice-1)).toFixed(1)}}%</td>
                        <td>
                            <span class="action" @click="deleteItem(item)" v-if="!item.deleted">Удалить</span>
                            <span class="action" @click="restoreItem(item)" v-if="item.deleted">Восстановить</span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="6"></td>
                        <td colspan="3" style="text-align:right; font-weight: bold;">Итого: {{total()|currency}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="6"></td>
                        <td colspan="3" style="text-align:right; font-weight: bold;">Оплачено: {{outgo?paymentSum(outgo.paymentSet):0|currency}}</td>
                        <td></td>
                    </tr>

                </tfoot>
            </table>
            <TitleWithCheckModal :isActive="submitted">
                <div class="text">
                    Расходный документ сохранен
                    <div class="field" style="padding: .5rem 0;">
                        <button class="button" @click="submitted=false;">Продолжить</button>
                    </div>
                </div>
            </TitleWithCheckModal>

            <div class="field">
                <div class="control tu-items">
                    <treeselect 
                        :multiple="false" 
                        :async="true"
                        @select="itemSelect"
                        :always-open="false"
                        :normalizer="tuListNormalizer"
                        :clearOnSelect="true"
                        :cacheOptions="false"
                        placeholder="Выберите товар..."
                        searchPromptText="Начните ввод для поиска"
                        noResultsText="Товар не найден"
                        loadingText="Загрузка данных"
                        :load-options="loadOptions" >
                        
                        <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
                            <div class="name">{{ node.label[0] }}</div>
                            <div class="price">{{node.label[1]|currency-null2}}</div>
                            <div class="balance">{{node.label[2]}}</div>
                        </label>

                        <label slot="value-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
                            <div class="name">{{ node.label[0] }}</div>
                            <div class="price">{{node.label[1]|currency-null2}}</div>
                            <div class="balance">{{node.label[2]}}</div>
                        </label>
                    </treeselect>
                </div>
            </div>

            <part-select :item="selectedItem" :skladId="sklad?sklad.code:null" @close="closePartSelect"></part-select>
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-primary" @click="save" 
                        :disabled="checkEmpty || !agentId || !reason || !answer || !sklad || !host || !editMode || checkEditable"
                        :class="{'is-loading': sending}"
                        >
                        Сохранить
                    </button>
                </div>
                <div class="control">
                    <a class="button" @click="$emit('print')">Печать</a>
                </div>
                <div class="control">
                    <a class="button" @click="printCheck">Печать чека</a>
                </div>
                <div class="control">
                    <button class="button" @click="goPayment" :disabled="!itemId || !outgo"
                        :class="{'is-loading': false}"
                        >
                        Оплата
                    </button>
                </div>
                <div class="control">
                    <button class="button is-danger" @click="remove" :disabled="!itemId || !outgo || !editMode || checkEditable"
                        :class="{'is-loading': sendingDelete}">
                        Удалить
                    </button>
                </div>
            </div>
            <div style="margin-top:3rem" v-if="itemId">
                <a :href="'/torg12_pdf/'+itemId">Торг12</a>
            </div>

        </section>

        <div class="modal modal-fx-fadeInScale no-print" :class="{'is-active': showPayment}">
            <div class="modal-background" @click="showPayment=false"></div>
            <div class="modal-card" style="background: #fff;">
                <cash-in :agent="{id: agentId, name: agentName()}" 
                    :startAmount="+(total() - (outgo?paymentSum(outgo.paymentSet):0)).toFixed(2)"
                    :outgoId="itemId"
                    @close="showPayment=false"
                    v-if="showPayment"></cash-in>
            </div>
        </div>

        <div class="print-only">
            <div style="display: flex; justify-content: space-between;">
                <div>{{host?host.label:''}}</div>
                <div>{{answer?answer.label:''}}</div>
            </div>
            <div style="text-align: center; font-size: 110%;">Накладная № {{itemId}} от {{incomeDate}}</div>
            <div>Получатель: <span style="font-weight: bold;">{{agentName()}}</span></div>
            <div>№ склада: {{sklad?sklad.label:''}}</div>
            <div>Основание: {{reason?reason.label:''}}</div>
            <table class="report outgo-list">
                <thead>
                    <tr>
                        <th>№<br>п/п</th><th>Номер<br>товара</th><th>Наименование<br>товара</th>
                        <th>Ед.<br>изм</th>
                        <th>Кол-во</th><th>Уп</th>
                        <th>Цена</th><th>Сумма</th><th>Вес</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,idx) in groupedList" :key="item.id" class="hover" :class="{'deleted': item.deleted}">
                        <td style="text-align: right;">{{idx+1}}</td>
                        <td style="text-align: center;">{{item.code}}</td>
                        <td>{{item.name}}</td>
                        <td style="text-align: center;">{{item.unit}}</td>
                        <td style="text-align: right;">{{item.amount}}</td>
                        <td style="text-align: right;">{{item.amountInbox}}</td>
                        <td class="currency">{{item.price|currency-null2}}</td>
                        <td class="currency">{{ item.sum|currency-null2 }}</td>
                        <td class="currency">{{parseFloat((item.weight*item.amount).toFixed(1))}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4" style="border:0;"></td>
                        <td style="text-align: right;">{{totalAmount}}</td>
                        <td colspan="3" style="text-align:right; font-weight: bold;">Итого: {{total()|currency-null}}</td>
                        <td style="text-align: right;">{{totalWeight}}</td>
                    </tr>
                </tfoot>
            </table>

            <div style="font-size: 90%; margin-top: 5pt;">
                {{new Date()|datetime}}
            </div>
            <div style="display: flex; justify-content: space-between; margin-top: 20pt;">
                <div>Отпустил ___________________</div>
                <div>Получил ___________________</div>
            </div>

        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import gql from "graphql-tag"
    import SendingMix from '../SendingMix.vue'
    import InOutMixin from './InOutMixin.vue'

    import {format, parse} from 'date-fns'
    import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
    import PartSelect from './PartSelect.vue'
    import TitleWithCheckModal from '../TitleWithCheckModal.vue'
    import CashIn from '@/components/CashIn/CashIn'

    const outgoQueryFields = `
        id 
        createDt
        updateDt
        date 
        reason{code:id label:name} 
        host{code:id label:name} 
        agent{id label:name} 
        answer{code:id label:name} 
        sklad{code:id label:name}
        outgoposSet{id amount price discount incomepos{id income{id} price tu{id nomer name inbox isWeight weight edizm}}}
        paymentSet{id amount}`

    export default {
        data: () => ({
                agentOptions: [],
                selectedItem: null,
                toPrintCheck: false,
                posState: null,
                showPayment: false,
                groupMode: true,
                createDt: null,
                updateDt: null,
            }
        ),
        mixins: [
            SendingMix, 
            InOutMixin, 
        ],
        components: {
            PartSelect, TitleWithCheckModal, CashIn,
        },
        watch: {
            host: function (val) { this.setCookie('outgoHost', val); },
            sklad: function (val) { this.setCookie('outgoSklad', val); },
            answer: function (val) { this.setCookie('outgoAnswer', val); },
            reason: function (val) { this.setCookie('outgoReason', val); },
        },
        computed: {
            totalWeight() { return parseFloat((this.list.reduce((prev, item)=> prev+item.weight*item.amount, 0)).toFixed(1))},
            totalAmount() { return this.list.reduce((prev, item)=> prev+parseFloat(item.amount), 0) },
            checkEmpty: function() { return !this.list.length },
            checkEditable() {
                return (this.itemId && !this.checkGroups(this.user, ['Расход изменение'])) 
                    || (this.itemId==null && !this.checkGroups(this.user, ['Расход создание']))
            },
            groupedList() { 
                return this.list.reduce((accum, e) => {
                    let finded = accum.find((x) => x.tuId == e.tuId);
                    if (finded){
                        finded.amount = Math.round((parseFloat(finded.amount) + parseFloat(e.amount)) * 1000)/1000;
                        finded.sum = Math.round((parseFloat(finded.sum) + parseFloat(e.sum)) * 100)/100;
                    } else {
                        accum.push( JSON.parse(JSON.stringify(e)));
                    }
                    return accum;
                }, [])
            },
        },
        mounted(){
            this.toPrintCheck = this.$cookies.get('toPrintCheck')=='true';
            //this.$refs.toPrintCheck.setChecked(this.toPrintCheck);
            //this.$refs.editMode.setChecked(this.editMode);
        },
        methods: {
            hostLoaded(data){ this.host = this.getCookie('outgoHost', data); },
            skladLoaded(data){ this.sklad = this.getCookie('outgoSklad', data); },
            answerLoaded(data){ this.answer = this.getCookie('outgoAnswer', data); },
            reasonLoaded(data){ this.reason = this.getCookie('outgoReason', data); },
            total() { return this.list.reduce((prev, item)=> prev+item.sum, 0)},
            closePartSelect(selected) {
                if (selected){
                    this.list.push.apply(this.list, selected)
                }
                this.selectedItem=null;
            },
            changeToPrintCheck(newvalue){
                console.log('changeToPrintCheck', newvalue);
                this.toPrintCheck = newvalue;
                this.toPrintCheck = this.$cookies.set('toPrintCheck', this.toPrintCheck);
            },
            itemSelect(item){
                this.selectedItem = item;
            },
            goPayment(){
                this.showPayment = true;
            },
            agentName(){
                if (this.agentOptions && this.agentOptions){
                    let f=this.agentOptions.find(e=>e.id==this.agentId)
                    if (f){
                        return f.label
                    }
                }
            },
            convertData(){
                return this.list.map(e=>{
                    return {
                        outgoposId: e.id,
                        incomeposId: e.incomeposId,
                        amount: e.amount,
                        price: e.price,
                        discount: e.discount,
                        deleting: e.deleted,
                    }
                });
            },
            remove(){
                this.sendingDelete=true;
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!) {
                        deleteOutgo(id: $id) { ok }
                    }`,
                    variables: {
                        id: this.itemId,
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    this.sendingDelete=false;
                    //this.data=null;
                    //this.list=Array();
                    //this.submitted=true;
                    //this.timerid = setTimeout(() => {
                    //    this.submitted=false;
                    //}, 3200);
                    this.$router.push('/outgo-list/');
                }).catch(error => {
                    this.sendingDelete=false;
                    this.$emit('error', error);
                });
            },
            save(){
                let ok=true
                this.list.forEach(e=>{
                    if (ok && e.amount>e.balance)
                        ok=false
                })
                if (!ok)
                    return
                this.sending=true;
                this.$apollo.mutate({
                    mutation: gql(`
                        mutation($id: ID, $date: Date!, $skladId: ID!, $data: [CreateOutgoItemType]!, 
                                 $agentId: ID!, $hostId: ID!, $reasonId: ID!, $answerId: ID!) {
                            createUpdateOutgo(id: $id, date: $date, skladId: $skladId, data: $data, agentId: $agentId, hostId: $hostId, 
                                              reasonId: $reasonId, answerId: $answerId) { 
                                outgo{` + outgoQueryFields + `} 
                            }
                        }`),
                    variables: {
                        id: this.itemId,
                        date: format(parse(this.incomeDate,"dd.MM.yyyy", new Date()),'yyyy-MM-dd'),
                        skladId: this.sklad.code,
                        data: this.convertData(),
                        agentId: this.agentId,
                        hostId: this.host?this.host.code:null,
                        reasonId: this.reason?this.reason.code:null,
                        answerId: this.answer ? this.answer.code : null,
                    },
                }).then(({data, loading}) => { // eslint-disable-line no-unused-vars
                    this.sending=false;
                    this.outgo=data.createUpdateOutgo.outgo;
                    this.parseAfterLoading(this.outgo)
                    this.submitted=true;
                    this.timerid = setTimeout(() => {
                        this.submitted=false;
                        this.$router.push('/outgo/' + data.createUpdateOutgo.outgo.id)
                    }, 3200);
                    if (this.toPrintCheck)
                        this.printCheck()
                }).catch(error => {
                    this.sending=false;
                    this.$emit('error', JSON.stringify(error.message));
                    
                });
            },
            loadOptions: _.debounce(function({ action, searchQuery, callback }) {
                    if (action === ASYNC_SEARCH) {
                        this.q_debounced = searchQuery;
                        this.callback = callback;
                    }
                }, 500),
            tuListNormalizer(node) {
                const priceItem = node.tu.priceSet.find(e=>e.pricetype.id==5)
                return {
                    id: node.tu.id,
                    label: [
                        node.tu.name, 
                        priceItem?priceItem.price: null, 
                        parseFloat(node.balance), //node.incomePos.reduce((a,b)=> +a +b.balance, 0),
                    ],
                    children: node.children,
                }
            },
            fillStates(obj){
                this.posState = obj.state
                this.posStateStatus = obj.stateStatus
                this.posSubState = obj.subState
            },
            parseAfterLoading(outgo){
                this.incomeDate = format(parse(outgo.date,'yyyy-MM-dd', new Date()), "dd.MM.yyyy") 
                this.createDt = outgo.createDt;
                this.updateDt = outgo.updateDt;
                this.agentOptions.push({
                    id: outgo.agent.id, 
                    label: outgo.agent.name || outgo.agent.label,
                });
                this.agent = outgo.agent;
                this.agentId = outgo.agent.id;
                this.initOptions = [outgo.agent]
                this.host = outgo.host //this.hosts.find(e=>e.code==outgo.host.id);
                this.reason = outgo.reason // this.reasons.find(e=>e.code==outgo.reason.id);
                this.answer = outgo.answer // this.answers.find(e=>e.code==outgo.answer.id);
                this.sklad = outgo.sklad // this.sklads.find(e=>e.code==outgo.sklad.id);
                this.list=[]
                outgo.outgoposSet.forEach(e=>{
                    //if (e.outgo.state<2){
                        this.list.push(
                            {
                                id: e.id,
                                incomeposId: e.incomepos.id,
                                incomeId: e.incomepos.income.id,
                                tuId: e.incomepos.tu.id,
                                inbox: e.incomepos.tu.inbox,
                                name: e.incomepos.tu.name,
                                code: e.incomepos.tu.nomer,
                                unit: e.incomepos.tu.edizm,
                                weight: e.incomepos.tu.weight,
                                isWeight: e.incomepos.tu.isWeight,
                                amount: e.amount,
                                amountInbox: (!e.incomepos.tu.isWeight && e.incomepos.tu.inbox && e.amount%e.incomepos.tu.inbox==0)?e.amount/e.incomepos.tu.inbox:null,
                                price: e.price,
                                discount: e.discount,
                                incomePosPrice: e.incomepos.price,
                                sum: ( Math.round((e.price * e.amount + Number.EPSILON) * 100) / 100) * (e.discount?(1-e.discount/100):1),
                                //sum: ( e.price * e.amount ) * (e.discount?(1-e.discount/100):1),
                                deleted: false,
                            }
                        );
                    //}
                });
            },
            async printCheck(){
                try{
                    await this.pos.openCheck(0);
                    //this.fillStates(obj)
                    for (let i of this.list){
                        await this.pos.income(i.amount, i.price, i.name);
                        //this.fillStates(obj)
                    }
                    //obj=new CloseCheck(this.total())
                    await this.pos.pay(0, this.total(), null);
                    await this.pos.closeCheck(this.total());
                } catch (e){
                    console.log(e)
                }
            },
        },
        apollo: {
            outgo: {
                query: gql(`
                    query($id: ID){
                        outgo(id: $id) {
                            ` + outgoQueryFields + `
                        }
                    }`),
                variables() {
                    return {
                        id: this.itemId,
                    }
                },
                skip(){
                    return !this.itemId || !this.user || !this.checkGroups(this.user, ['Расход изменение', 'Расход создание', 'Расход просмотр']);
                },
                result ({ data, loading }) {
                    if (!loading) {
                        if (data && data.outgo)
                            this.parseAfterLoading(data.outgo);
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },

            },
            tuOutgo: {
                query: gql`
                    query($skladId: ID, $q: String, $barcode: String) {
                        tuOutgo(skladId: $skladId, q: $q, barcode: $barcode) {
                            tu{
                                id name nomer inbox isWeight priceSet{
                                    pricetype{id} price
                                }
                            }
                            balance
                        }
                    }`,  /* incomePos {
                                id amount price income{date}
                                outgoposSet{amount outgo{state}}
                            }
*/
                variables() {
                    return {
                        q: this.q_debounced,
                        skladId: this.sklad.code,
                        barcode: this.scannedBarcode,
                    }
                },
                skip(){
                    return !this.sklad || !this.sklad.code || (!this.q_debounced && !this.scannedBarcode);
                },
                result ({ data, loading }) {
                    if (!loading) {
                        if (this.scannedBarcode){
                            if (data.tuOutgo.length==1){
                                this.itemSelect(data.tuOutgo[0])
                            } else {
                                this.notFound=true;
                            }
                            this.callback=null;
                        } else {
                            if (this.callback){
                                this.callback(null, data.tuOutgo);
                                this.callback = null;
                            }
                        }
                        //this.scannedBarcode = null;
                    }
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
            /*
            partList: {
                query: gql`
                    query($tuId: ID!) {
                        partList(tuId: $tuId) {
                            id date balance
                        }
                    }`,
                variables() {
                    return {
                        tuId: this.selectedItem.id,
                    }
                },
                skip() { return this.selectedItem==null || this.selectedItem.id==null },
                result ({ data, loading }) {
                    if (!loading) {
                        data.partList.forEach(e => {
                            e['order'] = 0;
                        });

                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },*/
        }
    }
</script>

<style lang="scss" scoped>
    .print-only{
        // display: block;
    }
    .print-only .outgo-list {
        font-size: 8pt;
        width: 100%;
        td, th {
            border-collapse: none;
            border: 1px solid #000 !important;
            font-weight: normal;
            padding: 0.8pt;
        }
    }
    .x-input{
        min-width: 280px;
    }

    .tu-items {
        .vue-treeselect__multi-value-item-container{
            display: none;
        }
        .vue-treeselect__label{
            display: flex;
            align-items: center;
            .name {
                width: calc( 50% - 4px);
                padding-right: 4px;
            }
            .price {
                width: calc( 25% - 4px );
                padding-right: 4px;
                text-align: right;
            }
            .balance {
                width: 25%;
                text-align: right;
            }
        }
        .vue-treeselect__single-value{
            label{
                display: flex;
                align-items: center;
                .name {
                    width: calc( 50% - 4px);
                    padding-right: 4px;
                }
                .price {
                    width: calc( 25% - 4px );
                    padding-right: 4px;
                    text-align: right;
                }
                .balance {
                    width: 25%;
                    text-align: right;
                }
            }
        }
    }

</style>