<template>
    <div class="container">
        <section class="section" v-if="user && user.isAnonymous && !unsuccesfull">
            <h1 class="title">Вход</h1>
            <form>
                <div class="field" style="max-width: 320px;">
                    <label class="label">Email или телефон:</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="name@mail.ru или +7..." v-model="login" @keyup.enter="flogin" autocomplete="username">
                    </div>
                </div>

                <div class="field" style="max-width: 320px;">
                    <label class="label">Пароль:</label>
                    <div class="control">
                        <input class="input" type="password" v-model="password" @keyup.enter="flogin" autocomplete="current-password">
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button @click="flogin" class="button is-primary" :class="{'is-loading': logging}" type="button">Войти</button>
                    </div>
                </div>
            </form>
            <p style="margin-top: 3rem;">Либо отсканируйте qr-код кассира</p>

        </section>
        <section class="section" v-if="unsuccesfull">
            Ошибка. Не правильно указан логин или пароль. <a @click="unsuccesfull=false;">Повторите вход</a>
        </section>

        <section class="section" v-if="user && !user.isAnonymous">
            <article class="message is-success">
                <div class="message-body">
                    <p>Здравствуйте, {{user.name}}! Вход успешно завершен.</p>
                    <p style="margin: 1rem 0;">
                        <a href="#/store">Перейти в каталог</a>
                    </p>
                    <p style="margin: 1rem 0;" v-if="checkGroups(user, ['Касса'])">
                        <a href="/pos.html">В кассу</a>
                    </p>
                    <!--p style="margin: .5rem 0;">
                        <a href="/profile.html">Личный кабинет</a>
                    </p-->
                    <p v-if="user && user.isStaff" style="margin: 1rem 0;">
                        <a href="/admin/">Администрирование</a>
                    </p>
                    <p style="margin: 1rem 0;">
                        <span class="action" @click="$emit('logout')">Выйти</span>
                    </p>
                </div>
            </article>
        </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import userGql from "../common"
    const LOGIN= gql(`
        mutation($username: String!, $password: String!) {
            login(username: $username, password: $password) { 
                ` + userGql + `
             }
        }`);
    export default {
        name: 'Auth',
        components: {
        },
        props:{
            user: Object,
        },
        data: function(){
            return {
                login: null,
                password: null,
                logging: false,
                unsuccesfull: false,
            }
        },
        methods: {
            flogin(){
                this.logging=true;
                this.$apollo.mutate({
                    mutation: LOGIN,
                    variables: {
                        "username": this.login, 
                        "password": this.password,
                    },
                }).then(({data}) => {
                    this.logging=false;
                    if (data.login.user!=null){
                        this.$emit('newUser', data.login.user);
                    } else {
                        this.unsuccesfull = true
                    }
                    this.login=null;
                    this.password=null;
                }).catch(error => {
                    this.$emit('error', JSON.stringify(error.message));
                    this.logging=false;
                });
            },
        }
    }
</script>
