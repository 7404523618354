<template>
    <div class="container">
        <h1>Приход # {{itemId}}</h1>
        <table class="report stripped" :class="{'loading': $apollo.queries.incomePos.loading}">
            <tr>
                <th>#</th>
                <th>Арт</th>
                <th>Наименование</th>
                <th>Кол-во</th>
                <th>Уп.</th>
                <th>Цена</th>
            </tr>
            <tr v-for="(i, index) in incomePos?incomePos:[]" :key="i.id" class="hover">
                <td>{{index + 1}}</td>
                <td>{{i.tu.nomer}}</td>
                <td><router-link :to="'/dict/tu/'+i.tu.id">{{i.tu.name}}</router-link></td>
                <td style="text-align: right;">{{i.amount}}</td>
                <td style="text-align: center;">
                    <span v-if="i.tu.inbox>0 && i.amount%i.tu.inbox==0">
                        {{i.amount/i.tu.inbox}}
                    </span>
                </td>
                <td style="text-align: right;">{{i.price|currency}}</td>
            </tr>
            <tfoot style="font-weight: bold;">
                <tr>
                    <td colspan="3"></td>
                    <td colspan="2" style="text-align: right;">Итого:</td>
                    <td>{{total|currency}}</td>
                </tr>
            </tfoot>
        </table>
        <div class="field" style="margin-bottom: 10em;">
            <button class="button" @click="printTickets">Печать ценников</button>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    export default {
        props: {
            itemId: String,
        },
        data() {
            return {
                incomePos: null,
            }
        },
        computed:{
            total() { return this.incomePos?this.incomePos.reduce((prev, item)=> prev+(item.amount*item.price), 0):null },
        },
        methods:{
            printTickets(){
                let arr=[];
                this.incomePos.forEach(e=>{
                    arr.push(e.tu.id);
                });
                this.$router.push(
                    {
                        name: 'print-ticket',
                        query: {
                            idList: arr,
                        }
                    });
            },
        },
        apollo: {
            incomePos: {
                query: gql`
                    query($id: ID!){
                        incomePos(id: $id) {
                            id amount price tu{id name nomer inbox} realizationPrice
                        }
                    }`,
                variables() {
                    return {
                        id: this.itemId,
                    }
                },
                //result ({ data, loading }) {
                //    if (!loading) {
                //    }
                //},
                errorPolicy: 'all',
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    .report .total {
        text-align: right;
        white-space: nowrap;
    }
</style>