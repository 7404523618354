<template>
    <div class="container">
        <section class="section" v-if="!checkIsAnonymous(user)">
            <!-- v-if="checkGroups(user, ['Оптовый клиент', 'Полный прайслист', 'Продавец СБИС', 'Продавец', ])" -->
            <h2>Новинки</h2>
            <div class="tu" style="margin-top: 2rem;">
                <TuListGrid
                    ref="tulist"
                    :tu="tu?tu.items:[]"
                    :user="user"
                    @modcart="modcart"
                    :loading="$apollo.queries.tu.loading"
                    :type="'novelty'"
                    @removeItem="removeItem"
                    />
            </div>
        </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import TuListGrid from './Store/TuListGrid.vue'
    import CartMixin from './Store/CartMixin'
    export default {
        name: 'Index',
        mixins: [CartMixin, ],
        components: {
            TuListGrid,
        },
        props:{
            user: Object,
        },
        data: function(){
            return {
                error: null,
            }
        },
        methods: {
            removeItem(item){
                this.tu.items.splice(this.tu.items.findIndex(el => el==item), 1);
            }
        },
        apollo: {
            tu: {
                query: gql`
                    query {
                        tu(search: "novelty", branchId: 0, availability: true, offset: 0, withPhoto: "with-photo") {
                            items {
                                id
                                name
                                goodsId
                                quantity
                                price
                                balance
                                isWeight
                                inbox
                                quantityType
                                img
                                images {
                                    id
                                    img
                                }
                                packOrderOnly
                            }
                            count
                        }
                    }`,
                skip(){
                    return !this.user;
                },    
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        },
    }
</script>
