<template>
    <div class="container" v-if="checkGroups(user, ['Заказ фруктов', 'Продавец', 'Продавец СБИС'])">
        <section class="section">
            <h1>Заказ фруктов/овощей</h1>

            <div>
                <div style="margin: 1.5rem 0;">
                    <router-link to="/fruits-confirm" class="button is-primary">
                        Перейти к подтверждению заказа</router-link>
                </div>


                <div class="field" v-if="checkGroup(user,'operator')">
                    <label class="label">Матрица:</label>
                    <div class="select">
                        <select v-model="matrixId">
                            <option value="1">Магазины</option>
                            <option value="2">Рестораны</option>
                        </select>
                    </div>
                </div>
                <p class="control is-expanded">
                    <input placeholder="Поиск" class="input" type="text" v-model="search">
                </p>


                <div class="tu" style="margin-top: 2rem;">
                    <tu-list-grid
                        ref="tulist"
                        :tu="fruits?filter(fruits):[]"
                        :user="user"
                        @modcart="modcart"
                        :loading="$apollo.queries.fruits.loading"
                        />
                </div>
            </div>

        </section>
    </div>
    <section class="container section" v-else-if="user">
        Недостаточно прав для данного раздела
    </section>
</template>

<script>
    import gql from "graphql-tag"
    import TuListGrid from './List.vue'
    //import EditItem from '@/components/EditItem.vue'
    //import {format} from 'date-fns'
    //import _ from 'lodash'
    import CartMixin from '../Store/CartMixin.vue'

    export default {
        name: 'FastOrder',
        components: {
            TuListGrid, // EditItem,
        },
        props: [
            'user',
            'category',
            'showmissing',
            'ppage',
            'q',
            ],
        mixins: [CartMixin],
        data: function(){
            return {
                itemModal: false,
                bread: null,
                item: null,
                error: null,
                date: null,
                intervalid1: null,
                matrixId: null,
                search: null,
            }
        },
        created(){
            this.date=new Date();
            if (this.intervalid1){
                clearInterval(this.intervalid1);
            }
            this.intervalid1 = setInterval(() => {
                this.date=new Date();
            }, 1000);
        },
        methods: {
            getCartName(){
                return 'FRUIT-CART-ID';
            },
            user_fetched(){
            },
            tu_refetch(){
            },
            filter(){
                if (!this.search || this.search.trim()=='')
                    return this.fruits;
                let regex = new RegExp(this.search.trim(), "i");
                return this.fruits.filter(e=> regex.test(e.name))
            }
        },
        apollo: {
            fruits: {
                query: gql`
                    query ($matrixId: ID) {
                        fruits(matrixId: $matrixId) {
                            id name goodsId quantity price balance isWeight inbox quantityType img
                            images {id img} measures{id name}
                        }
                    }`,
                variables() {
                    return {
                        matrixId: this.matrixId,
                    }
                },
                skip() {
                    return this.user == null || this.user.isAnonymous
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
                result({data, loading}){
                    data.fruits.forEach(e=>{
                        if (e.quantityType==null){
                            let find=e.measures.findIndex(e=>e.id=="5"); // Кг
                            if (find>=0){
                                e.quantityType=e.measures[find].id
                            } else{
                                e.quantityType=e.measures[0].id
                            }
                        }
                    })
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 150%;
    }
</style>
