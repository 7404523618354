<template>
    <div>
        <label class="label">Основание:</label>
        <v-select :options="reasons" v-model="selected" @input="onInput" />
    </div>
</template>

<script>
    import ApolloReasonMixin from '../Warehouse/ApolloReasonMixin';

    export default {
        name: "ReasonSelect",
        props:{
            value: {
                default: null,
            },
            user: Object,
        },
        mixins: [ApolloReasonMixin, ],
        data(){
            return{
                selected: null,
            }
        },
        watch: {
            value(v) {
                if(!this.selected || this.selected.code!=v){
                    this.setSelected();
                    this.onInput(this.selected);
                } 
            },
        },
        methods:{
            onInput(item) {
                this.$emit('input', item? item.code: null);
            },
            onUpdate(){
                //cacsh = null
                this.items = [];
                this.inited = false;
            },
            setSelected() {
                this.selected = this.reasons.find(x => x.code == this.value) || null;
            },
            reasonLoaded(data){
                this.setSelected();
                this.$forceUpdate();
            },
        },
    }
</script>
